import { graphql } from '../gql';

export default graphql(`query getRaceByTrack(
    $profile: String!
    $trackCode: String!
    $raceNumber: Int!
  ) {
    HR_Track(profile: $profile, code: $trackCode) {
      code
      name
      trackDataSource
      races(pagesByField: { raceNumber: $raceNumber, results: 1 }) {
        number
        status {
          code
        }
        postTime
        numRunners
        type {
          name
        }
        distance
        raceClass {
          name
        }
        surface {
          name
          defaultCondition
        }
        purse
        timeform {
          analystVerdict
        }
        bettingInterests {
          saddleColor
          numberColor
          favorite
          currentOdds {
            numerator
            denominator
          }
          runners {
            jockey
            trainer
            timeform {
              silkUrl
              silkUrlSvg
            }
            runnerId
            horseName
            scratched
            handicapping {
              freePick {
                number
                info
              }
              snapshot {
                powerRating
                normalisedPowerRating
              }
              speedAndClass {
                highSpeed
                normalisedSpeedRating
              }
            }
            timeform {
              freePick {
                number
                info
              }
            }
          }
        }
      }
    }
  }`);
