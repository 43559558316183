import { graphql } from '../../gql/gql';

const getContentFilters = graphql(`
  query GetContentFilters {
    getContentFilters {
      shortFormNewsType {
        enum
        name
      }
    }
  }
`);

export default getContentFilters;
