import { graphql } from '../gql/gql';

const getPlayerNewsSports = graphql(`
query GetPlayerNewsSports($isPreview: Boolean, $featured: Boolean) {
  getSupportedSports(isPreview: $isPreview, featured: $featured) {
      name
      hasLiveScoring
      hasPlayerUpdates
      showAuthors
      contentSport
      slug
      seo {
        description
        canonicalUrl
        title
        image {
          url
        }
        noIndex
      }
    }
  }
`);

export default getPlayerNewsSports;
