import { graphql } from '../gql';

const getAuthorsQuery = graphql(`
query getAuthors(
  $input: AuthorSearchInput
  $contentType: Type
  $sport: ContentSportsEnum
) {
  getAuthors(input: $input) {
    pageInfo {
      hasNextPage
      cursor
    }
    authors {
      entity {
        authoredContent(contentType: $contentType, sport: $sport) {
          slug
          title
          id
          modifiedDate
          ... on Article {
            sport {
              name
              slug
            }
            thumbnailImageUrl {
              url
            }
          }
        }
        id
        name
        thumbnailUrl
        socialMedia {
          url
          accountName
          type
        }
      }
    }
  }
}

`);

export default getAuthorsQuery;
