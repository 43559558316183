import { fanduel as fd } from "@fanduel/formation-tokens";
import {
  ArrowsChevronUp,
  ArrowsChevronDown,
} from "@fanduel/formation-react-icons";
import { LinkBehaviour, NestedLink } from "@/graphql/gql/graphql";
import Image from "@/components/Image";
import { filterEmpty } from "@/utils/index";
import Link from "next/link";
import { NAV_LINK_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import ampAppendSbkUrl from "@/utils/amplitude";
import cx from "classnames";
import Styles from "./MobileMenuItem.module.scss";

type MobileMenuItemProps = NestedLink & {
  setActive: (sport: string) => void;
  activeSport?: string;
  index: number;
};

const MobileMenuItem = ({
  text,
  iconUrl,
  children,
  setActive,
  activeSport,
  index,
}: MobileMenuItemProps) => {
  const { logAmplitudeEvent, sessionId, deviceId } = useAmplitude();
  return (
    <li className={Styles.container}>
      <div
        onKeyDown={() => {}}
        role="button"
        tabIndex={index}
        onClick={() => setActive(activeSport === text ? "" : text)}
      >
        <Image
          alt={`${text} icon`}
          src={iconUrl ?? ""}
          height={16}
          width={16}
        />
        {text}
        <div className={Styles.arrowContainer}>
          {activeSport === text ? (
            <ArrowsChevronUp color={fd.colors.coreBlueD1} />
          ) : (
            <ArrowsChevronDown color={fd.colors.coreBlueD1} />
          )}
        </div>
      </div>
      <ul
        className={cx(
          Styles.submenu,
          activeSport === text ? Styles.active : ""
        )}
      >
        {filterEmpty(children ?? []).map((child) => {
          const childUrl = ampAppendSbkUrl(child.url, sessionId, deviceId);

          return (
            <li key={child.text}>
              <Link
                target={
                  child?.behaviour === LinkBehaviour.NewWindow
                    ? "_blank"
                    : "_self"
                }
                href={childUrl}
                onClick={() => {
                  logAmplitudeEvent(NAV_LINK_CLICKED, {
                    "Link Text": child.text,
                    "Link URL": childUrl,
                    Module: "navigation_menu",
                    Menu: activeSport ?? "",
                  });
                }}
              >
                {child.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default MobileMenuItem;
