import { graphql } from '../gql/gql';

export default graphql(`query GetProjections($input: ProjectionsInput!) {
  getProjections(input: $input) {
    ... on MlbPitcher {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      winsLosses
      salary
      value
      wins
      losses
      earnedRunsAvg
      gamesStarted
      saves
      inningsPitched
      hits
      runs
      earnedRuns
      homeRuns
      walks
      strikeouts
      walksPlusHitsPerInningsPitched
      gamesPlayed
      fantasy
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
    }
    ... on MlbBatter {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      salary
      value
      plateAppearances
      runs
      hits
      singles
      doubles
      triples
      homeRuns
      runsBattedIn
      stolenBases
      caughtStealing
      walks
      strikeouts
      battingAverage
      onBasePercentage
      sluggingPercentage
      onBasePlusSlugging
      fantasy
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
    }
    ... on NflSkill {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
      salary
      value
      completionsAttempts
      passingYards
      passingTouchdowns
      interceptionsThrown
      rushingAttempts
      rushingYards
      rushingTouchdowns
      receptions
      targets
      receivingYards
      receivingTouchdowns
      fantasy
      positionRank
      overallRank
      opponentDefensiveRank
    }
    ... on NflKicker {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
      salary
      value
      extraPointsAttempted
      extraPointsMade
      fieldGoalsAttempted
      fieldGoalsMade
      fieldGoalsMade0To19
      fieldGoalsMade20To29
      fieldGoalsMade30To39
      fieldGoalsMade40To49
      fieldGoalsMade50Plus
      fantasy
      positionRank
      opponentDefensiveRank
    }
    ... on NflDefenseSt {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
      salary
      value
      pointsAllowed
      yardsAllowed
      sacks
      interceptions
      fumblesRecovered
      touchdowns
      fantasy
      positionRank
      opponentOffensiveRank
    }
    ... on NflDefensePlayer {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
      tackles
      sacks
      interceptions
      touchdowns
      passesDefended
      fumblesRecovered
      opponentOffensiveRank
    }
    ... on NhlSkater {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      minutesPlayed
      salary
      value
      shots
      goals
      assists
      points
      powerPlayGoals
      powerPlayAssists
      plusMinus
      blockedShots
      penaltiesInMinutes
      fantasy
      timeOnIce
      avgTimeOnIce
      gamesPlayed
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
    }
    ... on NhlGoalie {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      minutesPlayed
      salary
      value
      goalsAgainst
      shotsAgainst
      saves
      shutouts
      wins
      losses
      savePercent
      timeOnIce
      tiesPlusOvertimeOrShootoutLosses
      fantasy
      gamesPlayed
      goalsAgainstAvg
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
    }
    ... on NbaPlayer {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      team {
        numberFireId
        name
        imageUrl
        abbreviation
      }
      gameInfo {
        homeTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        awayTeam {
          numberFireId
          name
          imageUrl
          abbreviation
        }
        gameTime
      }
      salary
      value
      minutes
      fieldGoalsMade
      fieldGoalsAttempted
      threePointsMade
      threePointsAttempted
      freeThrowsMade
      freeThrowsAttempted
      assists
      steals
      blocks
      turnovers
      points
      rebounds
      gamesPlayed
      fieldGoalShootingPercentage
      threePointsShootingPercentage
      freeThrowShootingPercentage
      fantasy
      positionRank
      overallRank
    }
    ... on GolfPlayer {
      player {
        numberFireId
        name
        position
        playerPageUrl
        imageUrl
        handedness
      }
      fantasy
      salary
      value
      score
      madeCut
      first
      topFive
      topTen
      topTwentyFive
      eagles
      birdies
      pars
      bogeys
      doubleBogeys
    }
  }
}`);
