import { graphql } from '../gql';

const getAllTentpoleEvents = graphql(`query GetTentpoleEvents{
    getTentpoleEvents {
      slug
      sport{
        slug
      }
    }
  }`);

export default getAllTentpoleEvents;
