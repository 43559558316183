import { graphql } from '../gql';

const getShortFormsQuery = graphql(`query getPlayerNewsQuery($filter: ShortFormSearchInput!) {
  getShortForms(filter: $filter) {
    pageInfo {
      cursor
      hasNextPage
    }
    shortForms {
      cursor
      entity {
        id
        slug
        title
        fact
        analysis
        quant
        newsType {
          name
          enum
        }
        sport {
          id
          name
          logo {
            url
          }
          slug
          contentSport
        }
        primaryRef {
          ... on Player {
            identifier
            name
            number
            position
            image {
              url
            }
            team {
              teamJerseyImage {
                url
              }
              name
              image {
                url
              }
              sportsbookLink
              abbreviation
            }
          }
          ... on Team {
            name
            abbreviation
            numberFireId
            sportsbookLink
            image {
              url
            }
            teamJerseyImage {
              url
            }
          }
        }
        author {
          id
          name
          thumbnailUrl
          socialMedia {
            type
            accountName
            url
          }
        }
        firstPublishedAt
        lastPublishedAt
        description
        attribution {
          name
          link
        }
      }
    }
  }
}
`);

export default getShortFormsQuery;
