import logger from "@/utils/logger";
import React, { ErrorInfo } from "react";
import ErrorComponent from "@/components/empty/ErrorComponent";
import ErrorContainer from "@/components/common/ErrorContainer";

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error("Uncaught error:", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorContainer>
          <ErrorComponent
            headline="Content failed to load"
            subtext="Please check back later."
            ctaButtonLabel="Refresh module"
            ctaButtonOnClick={() => {
              this.setState({ hasError: false });
              window.location.reload();
            }}
          />
        </ErrorContainer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
