export { API_PREVIEW, API_EXIT_PREVIEW } from './routes';
export { SEO } from './seo';
export { HELP_MESSAGE } from './projections';

export namespace FOOTER_URLS {
  export const FANDUEL_SUPPORT_URL = 'https://www.fanduel.com/support';
  export const FANDUEL_RG_URL = 'https://www.fanduel.com/RG';
  export const GAMBLING_HELPLINE_MA_URL = 'https://www.GamblingHelpLineMA.org';
  export const KANSAS_GAMBLING_HELP_URL = 'https://www.ksgamblinghelp.com';
  export const CCPG_URL = 'https://www.ccpg.org/chat';
  export const MD_GAMBLING_HELP_URL = 'https://www.mdgamblinghelp.org';
  export const GAMBLER_NET_URL = 'https://www.1800gambler.net';

}

export namespace FOOTER_LABELS {
  export const FANDUEL_SUPPORT_LABEL = 'FanDuel.com/RG';
  export const FANDUEL_RG_LABEL = 'http://FanDuel.com/RG ';
  export const GAMBLING_HELPLINE_MA_LABEL = 'GamblingHelpLineMA.org ';
  export const KANSAS_GAMBLING_HELP_LABEL = 'http://ksgamblinghelp.com';
  export const CCPG_LABEL = 'ccpg.org/chat';
  export const MD_GAMBLING_HELP_LABEL = 'www.mdgamblinghelp.org';
  export const GAMBLER_NET_LABEL = 'www.1800gambler.net';

}
