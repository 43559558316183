import { isMobile } from "react-device-detect";
import { Button } from "@fanduel/formation-react-components";
import { ArrowsChevronRight } from "@fanduel/formation-react-icons";
import { Loader } from "@fanduel/og-fe-library";
import Link from "next/link";
import useSiteSettings from "@/utils/hooks/useSiteSettings";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import {
  SPORTSBOOK_PROMO_BANNER_CLICKED,
  SPORTSBOOK_APP_DOWNLOAD_CLICKED,
} from "@/constants/amplitude";
import { SbkPromoBanner } from "@/graphql/gql/graphql";
import Styles from "./SportsbookPromo.module.scss";
import ChevronsTexture from "../svg/promoBanner/ChevronsTexture";
import ChevronsTextureMobile from "../svg/promoBanner/ChevronsTextureMobile";
import SportsBookLogo from "../svg/promoBanner/SportsBookLogo";
import PlayerImg from "../svg/promoBanner/PlayerImg";

const SportsbookPromo = () => {
  const { data, isLoading, isError } = useSiteSettings();
  const { logAmplitudeEvent } = useAmplitude();

  if (isLoading) {
    return <Loader className={Styles.loading} />;
  }

  if (isError || !data?.getSiteSettings?.sbkPromoBanner) return null;

  const {
    hidePromoBanner,
    desktopButtonText,
    desktopLink,
    mobileButtonText,
    mobileLink,
  } = data.getSiteSettings.sbkPromoBanner as SbkPromoBanner;

  if (hidePromoBanner) {
    return null;
  }

  const linkText = isMobile
    ? (mobileButtonText?.toLowerCase().replace(/\s+/g, "_") ?? "")
    : (desktopButtonText?.toLowerCase().replace(/\s+/g, "_") ?? "");

  const linkUrl = isMobile ? (mobileLink ?? "") : (desktopLink ?? "");

  const ampProps = {
    "Link Text": linkText,
    "Link URL": linkUrl,
    Module: "header_ribbon",
  };

  const handlePromoClick = () => {
    const eventType = isMobile
      ? SPORTSBOOK_APP_DOWNLOAD_CLICKED
      : SPORTSBOOK_PROMO_BANNER_CLICKED;
    logAmplitudeEvent(eventType, ampProps);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <h1>
          START YOUR OWN&nbsp;
          <span className={Styles.winning}>WINNING</span>
          &nbsp;STREAK
        </h1>
        <div className={Styles.playerImg}>
          <PlayerImg />
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles.desktopLogo}>
          <SportsBookLogo />
        </div>
        <Link href={linkUrl}>
          <Button
            label={
              isMobile ? (mobileButtonText ?? "") : (desktopButtonText ?? "")
            }
            size="small"
            iconRight={<ArrowsChevronRight />}
            onClick={handlePromoClick}
          />
        </Link>
      </div>
      <div className={Styles.chevrons}>
        <div className={Styles.chevronsDesktop}>
          <ChevronsTexture />
        </div>
        <div className={Styles.chevronsMobile}>
          <ChevronsTextureMobile />
        </div>
      </div>
    </div>
  );
};

export default SportsbookPromo;
