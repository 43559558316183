import { graphql } from '../gql';

const getActiveFixturesQuery = graphql(`
query GetActiveFixtures($input: FixtureSearchInput){
  getActiveFixtures(input: $input){
    id
    scheduledStartTime
    currentGameTime
    scheduledEndTime
    gameStatus
    eventUrl
    tvNetwork {
      name
    }
    sport{
      name
    }
    teams{
      currentScore
      moneyLine
      pointSpread
      winProbability
      fixtureMoneyLine{
        odds
        link
      }
      fixtureSpread{
        odds
        value
        link
      }
      fixtureTotal{
        odds
        value
        link
      }
      team{
        abbreviation
        name
        primaryColor
        winCount
        loseCount
        drawCount
        sportsbookLink
        image {
          url
        }
      }
    }
  }
}`);

export default getActiveFixturesQuery;
