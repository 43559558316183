import { graphql } from '../gql/gql';

const getPlayerNewsHome = graphql(`
query GetPlayerNewsHome($filter: ShortFormSearchInput!) {
  getShortForms(filter: $filter) {
    shortForms {
      entity {
        id
        title
        publishedDate
        slug
        newsType {
          name
          enum
        }
        sport {
          name
          slug
        }
        primaryRef {
          ... on Player {
            name
            identifier
            type
            image {
              url
            }
            team {
              name
              image {
                url
              }
              teamJerseyImage {
                url
              }
            }
          }
          ... on Team {
            name
            identifier
            type
            image {
              url
            }
            teamJerseyImage {
              url
            }
          }
        }
        attribution {
          name
          link
        }
      }
    }
  }
}
`);

export default getPlayerNewsHome;
