import { graphql } from '../gql';

const getProjectionMetaDataQuery = graphql(`query GetProjectionTableMetadata($input: ProjectionTableMetadataInput) {
  getProjectionTableMetadata(input: $input) {
    name
    sport{
      name
      slug
    }
    attributes {
      key
      value
    }
    slug
    id
    positionFilter {
      label
      slug
      value
      positionGroup
      isDefaultPosition
    }
    columns {
      label
      positions
      columnHeaders {
        label
        columns {
          label
          value
          description
        }
      }
    }
    sortOrder
  }
}`);
export default getProjectionMetaDataQuery;
