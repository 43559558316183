import { graphql } from '../gql';

const getTentpoleEventQuery = graphql(`query GetTentpoleEvent($input: TentpoleEventInput!) {
  getTentpoleEvent(input: $input) {
    title
    sport {
      name
      logo {
        url
      }
      slug
    }
    slug
    subHeading
    description
    image
    heroHeight
    menu {
      label
      target
    }
    seo {
      title
      description
    }
    pageContent {
      ... on ImageBanner {
        __typename
        mainImage
        mobileImage
        link
        target
      }
      ... on ScrollableOdds {
        __typename
        markets {
          type
          title
          market
          info
          bottomLinkText
          bottomLinkUrl
        }
        icon
        title
        target
      }
      ... on Faq {
        __typename
        questions {
          questionText
          answer
        }
        icon
        title
        target
      }
      ... on Scoreboard {
        __typename
        icon
        title
        target
        tabs {
          label
          value
        }
      }
      ... on TextBanner {
        __typename
        title
        mainImage
        icon
        linkText
        link
        target
      }
      ... on PlayerOddsTabs {
        __typename
        icon
        title
        target
        cornerLinkText
        cornerLinkUrl
        bettingTabs {
          title
          market
        }
      }
      ... on ImageShowcase {
        __typename
        title
        cornerLinkText
        cornerLinkUrl
        icon
        link
        mainImage
        tabletImage
        mobileImage
        target
        type
      }
      ... on FeaturedSport {
        __typename
        type
        icon
        title
        target
        articles {
          id
          slug
          title
          thumbnailImageUrl {
            url
          }
          author {
            id
            name
            thumbnailUrl
            socialMedia {
              type
              accountName
              url
            }
          }
          publishedDate
          modifiedDate
          firstPublishedAt
          lastPublishedAt
          subHeading
          description
          hero {
            imageUrl {
              url
            }
          }
        }
      }
      ... on TaggedArticles {
        __typename
        type
        target
        icon
        title
        cornerLinkText
        cornerLinkUrl
        tag
        articles {
          id
          slug
          title
          hero {
            imageUrl {
              url
            }
          }
          thumbnailImageUrl {
            url
          }
          author {
            id
            name
            thumbnailUrl
            socialMedia {
              accountName
              type
              url
            }
          }
          publishedDate
          modifiedDate
          firstPublishedAt
          lastPublishedAt
          subHeading
          description
        }
      }
      ... on Promos_TaggedArticles {
        __typename
        title
        taggedArticles {
          __typename
          type
          tag
          target
          icon
          title
          cornerLinkText
          cornerLinkUrl
          articles {
            id
            slug
            title
            hero {
              imageUrl {
                url
              }
            }
            thumbnailImageUrl {
              url
            }
            author {
              id
              name
              thumbnailUrl
              socialMedia {
                accountName
                type
                url
              }
            }
            publishedDate
            modifiedDate
            firstPublishedAt
            lastPublishedAt
            subHeading
            description
          }
        }
        promos {
          __typename
          cornerLinkText
          cornerLinkUrl
          icon
          promos {
            buttonOne {
              text
              url
            }
            buttonTwo {
              url
              text
            }
            image
          }
          title
          target
          type
        }
        target
      }
      ... on TaggedArticles_ScrollableOdds {
        __typename
        title
        target
        taggedArticles {
          __typename
          type
          tag
          target
          icon
          title
          cornerLinkText
          cornerLinkUrl
          articles {
            id
            slug
            title
            hero {
              imageUrl {
                url
              }
            }
            thumbnailImageUrl {
              url
            }
            author {
              id
              name
              thumbnailUrl
              socialMedia {
                accountName
                type
                url
              }
            }
            publishedDate
            modifiedDate
            firstPublishedAt
            lastPublishedAt
            subHeading
            description
          }
        }
        scrollableOdds {
          __typename
          markets {
            type
            title
            market
            info
            bottomLinkText
            bottomLinkUrl
          }
          icon
          title
          target
        }
      }
      ... on TaggedArticles_PlayerOddsTabs {
        __typename
        title
        target
        taggedArticles {
          __typename
          type
          tag
          target
          icon
          title
          cornerLinkText
          cornerLinkUrl
          articles {
            id
            slug
            title
            hero {
              imageUrl {
                url
              }
            }
            thumbnailImageUrl {
              url
            }
            author {
              id
              name
              thumbnailUrl
              socialMedia {
                accountName
                type
                url
              }
            }
            publishedDate
            modifiedDate
            firstPublishedAt
            lastPublishedAt
            subHeading
            description
          }
        }
        playerOddsTabs {
          __typename
          icon
          title
          target
          cornerLinkText
          cornerLinkUrl
          bettingTabs {
            title
            market
          }
        }
      }
    }
  }
}
`);

export default getTentpoleEventQuery;
