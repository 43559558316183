import { graphql } from '../gql';

const getFeaturedArticles = graphql(` 
query GetFeaturedArticles($filter: FeaturedArticleInput) {
  getFeaturedArticles(filter: $filter) {
    firstPublishedAt
    publishedDate
    slug
    id
    title
    description
    seo {
      description
      noIndex
      canonicalUrl
      title
    }
    sport {
      name
      slug
      logo {
        url
      }
    }
    thumbnailImageUrl {
      url
    }
    hero {
      imageUrl {
        url
      }
    }
    author {
      name
      id
      socialMedia {
        url
        type
      }
    }
  }
}
`);

export default getFeaturedArticles;
