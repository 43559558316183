import { graphql } from '../gql';

export default graphql(`query GetShortForm($slug: String!) {
  getShortForm(slug: $slug) {
    seo {
      title
      description
    }
    id
    slug
    title
    fact
    analysis
    quant
    newsType {
      enum
      name
    }
    sport {
      id
      slug
      name
      contentSport
      logo {
        url
      }
    }
    primaryRef {
      ... on Player {
        identifier
        image {
          url
        }
        name
        position
        number
        team {
          teamJerseyImage {
            url
          }
          numberFireId
          name
          image {
            url
          }
          sportsbookLink
          abbreviation
        }
      }
      ... on Team {
        name
        abbreviation
        numberFireId
        sportsbookLink
        teamJerseyImage {
          url
        }
        image {
          url
        }
      }
    }
    author {
      id
      name
      thumbnailUrl
      socialMedia {
        type
        accountName
        url
      }
    }
    firstPublishedAt
    lastPublishedAt
    description
    attribution {
      name
      link
    }
  }
}`);
