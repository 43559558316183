import { graphql } from '../gql';

const getShortFormsQuery = graphql(`query getSitemapPlayerNewsQuery($filter: ShortFormSearchInput!) {
  getShortForms(filter: $filter) {
    pageInfo {
      cursor
      hasNextPage
    }
    shortForms {
      cursor
      entity {
        id
        slug
        title
        newsType {
          name
          enum
        }
        sport {
          slug
        }
        firstPublishedAt
        lastPublishedAt
      }
    }
  }
}
`);

export default getShortFormsQuery;
