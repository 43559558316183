import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { fanduel as fd } from "@fanduel/formation-tokens";
import {
  ArrowsChevronUp,
  ArrowsChevronDown,
} from "@fanduel/formation-react-icons";
import { useNavMenus } from "@/utils/hooks/useMenus";
import Image from "@/components/Image";
import { filterEmpty } from "@/utils/index";
import { NAV_LINK_CLICKED } from "@/constants/amplitude";
import ampAppendSbkUrl from "@/utils/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import Styles from "./MobileSubNavigation.module.scss";

interface MobileSubNavigationProps {
  sport?: string;
  sportSlug: string | undefined;
}

const MobileSubNavigation = ({
  sport,
  sportSlug,
}: MobileSubNavigationProps) => {
  const { menuData } = useNavMenus();
  const mobileMenu = menuData?.["Research Mobile"];

  const { logAmplitudeEvent, sessionId, deviceId } = useAmplitude();
  const [active, setActive] = useState(false);
  const router = useRouter();
  const menu = useMemo(
    () =>
      mobileMenu?.menuItems?.find(
        (link) =>
          `/${(router.query.slug ?? sportSlug ?? "notfound")
            .toString()
            .toLocaleLowerCase()}` === link?.url.toLocaleLowerCase() ||
          sport?.toLocaleLowerCase() === link?.text.toLocaleLowerCase()
      ),
    [mobileMenu?.menuItems, router.query, sport, sportSlug]
  );

  useEffect(() => {
    setActive(false);
  }, [router.asPath]);

  const title = useMemo(() => {
    if (!menu) return "";
    const { slug } = router.query;
    const numSegments = router.asPath.split("/").length;
    if (
      numSegments === 2 &&
      slug?.toLocaleString() === menu.text.toLocaleLowerCase()
    ) {
      return menu.text;
    }
    return `${menu.text} News`;
  }, [menu, router.query, router.asPath]);
  if (!menu) return <></>;

  return (
    <div className={cx(Styles.container, active ? Styles.active : "")}>
      <button
        aria-label="expand menu"
        type="button"
        onClick={() => setActive(!active)}
      >
        <Image
          width={16}
          height={16}
          alt={`${menu.text} icon`}
          src={menu.iconUrl ?? "/research/images/Shield.png"}
        />
        {title}
        <span className={Styles.arrowContainer}>
          {active ? (
            <ArrowsChevronUp color={fd.colors.coreBlueD1} />
          ) : (
            <ArrowsChevronDown color={fd.colors.coreBlueD1} />
          )}
        </span>
      </button>
      <ul>
        {filterEmpty(menu.children || [])?.map((item) => {
          const menuItemUrl = ampAppendSbkUrl(item?.url, sessionId, deviceId);
          return (
            <Link
              key={item.text}
              onClick={() => {
                logAmplitudeEvent(NAV_LINK_CLICKED, {
                  "Link Text": item.text,
                  "Link URL": menuItemUrl,
                  Module: "navigation_menu",
                  Menu: "mobile_nav",
                });
              }}
              className={Styles.link}
              href={menuItemUrl}
            >
              {item?.text}
            </Link>
          );
        })}
      </ul>
    </div>
  );
};
export default MobileSubNavigation;
