import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isDesktop } from "react-device-detect";
import * as amplitude from "@fanduel/core-analytics";
import { useRouter } from "next/router";
import { FdUserType } from "@/types/common";
import getConfig from "next/config";

type SetStateT = React.Dispatch<React.SetStateAction<string>>;
interface FunctionT {
  setPage: SetStateT;
  isActive: boolean;
  sessionId: number | undefined;
  deviceId: string | undefined;
  logAmplitudeEvent: (
    eventName: string,
    eventProps?: Record<string, string | number>,
    otherAtts?: Record<string, string>
  ) => void;
}

const AmplitudeEventContext = createContext<FunctionT | Record<string, never>>(
  {}
);

const { publicRuntimeConfig } = getConfig();

export function AmplitudeProvider({ children }: { children: JSX.Element }) {
  const [isReady, setReadyState] = useState(false);
  const [deviceId, setDeviceId] = useState<string>();
  const [sessionId, setSessionId] = useState<number>();
  const [userId, setUserID] = useState<number | string>();
  const init = useCallback(async () => {
    const dfsLoggedInState = localStorage.getItem("FD_USER");
    const dfsUserId = dfsLoggedInState
      ? (JSON.parse(dfsLoggedInState) as FdUserType)
      : undefined;

    await amplitude.initWithConfig({
      codeSource: "content-hub-nextjs",
      environment: publicRuntimeConfig.AMP_ENV,
      product: "research",
      stateIdCookieHandling: true,
      debug: false,
    });
    if (dfsUserId && dfsUserId.id) {
      amplitude.setUserId(dfsUserId.id);
      setUserID(dfsUserId.id);
    }
    setReadyState(true);
    return dfsUserId?.id;
  }, []);

  useEffect(() => {
    init().then(() => {
      setDeviceId(amplitude.getDeviceId());
      setSessionId(amplitude.getSessionId());
    });
  }, [init]);

  const [pageName, setPage] = useState<string>();
  const [prevPage, setPrevPage] = useState<string[]>([]);
  const { asPath } = useRouter();
  useEffect(() => {
    if (prevPage.length === 0) {
      setPrevPage(() => [window.location.href]);
    }
  }, [prevPage]);
  useEffect(() => {
    if (window.location.href)
      setPrevPage((prev) => [window.location.href, ...prev]);
  }, [asPath]);

  const logAmplitudeEvent = useCallback(
    async (
      eventName: string,
      eventProps?: Record<string, string | number>,
      otherAtts?: Record<string, string>
    ) => {
      let loggedInUserId = userId;
      if (!isReady) {
        loggedInUserId = await init();
      }

      const defaultProperties = {
        "Page Path": window.location.pathname,
        "Full URL": window.location.href,
        Hostname: window.location.hostname,
        "Page Title": document?.title,
        Vertical: "content",
        Product: "research_articles",
        "Page Name": otherAtts?.page || pageName,
        user_id: loggedInUserId || "logged_out",
        "account type": loggedInUserId ? "DFS" : "",
        "Previous Page": prevPage?.at(0),
        "Site Platform": isDesktop ? "desktop" : "mobile web",
        "Site Version": isDesktop ? "desktop" : "mobile web",
      };

      const properties = eventProps;
      amplitude?.log(
        eventName,
        amplitude.cleanProperties({ ...defaultProperties, ...properties })
      );
      amplitude.flush();
    },
    [pageName, prevPage, init, isReady, userId]
  );

  const functions = useMemo(
    () => ({
      logAmplitudeEvent,
      setPage,
      isActive: !!amplitude,
      deviceId,
      sessionId,
    }),
    [logAmplitudeEvent, sessionId, deviceId]
  ) as FunctionT;
  return (
    <AmplitudeEventContext.Provider value={functions}>
      {children}
    </AmplitudeEventContext.Provider>
  );
}

export const useAmplitude = () => useContext(AmplitudeEventContext);
