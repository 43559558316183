import { graphql } from "../gql";

const getMenusQuery = graphql(`
  query getMenus($isPreview: Boolean) {
    getMenus(isPreview: $isPreview) {
      iconUrl
      name
      menuItems {
        behaviour
        text
        url
        iconUrl
        imageUrl
        children {
          behaviour
          text
          url
          imageUrl
          iconUrl
          children {
            behaviour
            text
            url
            imageUrl
            iconUrl
            children {
              behaviour
              text
              url
              iconUrl
              imageUrl
            }
          }
        }
      }
    }
  }
`);

export default getMenusQuery;
