import { Maybe } from "graphql/jsutils/Maybe";

/**
 * Apply this function to ALL links from FanDuel Research to Sportsbook.
 * We want sessionId and deviceId appended for Amplitude tracking.
 */
export default function ampAppendSbkUrl(
  urlToCheck: Maybe<string>,
  sessionId: Maybe<number>,
  deviceId: Maybe<string>
) {
  if (urlToCheck?.includes("sportsbook.fanduel.com")) {
    try {
      const url = new URL(urlToCheck);
      if (sessionId) {
        url.searchParams.delete("ampSessionId");
        url.searchParams.append("ampSessionId", sessionId.toString());
      }
      if (deviceId) {
        url.searchParams.delete("ampDeviceId");
        url.searchParams.append("ampDeviceId", deviceId.toString());
      }
      return url.toString();
    } catch {
      return urlToCheck ?? "";
    }
  }
  return urlToCheck ?? "";
}
