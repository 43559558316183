/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An arbitrary precision signed decimal */
  CTP_BigDecimal: any;
  /** Built-in scalar representing an instant in time */
  CTP_Instant: any;
  /** Built-in scalar representing a local date */
  CTP_LocalDate: any;
  /** Built-in scalar representing a local date-time */
  CTP_LocalDateTime: any;
  /** A 64-bit signed integer */
  CTP_Long: any;
  CTP__Any: any;
  /** An arbitrary precision signed decimal */
  HR_BigDecimal: any;
  HR_Long: any;
  /** Built-in scalar representing an instant in time */
  TOURNAMENT_Instant: any;
  /** Built-in scalar representing a local date-time */
  TOURNAMENT_LocalDateTime: any;
};

export type Article = ContentItem & {
  __typename?: "Article";
  author: ContentAuthor;
  body: Scalars["String"];
  dataskriveId?: Maybe<Scalars["String"]>;
  /**
   * The SEO description without needing to go through the 'seo' object
   *
   * Note: This defaults to grab the first 3 sentences from the 'body' if the SEO description isn't present in the CMS
   */
  description: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["String"]>;
  hero?: Maybe<ArticleHero>;
  /** Hide the bottom newsletter sign up form */
  hideBottomNewsletter?: Maybe<Scalars["Boolean"]>;
  /** Hide the top newsletter sign up form */
  hideTopNewsletter?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  lastPublishedAt?: Maybe<Scalars["String"]>;
  modifiedDate: Scalars["String"];
  publishedDate?: Maybe<Scalars["String"]>;
  seo: SeoInformation;
  slug: Scalars["String"];
  sport: Sport;
  subHeading?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  thumbnailImageUrl?: Maybe<ImageReference>;
  title: Scalars["String"];
  url: Scalars["String"];
};

/** Data encapsulating a specific article and the id to help with pagination */
export type ArticleConnection = {
  __typename?: "ArticleConnection";
  cursor: Scalars["String"];
  entity: Article;
};

export type ArticleGroup = {
  articles?: Maybe<Array<Maybe<Article>>>;
  icon?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type ArticleHero = {
  __typename?: "ArticleHero";
  imageUrl?: Maybe<ImageReference>;
  jwPlayerId?: Maybe<Scalars["String"]>;
  jwPlayerPlayerId?: Maybe<Scalars["String"]>;
  /** Defaults to IMAGE if no JWPLAYER media is present */
  type: HeroType;
};

/** List of articles including pagination data and total articles in the list */
export type ArticleList = {
  __typename?: "ArticleList";
  articles: Array<Maybe<ArticleConnection>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Filter article searches */
export type ArticleSearchInput = {
  /**
   * Case-sensitive
   *
   * Used for pagination. Retrieve items that come after the given id.
   */
  afterCursor?: InputMaybe<Scalars["String"]>;
  /**
   * Case-sensitive
   *
   * Filter article searches by those that were written by a specific author with the given id.
   */
  author?: InputMaybe<AuthorInput>;
  /**
   * Case-sensitive
   *
   * Filter article searches by those that contain a specific HeroType
   */
  heroType?: InputMaybe<HeroType>;
  /** Hide deprioritized content */
  hideDeprioritizedContent?: InputMaybe<Scalars["Boolean"]>;
  /**
   * Default 10.
   *
   * Input how many items to return.
   */
  limit?: InputMaybe<Scalars["Int"]>;
  player?: InputMaybe<PlayerInput>;
  /**
   * Format: ISO, i.e. YYYY-MM-DDTHH:mm:ssZ
   *
   * Filter article searches by those published within a given timeline
   */
  publishedWithin?: InputMaybe<TimestampBoundaries>;
  /**
   * Case-sensitive
   *
   * Filter article searches by those about the given sport.
   */
  sportName?: InputMaybe<Scalars["String"]>;
  subCategorySlug?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<TeamInput>;
  /**
   * Case-sensitive
   *
   * Filter article searches by those with the given title.
   */
  title?: InputMaybe<Scalars["String"]>;
};

export type Attribute = {
  __typename?: "Attribute";
  key: AttributeKey;
  value: Scalars["String"];
};

export enum AttributeKey {
  HidePositionDropdown = "HIDE_POSITION_DROPDOWN",
  ScopeFilter = "SCOPE_FILTER",
  ShowEventsDropdown = "SHOW_EVENTS_DROPDOWN",
  SlateOrWeekly = "SLATE_OR_WEEKLY",
}

export type Attribution = {
  __typename?: "Attribution";
  link?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AuthorInput = {
  /** The author's id */
  id?: InputMaybe<Scalars["String"]>;
};

/** Filter author searches */
export type AuthorSearchInput = {
  /** Used for pagination. Retrieve items that come after the given id. */
  afterCursor?: InputMaybe<Scalars["String"]>;
  /**
   * Default 10.
   *
   * Input how many items to return.
   */
  limit?: InputMaybe<Scalars["Int"]>;
  /**
   * Case-sensitive
   *
   * Filter author searches by those who have written about the given sport.
   */
  sportName?: InputMaybe<Scalars["String"]>;
};

export type BettingMarket = {
  __typename?: "BettingMarket";
  bottomLinkText?: Maybe<Scalars["String"]>;
  bottomLinkUrl?: Maybe<Scalars["String"]>;
  info?: Maybe<Scalars["String"]>;
  market: Scalars["String"];
  title: Scalars["String"];
  type: MarketType;
};

export type BettingTabs = {
  __typename?: "BettingTabs";
  market: Scalars["String"];
  title: Scalars["String"];
};

export type Ctp_ArticleSummary = {
  __typename?: "CTP_ArticleSummary";
  author?: Maybe<Scalars["String"]>;
  /** Deprecated. Use isoDate instead. */
  date?: Maybe<Scalars["String"]>;
  id: Scalars["CTP_Long"];
  isoDate?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic =
  {
    __typename?: "CTP_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic";
    attempted?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    made?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    missed?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    ratio?: Maybe<Ctp_RatioEventStatistic>;
  };

export type Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic =
  {
    __typename?: "CTP_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
    attempted?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    made?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    missed?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    ratio?: Maybe<Ctp_RatioStatistic>;
  };

export type Ctp_AttemptedMadeRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic =
  {
    __typename?: "CTP_AttemptedMadeRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic";
    attempted?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    made?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    ratio?: Maybe<Ctp_RatioEventStatistic>;
  };

export type Ctp_AttemptedMadeRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic =
  {
    __typename?: "CTP_AttemptedMadeRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
    attempted?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    made?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    ratio?: Maybe<Ctp_RatioStatistic>;
  };

export type Ctp_Bblg = {
  __typename?: "CTP_BBLG";
  events?: Maybe<Array<Maybe<Ctp_BblgEvent>>>;
};

export type Ctp_BaseballEventScore = {
  __typename?: "CTP_BaseballEventScore";
  awayScore?: Maybe<Scalars["Int"]>;
  clock?: Maybe<Scalars["String"]>;
  currentTeamAtBat?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  homeScore?: Maybe<Scalars["Int"]>;
};

/** Events for BBLG */
export type Ctp_BblgEvent = {
  __typename?: "CTP_BblgEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_BblgMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

/** Futures for BBLG */
export type Ctp_BblgMarket = {
  __typename?: "CTP_BblgMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_BblgSelection>>>;
};

export type Ctp_BblgSelection = {
  __typename?: "CTP_BblgSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerStatus?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_BetInfo = {
  __typename?: "CTP_BetInfo";
  description?: Maybe<Scalars["String"]>;
  line?: Maybe<Scalars["Float"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  selectionName?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_Cba = {
  __typename?: "CTP_CBA";
  events?: Maybe<Array<Maybe<Ctp_CbaEvent>>>;
};

/** Events for CBA */
export type Ctp_CbaEvent = {
  __typename?: "CTP_CbaEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_CbaMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

/** Futures for CBA */
export type Ctp_CbaMarket = {
  __typename?: "CTP_CbaMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_CbaSelection>>>;
};

export type Ctp_CbaSelection = {
  __typename?: "CTP_CbaSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerStatus?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_ContentArticlesQueryResponse = {
  __typename?: "CTP_ContentArticlesQueryResponse";
  data?: Maybe<Array<Maybe<Ctp_ArticleSummary>>>;
  nextPageState?: Maybe<Scalars["String"]>;
};

export type Ctp_ContentPlayerUpdatesQueryResponse = {
  __typename?: "CTP_ContentPlayerUpdatesQueryResponse";
  data?: Maybe<Array<Maybe<Ctp_PlayerUpdate>>>;
  nextPageState?: Maybe<Scalars["String"]>;
};

export type Ctp_CumulativeAverageEventStatistics_BigDecimal = {
  __typename?: "CTP_CumulativeAverageEventStatistics_BigDecimal";
  /** Rolling average of all statistic values earned for the season up to this event (inclusive). */
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Rolling sum of all statistic values earned for the season up to this event (inclusive). */
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Statistic value earned during this event. */
  event?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_CumulativeAverageEventStatistics_Integer = {
  __typename?: "CTP_CumulativeAverageEventStatistics_Integer";
  /** Rolling average of all statistic values earned for the season up to this event (inclusive). */
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Rolling sum of all statistic values earned for the season up to this event (inclusive). */
  cumulative?: Maybe<Scalars["Int"]>;
  /** Statistic value earned during this event. */
  event?: Maybe<Scalars["Int"]>;
};

export type Ctp_CumulativeAverageStatistics_BigDecimal = {
  __typename?: "CTP_CumulativeAverageStatistics_BigDecimal";
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_CumulativeAverageStatistics_Integer";
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  cumulative?: Maybe<Scalars["Int"]>;
};

export enum Ctp_DataProvider {
  Fanduel = "FANDUEL",
  FanduelSportsbook = "FANDUEL_SPORTSBOOK",
  Numberfire = "NUMBERFIRE",
  Sportradar = "SPORTRADAR",
}

export type Ctp_DepthChartDraft = {
  __typename?: "CTP_DepthChartDraft";
  number?: Maybe<Scalars["String"]>;
  pick?: Maybe<Scalars["String"]>;
  round?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type Ctp_Fibaa = {
  __typename?: "CTP_FIBAA";
  events?: Maybe<Array<Maybe<Ctp_FibaaEvent>>>;
};

export type Ctp_FanDuelResearchArticle = {
  __typename?: "CTP_FanDuelResearchArticle";
  author?: Maybe<Ctp_FanDuelResearchContentAuthor>;
  body?: Maybe<Scalars["String"]>;
  dataskriveId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  firstPublishedAt?: Maybe<Scalars["String"]>;
  hero?: Maybe<Ctp_FanDuelResearchArticleHero>;
  id?: Maybe<Scalars["String"]>;
  lastPublishedAt?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["String"]>;
  publishedDate?: Maybe<Scalars["String"]>;
  seo?: Maybe<Ctp_FanDuelResearchSeoInformation>;
  slug?: Maybe<Scalars["String"]>;
  sport?: Maybe<Ctp_FanDuelResearchSport>;
  subHeading?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentTag>>>;
  thumbnailImageUrl?: Maybe<Ctp_FanDuelResearchImageReference>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchArticleConnection = {
  __typename?: "CTP_FanDuelResearchArticleConnection";
  cursor?: Maybe<Scalars["String"]>;
  entity?: Maybe<Ctp_FanDuelResearchArticle>;
};

export type Ctp_FanDuelResearchArticleHero = {
  __typename?: "CTP_FanDuelResearchArticleHero";
  imageUrl?: Maybe<Ctp_FanDuelResearchImageReference>;
  jwPlayerId?: Maybe<Scalars["String"]>;
  jwPlayerPlayerId?: Maybe<Scalars["String"]>;
  type?: Maybe<Ctp_FanDuelResearchHeroType>;
};

export type Ctp_FanDuelResearchArticleList = {
  __typename?: "CTP_FanDuelResearchArticleList";
  articles?: Maybe<Array<Maybe<Ctp_FanDuelResearchArticleConnection>>>;
  pageInfo?: Maybe<Ctp_FanDuelResearchPageInfo>;
  totalCount: Scalars["Int"];
};

export type Ctp_FanDuelResearchArticleSearchInputInput = {
  afterCursor?: InputMaybe<Scalars["String"]>;
  author?: InputMaybe<Ctp_FanDuelResearchAuthorInputInput>;
  heroType?: InputMaybe<Ctp_FanDuelResearchHeroType>;
  hideDeprioritizedContent?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  player?: InputMaybe<Ctp_FanDuelResearchPlayerInputInput>;
  publishedWithin?: InputMaybe<Ctp_FanDuelResearchTimestampBoundariesInput>;
  sportName?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<Ctp_FanDuelResearchTeamInputInput>;
  title?: InputMaybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchAttribution = {
  __typename?: "CTP_FanDuelResearchAttribution";
  link?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchAuthorInputInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchContentAuthor = {
  __typename?: "CTP_FanDuelResearchContentAuthor";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  socialMedia?: Maybe<Array<Maybe<Ctp_FanDuelResearchSocialMediaAccount>>>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchContentItem = {
  __typename?: "CTP_FanDuelResearchContentItem";
  author?: Maybe<Ctp_FanDuelResearchContentAuthor>;
  id?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["String"]>;
  publishedDate?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentTag>>>;
  title?: Maybe<Scalars["String"]>;
};

export enum Ctp_FanDuelResearchContentSportsEnum {
  Boxing = "BOXING",
  Casino = "CASINO",
  F1 = "F1",
  FanduelSportsbookPromoCodes = "FANDUEL_SPORTSBOOK_PROMO_CODES",
  HorseRacing = "HORSE_RACING",
  Mlb = "MLB",
  Mma = "MMA",
  Nascar = "NASCAR",
  Nba = "NBA",
  Ncaab = "NCAAB",
  Ncaaf = "NCAAF",
  Nfl = "NFL",
  Nhl = "NHL",
  Pga = "PGA",
  Soccer = "SOCCER",
  Tennis = "TENNIS",
  Ufc = "UFC",
  Wnba = "WNBA",
  Womenssoccer = "WOMENSSOCCER",
}

export type Ctp_FanDuelResearchContentTag = {
  __typename?: "CTP_FanDuelResearchContentTag";
  id?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  thumbnailImage?: Maybe<Ctp_FanDuelResearchImageReference>;
  type?: Maybe<Scalars["String"]>;
};

export enum Ctp_FanDuelResearchHeroType {
  Image = "IMAGE",
  Jwplayer = "JWPLAYER",
}

export type Ctp_FanDuelResearchImageReference = {
  __typename?: "CTP_FanDuelResearchImageReference";
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchImageReferenceNullable = {
  __typename?: "CTP_FanDuelResearchImageReferenceNullable";
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchOpenGraphTag = {
  __typename?: "CTP_FanDuelResearchOpenGraphTag";
  image?: Maybe<Ctp_FanDuelResearchImageReference>;
  title?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchPageInfo = {
  __typename?: "CTP_FanDuelResearchPageInfo";
  cursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type Ctp_FanDuelResearchPlayer = {
  __typename?: "CTP_FanDuelResearchPlayer";
  identifier?: Maybe<Scalars["String"]>;
  image?: Maybe<Ctp_FanDuelResearchImageReference>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  relatedContent?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentItem>>>;
  sports?: Maybe<Array<Maybe<Ctp_FanDuelResearchSport>>>;
  team?: Maybe<Ctp_FanDuelResearchTeam>;
  type?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchPlayerInputInput = {
  numberFireId?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_FanDuelResearchPlayerOrTeam =
  | Ctp_FanDuelResearchPlayer
  | Ctp_FanDuelResearchTeam;

export enum Ctp_FanDuelResearchPositionAbbrev {
  C = "C",
  D = "D",
  Db = "DB",
  Dh = "DH",
  Dl = "DL",
  F = "F",
  G = "G",
  If = "IF",
  K = "K",
  Lb = "LB",
  Of = "OF",
  Ol = "OL",
  P = "P",
  Qb = "QB",
  Rb = "RB",
  Te = "TE",
  W = "W",
  Wr = "WR",
}

export type Ctp_FanDuelResearchSeoInformation = {
  __typename?: "CTP_FanDuelResearchSEOInformation";
  canonicalUrl?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Ctp_FanDuelResearchImageReference>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  noIndex?: Maybe<Scalars["Boolean"]>;
  openGraphTag?: Maybe<Ctp_FanDuelResearchOpenGraphTag>;
  synonyms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchShortForm = {
  __typename?: "CTP_FanDuelResearchShortForm";
  analysis?: Maybe<Scalars["String"]>;
  attribution?: Maybe<Ctp_FanDuelResearchAttribution>;
  author?: Maybe<Ctp_FanDuelResearchContentAuthor>;
  description?: Maybe<Scalars["String"]>;
  fact?: Maybe<Scalars["String"]>;
  firstPublishedAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastPublishedAt?: Maybe<Scalars["String"]>;
  modifiedDate?: Maybe<Scalars["String"]>;
  primaryRef?: Maybe<Ctp_FanDuelResearchPlayerOrTeam>;
  publishedDate?: Maybe<Scalars["String"]>;
  quant?: Maybe<Scalars["String"]>;
  seo?: Maybe<Ctp_FanDuelResearchSeoInformation>;
  slug?: Maybe<Scalars["String"]>;
  sport?: Maybe<Ctp_FanDuelResearchSport>;
  tags?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentTag>>>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchShortFormConnection = {
  __typename?: "CTP_FanDuelResearchShortFormConnection";
  cursor?: Maybe<Scalars["String"]>;
  entity?: Maybe<Ctp_FanDuelResearchShortForm>;
};

export type Ctp_FanDuelResearchShortFormList = {
  __typename?: "CTP_FanDuelResearchShortFormList";
  pageInfo?: Maybe<Ctp_FanDuelResearchPageInfo>;
  shortForms?: Maybe<Array<Maybe<Ctp_FanDuelResearchShortFormConnection>>>;
  totalCount: Scalars["Int"];
};

export type Ctp_FanDuelResearchShortFormSearchInputInput = {
  afterCursor?: InputMaybe<Scalars["String"]>;
  author?: InputMaybe<Ctp_FanDuelResearchAuthorInputInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  player?: InputMaybe<Ctp_FanDuelResearchPlayerInputInput>;
  publishedWithin?: InputMaybe<Ctp_FanDuelResearchTimestampBoundariesInput>;
  sport?: InputMaybe<Ctp_FanDuelResearchContentSportsEnum>;
  team?: InputMaybe<Ctp_FanDuelResearchTeamInputInput>;
  title?: InputMaybe<Scalars["String"]>;
};

export type Ctp_FanDuelResearchSocialMediaAccount = {
  __typename?: "CTP_FanDuelResearchSocialMediaAccount";
  accountName?: Maybe<Scalars["String"]>;
  type?: Maybe<Ctp_FanDuelResearchSocialMediaType>;
  url?: Maybe<Scalars["String"]>;
};

export enum Ctp_FanDuelResearchSocialMediaType {
  Instagram = "INSTAGRAM",
  Twitter = "TWITTER",
}

export type Ctp_FanDuelResearchSport = {
  __typename?: "CTP_FanDuelResearchSport";
  description?: Maybe<Scalars["String"]>;
  governingBody?: Maybe<Scalars["String"]>;
  hasLiveScoring?: Maybe<Scalars["Boolean"]>;
  hasPlayerUpdates?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_FanDuelResearchImageReferenceNullable>;
  name?: Maybe<Scalars["String"]>;
  positions?: Maybe<Array<Maybe<Ctp_FanDuelResearchPositionAbbrev>>>;
  relatedContent?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentItem>>>;
  seo?: Maybe<Ctp_FanDuelResearchSeoInformation>;
  showAuthors?: Maybe<Scalars["Boolean"]>;
  slug?: Maybe<Scalars["String"]>;
  teams?: Maybe<Array<Maybe<Ctp_FanDuelResearchTeam>>>;
};

export type Ctp_FanDuelResearchTeam = {
  __typename?: "CTP_FanDuelResearchTeam";
  abbreviation?: Maybe<Scalars["String"]>;
  drawCount: Scalars["Int"];
  identifier?: Maybe<Scalars["String"]>;
  image?: Maybe<Ctp_FanDuelResearchImageReference>;
  loseCount: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  numberFireId?: Maybe<Scalars["Int"]>;
  primaryColor?: Maybe<Scalars["String"]>;
  relatedContent?: Maybe<Array<Maybe<Ctp_FanDuelResearchContentItem>>>;
  secondaryColor?: Maybe<Scalars["String"]>;
  sports?: Maybe<Array<Maybe<Ctp_FanDuelResearchSport>>>;
  sportsbookLink?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  winCount: Scalars["Int"];
};

export type Ctp_FanDuelResearchTeamInputInput = {
  id?: InputMaybe<Scalars["String"]>;
  numberFireId?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_FanDuelResearchTimestampBoundariesInput = {
  end?: InputMaybe<Scalars["String"]>;
  start?: InputMaybe<Scalars["String"]>;
};

/** Events for FIBAA */
export type Ctp_FibaaEvent = {
  __typename?: "CTP_FibaaEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_FibaaMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

/** Futures for FIBAA */
export type Ctp_FibaaMarket = {
  __typename?: "CTP_FibaaMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_FibaaSelection>>>;
};

export type Ctp_FibaaSelection = {
  __typename?: "CTP_FibaaSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerStatus?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_GameOddsDetails = {
  __typename?: "CTP_GameOddsDetails";
  moneyLine?: Maybe<Ctp_MoneyLine>;
  spread?: Maybe<Ctp_SpreadTotal>;
  total?: Maybe<Ctp_SpreadTotal>;
};

export type Ctp_Golf = {
  __typename?: "CTP_Golf";
  featuredCompetitions?: Maybe<Array<Maybe<Ctp_GolfCompetition>>>;
  featuredEvents?: Maybe<Array<Maybe<Ctp_GolfEvent>>>;
  mastersHistoricalWinners?: Maybe<Array<Maybe<Ctp_MastersHistoricalWinners>>>;
  players?: Maybe<Array<Maybe<Ctp_GolfPlayer>>>;
  /** Get the schedule of golf tournaments for the current year. */
  tournamentSchedule?: Maybe<Array<Maybe<Array<Maybe<Ctp_GolfTournament>>>>>;
};

export type Ctp_GolfPlayersArgs = {
  numberFirePlayerSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerIdentityFilters?: InputMaybe<
    Array<InputMaybe<Ctp_PlayerIdentityFilterInput>>
  >;
};

export type Ctp_GolfTournamentScheduleArgs = {
  tournamentScheduleFilter: Array<
    InputMaybe<Ctp_GolfTournamentScheduleContextInput>
  >;
};

export type Ctp_GolfBroadcast = {
  __typename?: "CTP_GolfBroadcast";
  cable?: Maybe<Scalars["String"]>;
  ending?: Maybe<Scalars["CTP_LocalDateTime"]>;
  internet?: Maybe<Scalars["String"]>;
  radio?: Maybe<Scalars["String"]>;
  satellite?: Maybe<Scalars["String"]>;
  starting?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

/** Featured Competitions for Golf */
export type Ctp_GolfCompetition = {
  __typename?: "CTP_GolfCompetition";
  competitionId?: Maybe<Scalars["Int"]>;
  events?: Maybe<Array<Maybe<Ctp_GolfEvent>>>;
  name?: Maybe<Scalars["String"]>;
};

export type Ctp_GolfCourse = {
  __typename?: "CTP_GolfCourse";
  courseId?: Maybe<Scalars["String"]>;
  holes?: Maybe<Array<Maybe<Ctp_GolfCourseHole>>>;
  name?: Maybe<Scalars["String"]>;
  par?: Maybe<Scalars["Int"]>;
  yardage?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfCourseHole = {
  __typename?: "CTP_GolfCourseHole";
  description?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
  par?: Maybe<Scalars["Int"]>;
  yardage?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfCourseHoleByRoundScoringResults = {
  __typename?: "CTP_GolfCourseHoleByRoundScoringResults";
  holeResults?: Maybe<Array<Maybe<Ctp_GolfCourseHoleScoringResult>>>;
  roundIdentifier?: Maybe<Ctp_GolfRoundIdentifier>;
};

/** How many players have scored the specified result on the hole. */
export type Ctp_GolfCourseHoleScoringResult = {
  __typename?: "CTP_GolfCourseHoleScoringResult";
  averageStrokes?: Maybe<Scalars["CTP_BigDecimal"]>;
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holeNumber?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfCourseHoleScoringResults = {
  __typename?: "CTP_GolfCourseHoleScoringResults";
  cumulativeResults?: Maybe<Array<Maybe<Ctp_GolfCourseHoleScoringResult>>>;
  holeResultsByRound?: Maybe<
    Array<Maybe<Ctp_GolfCourseHoleByRoundScoringResults>>
  >;
};

export type Ctp_GolfCourseRoundByHoleScoringResults = {
  __typename?: "CTP_GolfCourseRoundByHoleScoringResults";
  holeNumber?: Maybe<Scalars["Int"]>;
  roundResults?: Maybe<Array<Maybe<Ctp_GolfCourseRoundScoringResult>>>;
};

export type Ctp_GolfCourseRoundScoringResult = {
  __typename?: "CTP_GolfCourseRoundScoringResult";
  averageStrokes?: Maybe<Scalars["CTP_BigDecimal"]>;
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
  roundIdentifier?: Maybe<Ctp_GolfRoundIdentifier>;
};

export type Ctp_GolfCourseRoundScoringResults = {
  __typename?: "CTP_GolfCourseRoundScoringResults";
  cumulativeResults?: Maybe<Array<Maybe<Ctp_GolfCourseRoundScoringResult>>>;
  roundResultsByHole?: Maybe<
    Array<Maybe<Ctp_GolfCourseRoundByHoleScoringResults>>
  >;
};

export type Ctp_GolfCourseScoringResult = {
  __typename?: "CTP_GolfCourseScoringResult";
  averageStrokes?: Maybe<Scalars["CTP_BigDecimal"]>;
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfCourseTournamentScoringResults = {
  __typename?: "CTP_GolfCourseTournamentScoringResults";
  cumulative?: Maybe<Ctp_GolfCourseScoringResult>;
};

/** Featured Events for Golf */
export type Ctp_GolfEvent = {
  __typename?: "CTP_GolfEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_GolfMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

export enum Ctp_GolfEventType {
  Cup = "CUP",
  Match = "MATCH",
  Stroke = "STROKE",
  Team = "TEAM",
}

export enum Ctp_GolfHoleScoreResult {
  Birdie = "BIRDIE",
  Bogey = "BOGEY",
  DoubleBogey = "DOUBLE_BOGEY",
  DoubleEagle = "DOUBLE_EAGLE",
  Eagle = "EAGLE",
  HoleInOne = "HOLE_IN_ONE",
  OtherScore = "OTHER_SCORE",
  Par = "PAR",
}

export type Ctp_GolfLeaderboardRound = {
  __typename?: "CTP_GolfLeaderboardRound";
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  sequence?: Maybe<Scalars["Int"]>;
  strokes?: Maybe<Scalars["Int"]>;
  thru?: Maybe<Scalars["Int"]>;
};

/** Featured Futures for Golf */
export type Ctp_GolfMarket = {
  __typename?: "CTP_GolfMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_GolfSelection>>>;
};

export type Ctp_GolfPlayer = {
  __typename?: "CTP_GolfPlayer";
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  statistics?: Maybe<Ctp_LeaguePlayerStatisticsContext_GolfPlayer>;
};

export type Ctp_GolfPlayerCumulativeScoringResult = {
  __typename?: "CTP_GolfPlayerCumulativeScoringResult";
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  strokes?: Maybe<Scalars["Int"]>;
  /** How many holes the player has completed as reflected by this scorecard. */
  through?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfPlayerHoleByRoundScoringResults = {
  __typename?: "CTP_GolfPlayerHoleByRoundScoringResults";
  holeResults?: Maybe<Array<Maybe<Ctp_GolfPlayerHoleScoringResult>>>;
  roundIdentifier?: Maybe<Ctp_GolfRoundIdentifier>;
};

export type Ctp_GolfPlayerHoleScoringResult = {
  __typename?: "CTP_GolfPlayerHoleScoringResult";
  holeNumber?: Maybe<Scalars["Int"]>;
  scoreResult?: Maybe<Ctp_GolfHoleScoreResult>;
  strokes?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfPlayerHoleScoringResults = {
  __typename?: "CTP_GolfPlayerHoleScoringResults";
  cumulativeResults?: Maybe<Array<Maybe<Ctp_GolfPlayerRoundScoringResult>>>;
  holeResultsByRound?: Maybe<
    Array<Maybe<Ctp_GolfPlayerHoleByRoundScoringResults>>
  >;
};

export type Ctp_GolfPlayerRoundByHoleScoringResults = {
  __typename?: "CTP_GolfPlayerRoundByHoleScoringResults";
  holeNumber?: Maybe<Scalars["Int"]>;
  roundResults?: Maybe<Array<Maybe<Ctp_GolfPlayerRoundHoleScoringResult>>>;
};

export type Ctp_GolfPlayerRoundHoleScoringResult = {
  __typename?: "CTP_GolfPlayerRoundHoleScoringResult";
  roundIdentifier?: Maybe<Ctp_GolfRoundIdentifier>;
  scoreResult?: Maybe<Ctp_GolfHoleScoreResult>;
  strokes?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfPlayerRoundScoringResult = {
  __typename?: "CTP_GolfPlayerRoundScoringResult";
  birdies?: Maybe<Scalars["Int"]>;
  bogeys?: Maybe<Scalars["Int"]>;
  doubleBogeys?: Maybe<Scalars["Int"]>;
  eagles?: Maybe<Scalars["Int"]>;
  holesInOne?: Maybe<Scalars["Int"]>;
  otherScores?: Maybe<Scalars["Int"]>;
  pars?: Maybe<Scalars["Int"]>;
  roundIdentifier?: Maybe<Ctp_GolfRoundIdentifier>;
  score?: Maybe<Scalars["Int"]>;
  strokes?: Maybe<Scalars["Int"]>;
  /** How many holes the player has completed as reflected by this scorecard. */
  through?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfPlayerRoundScoringResults = {
  __typename?: "CTP_GolfPlayerRoundScoringResults";
  cumulativeResults?: Maybe<Array<Maybe<Ctp_GolfPlayerRoundScoringResult>>>;
  roundResultsByHole?: Maybe<
    Array<Maybe<Ctp_GolfPlayerRoundByHoleScoringResults>>
  >;
};

/** Player statistics for an entire season, noted by the year. Currently historical seasons are supported up to 5 years previous by this API. */
export type Ctp_GolfPlayerSeasonStatistics = {
  __typename?: "CTP_GolfPlayerSeasonStatistics";
  birdiesPerRound?: Maybe<Scalars["Float"]>;
  cuts?: Maybe<Scalars["Int"]>;
  cutsMade?: Maybe<Scalars["Int"]>;
  driveAcc?: Maybe<Scalars["Float"]>;
  driveAvg?: Maybe<Scalars["Float"]>;
  earnings?: Maybe<Scalars["Float"]>;
  earningsRank?: Maybe<Scalars["Int"]>;
  eventsPlayed?: Maybe<Scalars["Int"]>;
  firstPlace?: Maybe<Scalars["Int"]>;
  girPct?: Maybe<Scalars["Float"]>;
  holeProximityAvg?: Maybe<Scalars["String"]>;
  holesPerEagle?: Maybe<Scalars["Float"]>;
  points?: Maybe<Scalars["Float"]>;
  pointsRank?: Maybe<Scalars["Int"]>;
  puttAvg?: Maybe<Scalars["Float"]>;
  sandSavesPct?: Maybe<Scalars["Float"]>;
  scoringAvg?: Maybe<Scalars["Float"]>;
  scramblingPct?: Maybe<Scalars["Float"]>;
  secondPlace?: Maybe<Scalars["Int"]>;
  strokesGained?: Maybe<Scalars["Float"]>;
  strokesGainedTeeGreen?: Maybe<Scalars["Float"]>;
  strokesGainedTotal?: Maybe<Scalars["Float"]>;
  thirdPlace?: Maybe<Scalars["Int"]>;
  top10?: Maybe<Scalars["Int"]>;
  top25?: Maybe<Scalars["Int"]>;
  totalDriving?: Maybe<Scalars["Int"]>;
  withdrawals?: Maybe<Scalars["Int"]>;
  worldRank?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export enum Ctp_GolfPlayerStatus {
  Cut = "CUT",
  DidNotStart = "DID_NOT_START",
  Disqualified = "DISQUALIFIED",
  MadeCutDnf = "MADE_CUT_DNF",
  Withdrawn = "WITHDRAWN",
}

export type Ctp_GolfPlayerTournamentScoringResults = {
  __typename?: "CTP_GolfPlayerTournamentScoringResults";
  cumulative?: Maybe<Ctp_GolfPlayerCumulativeScoringResult>;
};

export type Ctp_GolfRound = {
  __typename?: "CTP_GolfRound";
  broadcasts?: Maybe<Array<Maybe<Ctp_GolfBroadcast>>>;
  number?: Maybe<Scalars["Int"]>;
  roundId?: Maybe<Scalars["String"]>;
  status?: Maybe<Ctp_GolfRoundStatus>;
  weather?: Maybe<Ctp_GolfWeather>;
};

export type Ctp_GolfRoundIdentifier = {
  __typename?: "CTP_GolfRoundIdentifier";
  courseIdentifier?: Maybe<Scalars["String"]>;
  roundIdentifier?: Maybe<Scalars["String"]>;
  roundNumber?: Maybe<Scalars["Int"]>;
  tournamentIdentifier?: Maybe<Scalars["String"]>;
};

export enum Ctp_GolfRoundStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Delayed = "DELAYED",
  InProgress = "IN_PROGRESS",
  Reopened = "REOPENED",
  Scheduled = "SCHEDULED",
  Suspended = "SUSPENDED",
}

export type Ctp_GolfSeasonStatisticsFilterInput = {
  previousYears?: InputMaybe<Ctp_PreviousYearsFilterInput>;
  yearRange?: InputMaybe<Ctp_YearRangeFilterInput>;
};

export type Ctp_GolfSelection = {
  __typename?: "CTP_GolfSelection";
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Ctp_GolfTour {
  Championship = "CHAMPIONSHIP",
  European = "EUROPEAN",
  Liv = "LIV",
  Lpga = "LPGA",
  Olympic = "OLYMPIC",
  Pga = "PGA",
  PgaDevelopment = "PGA_DEVELOPMENT",
}

export type Ctp_GolfTournament = {
  __typename?: "CTP_GolfTournament";
  endDate?: Maybe<Scalars["CTP_LocalDate"]>;
  eventId?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Ctp_GolfEventType>;
  leaderboard?: Maybe<Ctp_GolfTournamentLeaderboard>;
  name?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["CTP_LocalDate"]>;
  /** Provides statistics relating to the supplied golf tournament. */
  statistics?: Maybe<Ctp_GolfTournamentStatistics>;
  status?: Maybe<Ctp_GolfTournamentStatus>;
  tour?: Maybe<Ctp_GolfTour>;
  tourSeasonYear?: Maybe<Scalars["Int"]>;
  /** Gets specific details of the supplied golf tournament. */
  tournamentDetails?: Maybe<Ctp_GolfTournamentDetails>;
};

/** Scoring results for the tournament pertaining to the course. */
export type Ctp_GolfTournamentCourseScoringResults = {
  __typename?: "CTP_GolfTournamentCourseScoringResults";
  /** Scoring results broken out by hole and round. */
  holeScoringResults?: Maybe<Ctp_GolfCourseHoleScoringResults>;
  /** Scoring results broken out by round and hole. */
  roundScoringResults?: Maybe<Ctp_GolfCourseRoundScoringResults>;
  /** Scoring results for the entire tournament. */
  tournamentScoringResults?: Maybe<Ctp_GolfCourseTournamentScoringResults>;
};

export type Ctp_GolfTournamentDetails = {
  __typename?: "CTP_GolfTournamentDetails";
  courses?: Maybe<Array<Maybe<Ctp_GolfCourse>>>;
  rounds?: Maybe<Array<Maybe<Ctp_GolfRound>>>;
};

export type Ctp_GolfTournamentLeader = {
  __typename?: "CTP_GolfTournamentLeader";
  abbreviatedName?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  money?: Maybe<Scalars["CTP_BigDecimal"]>;
  playerId?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["CTP_BigDecimal"]>;
  position?: Maybe<Scalars["Int"]>;
  rounds?: Maybe<Array<Maybe<Ctp_GolfLeaderboardRound>>>;
  score?: Maybe<Scalars["Int"]>;
  status?: Maybe<Ctp_GolfPlayerStatus>;
  strokes?: Maybe<Scalars["Int"]>;
  tied?: Maybe<Scalars["Boolean"]>;
};

export type Ctp_GolfTournamentLeaderRoundHoleScoringResult = {
  __typename?: "CTP_GolfTournamentLeaderRoundHoleScoringResult";
  holeNumber?: Maybe<Scalars["Int"]>;
  roundIdentifiers?: Maybe<Array<Maybe<Ctp_GolfRoundIdentifier>>>;
  scoreResult?: Maybe<Ctp_GolfHoleScoreResult>;
  strokes?: Maybe<Scalars["Int"]>;
};

export type Ctp_GolfTournamentLeaderScoringResult = {
  __typename?: "CTP_GolfTournamentLeaderScoringResult";
  /** How many times the player has scored result this during the tournament. */
  cumulative?: Maybe<Scalars["Int"]>;
  firstName?: Maybe<Scalars["String"]>;
  holeScoreResult?: Maybe<Ctp_GolfHoleScoreResult>;
  /** Which hole(s) and round(s) this player has scored that result on. */
  holeScoreResultsByRoundAndHole?: Maybe<
    Array<Maybe<Ctp_GolfTournamentLeaderRoundHoleScoringResult>>
  >;
  lastName?: Maybe<Scalars["String"]>;
  playerId?: Maybe<Scalars["String"]>;
};

export type Ctp_GolfTournamentLeaderboard = {
  __typename?: "CTP_GolfTournamentLeaderboard";
  leaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeader>>>;
};

/** Players who have scored the most number of each result. There may be multiple players who have scored the same result the same number of times. */
export type Ctp_GolfTournamentLeadersScoringResults = {
  __typename?: "CTP_GolfTournamentLeadersScoringResults";
  birdieLeaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>>;
  bogeyLeaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>>;
  doubleBogeyLeaders?: Maybe<
    Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>
  >;
  doubleEagleLeaders?: Maybe<
    Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>
  >;
  eagleLeaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>>;
  holeInOneLeaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>>;
  otherScoreLeaders?: Maybe<
    Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>
  >;
  parLeaders?: Maybe<Array<Maybe<Ctp_GolfTournamentLeaderScoringResult>>>;
};

/** Scoring results for the tournament pertaining to the player. */
export type Ctp_GolfTournamentPlayerScoringResults = {
  __typename?: "CTP_GolfTournamentPlayerScoringResults";
  firstName?: Maybe<Scalars["String"]>;
  /** Scoring results broken out by hole and round. */
  holeScoringResults?: Maybe<Ctp_GolfPlayerHoleScoringResults>;
  lastName?: Maybe<Scalars["String"]>;
  playerId?: Maybe<Scalars["String"]>;
  /** Scoring results broken out by round and hole. */
  roundScoringResults?: Maybe<Ctp_GolfPlayerRoundScoringResults>;
  /** Scoring results for the entire tournament. */
  tournamentScoringResults?: Maybe<Ctp_GolfPlayerTournamentScoringResults>;
};

export type Ctp_GolfTournamentPlayersScoringResults = {
  __typename?: "CTP_GolfTournamentPlayersScoringResults";
  leaders?: Maybe<Ctp_GolfTournamentLeadersScoringResults>;
  players?: Maybe<Array<Maybe<Ctp_GolfTournamentPlayerScoringResults>>>;
};

/** Information that can be applied to querying golf tournament schedules. */
export type Ctp_GolfTournamentScheduleContextInput = {
  endDate?: InputMaybe<Scalars["CTP_LocalDate"]>;
  golfTours: Array<InputMaybe<Ctp_GolfTour>>;
  startDate?: InputMaybe<Scalars["CTP_LocalDate"]>;
  tournamentName?: InputMaybe<Scalars["String"]>;
};

export type Ctp_GolfTournamentScoringResults = {
  __typename?: "CTP_GolfTournamentScoringResults";
  course?: Maybe<Ctp_GolfTournamentCourseScoringResults>;
  player?: Maybe<Ctp_GolfTournamentPlayersScoringResults>;
};

export type Ctp_GolfTournamentStatistics = {
  __typename?: "CTP_GolfTournamentStatistics";
  scoringResults?: Maybe<Ctp_GolfTournamentScoringResults>;
};

export enum Ctp_GolfTournamentStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  InProgress = "IN_PROGRESS",
  Playoff = "PLAYOFF",
  Reopened = "REOPENED",
  Scheduled = "SCHEDULED",
}

export type Ctp_GolfWeather = {
  __typename?: "CTP_GolfWeather";
  condition?: Maybe<Scalars["String"]>;
  temperature?: Maybe<Scalars["Int"]>;
};

export type Ctp_LeaguePlayerStatisticsContext_GolfPlayer = {
  __typename?: "CTP_LeaguePlayerStatisticsContext_GolfPlayer";
  /** Season stats for the selected player. */
  seasons?: Maybe<Array<Maybe<Ctp_GolfPlayerSeasonStatistics>>>;
};

export type Ctp_LeaguePlayerStatisticsContext_GolfPlayerSeasonsArgs = {
  seasonStatisticsFilter?: InputMaybe<Ctp_GolfSeasonStatisticsFilterInput>;
};

export type Ctp_LeaguePlayerStatisticsContext_MlbLeaguePlayer = {
  __typename?: "CTP_LeaguePlayerStatisticsContext_MlbLeaguePlayer";
  /** Season stats for the selected player. */
  seasons?: Maybe<Array<Maybe<Ctp_MlbPlayerSeasonStatistics>>>;
};

export type Ctp_LeaguePlayerStatisticsContext_MlbLeaguePlayerSeasonsArgs = {
  seasonStatisticsFilter?: InputMaybe<Ctp_MlbSeasonStatisticsFilterInput>;
};

export type Ctp_LeaguePlayerStatisticsContext_NbaLeaguePlayer = {
  __typename?: "CTP_LeaguePlayerStatisticsContext_NbaLeaguePlayer";
  /** Game stats for the selected player by season type */
  games?: Maybe<Array<Maybe<Ctp_NbaPlayerGameStatisticsBySeason>>>;
  /** Season stats for the selected player by season type. */
  seasons?: Maybe<Array<Maybe<Ctp_NbaPlayerSeasonStatisticsBySeason>>>;
};

export type Ctp_LeaguePlayerStatisticsContext_NbaLeaguePlayerSeasonsArgs = {
  filter?: InputMaybe<Ctp_LeaguePlayerStatisticsFilterInput>;
};

export type Ctp_LeaguePlayerStatisticsContext_NflLeaguePlayer = {
  __typename?: "CTP_LeaguePlayerStatisticsContext_NflLeaguePlayer";
  /** Game stats for the selected player by season type */
  games?: Maybe<Array<Maybe<Ctp_NflPlayerGameStatisticsBySeason>>>;
  /** Season stats for the selected player by season type. */
  seasons?: Maybe<Array<Maybe<Ctp_NflPlayerSeasonStatisticsBySeason>>>;
};

export type Ctp_LeaguePlayerStatisticsContext_NflLeaguePlayerSeasonsArgs = {
  filter?: InputMaybe<Ctp_LeaguePlayerStatisticsFilterInput>;
};

export type Ctp_LeaguePlayerStatisticsFilterInput = {
  /** The type of season the stats should be pulled for. i.e. PRE, REG, PST, IST... */
  seasonType?: InputMaybe<Scalars["String"]>;
  /** The season year the stats should be pulled for. i.e. 2023. */
  seasonYear?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_Mlb = {
  __typename?: "CTP_MLB";
  futures?: Maybe<Array<Maybe<Ctp_MlbTeamFutureOdd>>>;
  /** Get live scoring for Sportsbook events */
  liveScoring?: Maybe<Array<Maybe<Ctp_BaseballEventScore>>>;
  players?: Maybe<Array<Maybe<Ctp_MlbLeaguePlayer>>>;
  schedule?: Maybe<Array<Maybe<Ctp_MlbLeagueSchedule>>>;
  /** @deprecated Sitemap provides inconsistent player slugs so it is being removed.  If player ids and player slugs are needed please use teams -> teamPlayers to get this info since it uses Almanac */
  sitemap?: Maybe<Array<Maybe<Ctp_MlbSiteMapPlayer>>>;
  teams?: Maybe<Array<Maybe<Ctp_MlbTeam>>>;
};

export type Ctp_MlbFuturesArgs = {
  marketType?: InputMaybe<Scalars["String"]>;
};

export type Ctp_MlbLiveScoringArgs = {
  eventIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type Ctp_MlbPlayersArgs = {
  numberFirePlayerSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerIdentityFilters?: InputMaybe<
    Array<InputMaybe<Ctp_PlayerIdentityFilterInput>>
  >;
};

export type Ctp_MlbScheduleArgs = {
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
};

export type Ctp_MlbTeamsArgs = {
  filter?: InputMaybe<Ctp_TeamFilterInput>;
};

export type Ctp_MastersHistoricalWinners = {
  __typename?: "CTP_MastersHistoricalWinners";
  playerName?: Maybe<Scalars["String"]>;
  victoryYear?: Maybe<Scalars["Int"]>;
  winningScoreMargin?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbBetRecord = {
  __typename?: "CTP_MlbBetRecord";
  lose?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbBetRecords = {
  __typename?: "CTP_MlbBetRecords";
  againstSpread?: Maybe<Ctp_MlbBetRecord>;
  moneyLine?: Maybe<Ctp_MlbBetRecord>;
  overUnder?: Maybe<Ctp_MlbBetRecord>;
};

export type Ctp_MlbBroadcastInfo = {
  __typename?: "CTP_MlbBroadcastInfo";
  network?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbCalculatedTeamStats = {
  __typename?: "CTP_MlbCalculatedTeamStats";
  gamesPlayed?: Maybe<Scalars["Int"]>;
  runDiff?: Maybe<Scalars["Int"]>;
  runDiffAverage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_MlbFutureOdd = {
  __typename?: "CTP_MlbFutureOdd";
  description?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbGame = {
  __typename?: "CTP_MlbGame";
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_MlbTeamMetadata>;
  awayTeamRuns?: Maybe<Scalars["Int"]>;
  awayTeamSportradarId?: Maybe<Scalars["String"]>;
  broadcastInfo?: Maybe<Ctp_MlbBroadcastInfo>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_MlbTeamMetadata>;
  homeTeamRuns?: Maybe<Scalars["Int"]>;
  homeTeamSportradarId?: Maybe<Scalars["String"]>;
  odds?: Maybe<Ctp_MlbGameOdds>;
  playoffs?: Maybe<Scalars["Boolean"]>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  scheduledTimeTbd?: Maybe<Scalars["Boolean"]>;
  sportRadarGameId?: Maybe<Scalars["String"]>;
  status?: Maybe<Ctp_SrMlbGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbGameOdds = {
  __typename?: "CTP_MlbGameOdds";
  away?: Maybe<Ctp_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Ctp_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbGamePitcher = {
  __typename?: "CTP_MlbGamePitcher";
  era?: Maybe<Scalars["CTP_BigDecimal"]>;
  firstName?: Maybe<Scalars["String"]>;
  jerseyNumber?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  loss: Scalars["Int"];
  preferredName?: Maybe<Scalars["String"]>;
  win: Scalars["Int"];
};

export type Ctp_MlbGamePitcherMatchup = {
  __typename?: "CTP_MlbGamePitcherMatchup";
  awayPitcher?: Maybe<Ctp_MlbGamePitcher>;
  homePitcher?: Maybe<Ctp_MlbGamePitcher>;
};

export type Ctp_MlbGamePlayerProps = {
  __typename?: "CTP_MlbGamePlayerProps";
  awayTeamCode?: Maybe<Scalars["String"]>;
  eventTime?: Maybe<Scalars["String"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  propsForAGames?: Maybe<Array<Maybe<Ctp_PropsForAGame>>>;
};

export type Ctp_MlbGameWeather = {
  __typename?: "CTP_MlbGameWeather";
  condition?: Maybe<Scalars["String"]>;
  humidity?: Maybe<Scalars["Int"]>;
  tempInF?: Maybe<Scalars["Int"]>;
  windDirection?: Maybe<Scalars["String"]>;
  windSpeedInMph?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbHittingTeamStats = {
  __typename?: "CTP_MlbHittingTeamStats";
  atBats?: Maybe<Scalars["Int"]>;
  battingAverage?: Maybe<Scalars["String"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  onBasePct?: Maybe<Scalars["CTP_BigDecimal"]>;
  rbis?: Maybe<Scalars["Int"]>;
  runsScored?: Maybe<Scalars["Int"]>;
  sluggingPct?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_MlbLeaguePlayer = {
  __typename?: "CTP_MlbLeaguePlayer";
  injuryStatus?: Maybe<Ctp_MlbPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_MlbPlayerInfo>;
  playerProps?: Maybe<Ctp_MlbPlayerProps>;
  /** Popular Bets provided by the FDX api with multiple bet types */
  popularBets?: Maybe<Ctp_MlbPlayerPopularBetsContext>;
  statistics?: Maybe<Ctp_LeaguePlayerStatisticsContext_MlbLeaguePlayer>;
  team?: Maybe<Ctp_MlbTeam>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbLeagueSchedule = {
  __typename?: "CTP_MlbLeagueSchedule";
  games?: Maybe<Array<Maybe<Ctp_MlbGame>>>;
  seasonType?: Maybe<Ctp_SrMlbSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbPitchingTeamStats = {
  __typename?: "CTP_MlbPitchingTeamStats";
  era?: Maybe<Scalars["CTP_BigDecimal"]>;
  pitchingLosses?: Maybe<Scalars["Int"]>;
  pitchingWins?: Maybe<Scalars["Int"]>;
  runsAllowed?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerInfo = {
  __typename?: "CTP_MlbPlayerInfo";
  age: Scalars["Int"];
  college?: Maybe<Scalars["String"]>;
  draft?: Maybe<Ctp_DepthChartDraft>;
  height?: Maybe<Scalars["String"]>;
  highSchool?: Maybe<Scalars["String"]>;
  jerseyNumber?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  primaryPosition?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbPlayerInjuryStatus = {
  __typename?: "CTP_MlbPlayerInjuryStatus";
  detail?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbPlayerPopularBetsContext = {
  __typename?: "CTP_MlbPlayerPopularBetsContext";
  /** A combination of single bets represented as one bet */
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** A combination of same game parlays and possibly other bet types */
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Parlays of bets within the same event */
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Individual popular bets provided by the FDX api */
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_MlbPlayerPopularBetsContextParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_MlbPlayerPopularBetsContextSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_MlbPlayerPopularBetsContextSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_MlbPlayerProps = {
  __typename?: "CTP_MlbPlayerProps";
  contentText?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<Ctp_MlbPlayerPropsEvent>>>;
  id?: Maybe<Scalars["String"]>;
  playerJersey?: Maybe<Ctp_PlayerPropsJersey>;
};

export type Ctp_MlbPlayerPropsEvent = {
  __typename?: "CTP_MlbPlayerPropsEvent";
  date?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  eventName?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_MlbPlayerPropsMarket>>>;
};

export type Ctp_MlbPlayerPropsMarket = {
  __typename?: "CTP_MlbPlayerPropsMarket";
  alternateMarket?: Maybe<Scalars["Boolean"]>;
  contentText?: Maybe<Scalars["String"]>;
  eligibleForSameGameParlay?: Maybe<Scalars["Boolean"]>;
  externalMarketId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  marketTypeName?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_MlbPlayerPropsSelection>>>;
};

export type Ctp_MlbPlayerPropsSelection = {
  __typename?: "CTP_MlbPlayerPropsSelection";
  betUrl?: Maybe<Scalars["String"]>;
  contentText?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonGames = {
  __typename?: "CTP_MlbPlayerSeasonGames";
  complete?: Maybe<Scalars["Int"]>;
  finish?: Maybe<Scalars["Int"]>;
  play?: Maybe<Scalars["Int"]>;
  start?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonHittingOverallSplits = {
  __typename?: "CTP_MlbPlayerSeasonHittingOverallSplits";
  byDayNight?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue>>
  >;
  byHomeAway?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue>>
  >;
  byMonth?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue>>
  >;
  byOpponent?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsOpponentStatistic>>
  >;
  byPitcherHand?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue>>
  >;
  bySurface?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue>>
  >;
  byVenue?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplitsVenueStatistic>>
  >;
};

export type Ctp_MlbPlayerSeasonHittingOverallSplitsOpponentStatistic = {
  __typename?: "CTP_MlbPlayerSeasonHittingOverallSplitsOpponentStatistic";
  atBats?: Maybe<Scalars["Int"]>;
  battingAverage?: Maybe<Scalars["String"]>;
  caughtStealing?: Maybe<Scalars["Int"]>;
  doubles?: Maybe<Scalars["Int"]>;
  hitByPitches?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePercentagePlusSluggingPercentage?: Maybe<Scalars["Float"]>;
  opponentSportRadarTeamId?: Maybe<Scalars["String"]>;
  runs?: Maybe<Scalars["Int"]>;
  runsBattedIn?: Maybe<Scalars["Int"]>;
  singles?: Maybe<Scalars["Int"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stolenBases?: Maybe<Scalars["Int"]>;
  strikeOuts?: Maybe<Scalars["Int"]>;
  triples?: Maybe<Scalars["Int"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue = {
  __typename?: "CTP_MlbPlayerSeasonHittingOverallSplitsStatisticWithValue";
  atBats?: Maybe<Scalars["Int"]>;
  battingAverage?: Maybe<Scalars["String"]>;
  caughtStealing?: Maybe<Scalars["Int"]>;
  doubles?: Maybe<Scalars["Int"]>;
  hitByPitches?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePercentagePlusSluggingPercentage?: Maybe<Scalars["Float"]>;
  runs?: Maybe<Scalars["Int"]>;
  runsBattedIn?: Maybe<Scalars["Int"]>;
  singles?: Maybe<Scalars["Int"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stolenBases?: Maybe<Scalars["Int"]>;
  strikeOuts?: Maybe<Scalars["Int"]>;
  triples?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonHittingOverallSplitsVenueStatistic = {
  __typename?: "CTP_MlbPlayerSeasonHittingOverallSplitsVenueStatistic";
  atBats?: Maybe<Scalars["Int"]>;
  battingAverage?: Maybe<Scalars["String"]>;
  caughtStealing?: Maybe<Scalars["Int"]>;
  doubles?: Maybe<Scalars["Int"]>;
  hitByPitches?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePercentagePlusSluggingPercentage?: Maybe<Scalars["Float"]>;
  runs?: Maybe<Scalars["Int"]>;
  runsBattedIn?: Maybe<Scalars["Int"]>;
  singles?: Maybe<Scalars["Int"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  sportRadarVenueId?: Maybe<Scalars["String"]>;
  stolenBases?: Maybe<Scalars["Int"]>;
  strikeOuts?: Maybe<Scalars["Int"]>;
  surface?: Maybe<Scalars["String"]>;
  triples?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonHittingOverallStatistics = {
  __typename?: "CTP_MlbPlayerSeasonHittingOverallStatistics";
  atBats?: Maybe<Scalars["Int"]>;
  atBatsPerHomeRun?: Maybe<Scalars["Float"]>;
  atBatsPerStrikeout?: Maybe<Scalars["Float"]>;
  atBatsWithRISP?: Maybe<Scalars["Int"]>;
  ballsInPlay?: Maybe<Scalars["Int"]>;
  baseRunningWar?: Maybe<Scalars["Float"]>;
  battingAverage?: Maybe<Scalars["String"]>;
  battingAverageOnBallsInPlay?: Maybe<Scalars["Float"]>;
  battingWar?: Maybe<Scalars["Float"]>;
  extraBaseHits?: Maybe<Scalars["Int"]>;
  fieldingWar?: Maybe<Scalars["Float"]>;
  flyBalls?: Maybe<Scalars["Int"]>;
  games?: Maybe<Ctp_MlbPlayerSeasonGames>;
  groundBalls?: Maybe<Scalars["Int"]>;
  groundOutFlyOutRatio?: Maybe<Scalars["Float"]>;
  hitsWithRISP?: Maybe<Scalars["Int"]>;
  isolatedPower?: Maybe<Scalars["Float"]>;
  lineDrives?: Maybe<Scalars["Int"]>;
  onBase?: Maybe<Ctp_MlbPlayerSeasonOnBase>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePercentagePlusSluggingPercentage?: Maybe<Scalars["Float"]>;
  outcome?: Maybe<Ctp_MlbPlayerSeasonOutcome>;
  outs?: Maybe<Ctp_MlbPlayerSeasonOuts>;
  pitchCount?: Maybe<Scalars["Int"]>;
  pitches?: Maybe<Ctp_MlbPlayerSeasonPitches>;
  plateAppearances?: Maybe<Scalars["Int"]>;
  popups?: Maybe<Scalars["Int"]>;
  runnersLeftOnBase?: Maybe<Scalars["Int"]>;
  runnersLeftOnBaseEndInning?: Maybe<Scalars["Int"]>;
  runnersLeftOnBaseWithRISPAnd2outs?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Ctp_MlbPlayerSeasonRuns>;
  runsBattedIn?: Maybe<Scalars["Int"]>;
  runsBattedInWith2outs?: Maybe<Scalars["Int"]>;
  secondaryAverage?: Maybe<Scalars["Float"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stealing?: Maybe<Ctp_MlbPlayerSeasonSteal>;
  walksPerPlateAppearance?: Maybe<Scalars["Float"]>;
  walksPerStrikeout?: Maybe<Scalars["Float"]>;
  weightedGroundToDoublePlays?: Maybe<Scalars["Float"]>;
  weightedOnBaseAverage?: Maybe<Scalars["Float"]>;
  weightedRunsAboveAverage?: Maybe<Scalars["Float"]>;
  winsAboveReplacement?: Maybe<Scalars["Float"]>;
};

export type Ctp_MlbPlayerSeasonHittingStatistics = {
  __typename?: "CTP_MlbPlayerSeasonHittingStatistics";
  overall?: Maybe<Ctp_MlbPlayerSeasonHittingOverallStatistics>;
  overallSplits?: Maybe<Array<Maybe<Ctp_MlbPlayerSeasonHittingOverallSplits>>>;
};

export type Ctp_MlbPlayerSeasonInPlay = {
  __typename?: "CTP_MlbPlayerSeasonInPlay";
  flyball?: Maybe<Scalars["Int"]>;
  groundball?: Maybe<Scalars["Int"]>;
  linedrive?: Maybe<Scalars["Int"]>;
  popup?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonOnBase = {
  __typename?: "CTP_MlbPlayerSeasonOnBase";
  cycles?: Maybe<Scalars["Int"]>;
  doubles?: Maybe<Scalars["Int"]>;
  fieldersChoice?: Maybe<Scalars["Int"]>;
  hitByPitches?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  reachedOnError?: Maybe<Scalars["Int"]>;
  singles?: Maybe<Scalars["Int"]>;
  totalBases?: Maybe<Scalars["Int"]>;
  triples?: Maybe<Scalars["Int"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonOutcome = {
  __typename?: "CTP_MlbPlayerSeasonOutcome";
  balls?: Maybe<Scalars["Int"]>;
  dirtballs?: Maybe<Scalars["Int"]>;
  foulBalls?: Maybe<Scalars["Int"]>;
  intentionalBalls?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  strikeoutsLooking?: Maybe<Scalars["Int"]>;
  strikeoutsSwinging?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonOuts = {
  __typename?: "CTP_MlbPlayerSeasonOuts";
  flyouts?: Maybe<Scalars["Int"]>;
  flyoutsToDoublePlay?: Maybe<Scalars["Int"]>;
  groundouts?: Maybe<Scalars["Int"]>;
  groundoutsToDoublePlay?: Maybe<Scalars["Int"]>;
  lineouts?: Maybe<Scalars["Int"]>;
  lineoutsToDoublePlay?: Maybe<Scalars["Int"]>;
  popouts?: Maybe<Scalars["Int"]>;
  sacrificeFlys?: Maybe<Scalars["Int"]>;
  sacrificeHits?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  strikeoutsLooking?: Maybe<Scalars["Int"]>;
  strikeoutsSwinging?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonOverallStatistics = {
  __typename?: "CTP_MlbPlayerSeasonOverallStatistics";
  hitting?: Maybe<Ctp_MlbPlayerSeasonHittingStatistics>;
  pitching?: Maybe<Ctp_MlbPlayerSeasonPitchingStatistics>;
};

export type Ctp_MlbPlayerSeasonPitches = {
  __typename?: "CTP_MlbPlayerSeasonPitches";
  balls?: Maybe<Scalars["Int"]>;
  count?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplits = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplits";
  byDayNight?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue>>
  >;
  byHitterHand?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsHitterHandStatistic>>
  >;
  byHomeAway?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue>>
  >;
  byMonth?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue>>
  >;
  byOpponent?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsOpponentStatistic>>
  >;
  bySurface?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue>>
  >;
  byVenue?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsVenueStatistic>>
  >;
  lastStart?: Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplitsLastStartStatistic>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplitsHitterHandStatistic = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplitsHitterHandStatistic";
  battersFaced?: Maybe<Scalars["Int"]>;
  battersHit?: Maybe<Scalars["Int"]>;
  caughtStealing?: Maybe<Scalars["Int"]>;
  doubles?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePercentagePlusSluggingPercentage?: Maybe<Scalars["Float"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  runs?: Maybe<Scalars["Int"]>;
  runsBattedIn?: Maybe<Scalars["Int"]>;
  singles?: Maybe<Scalars["Int"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stolenBasesAllowed?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  triples?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplitsLastStartStatistic = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplitsLastStartStatistic";
  completeGames?: Maybe<Scalars["Int"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  earnedRunsAllowed?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  losses?: Maybe<Scalars["Int"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  opponentSportRadarTeamId?: Maybe<Scalars["String"]>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Scalars["Int"]>;
  saveOpportunities?: Maybe<Scalars["Int"]>;
  saves?: Maybe<Scalars["Int"]>;
  scheduledDate?: Maybe<Scalars["CTP_Instant"]>;
  sportRadarGameId?: Maybe<Scalars["String"]>;
  starts?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  teamLosses?: Maybe<Scalars["Int"]>;
  teamWins?: Maybe<Scalars["Int"]>;
  walks?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplitsOpponentStatistic = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplitsOpponentStatistic";
  completeGames?: Maybe<Scalars["Int"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  earnedRunsAllowed?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  losses?: Maybe<Scalars["Int"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  opponentSportRadarTeamId?: Maybe<Scalars["String"]>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Scalars["Int"]>;
  saveOpportunities?: Maybe<Scalars["Int"]>;
  saves?: Maybe<Scalars["Int"]>;
  starts?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  teamLosses?: Maybe<Scalars["Int"]>;
  teamWins?: Maybe<Scalars["Int"]>;
  walks?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplitsStatisticWithValue";
  completeGames?: Maybe<Scalars["Int"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  earnedRunsAllowed?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  losses?: Maybe<Scalars["Int"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Scalars["Int"]>;
  saveOpportunities?: Maybe<Scalars["Int"]>;
  saves?: Maybe<Scalars["Int"]>;
  starts?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  teamLosses?: Maybe<Scalars["Int"]>;
  teamWins?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallSplitsVenueStatistic = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallSplitsVenueStatistic";
  completeGames?: Maybe<Scalars["Int"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  earnedRunsAllowed?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  hits?: Maybe<Scalars["Int"]>;
  homeRuns?: Maybe<Scalars["Int"]>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  intentionalWalks?: Maybe<Scalars["Int"]>;
  losses?: Maybe<Scalars["Int"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Scalars["Int"]>;
  saveOpportunities?: Maybe<Scalars["Int"]>;
  saves?: Maybe<Scalars["Int"]>;
  sportRadarVenueId?: Maybe<Scalars["String"]>;
  starts?: Maybe<Scalars["Int"]>;
  strikeouts?: Maybe<Scalars["Int"]>;
  surface?: Maybe<Scalars["String"]>;
  teamLosses?: Maybe<Scalars["Int"]>;
  teamWins?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonPitchingOverallStatistics = {
  __typename?: "CTP_MlbPlayerSeasonPitchingOverallStatistics";
  balks?: Maybe<Scalars["Int"]>;
  battersFaced?: Maybe<Scalars["Int"]>;
  battersFacedPerInning?: Maybe<Scalars["Float"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  eraMinus?: Maybe<Scalars["Float"]>;
  expectedFielding?: Maybe<Scalars["Float"]>;
  fieldingIndependentPitching?: Maybe<Scalars["Float"]>;
  games?: Maybe<Ctp_MlbPlayerSeasonGames>;
  groundBallFlyBallRatio?: Maybe<Scalars["Float"]>;
  groundOutFlyOutRatio?: Maybe<Scalars["Float"]>;
  inPlay?: Maybe<Ctp_MlbPlayerSeasonInPlay>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  onBase?: Maybe<Ctp_MlbPlayerSeasonOnBase>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  opponentAtBats?: Maybe<Scalars["Int"]>;
  opponentBabip?: Maybe<Scalars["Float"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  outcomes?: Maybe<Ctp_MlbPlayerSeasonOutcome>;
  outs?: Maybe<Ctp_MlbPlayerSeasonOuts>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  pitchCount?: Maybe<Scalars["Int"]>;
  pitches?: Maybe<Ctp_MlbPlayerSeasonPitches>;
  runnersLeftOnBase?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Ctp_MlbPlayerSeasonRuns>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stealing?: Maybe<Ctp_MlbPlayerSeasonSteal>;
  strikeoutsPer9Innings?: Maybe<Scalars["Float"]>;
  strikeoutsPerWalk?: Maybe<Scalars["Float"]>;
  walksHitsPerInning?: Maybe<Scalars["Float"]>;
  wildPitches?: Maybe<Scalars["Int"]>;
  winsAboveReplacement?: Maybe<Scalars["Float"]>;
};

export type Ctp_MlbPlayerSeasonPitchingStartingStatistics = {
  __typename?: "CTP_MlbPlayerSeasonPitchingStartingStatistics";
  balks?: Maybe<Scalars["Int"]>;
  battersFacePerStart?: Maybe<Scalars["Float"]>;
  battersFaced?: Maybe<Scalars["Int"]>;
  battersFacedPerInning?: Maybe<Scalars["Float"]>;
  earnedRunAverage?: Maybe<Scalars["Float"]>;
  eraMinus?: Maybe<Scalars["Float"]>;
  expectedFielding?: Maybe<Scalars["Float"]>;
  fieldingIndependentPitching?: Maybe<Scalars["Float"]>;
  games?: Maybe<Ctp_MlbPlayerSeasonGames>;
  groundBallFlyBallRatio?: Maybe<Scalars["Float"]>;
  groundOutFlyOutRatio?: Maybe<Scalars["Float"]>;
  inPlay?: Maybe<Ctp_MlbPlayerSeasonInPlay>;
  inningsRecorded?: Maybe<Scalars["Float"]>;
  onBase?: Maybe<Ctp_MlbPlayerSeasonOnBase>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  opponentAtBats?: Maybe<Scalars["Int"]>;
  opponentBabip?: Maybe<Scalars["Float"]>;
  opponentBattingAverage?: Maybe<Scalars["Float"]>;
  outcomes?: Maybe<Ctp_MlbPlayerSeasonOutcome>;
  outs?: Maybe<Ctp_MlbPlayerSeasonOuts>;
  outsRecorded?: Maybe<Scalars["Int"]>;
  pitchCount?: Maybe<Scalars["Int"]>;
  pitches?: Maybe<Ctp_MlbPlayerSeasonPitches>;
  runnersLeftOnBase?: Maybe<Scalars["Int"]>;
  runs?: Maybe<Ctp_MlbPlayerSeasonRuns>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stealing?: Maybe<Ctp_MlbPlayerSeasonSteal>;
  strikeoutsPer9Innings?: Maybe<Scalars["Float"]>;
  strikeoutsPerWalk?: Maybe<Scalars["Float"]>;
  walksHitsPerInning?: Maybe<Scalars["Float"]>;
  wildPitches?: Maybe<Scalars["Int"]>;
  winsAboveReplacement?: Maybe<Scalars["Float"]>;
};

export type Ctp_MlbPlayerSeasonPitchingStatistics = {
  __typename?: "CTP_MlbPlayerSeasonPitchingStatistics";
  overall?: Maybe<Ctp_MlbPlayerSeasonPitchingOverallStatistics>;
  overallSplits?: Maybe<Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplits>>>;
  starting?: Maybe<Ctp_MlbPlayerSeasonPitchingStartingStatistics>;
  startingSplits?: Maybe<
    Array<Maybe<Ctp_MlbPlayerSeasonPitchingOverallSplits>>
  >;
};

export type Ctp_MlbPlayerSeasonRuns = {
  __typename?: "CTP_MlbPlayerSeasonRuns";
  total?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonStatistics = {
  __typename?: "CTP_MlbPlayerSeasonStatistics";
  overallStatistics?: Maybe<Ctp_MlbPlayerSeasonOverallStatistics>;
  seasonType?: Maybe<Ctp_MlbSeasonType>;
  sportRadarSeasonId?: Maybe<Scalars["String"]>;
  statisticsByTeam?: Maybe<Array<Maybe<Ctp_MlbPlayerSeasonStatisticsByTeam>>>;
  year?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPlayerSeasonStatisticsByTeam = {
  __typename?: "CTP_MlbPlayerSeasonStatisticsByTeam";
  hitting?: Maybe<Ctp_MlbPlayerSeasonHittingStatistics>;
  pitching?: Maybe<Ctp_MlbPlayerSeasonPitchingStatistics>;
  sportRadarTeamId?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbPlayerSeasonSteal = {
  __typename?: "CTP_MlbPlayerSeasonSteal";
  caught?: Maybe<Scalars["Int"]>;
  caughtStealingPercentage?: Maybe<Scalars["Float"]>;
  pickOffs?: Maybe<Scalars["Int"]>;
  stolenBases?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbPowerRanking = {
  __typename?: "CTP_MlbPowerRanking";
  playoffProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
  rank?: Maybe<Scalars["Int"]>;
  rankingsDate?: Maybe<Scalars["CTP_LocalDate"]>;
  seasonYear: Scalars["Int"];
  worldSeriesProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_MlbPrevNextGame = {
  __typename?: "CTP_MlbPrevNextGame";
  nextGames?: Maybe<Array<Maybe<Ctp_MlbUpcomingTeamGame>>>;
  previousGames?: Maybe<Array<Maybe<Ctp_MlbTeamGame>>>;
};

export type Ctp_MlbSeasonStatisticsFilterInput = {
  previousYears?: InputMaybe<Ctp_PreviousYearsFilterInput>;
  seasonTypes?: InputMaybe<Array<InputMaybe<Ctp_MlbSeasonType>>>;
  yearRange?: InputMaybe<Ctp_YearRangeFilterInput>;
};

export enum Ctp_MlbSeasonType {
  Allstar = "ALLSTAR",
  Post = "POST",
  Pre = "PRE",
  Regular = "REGULAR",
}

/** MLB SiteMapPlayer */
export type Ctp_MlbSiteMapPlayer = {
  __typename?: "CTP_MlbSiteMapPlayer";
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  lastName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nfPlayerSlug?: Maybe<Scalars["String"]>;
  numberFireTeamId?: Maybe<Scalars["Int"]>;
  playerId?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** Team type for MLB */
export type Ctp_MlbTeam = {
  __typename?: "CTP_MlbTeam";
  betRecords?: Maybe<Ctp_MlbBetRecords>;
  code?: Maybe<Scalars["String"]>;
  /** Deprecated. Moving to Team Metadata. */
  division?: Maybe<Scalars["String"]>;
  fanDuelTeamId?: Maybe<Scalars["Int"]>;
  /** Deprecated. Moving to Team Metadata. */
  league?: Maybe<Scalars["String"]>;
  meta?: Maybe<Ctp_MlbTeamMetadata>;
  numberFireId?: Maybe<Scalars["Int"]>;
  players?: Maybe<Array<Maybe<Ctp_MlbLeaguePlayer>>>;
  popularBets?: Maybe<Ctp_MlbTeamPopularBets>;
  powerRanking?: Maybe<Ctp_MlbPowerRanking>;
  prevNextGame?: Maybe<Ctp_MlbPrevNextGame>;
  record?: Maybe<Ctp_MlbTeamRecord>;
  selectionIds?: Maybe<Array<Maybe<Scalars["CTP_Long"]>>>;
  srId?: Maybe<Scalars["String"]>;
  teamFutures?: Maybe<Array<Maybe<Ctp_MlbFutureOdd>>>;
  teamLeader?: Maybe<Ctp_MlbTeamLeaders>;
  /** @deprecated Use team.players instead */
  teamPlayers?: Maybe<Array<Maybe<Ctp_MlbTeamPlayer>>>;
  teamSchedule?: Maybe<Array<Maybe<Ctp_MlbTeamSchedule>>>;
  teamStats?: Maybe<Ctp_MlbTeamSeasonalStats>;
};

/** Team type for MLB */
export type Ctp_MlbTeamPrevNextGameArgs = {
  numberOfNext?: InputMaybe<Scalars["Int"]>;
  numberOfPrevious?: InputMaybe<Scalars["Int"]>;
};

/** Team type for MLB */
export type Ctp_MlbTeamTeamStatsArgs = {
  teamStatsFilter?: InputMaybe<Ctp_TeamStatsFilterInput>;
};

export type Ctp_MlbTeamCapLogo = {
  __typename?: "CTP_MlbTeamCapLogo";
  svg?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbTeamFutureOdd = {
  __typename?: "CTP_MlbTeamFutureOdd";
  futureOdds?: Maybe<Array<Maybe<Ctp_MlbFutureOdd>>>;
  teamMeta?: Maybe<Ctp_MlbTeamMetadata>;
};

export type Ctp_MlbTeamGame = {
  __typename?: "CTP_MlbTeamGame";
  broadcastInfo?: Maybe<Ctp_MlbBroadcastInfo>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  opponentRecord?: Maybe<Ctp_MlbTeamRecord>;
  opponentRuns?: Maybe<Scalars["Int"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_MlbTeamMetadata>;
  opponentTeamSportradarId?: Maybe<Scalars["String"]>;
  playoffs?: Maybe<Scalars["Boolean"]>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  scheduledTimeTbd?: Maybe<Scalars["Boolean"]>;
  sportRadarGameId?: Maybe<Scalars["String"]>;
  status?: Maybe<Ctp_SrMlbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamRuns?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
};

/** Team Leader Stat for MLB which has an available Prop Bet */
export type Ctp_MlbTeamLeaderStatWithBet_BigDecimal = {
  __typename?: "CTP_MlbTeamLeaderStatWithBet_BigDecimal";
  leader?: Maybe<Ctp_MlbTeamPlayer>;
  leaderPropBet?: Maybe<Ctp_PropsForAGame>;
  statInformation?: Maybe<Scalars["String"]>;
  statLabel?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["CTP_BigDecimal"]>;
};

/** Team Leader Stat for MLB which has an available Prop Bet */
export type Ctp_MlbTeamLeaderStatWithBet_BigDecimalLeaderPropBetArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

/** Team Leader Stat for MLB which has an available Prop Bet */
export type Ctp_MlbTeamLeaderStatWithBet_Integer = {
  __typename?: "CTP_MlbTeamLeaderStatWithBet_Integer";
  leader?: Maybe<Ctp_MlbTeamPlayer>;
  leaderPropBet?: Maybe<Ctp_PropsForAGame>;
  statInformation?: Maybe<Scalars["String"]>;
  statLabel?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["Int"]>;
};

/** Team Leader Stat for MLB which has an available Prop Bet */
export type Ctp_MlbTeamLeaderStatWithBet_IntegerLeaderPropBetArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

/** Team Leader Stat for MLB */
export type Ctp_MlbTeamLeaderStat_Integer = {
  __typename?: "CTP_MlbTeamLeaderStat_Integer";
  leader?: Maybe<Ctp_MlbTeamPlayer>;
  statInformation?: Maybe<Scalars["String"]>;
  statLabel?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["Int"]>;
};

/** MLB Team Leaders by stat value */
export type Ctp_MlbTeamLeaders = {
  __typename?: "CTP_MlbTeamLeaders";
  battingAverage?: Maybe<Ctp_MlbTeamLeaderStatWithBet_BigDecimal>;
  hits?: Maybe<Ctp_MlbTeamLeaderStat_Integer>;
  homeRuns?: Maybe<Ctp_MlbTeamLeaderStatWithBet_Integer>;
  runs?: Maybe<Ctp_MlbTeamLeaderStatWithBet_Integer>;
  runsBattedIn?: Maybe<Ctp_MlbTeamLeaderStatWithBet_Integer>;
  /** Find the next game starting pitcher and the number of strikeouts they've got on the season */
  strikeouts?: Maybe<Ctp_MlbTeamLeaderStatWithBet_Integer>;
};

/** Metadata pertaining to MLB team */
export type Ctp_MlbTeamMetadata = {
  __typename?: "CTP_MlbTeamMetadata";
  capLogo?: Maybe<Ctp_MlbTeamCapLogo>;
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Ctp_TeamColors>;
  division?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  league?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_TeamLogo>;
  manager?: Maybe<Scalars["String"]>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbTeamPlayer = {
  __typename?: "CTP_MlbTeamPlayer";
  injuryStatus?: Maybe<Ctp_MlbPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_MlbPlayerInfo>;
  playerProps?: Maybe<Ctp_MlbPlayerProps>;
  /** Popular Bets provided by the FDX api with multiple bet types */
  popularBets?: Maybe<Ctp_MlbPlayerPopularBetsContext>;
  /** @deprecated This field will be available via playerInfo */
  position?: Maybe<Scalars["String"]>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbTeamPopularBets = {
  __typename?: "CTP_MlbTeamPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_MlbTeamPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_MlbTeamPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_MlbTeamPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

/** Team record type for MLB */
export type Ctp_MlbTeamRecord = {
  __typename?: "CTP_MlbTeamRecord";
  awayRecord?: Maybe<Ctp_WinLoseRecord>;
  centralDivisionRecord?: Maybe<Ctp_WinLoseRecord>;
  clinched?: Maybe<Scalars["String"]>;
  divisionRank?: Maybe<Scalars["Int"]>;
  eastDivisionRecord?: Maybe<Ctp_WinLoseRecord>;
  gamesBack?: Maybe<Scalars["CTP_BigDecimal"]>;
  homeRecord?: Maybe<Ctp_WinLoseRecord>;
  lastTenRecord?: Maybe<Ctp_WinLoseRecord>;
  leagueRank?: Maybe<Scalars["Int"]>;
  nonLeagueRecord?: Maybe<Ctp_WinLoseRecord>;
  streak?: Maybe<Ctp_Streak>;
  teamStats?: Maybe<Ctp_MlbTeamSeasonalStats>;
  westDivisionRecord?: Maybe<Ctp_WinLoseRecord>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

/** Team record type for MLB */
export type Ctp_MlbTeamRecordTeamStatsArgs = {
  teamStatsFilter?: InputMaybe<Ctp_TeamStatsFilterInput>;
};

export type Ctp_MlbTeamSchedule = {
  __typename?: "CTP_MlbTeamSchedule";
  games?: Maybe<Array<Maybe<Ctp_MlbTeamGame>>>;
  seasonType?: Maybe<Ctp_SrMlbSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_MlbTeamSeasonalStats = {
  __typename?: "CTP_MlbTeamSeasonalStats";
  currentTeam?: Maybe<Ctp_MlbTeamStats>;
  seasonType?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type Ctp_MlbTeamStats = {
  __typename?: "CTP_MlbTeamStats";
  mlbCalculatedTeamStats?: Maybe<Ctp_MlbCalculatedTeamStats>;
  mlbHittingTeamStats?: Maybe<Ctp_MlbHittingTeamStats>;
  mlbPitchingTeamStats?: Maybe<Ctp_MlbPitchingTeamStats>;
};

export type Ctp_MlbUpcomingTeamGame = {
  __typename?: "CTP_MlbUpcomingTeamGame";
  broadcastInfo?: Maybe<Ctp_MlbBroadcastInfo>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  eventId?: Maybe<Scalars["Int"]>;
  gamePlayerProps?: Maybe<Array<Maybe<Ctp_MlbGamePlayerProps>>>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_MlbGameOdds>;
  opponentRecord?: Maybe<Ctp_MlbTeamRecord>;
  opponentRuns?: Maybe<Scalars["Int"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_MlbTeamMetadata>;
  opponentTeamSportradarId?: Maybe<Scalars["String"]>;
  pitchingMatchup?: Maybe<Ctp_MlbGamePitcherMatchup>;
  playoffs?: Maybe<Scalars["Boolean"]>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  scheduledTimeTbd?: Maybe<Scalars["Boolean"]>;
  sportRadarGameId?: Maybe<Scalars["String"]>;
  status?: Maybe<Ctp_SrMlbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamRuns?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_MlbGameWeather>;
};

export type Ctp_MlbUpcomingTeamGameGamePlayerPropsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_MlbUpcomingTeamGameOddsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_MoneyLine = {
  __typename?: "CTP_MoneyLine";
  odd?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_Nba = {
  __typename?: "CTP_NBA";
  futures?: Maybe<Array<Maybe<Ctp_NbaTeamFutureOdd>>>;
  players?: Maybe<Array<Maybe<Ctp_NbaLeaguePlayer>>>;
  schedule?: Maybe<Array<Maybe<Ctp_NbaLeagueSchedule>>>;
  /** @deprecated Sitemap provides inconsistent player slugs so it is being removed.  If player ids and player slugs are needed please use teams -> teamPlayers to get this info since it uses Almanac */
  sitemap?: Maybe<Array<Maybe<Ctp_NbaSiteMapPlayer>>>;
  starPlayers?: Maybe<Array<Maybe<Ctp_NbaLeaguePlayer>>>;
  teams?: Maybe<Array<Maybe<Ctp_NbaTeam>>>;
};

export type Ctp_NbaFuturesArgs = {
  marketType?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NbaPlayersArgs = {
  numberFirePlayerSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerIdentityFilters?: InputMaybe<
    Array<InputMaybe<Ctp_PlayerIdentityFilterInput>>
  >;
};

export type Ctp_NbaScheduleArgs = {
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
};

export type Ctp_NbaTeamsArgs = {
  filter?: InputMaybe<Ctp_TeamFilterInput>;
};

export type Ctp_Ncaafb = {
  __typename?: "CTP_NCAAFB";
  futures?: Maybe<Array<Maybe<Ctp_NcaaFbTeamFutureOdd>>>;
  schedule?: Maybe<Array<Maybe<Ctp_NcaaFbLeagueSchedule>>>;
  seasonMeta?: Maybe<Ctp_NcaaFbSeasonMeta>;
  teams?: Maybe<Array<Maybe<Ctp_NcaaFbTeam>>>;
};

export type Ctp_NcaafbFuturesArgs = {
  marketType?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NcaafbScheduleArgs = {
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
};

export type Ctp_NcaafbTeamsArgs = {
  filter?: InputMaybe<Ctp_TeamFilterInput>;
};

export type Ctp_Ncaamb = {
  __typename?: "CTP_NCAAMB";
  futures?: Maybe<Array<Maybe<Ctp_NcaaMbTeamFutureOdd>>>;
  popularBets?: Maybe<Ctp_NcaaMbPopularBets>;
  schedule?: Maybe<Array<Maybe<Ctp_NcaaMbLeagueSchedule>>>;
  teams?: Maybe<Array<Maybe<Ctp_NcaaMbTeam>>>;
  tournamentSchedules?: Maybe<Array<Maybe<Ctp_NcaaMbTournamentSchedule>>>;
};

export type Ctp_NcaambFuturesArgs = {
  marketType?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NcaambScheduleArgs = {
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
};

export type Ctp_NcaambTeamsArgs = {
  filter?: InputMaybe<Ctp_TeamFilterInput>;
};

export type Ctp_NcaambTournamentSchedulesArgs = {
  tournaments: Array<InputMaybe<Ctp_NcaaMbTournament>>;
};

export type Ctp_Nfl = {
  __typename?: "CTP_NFL";
  featuredEvents?: Maybe<Array<Maybe<Ctp_NflEvent>>>;
  featuredPlayerFutures?: Maybe<Array<Maybe<Ctp_NflPlayerFutures>>>;
  futures?: Maybe<Array<Maybe<Ctp_NflTeamFutureOdd>>>;
  players?: Maybe<Array<Maybe<Ctp_NflLeaguePlayer>>>;
  projections?: Maybe<Array<Maybe<Ctp_NflPlayerProjection>>>;
  schedule?: Maybe<Array<Maybe<Ctp_NflLeagueSchedule>>>;
  seasonMeta?: Maybe<Ctp_NflSeasonMeta>;
  /** @deprecated Sitemap provides inconsistent player slugs so it is being removed.  If player ids and player slugs are needed please use teams -> teamPlayers to get this info since it uses Almanac */
  sitemap?: Maybe<Array<Maybe<Ctp_NflSiteMapPlayer>>>;
  starPlayers?: Maybe<Array<Maybe<Ctp_NflLeaguePlayer>>>;
  teams?: Maybe<Array<Maybe<Ctp_NflTeam>>>;
};

export type Ctp_NflFuturesArgs = {
  marketType?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NflPlayersArgs = {
  numberFirePlayerSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  playerIdentityFilters?: InputMaybe<
    Array<InputMaybe<Ctp_PlayerIdentityFilterInput>>
  >;
};

export type Ctp_NflProjectionsArgs = {
  position?: InputMaybe<Scalars["String"]>;
  scoreSetting?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NflScheduleArgs = {
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
};

export type Ctp_NflTeamsArgs = {
  filter?: InputMaybe<Ctp_TeamFilterInput>;
};

export type Ctp_NbaBetRecord = {
  __typename?: "CTP_NbaBetRecord";
  lose?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaBetRecords = {
  __typename?: "CTP_NbaBetRecords";
  againstSpread?: Maybe<Ctp_NbaBetRecord>;
  moneyLine?: Maybe<Ctp_NbaBetRecord>;
  overUnder?: Maybe<Ctp_NbaBetRecord>;
};

export enum Ctp_NbaBroadcastLocale {
  Away = "AWAY",
  Home = "HOME",
  International = "INTERNATIONAL",
  National = "NATIONAL",
}

export type Ctp_NbaConferenceRecord = {
  __typename?: "CTP_NbaConferenceRecord";
  losses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaDivisionRecord = {
  __typename?: "CTP_NbaDivisionRecord";
  losses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaFutureOdd = {
  __typename?: "CTP_NbaFutureOdd";
  description?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaGame = {
  __typename?: "CTP_NbaGame";
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  awayTeamPoints?: Maybe<Scalars["Int"]>;
  broadCasts?: Maybe<Ctp_NbaGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  homeTeamPoints?: Maybe<Scalars["Int"]>;
  inSeasonTournamentGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_NbaGameOdds>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNbaGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaGameBroadcasts = {
  __typename?: "CTP_NbaGameBroadcasts";
  awayBroadcastInfo?: Maybe<Array<Maybe<Ctp_NbaLocaleBroadcastInfo>>>;
  homeBroadcastInfo?: Maybe<Array<Maybe<Ctp_NbaLocaleBroadcastInfo>>>;
  internationalBroadcastInfo?: Maybe<Array<Maybe<Ctp_NbaLocaleBroadcastInfo>>>;
  nationalBroadcastInfo?: Maybe<Array<Maybe<Ctp_NbaLocaleBroadcastInfo>>>;
};

export type Ctp_NbaGameDetails = {
  __typename?: "CTP_NbaGameDetails";
  gameDate?: Maybe<Scalars["CTP_LocalDate"]>;
  isHomeGame?: Maybe<Scalars["Boolean"]>;
  opponentTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  playerStarted?: Maybe<Scalars["Boolean"]>;
  playerTeamWin?: Maybe<Scalars["Boolean"]>;
  score?: Maybe<Ctp_StatisticScoreDetails>;
};

export type Ctp_NbaGameOdds = {
  __typename?: "CTP_NbaGameOdds";
  away?: Maybe<Ctp_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Ctp_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaLeaguePlayer = {
  __typename?: "CTP_NbaLeaguePlayer";
  /**
   * Career stats for the selected player
   * @deprecated Replaced by "statistics" path and related objects
   */
  careerStats?: Maybe<Array<Maybe<Ctp_NbaPlayerSeasonStats>>>;
  injuryStatus?: Maybe<Ctp_NbaPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerFutures?: Maybe<Array<Maybe<Ctp_PlayerFuture>>>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_NbaPlayerInfo>;
  playerProjections?: Maybe<Array<Maybe<Ctp_NbaPlayerProjection>>>;
  playerProps?: Maybe<Ctp_NbaPlayerProps>;
  /** Popular Bets provided by the FDX api with multiple bet types */
  popularBets?: Maybe<Ctp_NbaPlayerPopularBetsContext>;
  statistics?: Maybe<Ctp_LeaguePlayerStatisticsContext_NbaLeaguePlayer>;
  /**
   * Current Season Stats for selected player
   * @deprecated Replaced by "statistics" path and related objects
   */
  stats?: Maybe<Array<Maybe<Ctp_NbaPlayerGameStats>>>;
  team?: Maybe<Ctp_NbaTeam>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaLeaguePlayerPlayerProjectionsArgs = {
  daysForward?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NbaLeagueRecord = {
  __typename?: "CTP_NbaLeagueRecord";
  losses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaLeagueSchedule = {
  __typename?: "CTP_NbaLeagueSchedule";
  games?: Maybe<Array<Maybe<Ctp_NbaGame>>>;
  seasonType?: Maybe<Ctp_SrNbaSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaLocaleBroadcastInfo = {
  __typename?: "CTP_NbaLocaleBroadcastInfo";
  channel?: Maybe<Scalars["String"]>;
  locale?: Maybe<Ctp_NbaBroadcastLocale>;
  network?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaPlayerGameStatistics = {
  __typename?: "CTP_NbaPlayerGameStatistics";
  /** Assists to Turnovers ratio. */
  assistToTurnoverRatio?: Maybe<Ctp_RatioEventStatistic>;
  assists?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  blocks?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  fantasyPoints?: Maybe<Ctp_PlayerEventFantasyPoints>;
  fieldGoals?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  fouls?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  freeThrows?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  gameInfo?: Maybe<Ctp_NbaGameDetails>;
  minutes?: Maybe<Ctp_CumulativeAverageEventStatistics_BigDecimal>;
  playerTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  points?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  rebounds?: Maybe<Ctp_NbaReboundStatistics_CumulativeAverageEventStatistics_Integer>;
  steals?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  threePoints?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  turnovers?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
};

export type Ctp_NbaPlayerGameStatisticsBySeason = {
  __typename?: "CTP_NbaPlayerGameStatisticsBySeason";
  gameStatistics?: Maybe<Array<Maybe<Ctp_NbaPlayerGameStatistics>>>;
  seasonInfo?: Maybe<Ctp_SeasonDetails>;
};

export type Ctp_NbaPlayerGameStats = {
  __typename?: "CTP_NbaPlayerGameStats";
  assists?: Maybe<Scalars["Int"]>;
  assistsTurnoverRatio?: Maybe<Scalars["CTP_BigDecimal"]>;
  blocks?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["String"]>;
  defensiveRebounds?: Maybe<Scalars["Int"]>;
  fantasyPoints?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsAttempts?: Maybe<Scalars["Int"]>;
  fieldGoalsMade?: Maybe<Scalars["Int"]>;
  fieldGoalsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  fouls?: Maybe<Scalars["Int"]>;
  freeThrowsAttempts?: Maybe<Scalars["Int"]>;
  freeThrowsMade?: Maybe<Scalars["Int"]>;
  freeThrowsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  gamesStarted?: Maybe<Scalars["Int"]>;
  homeGame: Scalars["Boolean"];
  minutes?: Maybe<Scalars["CTP_BigDecimal"]>;
  nfOpponentTeamId?: Maybe<Scalars["String"]>;
  offensiveRebounds?: Maybe<Scalars["Int"]>;
  opponentScore?: Maybe<Scalars["Int"]>;
  playerStarted: Scalars["Boolean"];
  points?: Maybe<Scalars["Int"]>;
  rebounds?: Maybe<Scalars["Int"]>;
  result?: Maybe<Ctp_ScheduleResult>;
  season?: Maybe<Scalars["String"]>;
  seasonType?: Maybe<Scalars["String"]>;
  sportRadarId?: Maybe<Scalars["String"]>;
  steals?: Maybe<Scalars["Int"]>;
  teamScore?: Maybe<Scalars["Int"]>;
  teamWin: Scalars["Boolean"];
  threePointsAttempts?: Maybe<Scalars["Int"]>;
  threePointsMade?: Maybe<Scalars["Int"]>;
  threePointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  title?: Maybe<Scalars["String"]>;
  turnovers?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaPlayerInfo = {
  __typename?: "CTP_NbaPlayerInfo";
  age: Scalars["Int"];
  college?: Maybe<Scalars["String"]>;
  draft?: Maybe<Ctp_DepthChartDraft>;
  fppg?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  highSchool?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  weight: Scalars["Float"];
  yearsOfExperience?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaPlayerInjuryStatus = {
  __typename?: "CTP_NbaPlayerInjuryStatus";
  detail?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaPlayerPopularBetsContext = {
  __typename?: "CTP_NbaPlayerPopularBetsContext";
  /** A combination of single bets represented as one bet */
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** A combination of same game parlays and possibly other bet types */
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Parlays of bets within the same event */
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Individual popular bets provided by the FDX api */
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NbaPlayerPopularBetsContextParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NbaPlayerPopularBetsContextSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NbaPlayerPopularBetsContextSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NbaPlayerProjection = {
  __typename?: "CTP_NbaPlayerProjection";
  assists?: Maybe<Scalars["CTP_BigDecimal"]>;
  blocks?: Maybe<Scalars["CTP_BigDecimal"]>;
  defensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  fantasyPoints?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsAttempted?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsAttempted?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  minutes?: Maybe<Scalars["CTP_BigDecimal"]>;
  nfTeamId?: Maybe<Scalars["String"]>;
  offensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  opponentNfTeamId?: Maybe<Scalars["String"]>;
  opponentTeamAbbreviation?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  personalFouls?: Maybe<Scalars["CTP_BigDecimal"]>;
  playerName?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["CTP_BigDecimal"]>;
  sportradarId?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["String"]>;
  steals?: Maybe<Scalars["CTP_BigDecimal"]>;
  team?: Maybe<Scalars["String"]>;
  threePointsAttempted?: Maybe<Scalars["CTP_BigDecimal"]>;
  threePointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  turnovers?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaPlayerProps = {
  __typename?: "CTP_NbaPlayerProps";
  contentText?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<Ctp_NbaPlayerPropsEvent>>>;
  id?: Maybe<Scalars["String"]>;
  playerJersey?: Maybe<Ctp_PlayerPropsJersey>;
};

export type Ctp_NbaPlayerPropsEvent = {
  __typename?: "CTP_NbaPlayerPropsEvent";
  date?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  eventName?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_NbaPlayerPropsMarket>>>;
};

export type Ctp_NbaPlayerPropsMarket = {
  __typename?: "CTP_NbaPlayerPropsMarket";
  alternateMarket?: Maybe<Scalars["Boolean"]>;
  contentText?: Maybe<Scalars["String"]>;
  eligibleForSameGameParlay?: Maybe<Scalars["Boolean"]>;
  externalMarketId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  marketTypeName?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_NbaPlayerPropsSelection>>>;
  statInformation?: Maybe<Ctp_PlayerPropsStatInformation>;
};

export type Ctp_NbaPlayerPropsSelection = {
  __typename?: "CTP_NbaPlayerPropsSelection";
  betUrl?: Maybe<Scalars["String"]>;
  contentText?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaPlayerSeasonStatistics = {
  __typename?: "CTP_NbaPlayerSeasonStatistics";
  /** Assists to Turnovers ratio. */
  assistToTurnoverRatio?: Maybe<Ctp_RatioStatistic>;
  assists?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  blocks?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  fantasyPoints?: Maybe<Ctp_PlayerFantasyPoints>;
  fieldGoals?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  fouls?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  freeThrows?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  gamesStarted?: Maybe<Scalars["Int"]>;
  minutes?: Maybe<Ctp_CumulativeAverageStatistics_BigDecimal>;
  playerTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  points?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  rebounds?: Maybe<Ctp_NbaReboundStatistics_CumulativeAverageStatistics_Integer>;
  steals?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  threePoints?: Maybe<Ctp_AttemptedMadeRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  turnovers?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NbaPlayerSeasonStatisticsBySeason = {
  __typename?: "CTP_NbaPlayerSeasonStatisticsBySeason";
  seasonInfo?: Maybe<Ctp_SeasonDetails>;
  seasonStatistics?: Maybe<Ctp_NbaPlayerSeasonStatistics>;
};

export type Ctp_NbaPlayerSeasonStats = {
  __typename?: "CTP_NbaPlayerSeasonStats";
  assists?: Maybe<Scalars["Int"]>;
  assistsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  assistsTurnoverRatio?: Maybe<Scalars["CTP_BigDecimal"]>;
  blocks?: Maybe<Scalars["Int"]>;
  blocksPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  defensiveRebounds?: Maybe<Scalars["Int"]>;
  fantasyPoints?: Maybe<Scalars["CTP_BigDecimal"]>;
  fantasyPointsPerGame?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempts?: Maybe<Scalars["Int"]>;
  fieldGoalsMade?: Maybe<Scalars["Int"]>;
  fieldGoalsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  fouls?: Maybe<Scalars["Int"]>;
  foulsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsAttempts?: Maybe<Scalars["Int"]>;
  freeThrowsMade?: Maybe<Scalars["Int"]>;
  freeThrowsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  gamesStarted?: Maybe<Scalars["Int"]>;
  minutes?: Maybe<Scalars["CTP_BigDecimal"]>;
  minutesPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  offensiveRebounds?: Maybe<Scalars["Int"]>;
  playerName?: Maybe<Scalars["String"]>;
  playerTeam?: Maybe<Scalars["String"]>;
  playerTeamId?: Maybe<Scalars["Int"]>;
  points?: Maybe<Scalars["Int"]>;
  pointsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  rebounds?: Maybe<Scalars["Int"]>;
  reboundsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  season?: Maybe<Scalars["String"]>;
  seasonType?: Maybe<Scalars["String"]>;
  sportRadarId?: Maybe<Scalars["String"]>;
  steals?: Maybe<Scalars["Int"]>;
  stealsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  threePointsAttempts?: Maybe<Scalars["Int"]>;
  threePointsMade?: Maybe<Scalars["Int"]>;
  threePointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  turnovers?: Maybe<Scalars["Int"]>;
  turnoversPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaPowerRanking = {
  __typename?: "CTP_NbaPowerRanking";
  championshipProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
  playoffProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
  rank?: Maybe<Scalars["Int"]>;
  rankingsDate?: Maybe<Scalars["CTP_LocalDate"]>;
  seasonYear: Scalars["Int"];
};

export type Ctp_NbaPrevNextGame = {
  __typename?: "CTP_NbaPrevNextGame";
  nextGames?: Maybe<Array<Maybe<Ctp_NbaUpcomingTeamGame>>>;
  previousGames?: Maybe<Array<Maybe<Ctp_NbaTeamGame>>>;
};

export type Ctp_NbaReboundStatistics_CumulativeAverageEventStatistics_Integer =
  {
    __typename?: "CTP_NbaReboundStatistics_CumulativeAverageEventStatistics_Integer";
    /** Offensive & Defensive rebounds. */
    cumulative?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    defensive?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    offensive?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NbaReboundStatistics_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_NbaReboundStatistics_CumulativeAverageStatistics_Integer";
  /** Offensive & Defensive rebounds. */
  cumulative?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  defensive?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  offensive?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NbaRecentGamesRecord = {
  __typename?: "CTP_NbaRecentGamesRecord";
  lose?: Maybe<Scalars["Int"]>;
  numberOfGames?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

/** NBA SiteMapPlayer */
export type Ctp_NbaSiteMapPlayer = {
  __typename?: "CTP_NbaSiteMapPlayer";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  nfPlayerSlug?: Maybe<Scalars["String"]>;
  numberFireTeamId?: Maybe<Scalars["Int"]>;
  playerId?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

/** Team type for NBA */
export type Ctp_NbaTeam = {
  __typename?: "CTP_NbaTeam";
  betRecords?: Maybe<Ctp_NbaBetRecords>;
  code?: Maybe<Scalars["String"]>;
  /** Deprecated. Moving to Team Metadata. */
  conference?: Maybe<Scalars["String"]>;
  /** Deprecated. Moving to Team Metadata. */
  division?: Maybe<Scalars["String"]>;
  fanDuelTeamId?: Maybe<Scalars["Int"]>;
  meta?: Maybe<Ctp_NbaTeamMetadata>;
  numberFireId?: Maybe<Scalars["Int"]>;
  players?: Maybe<Array<Maybe<Ctp_NbaLeaguePlayer>>>;
  popularBets?: Maybe<Ctp_NbaTeamPopularBets>;
  powerRanking?: Maybe<Ctp_NbaPowerRanking>;
  prevNextGame?: Maybe<Ctp_NbaPrevNextGame>;
  record?: Maybe<Ctp_NbaTeamRecord>;
  selectionIds?: Maybe<Array<Maybe<Scalars["CTP_Long"]>>>;
  srId?: Maybe<Scalars["String"]>;
  teamFutures?: Maybe<Array<Maybe<Ctp_NbaFutureOdd>>>;
  teamLeader?: Maybe<Ctp_NbaTeamLeaders>;
  /** @deprecated Use team.players instead */
  teamPlayers?: Maybe<Array<Maybe<Ctp_NbaTeamPlayer>>>;
  teamSchedule?: Maybe<Array<Maybe<Ctp_NbaTeamSchedule>>>;
  teamStats?: Maybe<Ctp_NbaTeamSeasonalStats>;
};

/** Team type for NBA */
export type Ctp_NbaTeamPrevNextGameArgs = {
  numberOfNext?: InputMaybe<Scalars["Int"]>;
  numberOfPrevious?: InputMaybe<Scalars["Int"]>;
};

/** Team type for NBA */
export type Ctp_NbaTeamTeamStatsArgs = {
  teamStatsFilter?: InputMaybe<Ctp_TeamStatsFilterInput>;
};

export type Ctp_NbaTeamAverageStats = {
  __typename?: "CTP_NbaTeamAverageStats";
  assists?: Maybe<Scalars["CTP_BigDecimal"]>;
  blocks?: Maybe<Scalars["CTP_BigDecimal"]>;
  defensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  fouls?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  minutes?: Maybe<Scalars["CTP_BigDecimal"]>;
  offensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  points?: Maybe<Scalars["CTP_BigDecimal"]>;
  rebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  steals?: Maybe<Scalars["CTP_BigDecimal"]>;
  threePointsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  threePointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  turnovers?: Maybe<Scalars["CTP_BigDecimal"]>;
  twoPointsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  twoPointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaTeamFutureOdd = {
  __typename?: "CTP_NbaTeamFutureOdd";
  futureOdds?: Maybe<Array<Maybe<Ctp_NbaFutureOdd>>>;
  teamMeta?: Maybe<Ctp_NbaTeamMetadata>;
};

export type Ctp_NbaTeamGame = {
  __typename?: "CTP_NbaTeamGame";
  broadCasts?: Maybe<Ctp_NbaGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  inSeasonTournamentGame?: Maybe<Scalars["Boolean"]>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NbaTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNbaGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
};

/** Team Leader Stat for NBA */
export type Ctp_NbaTeamLeaderStat_BigDecimal = {
  __typename?: "CTP_NbaTeamLeaderStat_BigDecimal";
  leader?: Maybe<Ctp_NbaTeamPlayer>;
  statInformation?: Maybe<Scalars["String"]>;
  statLabel?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["CTP_BigDecimal"]>;
};

/** Nba Team Leaders by stat value */
export type Ctp_NbaTeamLeaders = {
  __typename?: "CTP_NbaTeamLeaders";
  averageAssists?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
  averageBlocks?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
  averagePoints?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
  averageRebounds?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
  averageSteals?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
  fieldGoalPercentage?: Maybe<Ctp_NbaTeamLeaderStat_BigDecimal>;
};

/** Metadata pertaining to NBA team */
export type Ctp_NbaTeamMetadata = {
  __typename?: "CTP_NbaTeamMetadata";
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Ctp_TeamColors>;
  conference?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  headCoach?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_TeamLogo>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaTeamPlayer = {
  __typename?: "CTP_NbaTeamPlayer";
  injuryStatus?: Maybe<Ctp_NbaPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerFutures?: Maybe<Array<Maybe<Ctp_PlayerFuture>>>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_NbaPlayerInfo>;
  playerProjections?: Maybe<Array<Maybe<Ctp_NbaPlayerProjection>>>;
  playerProps?: Maybe<Ctp_NbaPlayerProps>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaTeamPlayerPlayerProjectionsArgs = {
  daysForward?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NbaTeamPlayerStat = {
  __typename?: "CTP_NbaTeamPlayerStat";
  /** @deprecated duplicate field; use averageAssists */
  assistsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageAssists?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageBlocks?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageDefensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageFieldGoalsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageFieldGoalsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageFouls?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageFreeThrowsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageFreeThrowsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageMinutes?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageOffensiveRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  averagePoints?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageRebounds?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageSteals?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageThreePointsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageThreePointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageTurnovers?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageTwoPointsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageTwoPointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** @deprecated duplicate field; use averageBlocks */
  blocksPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  fullName?: Maybe<Scalars["String"]>;
  /** @deprecated duplicate field; use averagePoints */
  pointsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  primaryPosition?: Maybe<Scalars["String"]>;
  /** @deprecated duplicate field; use averageRebounds */
  reboundsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  srId?: Maybe<Scalars["String"]>;
  /** @deprecated duplicate field; use averageSteals */
  stealsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  totalAssistsTurnoverRatio?: Maybe<Scalars["CTP_BigDecimal"]>;
  totalFieldGoalPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  totalFreeThrowsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  totalGamesPlayed?: Maybe<Scalars["Int"]>;
  totalGamesStarted?: Maybe<Scalars["Int"]>;
  totalThreePointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  totalTwoPointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaTeamPlayerStats = {
  __typename?: "CTP_NbaTeamPlayerStats";
  stats?: Maybe<Array<Maybe<Ctp_NbaTeamPlayerStat>>>;
};

export type Ctp_NbaTeamPoints = {
  __typename?: "CTP_NbaTeamPoints";
  pointDiff?: Maybe<Scalars["CTP_BigDecimal"]>;
  pointsAgainst?: Maybe<Scalars["CTP_BigDecimal"]>;
  pointsFor?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaTeamPopularBets = {
  __typename?: "CTP_NbaTeamPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NbaTeamPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NbaTeamPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NbaTeamPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

/** Team record broken out into win/loss records. */
export type Ctp_NbaTeamRecord = {
  __typename?: "CTP_NbaTeamRecord";
  awayRecord?: Maybe<Ctp_WinLoseRecord>;
  clinched?: Maybe<Scalars["String"]>;
  conferenceRank?: Maybe<Scalars["Int"]>;
  conferenceRecord?: Maybe<Ctp_NbaConferenceRecord>;
  divisionRank?: Maybe<Scalars["Int"]>;
  divisionRecord?: Maybe<Ctp_NbaDivisionRecord>;
  homeRecord?: Maybe<Ctp_WinLoseRecord>;
  leagueRecord?: Maybe<Ctp_NbaLeagueRecord>;
  nbaTeamPoints?: Maybe<Ctp_NbaTeamPoints>;
  nonConference?: Maybe<Ctp_WinLoseRecord>;
  recentGamesRecord?: Maybe<Ctp_NbaRecentGamesRecord>;
  streak?: Maybe<Ctp_Streak>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaTeamSchedule = {
  __typename?: "CTP_NbaTeamSchedule";
  games?: Maybe<Array<Maybe<Ctp_NbaTeamGame>>>;
  seasonType?: Maybe<Ctp_SrNbaSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaTeamSeasonalStats = {
  __typename?: "CTP_NbaTeamSeasonalStats";
  currentTeam?: Maybe<Ctp_NbaTeamStats>;
  players?: Maybe<Ctp_NbaTeamPlayerStats>;
  seasonType?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type Ctp_NbaTeamStats = {
  __typename?: "CTP_NbaTeamStats";
  average?: Maybe<Ctp_NbaTeamAverageStats>;
  total?: Maybe<Ctp_NbaTeamTotalStats>;
};

export type Ctp_NbaTeamTotalStats = {
  __typename?: "CTP_NbaTeamTotalStats";
  assistsTurnoverRatio?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  freeThrowsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  threePointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  twoPointsPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NbaUpcomingTeamGame = {
  __typename?: "CTP_NbaUpcomingTeamGame";
  broadCasts?: Maybe<Ctp_NbaGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  eventId?: Maybe<Scalars["Int"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  inSeasonTournamentGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_NbaGameOdds>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NbaTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NbaTeamMetadata>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNbaGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
};

export type Ctp_NbaUpcomingTeamGameOddsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NcaaFbBetRecord = {
  __typename?: "CTP_NcaaFbBetRecord";
  lose?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_NcaaFbBetRecords = {
  __typename?: "CTP_NcaaFbBetRecords";
  againstSpread?: Maybe<Ctp_NcaaFbBetRecord>;
  moneyLine?: Maybe<Ctp_NcaaFbBetRecord>;
  overUnder?: Maybe<Ctp_NcaaFbBetRecord>;
};

export type Ctp_NcaaFbBroadcastInfo = {
  __typename?: "CTP_NcaaFbBroadcastInfo";
  internet?: Maybe<Scalars["String"]>;
  network?: Maybe<Scalars["String"]>;
  satellite?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaFbFutureOdd = {
  __typename?: "CTP_NcaaFbFutureOdd";
  description?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaFbGame = {
  __typename?: "CTP_NcaaFbGame";
  awayScore?: Maybe<Scalars["Int"]>;
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_NcaaFbTeamMetadata>;
  broadcastInfo?: Maybe<Ctp_NcaaFbBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  gameType?: Maybe<Ctp_SrNcaaFbGameType>;
  homeScore?: Maybe<Scalars["Int"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_NcaaFbTeamMetadata>;
  odds?: Maybe<Ctp_NcaaFbGameOdds>;
  playoffGameTitle?: Maybe<Scalars["String"]>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaFbGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NcaaFbGameWeather>;
  weekNumber: Scalars["Int"];
};

export type Ctp_NcaaFbGameOdds = {
  __typename?: "CTP_NcaaFbGameOdds";
  away?: Maybe<Ctp_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Ctp_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaFbGameWeather = {
  __typename?: "CTP_NcaaFbGameWeather";
  condition?: Maybe<Scalars["String"]>;
  humidity?: Maybe<Scalars["Int"]>;
  tempInF?: Maybe<Scalars["Int"]>;
  windDirection?: Maybe<Scalars["String"]>;
  windSpeedInMph?: Maybe<Scalars["Int"]>;
};

export type Ctp_NcaaFbLeagueSchedule = {
  __typename?: "CTP_NcaaFbLeagueSchedule";
  games?: Maybe<Array<Maybe<Ctp_NcaaFbGame>>>;
  seasonType?: Maybe<Ctp_SrNcaaFbGameType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaFbPrevNextGame = {
  __typename?: "CTP_NcaaFbPrevNextGame";
  nextGames?: Maybe<Array<Maybe<Ctp_NcaaFbUpcomingTeamGame>>>;
  previousGames?: Maybe<Array<Maybe<Ctp_NcaaFbTeamGame>>>;
};

export type Ctp_NcaaFbRanking = {
  __typename?: "CTP_NcaaFbRanking";
  fpVotes?: Maybe<Scalars["Int"]>;
  points?: Maybe<Scalars["Int"]>;
  prevRank?: Maybe<Scalars["Int"]>;
  rank?: Maybe<Scalars["Int"]>;
};

export type Ctp_NcaaFbSeasonMeta = {
  __typename?: "CTP_NcaaFbSeasonMeta";
  /** Breakdown of games by week and seasonType for schedule filtering */
  seasonStructure?: Maybe<Array<Maybe<Ctp_NcaaFbSeasonStructure>>>;
};

export type Ctp_NcaaFbSeasonStructure = {
  __typename?: "CTP_NcaaFbSeasonStructure";
  seasonType?: Maybe<Ctp_SrNcaaFbGameType>;
  weeks?: Maybe<Array<Maybe<Ctp_NcaaFbSeasonWeek>>>;
};

export type Ctp_NcaaFbSeasonWeek = {
  __typename?: "CTP_NcaaFbSeasonWeek";
  firstGameTime?: Maybe<Scalars["CTP_Instant"]>;
  lastGameTime?: Maybe<Scalars["CTP_Instant"]>;
  week?: Maybe<Scalars["Int"]>;
};

/** Team type for NCAAFB */
export type Ctp_NcaaFbTeam = {
  __typename?: "CTP_NcaaFbTeam";
  betRecords?: Maybe<Ctp_NcaaFbBetRecords>;
  code?: Maybe<Scalars["String"]>;
  fanDuelTeamId?: Maybe<Scalars["Int"]>;
  meta?: Maybe<Ctp_NcaaFbTeamMetadata>;
  numberFireId?: Maybe<Scalars["Int"]>;
  popularBets?: Maybe<Ctp_NcaaFbTeamPopularBets>;
  prevNextGame?: Maybe<Ctp_NcaaFbPrevNextGame>;
  rankings?: Maybe<Ctp_NcaaFbTeamFbRanking>;
  record?: Maybe<Ctp_NcaaFbTeamRecord>;
  selectionIds?: Maybe<Array<Maybe<Scalars["CTP_Long"]>>>;
  srId?: Maybe<Scalars["String"]>;
  teamFutures?: Maybe<Array<Maybe<Ctp_NcaaFbFutureOdd>>>;
  teamSchedule?: Maybe<Array<Maybe<Ctp_NcaaFbTeamSchedule>>>;
};

/** Team type for NCAAFB */
export type Ctp_NcaaFbTeamPrevNextGameArgs = {
  numberOfNext?: InputMaybe<Scalars["Int"]>;
  numberOfPrevious?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_NcaaFbTeamFbRanking = {
  __typename?: "CTP_NcaaFbTeamFbRanking";
  apRanking?: Maybe<Ctp_NcaaFbRanking>;
  cfpRanking?: Maybe<Ctp_NcaaFbRanking>;
};

export type Ctp_NcaaFbTeamFutureOdd = {
  __typename?: "CTP_NcaaFbTeamFutureOdd";
  futureOdds?: Maybe<Array<Maybe<Ctp_NcaaFbFutureOdd>>>;
  teamMeta?: Maybe<Ctp_NcaaFbTeamMetadata>;
};

export type Ctp_NcaaFbTeamGame = {
  __typename?: "CTP_NcaaFbTeamGame";
  broadcastInfo?: Maybe<Ctp_NcaaFbBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  gameType?: Maybe<Ctp_SrNcaaFbGameType>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NcaaFbTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NcaaFbTeamMetadata>;
  playoffGameTitle?: Maybe<Scalars["String"]>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaFbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NcaaFbGameWeather>;
  weekNumber: Scalars["Int"];
};

/** Metadata pertaining to NCAAFB team */
export type Ctp_NcaaFbTeamMetadata = {
  __typename?: "CTP_NcaaFbTeamMetadata";
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Ctp_TeamColors>;
  conference?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  divisionAlias?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_TeamLogo>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  subDivision?: Maybe<Scalars["String"]>;
  venue?: Maybe<Ctp_NcaaFbVenue>;
};

export type Ctp_NcaaFbTeamPoints = {
  __typename?: "CTP_NcaaFbTeamPoints";
  pointsAgainst?: Maybe<Scalars["Int"]>;
  pointsFor?: Maybe<Scalars["Int"]>;
};

export type Ctp_NcaaFbTeamPopularBets = {
  __typename?: "CTP_NcaaFbTeamPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NcaaFbTeamPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaFbTeamPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaFbTeamPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

/** Team record broken out into win/loss records. */
export type Ctp_NcaaFbTeamRecord = {
  __typename?: "CTP_NcaaFbTeamRecord";
  awayRecord?: Maybe<Ctp_WinLoseRecord>;
  conferenceRank?: Maybe<Scalars["Int"]>;
  conferenceRecord?: Maybe<Ctp_WinLoseRecord>;
  divisionRank?: Maybe<Scalars["Int"]>;
  homeRecord?: Maybe<Ctp_WinLoseRecord>;
  /** @deprecated Moving to TeamPoints object. */
  pointsAgainst?: Maybe<Scalars["Int"]>;
  /** @deprecated Moving to TeamPoints object. */
  pointsFor?: Maybe<Scalars["Int"]>;
  streak?: Maybe<Ctp_Streak>;
  teamPoints?: Maybe<Ctp_NcaaFbTeamPoints>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NcaaFbTeamSchedule = {
  __typename?: "CTP_NcaaFbTeamSchedule";
  games?: Maybe<Array<Maybe<Ctp_NcaaFbTeamGame>>>;
  seasonType?: Maybe<Ctp_SrNcaaFbGameType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaFbUpcomingTeamGame = {
  __typename?: "CTP_NcaaFbUpcomingTeamGame";
  broadcastInfo?: Maybe<Ctp_NcaaFbBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  gameType?: Maybe<Ctp_SrNcaaFbGameType>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_NcaaFbGameOdds>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NcaaFbTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NcaaFbTeamMetadata>;
  playoffGameTitle?: Maybe<Scalars["String"]>;
  popularGameBets?: Maybe<Array<Maybe<Ctp_BetInfo>>>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaFbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NcaaFbGameWeather>;
  weekNumber: Scalars["Int"];
};

export type Ctp_NcaaFbUpcomingTeamGameOddsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NcaaFbVenue = {
  __typename?: "CTP_NcaaFbVenue";
  address?: Maybe<Scalars["String"]>;
  capacity?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  roofType?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  surface?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbBetRecord = {
  __typename?: "CTP_NcaaMbBetRecord";
  lose?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_NcaaMbBetRecords = {
  __typename?: "CTP_NcaaMbBetRecords";
  againstSpread?: Maybe<Ctp_NcaaMbBetRecord>;
  moneyLine?: Maybe<Ctp_NcaaMbBetRecord>;
  overUnder?: Maybe<Ctp_NcaaMbBetRecord>;
};

export enum Ctp_NcaaMbBroadcastMediaType {
  Internet = "INTERNET",
  Tv = "TV",
}

export type Ctp_NcaaMbFutureOdd = {
  __typename?: "CTP_NcaaMbFutureOdd";
  description?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbGame = {
  __typename?: "CTP_NcaaMbGame";
  awayScore?: Maybe<Scalars["Int"]>;
  awaySeed?: Maybe<Scalars["Int"]>;
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  broadcasts?: Maybe<Ctp_NcaaMbGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  homeScore?: Maybe<Scalars["Int"]>;
  homeSeed?: Maybe<Scalars["Int"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  odds?: Maybe<Ctp_NcaaMbGameOdds>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaMbGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbGameBroadcasts = {
  __typename?: "CTP_NcaaMbGameBroadcasts";
  internetBroadcastInfo?: Maybe<Array<Maybe<Ctp_NcaaMbMediaTypeBroadcastInfo>>>;
  tvBroadcastInfo?: Maybe<Array<Maybe<Ctp_NcaaMbMediaTypeBroadcastInfo>>>;
};

export type Ctp_NcaaMbGameOdds = {
  __typename?: "CTP_NcaaMbGameOdds";
  away?: Maybe<Ctp_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Ctp_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbLeagueSchedule = {
  __typename?: "CTP_NcaaMbLeagueSchedule";
  games?: Maybe<Array<Maybe<Ctp_NcaaMbGame>>>;
  seasonType?: Maybe<Ctp_SrNcaaMbSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbMediaTypeBroadcastInfo = {
  __typename?: "CTP_NcaaMbMediaTypeBroadcastInfo";
  channel?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  network?: Maybe<Scalars["String"]>;
  type?: Maybe<Ctp_NcaaMbBroadcastMediaType>;
};

export type Ctp_NcaaMbPopularBets = {
  __typename?: "CTP_NcaaMbPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NcaaMbPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbPrevNextGame = {
  __typename?: "CTP_NcaaMbPrevNextGame";
  nextGames?: Maybe<Array<Maybe<Ctp_NcaaMbUpcomingTeamGame>>>;
  previousGames?: Maybe<Array<Maybe<Ctp_NcaaMbTeamGame>>>;
};

export type Ctp_NcaaMbRanking = {
  __typename?: "CTP_NcaaMbRanking";
  fpVotes?: Maybe<Scalars["Int"]>;
  points?: Maybe<Scalars["Int"]>;
  prevRank?: Maybe<Scalars["Int"]>;
  rank?: Maybe<Scalars["Int"]>;
};

/** Team type for NCAAMB */
export type Ctp_NcaaMbTeam = {
  __typename?: "CTP_NcaaMbTeam";
  betRecords?: Maybe<Ctp_NcaaMbBetRecords>;
  code?: Maybe<Scalars["String"]>;
  fanDuelTeamId?: Maybe<Scalars["Int"]>;
  meta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  numberFireId?: Maybe<Scalars["Int"]>;
  popularBets?: Maybe<Ctp_NcaaMbTeamPopularBets>;
  prevNextGame?: Maybe<Ctp_NcaaMbPrevNextGame>;
  rankings?: Maybe<Ctp_NcaaMbTeamRanking>;
  record?: Maybe<Ctp_NcaaMbTeamRecord>;
  selectionIds?: Maybe<Array<Maybe<Scalars["CTP_Long"]>>>;
  srId?: Maybe<Scalars["String"]>;
  teamFutures?: Maybe<Array<Maybe<Ctp_NcaaMbFutureOdd>>>;
  teamSchedule?: Maybe<Array<Maybe<Ctp_NcaaMbTeamSchedule>>>;
};

/** Team type for NCAAMB */
export type Ctp_NcaaMbTeamPrevNextGameArgs = {
  numberOfNext?: InputMaybe<Scalars["Int"]>;
  numberOfPrevious?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_NcaaMbTeamFutureOdd = {
  __typename?: "CTP_NcaaMbTeamFutureOdd";
  futureOdds?: Maybe<Array<Maybe<Ctp_NcaaMbFutureOdd>>>;
  teamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
};

export type Ctp_NcaaMbTeamGame = {
  __typename?: "CTP_NcaaMbTeamGame";
  broadcasts?: Maybe<Ctp_NcaaMbGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NcaaMbTeamRecord>;
  opponentSeed?: Maybe<Scalars["Int"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaMbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  teamSeed?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
};

/** Metadata pertaining to NCAAMB team */
export type Ctp_NcaaMbTeamMetadata = {
  __typename?: "CTP_NcaaMbTeamMetadata";
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Ctp_TeamColors>;
  conference?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  divisionAlias?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_TeamLogo>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  venue?: Maybe<Ctp_NcaaMbVenue>;
};

export type Ctp_NcaaMbTeamPoints = {
  __typename?: "CTP_NcaaMbTeamPoints";
  pointDiff?: Maybe<Scalars["CTP_BigDecimal"]>;
  pointsAgainst?: Maybe<Scalars["CTP_BigDecimal"]>;
  pointsFor?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NcaaMbTeamPopularBets = {
  __typename?: "CTP_NcaaMbTeamPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NcaaMbTeamPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbTeamPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbTeamPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NcaaMbTeamRanking = {
  __typename?: "CTP_NcaaMbTeamRanking";
  apRanking?: Maybe<Ctp_NcaaMbRanking>;
  usRanking?: Maybe<Ctp_NcaaMbRanking>;
};

export type Ctp_NcaaMbTeamRecord = {
  __typename?: "CTP_NcaaMbTeamRecord";
  awayRecord?: Maybe<Ctp_WinLoseRecord>;
  /** Current team ranking within its conference, if ranked at all. */
  conferenceRank?: Maybe<Scalars["Int"]>;
  /** Team Record for all Conference games played */
  conferenceRecord?: Maybe<Ctp_WinLoseRecord>;
  homeRecord?: Maybe<Ctp_WinLoseRecord>;
  /** Team Record for all games played */
  leagueRecord?: Maybe<Ctp_WinLoseRecord>;
  streak?: Maybe<Ctp_Streak>;
  teamPoints?: Maybe<Ctp_NcaaMbTeamPoints>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NcaaMbTeamSchedule = {
  __typename?: "CTP_NcaaMbTeamSchedule";
  games?: Maybe<Array<Maybe<Ctp_NcaaMbTeamGame>>>;
  seasonType?: Maybe<Ctp_SrNcaaMbSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export enum Ctp_NcaaMbTournament {
  MarchMadness = "MARCH_MADNESS",
  Nit = "NIT",
}

export type Ctp_NcaaMbTournamentGame = {
  __typename?: "CTP_NcaaMbTournamentGame";
  awayPreviousSrGameId?: Maybe<Scalars["String"]>;
  awayScore?: Maybe<Scalars["Int"]>;
  awaySeed?: Maybe<Scalars["Int"]>;
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  bracket?: Maybe<Scalars["String"]>;
  bracketRank?: Maybe<Scalars["Int"]>;
  broadcasts?: Maybe<Ctp_NcaaMbGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  homePreviousSrGameId?: Maybe<Scalars["String"]>;
  homeScore?: Maybe<Scalars["Int"]>;
  homeSeed?: Maybe<Scalars["Int"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  odds?: Maybe<Ctp_NcaaMbGameOdds>;
  postponed?: Maybe<Scalars["Boolean"]>;
  round?: Maybe<Scalars["Int"]>;
  roundName?: Maybe<Scalars["String"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  srGameId?: Maybe<Scalars["String"]>;
  status?: Maybe<Ctp_SrNcaaMbGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
  winnerNextSrGameId?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbTournamentSchedule = {
  __typename?: "CTP_NcaaMbTournamentSchedule";
  games?: Maybe<Array<Maybe<Ctp_NcaaMbTournamentGame>>>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  seasonType?: Maybe<Ctp_SrNcaaMbSeasonType>;
  seasonYear?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbUpcomingTeamGame = {
  __typename?: "CTP_NcaaMbUpcomingTeamGame";
  broadcasts?: Maybe<Ctp_NcaaMbGameBroadcasts>;
  cancelled?: Maybe<Scalars["Boolean"]>;
  conferenceGame: Scalars["Boolean"];
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_NcaaMbGameOdds>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NcaaMbTeamRecord>;
  opponentSeed?: Maybe<Scalars["Int"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NcaaMbTeamMetadata>;
  popularGameBets?: Maybe<Array<Maybe<Ctp_BetInfo>>>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrNcaaMbGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  teamSeed?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
};

export type Ctp_NcaaMbUpcomingTeamGameOddsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NcaaMbVenue = {
  __typename?: "CTP_NcaaMbVenue";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Ctp_News = {
  __typename?: "CTP_News";
  articles?: Maybe<Ctp_ContentArticlesQueryResponse>;
  playerUpdates?: Maybe<Ctp_ContentPlayerUpdatesQueryResponse>;
};

export type Ctp_NewsArticlesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageState?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NewsPlayerUpdatesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  nextPageState?: InputMaybe<Scalars["String"]>;
};

export type Ctp_NfPlayerFpHistory = {
  __typename?: "CTP_NfPlayerFpHistory";
  byeWeek: Scalars["Boolean"];
  fp?: Maybe<Scalars["String"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  week: Scalars["Int"];
};

export type Ctp_NflAllCategoriesTeamPlayerStats = {
  __typename?: "CTP_NflAllCategoriesTeamPlayerStats";
  gamesPlayed?: Maybe<Scalars["Int"]>;
  gamesStarted?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
};

export type Ctp_NflBetRecord = {
  __typename?: "CTP_NflBetRecord";
  lose?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflBetRecords = {
  __typename?: "CTP_NflBetRecords";
  againstSpread?: Maybe<Ctp_NflBetRecord>;
  moneyLine?: Maybe<Ctp_NflBetRecord>;
  overUnder?: Maybe<Ctp_NflBetRecord>;
};

export type Ctp_NflBroadcastInfo = {
  __typename?: "CTP_NflBroadcastInfo";
  internet?: Maybe<Scalars["String"]>;
  network?: Maybe<Scalars["String"]>;
  satellite?: Maybe<Scalars["String"]>;
};

export type Ctp_NflConferenceRecord = {
  __typename?: "CTP_NflConferenceRecord";
  losses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  ties?: Maybe<Scalars["Int"]>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflDefenseTeamPlayerStats = {
  __typename?: "CTP_NflDefenseTeamPlayerStats";
  assistedTackles?: Maybe<Scalars["Int"]>;
  combinedTackles?: Maybe<Scalars["Int"]>;
  forcedFumbles?: Maybe<Scalars["Int"]>;
  fumbleRecoveries?: Maybe<Scalars["Int"]>;
  interceptions?: Maybe<Scalars["Int"]>;
  knockdowns?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  passesDefended?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  quarterbackHits?: Maybe<Scalars["Int"]>;
  sacks?: Maybe<Scalars["Float"]>;
  safeties?: Maybe<Scalars["Int"]>;
  tacklesForLoss?: Maybe<Scalars["Float"]>;
};

export type Ctp_NflDefenseTeamStats = {
  __typename?: "CTP_NflDefenseTeamStats";
  defensiveKnockdowns?: Maybe<Scalars["Int"]>;
  forcedFumbles?: Maybe<Scalars["Int"]>;
  interceptionsDefensive?: Maybe<Scalars["Int"]>;
  passesDefended?: Maybe<Scalars["Int"]>;
  quarterbackHits?: Maybe<Scalars["Int"]>;
  quarterbackHurries?: Maybe<Scalars["Int"]>;
  quarterbackSacks?: Maybe<Scalars["Float"]>;
  tackles?: Maybe<Scalars["Int"]>;
  tacklesForLoss?: Maybe<Scalars["Float"]>;
  totalBlitzes?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflDefensiveStatistics_CumulativeAverageEventStatistics_Integer =
  {
    __typename?: "CTP_NflDefensiveStatistics_CumulativeAverageEventStatistics_Integer";
    interceptions?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    passesDefended?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    passingYardsAllowed?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    pointsAllowed?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    rushingYardsAllowed?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    safeties?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NflDefensiveStatistics_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_NflDefensiveStatistics_CumulativeAverageStatistics_Integer";
  interceptions?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  passesDefended?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  passingYardsAllowed?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  pointsAllowed?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  rushingYardsAllowed?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  safeties?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NflDivisionRecord = {
  __typename?: "CTP_NflDivisionRecord";
  losses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  ties?: Maybe<Scalars["Int"]>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
  wins?: Maybe<Scalars["Int"]>;
};

/** Featured Events for NFL */
export type Ctp_NflEvent = {
  __typename?: "CTP_NflEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_NflMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

export type Ctp_NflFieldGoalStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic =
  {
    __typename?: "CTP_NflFieldGoalStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic";
    cumulative?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
    fiftyPlusYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
    fortyToFortyNineYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
    zeroToThirtyNineYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  };

export type Ctp_NflFieldGoalStatistics_CumulativeAverageStatistics_Integer_RatioStatistic =
  {
    __typename?: "CTP_NflFieldGoalStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
    cumulative?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
    fiftyPlusYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
    fortyToFortyNineYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
    zeroToThirtyNineYards?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  };

export type Ctp_NflFumblesStatistics_CumulativeAverageEventStatistics_Integer =
  {
    __typename?: "CTP_NflFumblesStatistics_CumulativeAverageEventStatistics_Integer";
    forced?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    lost?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    recovered?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NflFumblesStatistics_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_NflFumblesStatistics_CumulativeAverageStatistics_Integer";
  forced?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  lost?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  recovered?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NflFutureOdd = {
  __typename?: "CTP_NflFutureOdd";
  description?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NflGame = {
  __typename?: "CTP_NflGame";
  awayTeamCode?: Maybe<Scalars["String"]>;
  awayTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  awayTeamPoints?: Maybe<Scalars["Int"]>;
  broadcastInfo?: Maybe<Ctp_NflBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeTeamCode?: Maybe<Scalars["String"]>;
  homeTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  homeTeamPoints?: Maybe<Scalars["Int"]>;
  odds?: Maybe<Ctp_NflGameOdds>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrGameStatus>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NflGameWeather>;
  weekNumber: Scalars["Int"];
};

export type Ctp_NflGameDetails = {
  __typename?: "CTP_NflGameDetails";
  gameDate?: Maybe<Scalars["CTP_LocalDate"]>;
  isByeWeek?: Maybe<Scalars["Boolean"]>;
  isHomeGame?: Maybe<Scalars["Boolean"]>;
  isPlayoffs?: Maybe<Scalars["Boolean"]>;
  opponentTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  playerTeamWin?: Maybe<Scalars["Boolean"]>;
  score?: Maybe<Ctp_StatisticScoreDetails>;
  weekNumber?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflGameOdds = {
  __typename?: "CTP_NflGameOdds";
  away?: Maybe<Ctp_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Ctp_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NflGameWeather = {
  __typename?: "CTP_NflGameWeather";
  condition?: Maybe<Scalars["String"]>;
  humidity?: Maybe<Scalars["Int"]>;
  tempInF?: Maybe<Scalars["Int"]>;
  windDirection?: Maybe<Scalars["String"]>;
  windSpeedInMph?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflKickingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic =
  {
    __typename?: "CTP_NflKickingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic";
    extraPoints?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
    fieldGoals?: Maybe<Ctp_NflFieldGoalStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  };

export type Ctp_NflKickingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic =
  {
    __typename?: "CTP_NflKickingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
    extraPoints?: Maybe<Ctp_AttemptedMadeMissedRatioStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
    fieldGoals?: Maybe<Ctp_NflFieldGoalStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  };

export type Ctp_NflKickingTeamPlayerStats = {
  __typename?: "CTP_NflKickingTeamPlayerStats";
  averagePuntingNetYards?: Maybe<Scalars["Float"]>;
  averagePuntingYards?: Maybe<Scalars["Float"]>;
  fieldGoalPercentage?: Maybe<Scalars["Float"]>;
  fieldGoalsMade?: Maybe<Scalars["Int"]>;
  fieldGoalsMade20And29Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade30And39Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade40And49Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade50PlusYards?: Maybe<Scalars["Int"]>;
  fieldGoalsMadeBetween1And19Yards?: Maybe<Scalars["Int"]>;
  kickOffTouchbacks?: Maybe<Scalars["Int"]>;
  kickOffsLandedInsideTheOpponents20?: Maybe<Scalars["Int"]>;
  longestMadeFieldGoal?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  puntAttempts?: Maybe<Scalars["Int"]>;
  puntingTouchbacks?: Maybe<Scalars["Int"]>;
  puntsLandedInsideTheOpponents20?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflLeaguePlayer = {
  __typename?: "CTP_NflLeaguePlayer";
  /**
   * Career stats for the selected player
   * @deprecated Replaced by "statistics" path and related objects
   */
  careerStats?: Maybe<Array<Maybe<Ctp_NflPlayerSeasonStats>>>;
  depthChartRank?: Maybe<Scalars["String"]>;
  /** @deprecated Required details are able to be found within "statistics" currentSeason path therefore this is redundant */
  fantasyHistory?: Maybe<Array<Maybe<Ctp_NfPlayerFpHistory>>>;
  injuryStatus?: Maybe<Ctp_NflPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  news?: Maybe<Ctp_News>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerFutures?: Maybe<Array<Maybe<Ctp_PlayerFuture>>>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_NflPlayerInfo>;
  playerProps?: Maybe<Ctp_NflPlayerProps>;
  /** Popular Bets provided by the FDX api with multiple bet types */
  popularBets?: Maybe<Ctp_NflPlayerPopularBetsContext>;
  statistics?: Maybe<Ctp_LeaguePlayerStatisticsContext_NflLeaguePlayer>;
  /**
   * Current Season Stats for selected player
   * @deprecated Replaced by "statistics" path and related objects
   */
  stats?: Maybe<Array<Maybe<Ctp_NflPlayerWeeklyStats>>>;
  team?: Maybe<Ctp_NflTeam>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_NflLeagueSchedule = {
  __typename?: "CTP_NflLeagueSchedule";
  games?: Maybe<Array<Maybe<Ctp_NflGame>>>;
  seasonType?: Maybe<Ctp_SrSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

/** Featured Futures for NFL */
export type Ctp_NflMarket = {
  __typename?: "CTP_NflMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_NflSelection>>>;
};

export type Ctp_NflOffenseTeamStats = {
  __typename?: "CTP_NflOffenseTeamStats";
  averagePassingYardsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  averagePassingYardsPerThrow?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageRushingYardsPerCarry?: Maybe<Scalars["CTP_BigDecimal"]>;
  averageRushingYardsPerGame?: Maybe<Scalars["CTP_BigDecimal"]>;
  fumblesLost?: Maybe<Scalars["Int"]>;
  interceptionsThrown?: Maybe<Scalars["Int"]>;
  passAttempts?: Maybe<Scalars["Int"]>;
  passCompPercentage?: Maybe<Scalars["Float"]>;
  passingRating?: Maybe<Scalars["Float"]>;
  passingYards?: Maybe<Scalars["Int"]>;
  receivingYards?: Maybe<Scalars["Int"]>;
  receptions?: Maybe<Scalars["Int"]>;
  rushingAttempts?: Maybe<Scalars["Int"]>;
  rushingYards?: Maybe<Scalars["Int"]>;
  targets?: Maybe<Scalars["Int"]>;
  yardsAfterCatch?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflPassingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic =
  {
    __typename?: "CTP_NflPassingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic";
    attempts?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    completions?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    interceptions?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    /** Passer Ratio (Percent) of passing completions vs attempts. */
    ratio?: Maybe<Ctp_RatioEventStatistic>;
    touchdowns?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    yards?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NflPassingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic =
  {
    __typename?: "CTP_NflPassingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
    attempts?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    completions?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    interceptions?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    /** Passer Ratio (Percent) of passing completions vs attempts. */
    ratio?: Maybe<Ctp_RatioStatistic>;
    touchdowns?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
    yards?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  };

export type Ctp_NflPassingTeamPlayerStats = {
  __typename?: "CTP_NflPassingTeamPlayerStats";
  averagePocketTime?: Maybe<Scalars["Float"]>;
  completionPercentage?: Maybe<Scalars["Float"]>;
  completions?: Maybe<Scalars["Int"]>;
  hurries?: Maybe<Scalars["Int"]>;
  interceptions?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  passerRating?: Maybe<Scalars["Float"]>;
  passingAttempts?: Maybe<Scalars["Int"]>;
  passingTouchdowns?: Maybe<Scalars["Int"]>;
  passingYards?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  sacks?: Maybe<Scalars["Int"]>;
};

/** Featured Player Futures for NFL */
export type Ctp_NflPlayerFutures = {
  __typename?: "CTP_NflPlayerFutures";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  nflPlayerFuturesOdds?: Maybe<Array<Maybe<Ctp_NflPlayerFuturesBet>>>;
};

export type Ctp_NflPlayerFuturesBet = {
  __typename?: "CTP_NflPlayerFuturesBet";
  odds?: Maybe<Scalars["String"]>;
  playerJersey?: Maybe<Ctp_PlayerPropsJersey>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_NflPlayerGameStatistics = {
  __typename?: "CTP_NflPlayerGameStatistics";
  defense?: Maybe<Ctp_NflDefensiveStatistics_CumulativeAverageEventStatistics_Integer>;
  fantasyPoints?: Maybe<Ctp_PlayerEventFantasyPoints>;
  fumbles?: Maybe<Ctp_NflFumblesStatistics_CumulativeAverageEventStatistics_Integer>;
  gameInfo?: Maybe<Ctp_NflGameDetails>;
  kicking?: Maybe<Ctp_NflKickingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  passing?: Maybe<Ctp_NflPassingStatistics_CumulativeAverageEventStatistics_Integer_RatioEventStatistic>;
  playerTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  receiving?: Maybe<Ctp_NflReceivingStatistics_CumulativeAverageEventStatistics_Integer>;
  rushing?: Maybe<Ctp_NflRushingStatistics_CumulativeAverageEventStatistics_Integer>;
  /** Number of times sacked if the player is a skill player, or number of sacks if player is defensive. */
  sacks?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  /** Cumulative sum of Rushing, Receiving, and Passing touchdowns scored. */
  touchdowns?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
};

export type Ctp_NflPlayerGameStatisticsBySeason = {
  __typename?: "CTP_NflPlayerGameStatisticsBySeason";
  gameStatistics?: Maybe<Array<Maybe<Ctp_NflPlayerGameStatistics>>>;
  seasonInfo?: Maybe<Ctp_SeasonDetails>;
};

export type Ctp_NflPlayerInfo = {
  __typename?: "CTP_NflPlayerInfo";
  age?: Maybe<Scalars["Int"]>;
  college?: Maybe<Scalars["String"]>;
  draft?: Maybe<Ctp_DepthChartDraft>;
  fppg?: Maybe<Scalars["String"]>;
  fppgLastGames?: Maybe<Scalars["String"]>;
  gamesPlayed?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["String"]>;
  highSchool?: Maybe<Scalars["String"]>;
  jerseyNumber?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  salary?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Float"]>;
  yearsOfExperience?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflPlayerInjuryStatus = {
  __typename?: "CTP_NflPlayerInjuryStatus";
  detail?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type Ctp_NflPlayerPopularBetsContext = {
  __typename?: "CTP_NflPlayerPopularBetsContext";
  /** A combination of single bets represented as one bet */
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** A combination of same game parlays and possibly other bet types */
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Parlays of bets within the same event */
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  /** Individual popular bets provided by the FDX api */
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NflPlayerPopularBetsContextParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NflPlayerPopularBetsContextSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NflPlayerPopularBetsContextSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NflPlayerProjection = {
  __typename?: "CTP_NflPlayerProjection";
  averageDraftPosition?: Maybe<Scalars["String"]>;
  byeWeek?: Maybe<Scalars["Int"]>;
  changeInAverageDraftPosition?: Maybe<Scalars["String"]>;
  completionsAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  depth?: Maybe<Scalars["Int"]>;
  extraPointsAttempted?: Maybe<Scalars["CTP_BigDecimal"]>;
  extraPointsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  fantasyPoints?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsAttempted?: Maybe<Scalars["CTP_BigDecimal"]>;
  fieldGoalsMade?: Maybe<Scalars["CTP_BigDecimal"]>;
  fumblesRecovered?: Maybe<Scalars["CTP_BigDecimal"]>;
  id?: Maybe<Scalars["Int"]>;
  injury?: Maybe<Scalars["String"]>;
  interceptions?: Maybe<Scalars["CTP_BigDecimal"]>;
  interceptionsThrown?: Maybe<Scalars["CTP_BigDecimal"]>;
  nfTeamId?: Maybe<Scalars["String"]>;
  overallRank?: Maybe<Scalars["Int"]>;
  passAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  passCompletions?: Maybe<Scalars["CTP_BigDecimal"]>;
  passingTouchdowns?: Maybe<Scalars["CTP_BigDecimal"]>;
  passingYards?: Maybe<Scalars["CTP_BigDecimal"]>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerName?: Maybe<Scalars["String"]>;
  pointsAllowed?: Maybe<Scalars["CTP_BigDecimal"]>;
  position?: Maybe<Scalars["String"]>;
  positionRank?: Maybe<Scalars["Int"]>;
  receivingTouchdowns?: Maybe<Scalars["CTP_BigDecimal"]>;
  receivingYards?: Maybe<Scalars["CTP_BigDecimal"]>;
  receptions?: Maybe<Scalars["CTP_BigDecimal"]>;
  risk?: Maybe<Scalars["String"]>;
  rushingAttempts?: Maybe<Scalars["CTP_BigDecimal"]>;
  rushingTouchdowns?: Maybe<Scalars["CTP_BigDecimal"]>;
  rushingYards?: Maybe<Scalars["CTP_BigDecimal"]>;
  sacks?: Maybe<Scalars["CTP_BigDecimal"]>;
  safeties?: Maybe<Scalars["CTP_BigDecimal"]>;
  scoring?: Maybe<Scalars["String"]>;
  sportradarId?: Maybe<Scalars["String"]>;
  targets?: Maybe<Scalars["CTP_BigDecimal"]>;
  team?: Maybe<Scalars["String"]>;
  teamMeta?: Maybe<Ctp_NflTeamMetadata>;
  touchdowns?: Maybe<Scalars["CTP_BigDecimal"]>;
  yardsAllowed?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NflPlayerProps = {
  __typename?: "CTP_NflPlayerProps";
  contentText?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<Ctp_NflPlayerPropsEvent>>>;
  id?: Maybe<Scalars["String"]>;
  playerJersey?: Maybe<Ctp_PlayerPropsJersey>;
};

export type Ctp_NflPlayerPropsEvent = {
  __typename?: "CTP_NflPlayerPropsEvent";
  date?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  eventName?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_NflPlayerPropsMarket>>>;
};

export type Ctp_NflPlayerPropsMarket = {
  __typename?: "CTP_NflPlayerPropsMarket";
  alternateMarket?: Maybe<Scalars["Boolean"]>;
  contentText?: Maybe<Scalars["String"]>;
  eligibleForSameGameParlay?: Maybe<Scalars["Boolean"]>;
  externalMarketId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  marketTypeName?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_NflPlayerPropsSelection>>>;
};

export type Ctp_NflPlayerPropsSelection = {
  __typename?: "CTP_NflPlayerPropsSelection";
  betUrl?: Maybe<Scalars["String"]>;
  contentText?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflPlayerSeasonStatistics = {
  __typename?: "CTP_NflPlayerSeasonStatistics";
  defense?: Maybe<Ctp_NflDefensiveStatistics_CumulativeAverageStatistics_Integer>;
  fantasyPoints?: Maybe<Ctp_PlayerFantasyPoints>;
  fumbles?: Maybe<Ctp_NflFumblesStatistics_CumulativeAverageStatistics_Integer>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  kicking?: Maybe<Ctp_NflKickingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  passing?: Maybe<Ctp_NflPassingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic>;
  playerTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  receiving?: Maybe<Ctp_NflReceivingStatistics_CumulativeAverageStatistics_Integer>;
  rushing?: Maybe<Ctp_NflRushingStatistics_CumulativeAverageStatistics_Integer>;
  /** Number of times sacked if the player is a skill player, or number of sacks if player is defensive. */
  sacks?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  /** Cumulative sum of Rushing, Receiving, and Passing touchdowns scored. */
  touchdowns?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NflPlayerSeasonStatisticsBySeason = {
  __typename?: "CTP_NflPlayerSeasonStatisticsBySeason";
  seasonInfo?: Maybe<Ctp_SeasonDetails>;
  seasonStatistics?: Maybe<Ctp_NflPlayerSeasonStatistics>;
};

export type Ctp_NflPlayerSeasonStats = {
  __typename?: "CTP_NflPlayerSeasonStats";
  avg?: Maybe<Scalars["Float"]>;
  extraPointAttempts?: Maybe<Scalars["Int"]>;
  extraPointsMade?: Maybe<Scalars["Int"]>;
  fantasyPoints?: Maybe<Scalars["Float"]>;
  fieldGoalAttempts0To39Yards?: Maybe<Scalars["Int"]>;
  fieldGoalAttempts40To49Yards?: Maybe<Scalars["Int"]>;
  fieldGoalAttempts50PlusYards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade0To39Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade40To49Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade50PlusYards?: Maybe<Scalars["Int"]>;
  fumbleRecoveries?: Maybe<Scalars["Int"]>;
  fumblesLost?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  interceptions?: Maybe<Scalars["Int"]>;
  passesDefended?: Maybe<Scalars["Int"]>;
  passingAttempts?: Maybe<Scalars["Int"]>;
  passingCompletions?: Maybe<Scalars["Int"]>;
  passingInterceptions?: Maybe<Scalars["Int"]>;
  passingPercent?: Maybe<Scalars["Int"]>;
  passingTouchdowns?: Maybe<Scalars["Int"]>;
  passingYards?: Maybe<Scalars["Int"]>;
  passingYardsAllowed?: Maybe<Scalars["Int"]>;
  playerName?: Maybe<Scalars["String"]>;
  playerTeam?: Maybe<Scalars["String"]>;
  playerTeamId?: Maybe<Scalars["Int"]>;
  pointsAllowed?: Maybe<Scalars["Int"]>;
  receivingTouchdowns?: Maybe<Scalars["Int"]>;
  receivingYards?: Maybe<Scalars["Int"]>;
  receptions?: Maybe<Scalars["Int"]>;
  rushTds?: Maybe<Scalars["Int"]>;
  rushingAttempts?: Maybe<Scalars["Int"]>;
  rushingYards?: Maybe<Scalars["Int"]>;
  rushingYardsAllowed?: Maybe<Scalars["Int"]>;
  sacks?: Maybe<Scalars["Int"]>;
  safeties?: Maybe<Scalars["Int"]>;
  season?: Maybe<Scalars["String"]>;
  seasonType?: Maybe<Scalars["String"]>;
  targets?: Maybe<Scalars["Int"]>;
  touchdowns?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflPlayerWeeklyStats = {
  __typename?: "CTP_NflPlayerWeeklyStats";
  avg?: Maybe<Scalars["Float"]>;
  byeWeek: Scalars["Boolean"];
  currentWeek: Scalars["Boolean"];
  date?: Maybe<Scalars["String"]>;
  extraPointAttempts?: Maybe<Scalars["Int"]>;
  extraPointsMade?: Maybe<Scalars["Int"]>;
  fantasyPoints?: Maybe<Scalars["Float"]>;
  fieldGoalAttempts0To39Yards?: Maybe<Scalars["Int"]>;
  fieldGoalAttempts40To49Yards?: Maybe<Scalars["Int"]>;
  fieldGoalAttempts50PlusYards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade0To39Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade40To49Yards?: Maybe<Scalars["Int"]>;
  fieldGoalsMade50PlusYards?: Maybe<Scalars["Int"]>;
  fumbleRecoveries?: Maybe<Scalars["Int"]>;
  fumblesLost?: Maybe<Scalars["Int"]>;
  homeGame: Scalars["Boolean"];
  interceptions?: Maybe<Scalars["Int"]>;
  opponentId?: Maybe<Scalars["String"]>;
  opponentSportRadarTeamId?: Maybe<Scalars["String"]>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  passesDefended?: Maybe<Scalars["Int"]>;
  passingAttempts?: Maybe<Scalars["Int"]>;
  passingCompletions?: Maybe<Scalars["Int"]>;
  passingInterceptions?: Maybe<Scalars["Int"]>;
  passingPercent?: Maybe<Scalars["Int"]>;
  passingTouchdowns?: Maybe<Scalars["Int"]>;
  passingYards?: Maybe<Scalars["Int"]>;
  passingYardsAllowed?: Maybe<Scalars["Int"]>;
  playoffs: Scalars["Boolean"];
  pointsAllowed?: Maybe<Scalars["Int"]>;
  receivingTouchdowns?: Maybe<Scalars["Int"]>;
  receivingYards?: Maybe<Scalars["Int"]>;
  receptions?: Maybe<Scalars["Int"]>;
  rushTds?: Maybe<Scalars["Int"]>;
  rushingAttempts?: Maybe<Scalars["Int"]>;
  rushingYards?: Maybe<Scalars["Int"]>;
  rushingYardsAllowed?: Maybe<Scalars["Int"]>;
  sacks?: Maybe<Scalars["Int"]>;
  safeties?: Maybe<Scalars["Int"]>;
  targets?: Maybe<Scalars["Int"]>;
  touchdowns?: Maybe<Scalars["Int"]>;
  week?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflPowerRanking = {
  __typename?: "CTP_NflPowerRanking";
  playoffProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
  rank?: Maybe<Scalars["Int"]>;
  rankingsWeek: Scalars["Int"];
  seasonYear: Scalars["Int"];
  superBowlProbability?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NflPrevNextGame = {
  __typename?: "CTP_NflPrevNextGame";
  nextGames?: Maybe<Array<Maybe<Ctp_NflUpcomingTeamGame>>>;
  previousGames?: Maybe<Array<Maybe<Ctp_NflTeamGame>>>;
};

export type Ctp_NflReceivingStatistics_CumulativeAverageEventStatistics_Integer =
  {
    __typename?: "CTP_NflReceivingStatistics_CumulativeAverageEventStatistics_Integer";
    receptions?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    targets?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    touchdowns?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    yards?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NflReceivingStatistics_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_NflReceivingStatistics_CumulativeAverageStatistics_Integer";
  receptions?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  targets?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  touchdowns?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  yards?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NflReceivingTeamPlayerStats = {
  __typename?: "CTP_NflReceivingTeamPlayerStats";
  averageReceivingYards?: Maybe<Scalars["Float"]>;
  droppedPasses?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  receivingTouchdowns?: Maybe<Scalars["Int"]>;
  receivingYards?: Maybe<Scalars["Int"]>;
  receptions?: Maybe<Scalars["Int"]>;
  targets?: Maybe<Scalars["Int"]>;
  yardsAfterCatch?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflRecentGameRecord = {
  __typename?: "CTP_NflRecentGameRecord";
  lose?: Maybe<Scalars["Int"]>;
  numberOfGames?: Maybe<Scalars["Int"]>;
  tie?: Maybe<Scalars["Int"]>;
  win?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflReturningTeamPlayerStats = {
  __typename?: "CTP_NflReturningTeamPlayerStats";
  averageKickReturnYards?: Maybe<Scalars["Float"]>;
  averagePuntReturnYards?: Maybe<Scalars["Float"]>;
  kickReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickReturnYards?: Maybe<Scalars["Int"]>;
  kickReturns?: Maybe<Scalars["Int"]>;
  longestKickReturn?: Maybe<Scalars["Int"]>;
  longestPuntReturn?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  puntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  puntReturnYards?: Maybe<Scalars["Int"]>;
  puntReturns?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflRushingStatistics_CumulativeAverageEventStatistics_Integer =
  {
    __typename?: "CTP_NflRushingStatistics_CumulativeAverageEventStatistics_Integer";
    attempts?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    touchdowns?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
    yards?: Maybe<Ctp_CumulativeAverageEventStatistics_Integer>;
  };

export type Ctp_NflRushingStatistics_CumulativeAverageStatistics_Integer = {
  __typename?: "CTP_NflRushingStatistics_CumulativeAverageStatistics_Integer";
  attempts?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  touchdowns?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
  yards?: Maybe<Ctp_CumulativeAverageStatistics_Integer>;
};

export type Ctp_NflRushingTeamPlayerStats = {
  __typename?: "CTP_NflRushingTeamPlayerStats";
  averageRushingYards?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  rushingAttempts?: Maybe<Scalars["Int"]>;
  rushingTouchdowns?: Maybe<Scalars["Int"]>;
  rushingYards?: Maybe<Scalars["Int"]>;
  yardsAfterContact?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflScoringTeamStats = {
  __typename?: "CTP_NflScoringTeamStats";
  fieldGoalMade?: Maybe<Scalars["Int"]>;
  fieldGoalPercentage?: Maybe<Scalars["Float"]>;
  fumblesReturnedForTouchdowns?: Maybe<Scalars["Int"]>;
  intTouchdowns?: Maybe<Scalars["Int"]>;
  passingTouchdowns?: Maybe<Scalars["Int"]>;
  redZoneConversionPercentage?: Maybe<Scalars["Float"]>;
  rushingTouchdowns?: Maybe<Scalars["Int"]>;
  totalRedZoneConversions?: Maybe<Scalars["Int"]>;
  totalSpecialTeamsReturnTouchdowns?: Maybe<Scalars["Int"]>;
  totalTouchdowns?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflSeasonMeta = {
  __typename?: "CTP_NflSeasonMeta";
  /** Breakdown of games by week and seasonType for schedule filtering */
  seasonStructure?: Maybe<Array<Maybe<Ctp_NflSeasonStructure>>>;
};

export type Ctp_NflSeasonStructure = {
  __typename?: "CTP_NflSeasonStructure";
  seasonType?: Maybe<Ctp_SrSeasonType>;
  weeks?: Maybe<Array<Maybe<Ctp_NflSeasonWeek>>>;
};

export type Ctp_NflSeasonWeek = {
  __typename?: "CTP_NflSeasonWeek";
  firstGameTime?: Maybe<Scalars["CTP_Instant"]>;
  lastGameTime?: Maybe<Scalars["CTP_Instant"]>;
  week?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflSelection = {
  __typename?: "CTP_NflSelection";
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

/** NFL SiteMapPlayer */
export type Ctp_NflSiteMapPlayer = {
  __typename?: "CTP_NflSiteMapPlayer";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  nfPlayerSlug?: Maybe<Scalars["String"]>;
  numberFireTeamId?: Maybe<Scalars["Int"]>;
  playerId?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type Ctp_NflSpecialTeamStats = {
  __typename?: "CTP_NflSpecialTeamStats";
  extraPointPercentage?: Maybe<Scalars["Float"]>;
  extraPointsMade?: Maybe<Scalars["Int"]>;
  fieldGoalPercentage?: Maybe<Scalars["Float"]>;
  kickOffTouchbacks?: Maybe<Scalars["Int"]>;
  kickOffsInsideOpponent20?: Maybe<Scalars["Int"]>;
  kickReturnAverageYards?: Maybe<Scalars["Float"]>;
  kickReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickReturnYards?: Maybe<Scalars["Int"]>;
  longestFieldGoal?: Maybe<Scalars["Int"]>;
  madeFieldGoals?: Maybe<Scalars["Int"]>;
  puntReturnAverageYards?: Maybe<Scalars["Float"]>;
  puntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  puntingAverage?: Maybe<Scalars["Float"]>;
  puntingNetAverage?: Maybe<Scalars["Float"]>;
  puntingTouchbacks?: Maybe<Scalars["Int"]>;
  puntsInsideOpponent20?: Maybe<Scalars["Int"]>;
  totalPuntReturnYards?: Maybe<Scalars["Int"]>;
};

/** Team type for NFL */
export type Ctp_NflTeam = {
  __typename?: "CTP_NflTeam";
  betRecords?: Maybe<Ctp_NflBetRecords>;
  code?: Maybe<Scalars["String"]>;
  /** Deprecated. Moving to Team Metadata. */
  conference?: Maybe<Scalars["String"]>;
  /** Deprecated. Moving to Team Metadata. */
  division?: Maybe<Scalars["String"]>;
  fanDuelTeamId?: Maybe<Scalars["Int"]>;
  meta?: Maybe<Ctp_NflTeamMetadata>;
  numberFireId?: Maybe<Scalars["Int"]>;
  players?: Maybe<Array<Maybe<Ctp_NflLeaguePlayer>>>;
  popularBets?: Maybe<Ctp_NflTeamPopularBets>;
  powerRanking?: Maybe<Ctp_NflPowerRanking>;
  prevNextGame?: Maybe<Ctp_NflPrevNextGame>;
  record?: Maybe<Ctp_NflTeamRecord>;
  selectionIds?: Maybe<Array<Maybe<Scalars["CTP_Long"]>>>;
  srId?: Maybe<Scalars["String"]>;
  teamFutures?: Maybe<Array<Maybe<Ctp_NflFutureOdd>>>;
  teamLeader?: Maybe<Ctp_NflTeamLeaders>;
  /** @deprecated Use team.players instead */
  teamPlayers?: Maybe<Array<Maybe<Ctp_NflTeamPlayer>>>;
  teamSchedule?: Maybe<Array<Maybe<Ctp_NflTeamSchedule>>>;
  teamStats?: Maybe<Ctp_NflTeamSeasonalStats>;
};

/** Team type for NFL */
export type Ctp_NflTeamPrevNextGameArgs = {
  numberOfNext?: InputMaybe<Scalars["Int"]>;
  numberOfPrevious?: InputMaybe<Scalars["Int"]>;
};

/** Team type for NFL */
export type Ctp_NflTeamTeamStatsArgs = {
  teamStatsFilter?: InputMaybe<Ctp_TeamStatsFilterInput>;
};

export type Ctp_NflTeamFutureOdd = {
  __typename?: "CTP_NflTeamFutureOdd";
  futureOdds?: Maybe<Array<Maybe<Ctp_NflFutureOdd>>>;
  teamMeta?: Maybe<Ctp_NflTeamMetadata>;
};

export type Ctp_NflTeamGame = {
  __typename?: "CTP_NflTeamGame";
  broadcastInfo?: Maybe<Ctp_NflBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NflTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NflGameWeather>;
  weekNumber: Scalars["Int"];
};

/** Team Leader Stat for NFL */
export type Ctp_NflTeamLeaderStat_Integer = {
  __typename?: "CTP_NflTeamLeaderStat_Integer";
  leader?: Maybe<Ctp_NflTeamPlayer>;
  statInformation?: Maybe<Scalars["String"]>;
  statLabel?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["Int"]>;
};

/** Nfl Team Leaders by stat value */
export type Ctp_NflTeamLeaders = {
  __typename?: "CTP_NflTeamLeaders";
  totalPassingTouchdowns?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
  totalPassingYards?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
  totalReceivingTouchdowns?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
  totalReceivingYards?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
  totalRushingTouchdowns?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
  totalRushingYards?: Maybe<Ctp_NflTeamLeaderStat_Integer>;
};

/** Metadata pertaining to NFL team */
export type Ctp_NflTeamMetadata = {
  __typename?: "CTP_NflTeamMetadata";
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Ctp_TeamColors>;
  conference?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  estYear?: Maybe<Scalars["Int"]>;
  headCoach?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  logo?: Maybe<Ctp_TeamLogo>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  stadium?: Maybe<Scalars["String"]>;
  teamJersey?: Maybe<Ctp_TeamJersey>;
};

export type Ctp_NflTeamPlayer = {
  __typename?: "CTP_NflTeamPlayer";
  depthChartRank?: Maybe<Scalars["String"]>;
  injuryStatus?: Maybe<Ctp_NflPlayerInjuryStatus>;
  name?: Maybe<Scalars["String"]>;
  numberFirePlayerId?: Maybe<Scalars["Int"]>;
  numberFirePlayerSlug?: Maybe<Scalars["String"]>;
  playerFutures?: Maybe<Array<Maybe<Ctp_PlayerFuture>>>;
  playerImageUrl?: Maybe<Scalars["String"]>;
  playerInfo?: Maybe<Ctp_NflPlayerInfo>;
  playerProps?: Maybe<Ctp_NflPlayerProps>;
  /** Popular Bets provided by the FDX api with multiple bet types */
  popularBets?: Maybe<Ctp_NflPlayerPopularBetsContext>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_NflTeamPlayerStats = {
  __typename?: "CTP_NflTeamPlayerStats";
  allCategories?: Maybe<Array<Maybe<Ctp_NflAllCategoriesTeamPlayerStats>>>;
  defense?: Maybe<Array<Maybe<Ctp_NflDefenseTeamPlayerStats>>>;
  kicking?: Maybe<Array<Maybe<Ctp_NflKickingTeamPlayerStats>>>;
  passing?: Maybe<Array<Maybe<Ctp_NflPassingTeamPlayerStats>>>;
  receiving?: Maybe<Array<Maybe<Ctp_NflReceivingTeamPlayerStats>>>;
  returning?: Maybe<Array<Maybe<Ctp_NflReturningTeamPlayerStats>>>;
  rushing?: Maybe<Array<Maybe<Ctp_NflRushingTeamPlayerStats>>>;
};

export type Ctp_NflTeamPoints = {
  __typename?: "CTP_NflTeamPoints";
  pointDiff?: Maybe<Scalars["Int"]>;
  pointsAgainst?: Maybe<Scalars["Int"]>;
  pointsFor?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflTeamPopularBets = {
  __typename?: "CTP_NflTeamPopularBets";
  parlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlayPluses?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  sameGameParlays?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
  singles?: Maybe<Array<Maybe<Ctp_PopularBet>>>;
};

export type Ctp_NflTeamPopularBetsParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NflTeamPopularBetsSameGameParlayPlusesArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

export type Ctp_NflTeamPopularBetsSameGameParlaysArgs = {
  includeOtherSports?: InputMaybe<Scalars["Boolean"]>;
};

/** Team record broken out into win/loss records. */
export type Ctp_NflTeamRecord = {
  __typename?: "CTP_NflTeamRecord";
  awayRecord?: Maybe<Ctp_WinLoseTieRecord>;
  clinched?: Maybe<Scalars["String"]>;
  conferenceRank?: Maybe<Scalars["Int"]>;
  conferenceRecord?: Maybe<Ctp_NflConferenceRecord>;
  divisionRank?: Maybe<Scalars["Int"]>;
  divisionRecord?: Maybe<Ctp_NflDivisionRecord>;
  homeRecord?: Maybe<Ctp_WinLoseTieRecord>;
  nflTeamPoints?: Maybe<Ctp_NflTeamPoints>;
  nonConference?: Maybe<Ctp_WinLoseTieRecord>;
  recentGamesRecord?: Maybe<Ctp_NflRecentGameRecord>;
  streak?: Maybe<Ctp_Streak>;
  winPercentage?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_NflTeamSchedule = {
  __typename?: "CTP_NflTeamSchedule";
  games?: Maybe<Array<Maybe<Ctp_NflTeamGame>>>;
  seasonType?: Maybe<Ctp_SrSeasonType>;
  title?: Maybe<Scalars["String"]>;
};

export type Ctp_NflTeamSeasonalStats = {
  __typename?: "CTP_NflTeamSeasonalStats";
  currentTeam?: Maybe<Ctp_NflTeamStats>;
  opponentAgainstTeam?: Maybe<Ctp_NflTeamStats>;
  players?: Maybe<Ctp_NflTeamPlayerStats>;
  seasonType?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type Ctp_NflTeamStats = {
  __typename?: "CTP_NflTeamStats";
  defenseTeamStats?: Maybe<Ctp_NflDefenseTeamStats>;
  offenseTeamStats?: Maybe<Ctp_NflOffenseTeamStats>;
  scoringStats?: Maybe<Ctp_NflScoringTeamStats>;
  specialTeamStats?: Maybe<Ctp_NflSpecialTeamStats>;
};

export type Ctp_NflUpcomingTeamGame = {
  __typename?: "CTP_NflUpcomingTeamGame";
  broadcastInfo?: Maybe<Ctp_NflBroadcastInfo>;
  byeWeek: Scalars["Boolean"];
  cancelled?: Maybe<Scalars["Boolean"]>;
  eventId?: Maybe<Scalars["Int"]>;
  gameTitle?: Maybe<Scalars["String"]>;
  homeGame?: Maybe<Scalars["Boolean"]>;
  odds?: Maybe<Ctp_NflGameOdds>;
  opponentPoints?: Maybe<Scalars["Int"]>;
  opponentRecord?: Maybe<Ctp_NflTeamRecord>;
  opponentTeamCode?: Maybe<Scalars["String"]>;
  opponentTeamMeta?: Maybe<Ctp_NflTeamMetadata>;
  postponed?: Maybe<Scalars["Boolean"]>;
  scheduledTime?: Maybe<Scalars["CTP_Instant"]>;
  status?: Maybe<Ctp_SrGameStatus>;
  teamCode?: Maybe<Scalars["String"]>;
  teamPoints?: Maybe<Scalars["Int"]>;
  venueName?: Maybe<Scalars["String"]>;
  venueType?: Maybe<Scalars["String"]>;
  weather?: Maybe<Ctp_NflGameWeather>;
  weekNumber: Scalars["Int"];
};

export type Ctp_NflUpcomingTeamGameOddsArgs = {
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type Ctp_PlayerEventFantasyPoints = {
  __typename?: "CTP_PlayerEventFantasyPoints";
  /** Rolling Average of Fantasy Points scored for the year through this event (inclusive). */
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Cumulative sum of Fantasy Points scored for the year through this event (inclusive). */
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Fantasy Points scored during this event. */
  event?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_PlayerFantasyPoints = {
  __typename?: "CTP_PlayerFantasyPoints";
  /** Average number of Fantasy Points per game scored by the player. */
  average?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Cumulative sum of Fantasy Points scored by the player. */
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_PlayerFuture = {
  __typename?: "CTP_PlayerFuture";
  betUrl?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
};

export type Ctp_PlayerIdentityFilterInput = {
  numberFirePlayerSlug?: InputMaybe<Scalars["String"]>;
  providerId?: InputMaybe<Ctp_ProviderIdInput>;
};

export type Ctp_PlayerOdd = {
  __typename?: "CTP_PlayerOdd";
  betUrl?: Maybe<Scalars["String"]>;
  playerLine?: Maybe<Scalars["Float"]>;
  playerName?: Maybe<Scalars["String"]>;
  playerOdd?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["String"]>;
};

export type Ctp_PlayerPropsJersey = {
  __typename?: "CTP_PlayerPropsJersey";
  background?: Maybe<Scalars["String"]>;
  png?: Maybe<Scalars["String"]>;
  svg?: Maybe<Scalars["String"]>;
};

export type Ctp_PlayerPropsStatInformation = {
  __typename?: "CTP_PlayerPropsStatInformation";
  context?: Maybe<Scalars["String"]>;
  statValue?: Maybe<Scalars["CTP_BigDecimal"]>;
};

export type Ctp_PlayerUpdate = {
  __typename?: "CTP_PlayerUpdate";
  content?: Maybe<Scalars["String"]>;
  /** Deprecated. Use isoDate instead. */
  date?: Maybe<Scalars["String"]>;
  isoDate?: Maybe<Scalars["String"]>;
  playerImage?: Maybe<Scalars["String"]>;
  playerName?: Maybe<Scalars["String"]>;
  playerPosition?: Maybe<Scalars["String"]>;
  playerTeam?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};

export type Ctp_PopularBet = {
  __typename?: "CTP_PopularBet";
  americanOdds?: Maybe<Scalars["Int"]>;
  betUrl?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  events?: Maybe<Array<Maybe<Ctp_PopularBetEvent>>>;
  type?: Maybe<Scalars["String"]>;
};

export type Ctp_PopularBetEvent = {
  __typename?: "CTP_PopularBetEvent";
  competitionName?: Maybe<Scalars["String"]>;
  eventName?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_PopularBetSelection>>>;
};

export type Ctp_PopularBetSelection = {
  __typename?: "CTP_PopularBetSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  marketName?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
};

export type Ctp_PreviousYearsFilterInput = {
  includeCurrentYear?: InputMaybe<Scalars["Boolean"]>;
  numPreviousYears?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_PropsForAGame = {
  __typename?: "CTP_PropsForAGame";
  marketType?: Maybe<Scalars["String"]>;
  playerPropsForGame?: Maybe<Array<Maybe<Ctp_PlayerOdd>>>;
  propName?: Maybe<Scalars["String"]>;
};

export type Ctp_ProviderIdInput = {
  id?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Ctp_DataProvider>;
};

export type Ctp_RatioEventStatistic = {
  __typename?: "CTP_RatioEventStatistic";
  /** Ratio of the two corresponding cumulative values as a decimal. */
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the two corresponding event values as a decimal. */
  event?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the corresponding event values as a percentage. */
  eventPercent?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the corresponding event values as a percentage String. */
  eventPercentDisplay?: Maybe<Scalars["String"]>;
  /** Ratio of the corresponding cumulative values as a percentage. */
  percent?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the corresponding cumulative values as a percentage String. */
  percentDisplay?: Maybe<Scalars["String"]>;
};

export type Ctp_RatioStatistic = {
  __typename?: "CTP_RatioStatistic";
  /** Ratio of the two corresponding cumulative values as a decimal. */
  cumulative?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the corresponding cumulative values as a percentage. */
  percent?: Maybe<Scalars["CTP_BigDecimal"]>;
  /** Ratio of the corresponding cumulative values as a percentage String. */
  percentDisplay?: Maybe<Scalars["String"]>;
};

export type Ctp_ScheduleResult = {
  __typename?: "CTP_ScheduleResult";
  current?: Maybe<Scalars["Int"]>;
  opponent?: Maybe<Scalars["Int"]>;
};

export type Ctp_SeasonDetails = {
  __typename?: "CTP_SeasonDetails";
  /** Display value for season - i.e. "2023-2024" */
  season?: Maybe<Scalars["String"]>;
  seasonType?: Maybe<Scalars["String"]>;
  seasonYear?: Maybe<Scalars["Int"]>;
};

export type Ctp_SpreadTotal = {
  __typename?: "CTP_SpreadTotal";
  odd?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum Ctp_SrGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  FlexSchedule = "FLEX_SCHEDULE",
  Halftime = "HALFTIME",
  InProgress = "IN_PROGRESS",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  TimeTbd = "TIME_TBD",
}

export enum Ctp_SrMlbGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  FDelay = "F_DELAY",
  IfNecessary = "IF_NECESSARY",
  InProgress = "IN_PROGRESS",
  Maintenance = "MAINTENANCE",
  ODelay = "O_DELAY",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  Suspended = "SUSPENDED",
  Unnecessary = "UNNECESSARY",
  WDelay = "W_DELAY",
}

export enum Ctp_SrMlbSeasonType {
  Allstar = "ALLSTAR",
  Post = "POST",
  Pre = "PRE",
  Regular = "REGULAR",
}

export enum Ctp_SrNbaGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  Halftime = "HALFTIME",
  IfNecessary = "IF_NECESSARY",
  InProgress = "IN_PROGRESS",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  TimeTbd = "TIME_TBD",
  Unnecessary = "UNNECESSARY",
}

export enum Ctp_SrNbaSeasonType {
  InSeasonTournament = "IN_SEASON_TOURNAMENT",
  Pit = "PIT",
  Post = "POST",
  Pre = "PRE",
  Regular = "REGULAR",
}

export enum Ctp_SrNcaaFbGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  FlexSchedule = "FLEX_SCHEDULE",
  Halftime = "HALFTIME",
  InProgress = "IN_PROGRESS",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  TimeTbd = "TIME_TBD",
}

export enum Ctp_SrNcaaFbGameType {
  Bowl = "BOWL",
  ConferenceChampionship = "CONFERENCE_CHAMPIONSHIP",
  Playoff = "PLAYOFF",
  Regular = "REGULAR",
}

export enum Ctp_SrNcaaMbGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  Halftime = "HALFTIME",
  IfNecessary = "IF_NECESSARY",
  InProgress = "IN_PROGRESS",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  TimeTbd = "TIME_TBD",
  Unnecessary = "UNNECESSARY",
}

export enum Ctp_SrNcaaMbSeasonType {
  ConferenceTournament = "CONFERENCE_TOURNAMENT",
  Post = "POST",
  Regular = "REGULAR",
}

export enum Ctp_SrSeasonType {
  Post = "POST",
  Pre = "PRE",
  Regular = "REGULAR",
}

export type Ctp_StatisticScoreDetails = {
  __typename?: "CTP_StatisticScoreDetails";
  opponentTeam?: Maybe<Scalars["Int"]>;
  playerTeam?: Maybe<Scalars["Int"]>;
};

export type Ctp_Streak = {
  __typename?: "CTP_Streak";
  number?: Maybe<Scalars["Int"]>;
  type?: Maybe<Ctp_StreakEnum>;
};

export enum Ctp_StreakEnum {
  Loss = "LOSS",
  Tie = "TIE",
  Win = "WIN",
}

export type Ctp_Tbsl = {
  __typename?: "CTP_TBSL";
  events?: Maybe<Array<Maybe<Ctp_TbslEvent>>>;
};

/** Events for TBSL */
export type Ctp_TbslEvent = {
  __typename?: "CTP_TbslEvent";
  eventId?: Maybe<Scalars["Int"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Ctp_TbslMarket>>>;
  name?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["CTP_LocalDateTime"]>;
};

/** Futures for TBSL */
export type Ctp_TbslMarket = {
  __typename?: "CTP_TbslMarket";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Ctp_TbslSelection>>>;
};

export type Ctp_TbslSelection = {
  __typename?: "CTP_TbslSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerStatus?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Ctp_TeamColors = {
  __typename?: "CTP_TeamColors";
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

/** Filter team by conference (or league in MLB), or teamSlug */
export type Ctp_TeamFilterInput = {
  conference?: InputMaybe<Scalars["String"]>;
  teamCode?: InputMaybe<Scalars["String"]>;
  teamIds?: InputMaybe<Array<InputMaybe<Ctp_ProviderIdInput>>>;
  teamSlug?: InputMaybe<Scalars["String"]>;
};

export type Ctp_TeamGameOdds = {
  __typename?: "CTP_TeamGameOdds";
  oddsDetails?: Maybe<Ctp_GameOddsDetails>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Ctp_TeamJersey = {
  __typename?: "CTP_TeamJersey";
  png?: Maybe<Scalars["String"]>;
  svg?: Maybe<Scalars["String"]>;
};

export type Ctp_TeamLogo = {
  __typename?: "CTP_TeamLogo";
  bitmap?: Maybe<Scalars["String"]>;
  svg?: Maybe<Scalars["String"]>;
};

/** Filter teamStats by year or seasonType (defaults to current regular season stats */
export type Ctp_TeamStatsFilterInput = {
  seasonType?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type Ctp_WinLoseRecord = {
  __typename?: "CTP_WinLoseRecord";
  losses?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_WinLoseTieRecord = {
  __typename?: "CTP_WinLoseTieRecord";
  losses?: Maybe<Scalars["Int"]>;
  ties?: Maybe<Scalars["Int"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Ctp_YearRangeFilterInput = {
  maxYear?: InputMaybe<Scalars["Int"]>;
  minYear?: InputMaybe<Scalars["Int"]>;
};

export type Ctp__Entity =
  | Ctp_MlbLeaguePlayer
  | Ctp_MlbTeamPlayer
  | Ctp_NbaLeaguePlayer
  | Ctp_NbaTeam
  | Ctp_NbaTeamPlayer
  | Ctp_NflLeaguePlayer
  | Ctp_NflTeam
  | Ctp_NflTeamPlayer;

export type Ctp__Service = {
  __typename?: "CTP__Service";
  sdl: Scalars["String"];
};

export type Column = {
  __typename?: "Column";
  description: Scalars["String"];
  label: Scalars["String"];
  value: Scalars["String"];
};

export type ColumnGroups = {
  __typename?: "ColumnGroups";
  columnHeaders: Array<Maybe<ColumnHeaders>>;
  label: Scalars["String"];
  positions: Array<Maybe<Scalars["String"]>>;
};

export type ColumnHeaders = {
  __typename?: "ColumnHeaders";
  columns: Array<Maybe<Column>>;
  label?: Maybe<Scalars["String"]>;
};

export type ContentAuthor = {
  __typename?: "ContentAuthor";
  authoredContent?: Maybe<Array<Maybe<ContentItem>>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  socialMedia?: Maybe<Array<Maybe<SocialMediaAccount>>>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type ContentAuthorAuthoredContentArgs = {
  contentType?: InputMaybe<Type>;
  sport?: InputMaybe<ContentSportsEnum>;
};

/** Data encapsulating a specific author and the id to help with pagination */
export type ContentAuthorConnection = {
  __typename?: "ContentAuthorConnection";
  cursor: Scalars["String"];
  entity: ContentAuthor;
};

/** List of authors including pagination data and total authors in the list */
export type ContentAuthorList = {
  __typename?: "ContentAuthorList";
  authors: Array<Maybe<ContentAuthorConnection>>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ContentFeed = {
  __typename?: "ContentFeed";
  contentItems: Array<Maybe<ContentItem>>;
  context: Scalars["String"];
  sport: Sport;
  totalCount: Scalars["Int"];
};

/** WIP */
export type ContentFeedInput = {
  afterCursor?: InputMaybe<Scalars["String"]>;
  author?: InputMaybe<AuthorInput>;
  context?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  relatedEntity?: InputMaybe<Scalars["String"]>;
  sport?: InputMaybe<Scalars["String"]>;
};

export type ContentFilters = {
  __typename?: "ContentFilters";
  shortFormNewsType?: Maybe<Array<Maybe<ShortFormNewsType>>>;
  sport?: Maybe<Array<Maybe<ContentSports>>>;
};

export type ContentItem = {
  author: ContentAuthor;
  id: Scalars["ID"];
  modifiedDate: Scalars["String"];
  publishedDate?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  tags?: Maybe<Array<Maybe<ContentTag>>>;
  title: Scalars["String"];
};

export type ContentSports = {
  __typename?: "ContentSports";
  enum?: Maybe<ContentSportsEnum>;
  name?: Maybe<Scalars["String"]>;
};

/**
 * The available sports to get content from.
 *
 * These correspond to the slugs returned in the "getSupportedSports" query (capitalized and replace - with _)
 */
export enum ContentSportsEnum {
  Boxing = "BOXING",
  Casino = "CASINO",
  F1 = "F1",
  FanduelSportsbookPromoCodes = "FANDUEL_SPORTSBOOK_PROMO_CODES",
  HorseRacing = "HORSE_RACING",
  Mlb = "MLB",
  Mma = "MMA",
  Nascar = "NASCAR",
  Nba = "NBA",
  Ncaab = "NCAAB",
  Ncaaf = "NCAAF",
  Nfl = "NFL",
  Nhl = "NHL",
  Pga = "PGA",
  Soccer = "SOCCER",
  Tennis = "TENNIS",
  Ufc = "UFC",
  Wnba = "WNBA",
  Womenssoccer = "WOMENSSOCCER",
}

export type ContentTag = {
  __typename?: "ContentTag";
  id: Scalars["String"];
  key: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  thumbnailImage?: Maybe<ImageReference>;
  type?: Maybe<Scalars["String"]>;
};

export type Faq = {
  __typename?: "Faq";
  icon?: Maybe<Scalars["String"]>;
  questions: Array<Maybe<Question>>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

/** Filter featured article searches */
export type FeaturedArticleInput = {
  /**
   * Case-sensitive
   *
   * Filter featured article searches by those on a specific page.
   */
  pageType?: InputMaybe<PageType>;
  /**
   * Case-sensitive
   *
   * Filter featured article searches by those about the given sport.
   */
  sportName?: InputMaybe<Scalars["String"]>;
  /**
   * Case-sensitive
   *
   * Filter featured article searches by those about the given sport (slug).
   *
   * If both 'sportName' and 'sportSlug' are provided, 'sportSlug' will take priority.
   */
  sportSlug?: InputMaybe<Scalars["String"]>;
};

export type FeaturedSport = ArticleGroup & {
  __typename?: "FeaturedSport";
  articles?: Maybe<Array<Maybe<Article>>>;
  icon?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type Fixture = {
  __typename?: "Fixture";
  currentGameTime?: Maybe<Scalars["String"]>;
  eventUrl?: Maybe<Scalars["String"]>;
  gameStatus?: Maybe<GameStatus>;
  /** Summarises a single fixture. */
  id: Scalars["ID"];
  scheduledEndTime?: Maybe<Scalars["String"]>;
  scheduledStartTime?: Maybe<Scalars["String"]>;
  sport?: Maybe<Sport>;
  teams: Array<Maybe<FixtureTeamSummary>>;
  /** Implement post MVP */
  tvNetwork?: Maybe<TvNetwork>;
};

export type FixtureMoneyLine = {
  __typename?: "FixtureMoneyLine";
  link?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["Int"]>;
};

/** Filter active fixture searches */
export type FixtureSearchInput = {
  /**
   * Case-insensitive
   *
   * Filter active fixture searches by a specific sport.
   */
  sport?: InputMaybe<Scalars["String"]>;
};

export type FixtureSpread = {
  __typename?: "FixtureSpread";
  link?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type FixtureTeamSummary = {
  __typename?: "FixtureTeamSummary";
  currentScore?: Maybe<Scalars["Int"]>;
  fixtureMoneyLine?: Maybe<FixtureMoneyLine>;
  fixtureSpread?: Maybe<FixtureSpread>;
  fixtureTotal?: Maybe<FixtureTotal>;
  /** @deprecated Use fixtureMoneyLine */
  moneyLine?: Maybe<Scalars["Int"]>;
  /** @deprecated Use fixtureSpread */
  pointSpread?: Maybe<Scalars["Float"]>;
  team: Team;
  winProbability?: Maybe<Scalars["Float"]>;
};

export type FixtureTotal = {
  __typename?: "FixtureTotal";
  link?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum GameStatus {
  Final = "FINAL",
  InProgress = "IN_PROGRESS",
  Scheduled = "SCHEDULED",
}

/** Container type describing an individual sport. */
export type GenericSport = Sport &
  SportsEntity & {
    __typename?: "GenericSport";
    contentSport?: Maybe<ContentSportsEnum>;
    description?: Maybe<Scalars["String"]>;
    governingBody?: Maybe<Scalars["String"]>;
    hasLiveScoring?: Maybe<Scalars["Boolean"]>;
    hasPlayerUpdates?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    logo?: Maybe<ImageReferenceNullable>;
    name: Scalars["String"];
    positions?: Maybe<Array<Maybe<PositionAbbrev>>>;
    relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
    seo: SeoInformation;
    showAuthors?: Maybe<Scalars["Boolean"]>;
    slug: Scalars["String"];
    teams?: Maybe<Array<Maybe<Team>>>;
  };

export type GolfEvent = {
  __typename?: "GolfEvent";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type GolfPlayer = {
  __typename?: "GolfPlayer";
  birdies?: Maybe<Scalars["Float"]>;
  bogeys?: Maybe<Scalars["Float"]>;
  doubleBogeys?: Maybe<Scalars["Float"]>;
  eagles?: Maybe<Scalars["Float"]>;
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Returned as %  */
  first?: Maybe<Scalars["String"]>;
  /**  Returned as %  */
  madeCut?: Maybe<Scalars["String"]>;
  pars?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  salary?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Float"]>;
  /**  Returned as %  */
  topFive?: Maybe<Scalars["String"]>;
  /**  Returned as %  */
  topTen?: Maybe<Scalars["String"]>;
  /**  Returned as %  */
  topTwentyFive?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Hr_AveragePace = {
  __typename?: "HR_AveragePace";
  early?: Maybe<Scalars["Float"]>;
  finish?: Maybe<Scalars["Float"]>;
  middle?: Maybe<Scalars["Float"]>;
  numRaces?: Maybe<Scalars["Int"]>;
};

export type Hr_BetCombo = {
  __typename?: "HR_BetCombo";
  payout?: Maybe<Scalars["String"]>;
  runner1?: Maybe<Scalars["String"]>;
  runner2?: Maybe<Scalars["String"]>;
};

export type Hr_BettingInterest = {
  __typename?: "HR_BettingInterest";
  biNumber?: Maybe<Scalars["Int"]>;
  biPools?: Maybe<Array<Maybe<Hr_BiPoolType>>>;
  currentOdds?: Maybe<Hr_Odd>;
  favorite?: Maybe<Scalars["Boolean"]>;
  morningLineOdds?: Maybe<Hr_Odd>;
  numberColor?: Maybe<Scalars["String"]>;
  pools?: Maybe<Array<Maybe<Hr_PoolType>>>;
  race?: Maybe<Hr_Race>;
  recentOdds?: Maybe<Array<Maybe<Hr_RecentOdd>>>;
  runners?: Maybe<Array<Maybe<Hr_Runner>>>;
  saddleColor?: Maybe<Scalars["String"]>;
};

export type Hr_BettingInterestRecentOddsArgs = {
  pages?: InputMaybe<Array<InputMaybe<Hr_RecentOddsPagination>>>;
};

export type Hr_BettingInterestRunnersArgs = {
  filters?: InputMaybe<Array<InputMaybe<Hr_RunnersFilter>>>;
};

export enum Hr_BettingInterestsField {
  NumberColor = "numberColor",
  Race = "race",
}

export type Hr_BettingInterestsFilter = {
  biNumber?: InputMaybe<Scalars["Int"]>;
  hasFreePick?: InputMaybe<Scalars["Boolean"]>;
  isWagerable?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_BettingInterestsPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_BettingInterestsField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_BettingInterestsSort = {
  byCurrentOdds?: InputMaybe<Hr_Sort>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_BiPoolData = {
  __typename?: "HR_BiPoolData";
  amount?: Maybe<Scalars["Float"]>;
  biOwner?: Maybe<Scalars["Int"]>;
  biTarget?: Maybe<Scalars["Int"]>;
  poolStatus?: Maybe<Scalars["String"]>;
};

export type Hr_BiPoolType = {
  __typename?: "HR_BiPoolType";
  poolRunnersData?: Maybe<Array<Maybe<Hr_BiPoolData>>>;
  wagerType?: Maybe<Hr_WagerGroupEnumeration>;
};

export type Hr_CarryOverListFilter = {
  order?: InputMaybe<Scalars["Int"]>;
  raceStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Hr_CarryOverPool = {
  __typename?: "HR_CarryOverPool";
  amount?: Maybe<Scalars["Float"]>;
  perfAbbr?: Maybe<Scalars["String"]>;
  poolType?: Maybe<Scalars["String"]>;
  race?: Maybe<Hr_Race>;
  raceNumber?: Maybe<Scalars["Int"]>;
  trackCode?: Maybe<Scalars["String"]>;
  trackName?: Maybe<Scalars["String"]>;
  wagerType?: Maybe<Hr_WagerGroupEnumeration>;
};

export enum Hr_CarryOverPoolField {
  TrackName = "trackName",
}

export type Hr_CarryOverPoolsPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_CarryOverPoolField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_Changes = {
  __typename?: "HR_Changes";
  /** @deprecated Use new changes */
  date?: Maybe<Scalars["String"]>;
  /** @deprecated Use new changes */
  description?: Maybe<Scalars["String"]>;
  horse?: Maybe<Hr_HorseChanges>;
  /** @deprecated Use new changes */
  newValue?: Maybe<Scalars["String"]>;
  /** @deprecated Use new changes */
  oldValue?: Maybe<Scalars["String"]>;
  surface?: Maybe<Hr_SurfaceChanges>;
};

export type Hr_ConditionChange = {
  __typename?: "HR_ConditionChange";
  date?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
};

export type Hr_CourseChange = {
  __typename?: "HR_CourseChange";
  date?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
};

export type Hr_DistanceChange = {
  __typename?: "HR_DistanceChange";
  date?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Hr_DistanceChangeValue>;
  oldValue?: Maybe<Hr_DistanceChangeValue>;
};

export type Hr_DistanceChangeValue = {
  __typename?: "HR_DistanceChangeValue";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Hr_FavoriteOrder = {
  __typename?: "HR_FavoriteOrder";
  currentOdd?: Maybe<Scalars["Int"]>;
  highSpeed?: Maybe<Scalars["Int"]>;
  morningLineOdd?: Maybe<Scalars["Int"]>;
  powerRating?: Maybe<Scalars["Int"]>;
};

export type Hr_Flags = {
  __typename?: "HR_Flags";
  clearTopRated?: Maybe<Scalars["Boolean"]>;
  coldTrainer?: Maybe<Scalars["Boolean"]>;
  firstTimeBlinkers?: Maybe<Scalars["Boolean"]>;
  highestLastSpeedRating?: Maybe<Scalars["Boolean"]>;
  horseInFocus?: Maybe<Scalars["Boolean"]>;
  horsesForCourseNeg?: Maybe<Scalars["Boolean"]>;
  horsesForCoursePos?: Maybe<Scalars["Boolean"]>;
  hotTrainer?: Maybe<Scalars["Boolean"]>;
  interestingJockeyBooking?: Maybe<Scalars["Boolean"]>;
  jockeyInForm?: Maybe<Scalars["Boolean"]>;
  jockeyUplift?: Maybe<Scalars["Boolean"]>;
  sectionalFlag?: Maybe<Scalars["Boolean"]>;
  significantImprover?: Maybe<Scalars["Boolean"]>;
  trainerUplift?: Maybe<Scalars["Boolean"]>;
  warningHorse?: Maybe<Scalars["Boolean"]>;
};

export type Hr_FreePick = {
  __typename?: "HR_FreePick";
  info?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
};

export type Hr_GenericValue = {
  __typename?: "HR_GenericValue";
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["HR_BigDecimal"]>;
};

export type Hr_Handicapping = {
  __typename?: "HR_Handicapping";
  averagePace?: Maybe<Hr_AveragePace>;
  freePick?: Maybe<Hr_FreePick>;
  jockeyTrainer?: Maybe<Hr_JockeyTrainer>;
  pastResults?: Maybe<Hr_PastResults>;
  snapshot?: Maybe<Hr_Snapshot>;
  speedAndClass?: Maybe<Hr_SpeedAndClass>;
};

export type Hr_HasHighlightedFilter = {
  brand: Scalars["String"];
  device: Scalars["String"];
  product: Scalars["String"];
};

export type Hr_HighlightedSort = {
  brand: Scalars["String"];
  device: Scalars["String"];
  order?: InputMaybe<Hr_Sort>;
  product: Scalars["String"];
};

export type Hr_HighlightedType = {
  __typename?: "HR_HighlightedType";
  action?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  device?: Maybe<Scalars["String"]>;
  pinnedOrder?: Maybe<Scalars["Int"]>;
  product?: Maybe<Scalars["String"]>;
  style?: Maybe<Scalars["String"]>;
};

export type Hr_HorseChanges = {
  __typename?: "HR_HorseChanges";
  /** @deprecated Use new changes */
  changes?: Maybe<Array<Maybe<Hr_Changes>>>;
  /** @deprecated Use new changes */
  horseName?: Maybe<Scalars["String"]>;
  jockey?: Maybe<Array<Maybe<Hr_JockeyChange>>>;
  /** @deprecated Use new changes */
  runnerId?: Maybe<Scalars["String"]>;
  scratched?: Maybe<Array<Maybe<Hr_ScratchedChange>>>;
};

export type Hr_JockeyChange = {
  __typename?: "HR_JockeyChange";
  date?: Maybe<Scalars["String"]>;
  horseName?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["String"]>;
};

export type Hr_JockeyTrainer = {
  __typename?: "HR_JockeyTrainer";
  entityJockeyId?: Maybe<Scalars["HR_Long"]>;
  entityTrainerId?: Maybe<Scalars["HR_Long"]>;
  jockeyName?: Maybe<Scalars["String"]>;
  places?: Maybe<Scalars["Int"]>;
  shows?: Maybe<Scalars["Int"]>;
  starts?: Maybe<Scalars["Int"]>;
  trainerName?: Maybe<Scalars["String"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Hr_LastEvent = {
  __typename?: "HR_LastEvent";
  date?: Maybe<Scalars["String"]>;
  race?: Maybe<Scalars["Int"]>;
  track?: Maybe<Scalars["String"]>;
};

export type Hr_LateChanges = {
  __typename?: "HR_LateChanges";
  horseChanges?: Maybe<Array<Maybe<Hr_HorseChanges>>>;
  raceChanges?: Maybe<Array<Maybe<Hr_Changes>>>;
  wagerChanges?: Maybe<Array<Maybe<Hr_Changes>>>;
};

export type Hr_MtpIntervalFilter = {
  afterCurrentTime?: InputMaybe<Scalars["Int"]>;
  beforeCurrentTime?: InputMaybe<Scalars["Int"]>;
};

export type Hr_MatchRacesFilter = {
  number?: InputMaybe<Scalars["String"]>;
  trackCode?: InputMaybe<Scalars["String"]>;
  trackName?: InputMaybe<Scalars["String"]>;
};

export type Hr_NewRaceDayUpdate = {
  __typename?: "HR_NewRaceDayUpdate";
  data?: Maybe<Array<Maybe<Hr_Race>>>;
  key?: Maybe<Scalars["String"]>;
};

export type Hr_Odd = {
  __typename?: "HR_Odd";
  denominator?: Maybe<Scalars["Int"]>;
  numerator?: Maybe<Scalars["Int"]>;
};

export type Hr_Pagination = {
  current?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_PastAveragePace = {
  __typename?: "HR_PastAveragePace";
  early?: Maybe<Scalars["Float"]>;
  finish?: Maybe<Scalars["Float"]>;
  middle?: Maybe<Scalars["Float"]>;
  numRaces?: Maybe<Scalars["Int"]>;
};

export type Hr_PastBettingInterest = {
  __typename?: "HR_PastBettingInterest";
  biNumber?: Maybe<Scalars["Int"]>;
  currentOdds?: Maybe<Hr_PastOdd>;
  favorite?: Maybe<Scalars["Boolean"]>;
  morningLineOdds?: Maybe<Hr_PastOdd>;
  numberColor?: Maybe<Scalars["String"]>;
  race?: Maybe<Hr_PastRace>;
  runners?: Maybe<Array<Maybe<Hr_PastRunner>>>;
  saddleColor?: Maybe<Scalars["String"]>;
};

export type Hr_PastFreePick = {
  __typename?: "HR_PastFreePick";
  info?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
};

export type Hr_PastHandicapping = {
  __typename?: "HR_PastHandicapping";
  averagePace?: Maybe<Hr_PastAveragePace>;
  freePick?: Maybe<Hr_PastFreePick>;
  jockeyTrainer?: Maybe<Hr_PastJockeyTrainer>;
  snapshot?: Maybe<Hr_PastSnapshot>;
  speedAndClass?: Maybe<Hr_PastSpeedAndClass>;
};

export type Hr_PastJockeyTrainer = {
  __typename?: "HR_PastJockeyTrainer";
  jockeyName?: Maybe<Scalars["String"]>;
  places?: Maybe<Scalars["Int"]>;
  shows?: Maybe<Scalars["Int"]>;
  starts?: Maybe<Scalars["Int"]>;
  trainerName?: Maybe<Scalars["String"]>;
  wins?: Maybe<Scalars["Int"]>;
};

export type Hr_PastOdd = {
  __typename?: "HR_PastOdd";
  denominator?: Maybe<Scalars["Int"]>;
  numerator?: Maybe<Scalars["Int"]>;
};

export type Hr_PastPayoffSelection = {
  __typename?: "HR_PastPayoffSelection";
  payoutAmount?: Maybe<Scalars["Float"]>;
  selection?: Maybe<Scalars["String"]>;
};

export type Hr_PastRace = {
  __typename?: "HR_PastRace";
  bettingInterests?: Maybe<Array<Maybe<Hr_PastBettingInterest>>>;
  claimingPrice?: Maybe<Scalars["Float"]>;
  date?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  distance?: Maybe<Hr_GenericValue>;
  id?: Maybe<Scalars["String"]>;
  isGreyhound?: Maybe<Scalars["Boolean"]>;
  numRunners?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  postTime?: Maybe<Scalars["String"]>;
  purse?: Maybe<Scalars["Float"]>;
  raceClass?: Maybe<Hr_ShortNameEnumeration>;
  results?: Maybe<Hr_PastResultsType>;
  surface?: Maybe<Hr_SurfaceEnumeration>;
  track?: Maybe<Hr_PastTrack>;
  type?: Maybe<Hr_ShortNameEnumeration>;
  video?: Maybe<Hr_PastVideo>;
};

export type Hr_PastRaceListFilter = {
  isGreyhound?: InputMaybe<Scalars["Boolean"]>;
};

export type Hr_PastRaceListSort = {
  byPostTime?: InputMaybe<Hr_Sort>;
};

export type Hr_PastResultPayoff = {
  __typename?: "HR_PastResultPayoff";
  selections?: Maybe<Array<Maybe<Hr_PastPayoffSelection>>>;
  wagerAmount?: Maybe<Scalars["Float"]>;
  wagerType?: Maybe<Hr_PastWagerGroupEnumeration>;
};

export type Hr_PastResultRunner = {
  __typename?: "HR_PastResultRunner";
  betAmount?: Maybe<Scalars["Float"]>;
  biNumber?: Maybe<Scalars["Int"]>;
  currentOdds?: Maybe<Hr_PastOdd>;
  favorite?: Maybe<Scalars["Boolean"]>;
  finishPosition?: Maybe<Scalars["Int"]>;
  placePayoff?: Maybe<Scalars["Float"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerNumber?: Maybe<Scalars["String"]>;
  scratched?: Maybe<Scalars["Boolean"]>;
  showPayoff?: Maybe<Scalars["Float"]>;
  timeform?: Maybe<Hr_Timeform>;
  winPayoff?: Maybe<Scalars["Float"]>;
};

export type Hr_PastResults = {
  __typename?: "HR_PastResults";
  numberOfFirstPlace?: Maybe<Scalars["Int"]>;
  numberOfSecondPlace?: Maybe<Scalars["Int"]>;
  numberOfThirdPlace?: Maybe<Scalars["Int"]>;
  top3Percentage?: Maybe<Scalars["Float"]>;
  top3PercentageRanking?: Maybe<Scalars["String"]>;
  totalNumberOfStarts?: Maybe<Scalars["Int"]>;
  winPercentage?: Maybe<Scalars["Float"]>;
  winPercentageRanking?: Maybe<Scalars["String"]>;
};

export type Hr_PastResultsType = {
  __typename?: "HR_PastResultsType";
  payoffs?: Maybe<Array<Maybe<Hr_PastResultPayoff>>>;
  runners?: Maybe<Array<Maybe<Hr_PastResultRunner>>>;
  winningTime?: Maybe<Scalars["Float"]>;
};

export type Hr_PastRunner = {
  __typename?: "HR_PastRunner";
  age?: Maybe<Scalars["Int"]>;
  dam?: Maybe<Scalars["String"]>;
  damSire?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["Int"]>;
  entityRunnerId?: Maybe<Scalars["String"]>;
  handicapping?: Maybe<Hr_PastHandicapping>;
  horseName?: Maybe<Scalars["String"]>;
  jockey?: Maybe<Scalars["String"]>;
  med?: Maybe<Scalars["String"]>;
  ownerName?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["String"]>;
  scratched?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<Scalars["String"]>;
  sire?: Maybe<Scalars["String"]>;
  trainer?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
};

export type Hr_PastSnapshot = {
  __typename?: "HR_PastSnapshot";
  daysOff?: Maybe<Scalars["Int"]>;
  horseStarts?: Maybe<Scalars["Int"]>;
  horseWins?: Maybe<Scalars["Int"]>;
  powerRating?: Maybe<Scalars["Float"]>;
};

export type Hr_PastSpeedAndClass = {
  __typename?: "HR_PastSpeedAndClass";
  avgClassRating?: Maybe<Scalars["Float"]>;
  avgDistance?: Maybe<Scalars["Float"]>;
  avgSpeed?: Maybe<Scalars["Float"]>;
  highSpeed?: Maybe<Scalars["Float"]>;
  lastClassRating?: Maybe<Scalars["Float"]>;
};

export type Hr_PastTrack = {
  __typename?: "HR_PastTrack";
  code?: Maybe<Scalars["String"]>;
  dates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  featured?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  imageLink?: Maybe<Scalars["String"]>;
  imageName?: Maybe<Scalars["String"]>;
  isGreyhound?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Hr_PastTrackLocation>;
  name?: Maybe<Scalars["String"]>;
  numberOfRaces?: Maybe<Scalars["Int"]>;
  raceTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  races?: Maybe<Array<Maybe<Hr_PastRace>>>;
  shortName?: Maybe<Scalars["String"]>;
};

export type Hr_PastTrackRacesArgs = {
  filter?: InputMaybe<Hr_PastRaceListFilter>;
};

export type Hr_PastTrackListFilter = {
  isGreyhound?: InputMaybe<Scalars["Boolean"]>;
};

export type Hr_PastTrackLocation = {
  __typename?: "HR_PastTrackLocation";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type Hr_PastVideo = {
  __typename?: "HR_PastVideo";
  flashAvailable?: Maybe<Scalars["Boolean"]>;
  mobileAvailable?: Maybe<Scalars["Boolean"]>;
  replayFileName?: Maybe<Scalars["String"]>;
};

export type Hr_PastWagerGroupEnumeration = {
  __typename?: "HR_PastWagerGroupEnumeration";
  code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Hr_PayoffSelection = {
  __typename?: "HR_PayoffSelection";
  payoutAmount?: Maybe<Scalars["Float"]>;
  qualifier?: Maybe<Scalars["String"]>;
  selection?: Maybe<Scalars["String"]>;
};

export type Hr_PoolType = {
  __typename?: "HR_PoolType";
  amount?: Maybe<Scalars["Float"]>;
  wagerType?: Maybe<Hr_WagerGroupEnumeration>;
};

export type Hr_Profile = {
  __typename?: "HR_Profile";
  profileName?: Maybe<Scalars["String"]>;
};

export type Hr_Promo = {
  __typename?: "HR_Promo";
  costCategoryPriority?: Maybe<Scalars["Int"]>;
  isAboveTheLine?: Maybe<Scalars["Boolean"]>;
  isPromoTagShown?: Maybe<Scalars["Boolean"]>;
  promoPath?: Maybe<Scalars["String"]>;
  promoTypePriority?: Maybe<Scalars["Int"]>;
  rootParentPromoID?: Maybe<Scalars["Int"]>;
};

export type Hr_PromoFilter = {
  brand: Scalars["String"];
  product: Scalars["String"];
  promoId: Scalars["Int"];
};

export type Hr_Race = {
  __typename?: "HR_Race";
  ageRestriction?: Maybe<Scalars["String"]>;
  bettingInterests?: Maybe<Array<Maybe<Hr_BettingInterest>>>;
  changes?: Maybe<Hr_Changes>;
  claimingPrice?: Maybe<Scalars["Float"]>;
  class?: Maybe<Hr_ShortNameEnumeration>;
  description?: Maybe<Scalars["String"]>;
  distance?: Maybe<Scalars["String"]>;
  highlighted?: Maybe<Hr_HighlightedType>;
  id?: Maybe<Scalars["String"]>;
  isGreyhound?: Maybe<Scalars["Boolean"]>;
  lastUpdateTimestamp?: Maybe<Scalars["HR_Long"]>;
  lateChanges?: Maybe<Hr_LateChanges>;
  location?: Maybe<Hr_TrackLocation>;
  mtp?: Maybe<Scalars["HR_Long"]>;
  numRunners?: Maybe<Scalars["String"]>;
  numWagerableRunners?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  pools?: Maybe<Array<Maybe<Hr_PoolType>>>;
  postTime?: Maybe<Scalars["String"]>;
  probables?: Maybe<Array<Maybe<Hr_RaceProbableType>>>;
  promos?: Maybe<Array<Maybe<Hr_Promo>>>;
  purse?: Maybe<Scalars["Float"]>;
  raceClass?: Maybe<Hr_ShortNameEnumeration>;
  raceDate?: Maybe<Scalars["String"]>;
  raceDistance?: Maybe<Hr_GenericValue>;
  racePools?: Maybe<Array<Maybe<Hr_PoolType>>>;
  raceProfiles?: Maybe<Array<Maybe<Hr_Profile>>>;
  results?: Maybe<Hr_ResultsType>;
  status?: Maybe<Hr_StatusEnumeration>;
  surface?: Maybe<Hr_SurfaceEnumeration>;
  talentPicks?: Maybe<Array<Maybe<Hr_TalentPickType>>>;
  timeform?: Maybe<Hr_RaceTimeform>;
  track?: Maybe<Hr_Track>;
  trackCode?: Maybe<Scalars["String"]>;
  trackName?: Maybe<Scalars["String"]>;
  tvgRaceId?: Maybe<Scalars["HR_Long"]>;
  type?: Maybe<Hr_ShortNameEnumeration>;
  video?: Maybe<Hr_Video>;
  wagerTypes?: Maybe<Array<Maybe<Hr_WagerType>>>;
  willPays?: Maybe<Array<Maybe<Hr_WillPayType>>>;
};

export type Hr_RaceBettingInterestsArgs = {
  filter?: InputMaybe<Hr_BettingInterestsFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_BettingInterestsFilter>>>;
  page?: InputMaybe<Hr_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Hr_BettingInterestsPagination>>>;
  sort?: InputMaybe<Hr_BettingInterestsSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_BettingInterestsSort>>>;
};

export type Hr_RaceHighlightedArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  device?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Scalars["String"]>;
};

export type Hr_RacePromosArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Scalars["String"]>;
};

export type Hr_RaceWagerTypesArgs = {
  filter?: InputMaybe<Hr_WagerTypesFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_WagerTypesFilter>>>;
  pages?: InputMaybe<Array<InputMaybe<Hr_WagerTypesPagination>>>;
  sort?: InputMaybe<Hr_WagerTypesSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_WagerTypesSort>>>;
};

export type Hr_RaceEntities = {
  __typename?: "HR_RaceEntities";
  jockeys?: Maybe<Array<Maybe<Hr_RaceEntity>>>;
  owners?: Maybe<Array<Maybe<Hr_RaceEntity>>>;
  runners?: Maybe<Array<Maybe<Hr_RaceEntity>>>;
  trainers?: Maybe<Array<Maybe<Hr_RaceEntity>>>;
};

/** ## Types */
export type Hr_RaceEntity = {
  __typename?: "HR_RaceEntity";
  dob?: Maybe<Scalars["Int"]>;
  entityRunnerId?: Maybe<Scalars["Int"]>;
  lastEvent?: Maybe<Hr_LastEvent>;
  name?: Maybe<Scalars["String"]>;
};

export type Hr_RaceEntityListSort = {
  byLastEvent?: InputMaybe<Hr_Sort>;
  byName?: InputMaybe<Hr_Sort>;
};

export enum Hr_RaceField {
  TrackCode = "trackCode",
}

export type Hr_RaceListFilter = {
  allRaceClasses?: InputMaybe<Scalars["Boolean"]>;
  excludeCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  hasFreePick?: InputMaybe<Scalars["Boolean"]>;
  hasMTP?: InputMaybe<Scalars["Boolean"]>;
  hasPromo?: InputMaybe<Hr_PromoFilter>;
  hasSpecificHighlighted?: InputMaybe<Hr_HasHighlightedFilter>;
  inFeaturedTrack?: InputMaybe<Scalars["Boolean"]>;
  includeCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isGreyhound?: InputMaybe<Scalars["Boolean"]>;
  isHighlighted?: InputMaybe<Scalars["Boolean"]>;
  isOTTExcluded?: InputMaybe<Scalars["Boolean"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  isTopRace?: InputMaybe<Hr_HasHighlightedFilter>;
  matchRaces?: InputMaybe<Array<InputMaybe<Hr_MatchRacesFilter>>>;
  onTvg?: InputMaybe<Scalars["Boolean"]>;
  onTvg2?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
  rangeInMinutes?: InputMaybe<Hr_MtpIntervalFilter>;
  startIn?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  trackCode?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  trackName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  typeCode?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Hr_RaceListPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_RaceField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_RaceListSort = {
  byMTP?: InputMaybe<Hr_Sort>;
  byPinnedOrder?: InputMaybe<Hr_HighlightedSort>;
  byPostTime?: InputMaybe<Hr_Sort>;
  byRaceNumber?: InputMaybe<Hr_Sort>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_RaceProbableType = {
  __typename?: "HR_RaceProbableType";
  amount?: Maybe<Scalars["Float"]>;
  betCombos?: Maybe<Array<Maybe<Hr_BetCombo>>>;
  minWagerAmount?: Maybe<Scalars["Float"]>;
  poolAmountTotal?: Maybe<Scalars["Float"]>;
  wagerType?: Maybe<Hr_WagerGroupEnumeration>;
  wagerTypeId?: Maybe<Scalars["Int"]>;
  wagerTypeName?: Maybe<Scalars["String"]>;
};

export type Hr_RaceTimeform = {
  __typename?: "HR_RaceTimeform";
  analystVerdict?: Maybe<Scalars["String"]>;
  smartStat1?: Maybe<Scalars["String"]>;
  smartStat2?: Maybe<Scalars["String"]>;
  smartStat3?: Maybe<Scalars["String"]>;
};

export type Hr_RecentOdd = {
  __typename?: "HR_RecentOdd";
  odd?: Maybe<Scalars["String"]>;
  trending?: Maybe<Scalars["Boolean"]>;
};

export type Hr_RecentOddsPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_ResultPayoff = {
  __typename?: "HR_ResultPayoff";
  selections?: Maybe<Array<Maybe<Hr_PayoffSelection>>>;
  wagerAmount?: Maybe<Scalars["Float"]>;
  wagerType?: Maybe<Hr_WagerGroupEnumeration>;
};

export type Hr_ResultRunner = {
  __typename?: "HR_ResultRunner";
  betAmount?: Maybe<Scalars["Float"]>;
  biNumber?: Maybe<Scalars["Int"]>;
  finishPosition?: Maybe<Scalars["Int"]>;
  placePayoff?: Maybe<Scalars["Float"]>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerNumber?: Maybe<Scalars["String"]>;
  showPayoff?: Maybe<Scalars["Float"]>;
  silkUrl?: Maybe<Scalars["String"]>;
  silkUrlSvg?: Maybe<Scalars["String"]>;
  timeform?: Maybe<Hr_RunnerResultTimeform>;
  winPayoff?: Maybe<Scalars["Float"]>;
};

export type Hr_ResultsType = {
  __typename?: "HR_ResultsType";
  payoffs?: Maybe<Array<Maybe<Hr_ResultPayoff>>>;
  runners?: Maybe<Array<Maybe<Hr_ResultRunner>>>;
  winningTime?: Maybe<Scalars["Float"]>;
};

export type Hr_Runner = {
  __typename?: "HR_Runner";
  age?: Maybe<Scalars["Int"]>;
  bettingInterest?: Maybe<Array<Maybe<Hr_BettingInterest>>>;
  dam?: Maybe<Scalars["String"]>;
  damSire?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  dob?: Maybe<Scalars["Int"]>;
  entityRunnerId?: Maybe<Scalars["String"]>;
  favoriteOrder?: Maybe<Hr_FavoriteOrder>;
  handicapping?: Maybe<Hr_Handicapping>;
  hasJockeyChanges?: Maybe<Scalars["Boolean"]>;
  horseName?: Maybe<Scalars["String"]>;
  jockey?: Maybe<Scalars["String"]>;
  med?: Maybe<Scalars["String"]>;
  nextRace?: Maybe<Hr_Race>;
  ownerName?: Maybe<Scalars["String"]>;
  raceNumber?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["String"]>;
  scratched?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<Scalars["String"]>;
  sire?: Maybe<Scalars["String"]>;
  timeform?: Maybe<Hr_RunnerTimeform>;
  trackCode?: Maybe<Scalars["String"]>;
  trainer?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
  winProbability?: Maybe<Scalars["Float"]>;
};

export type Hr_RunnerResultTimeform = {
  __typename?: "HR_RunnerResultTimeform";
  accBeatenDistance?: Maybe<Scalars["String"]>;
  accBeatenDistanceStatus?: Maybe<Scalars["String"]>;
  accBeatenDistanceStatusAbrev?: Maybe<Scalars["String"]>;
  beatenDistance?: Maybe<Scalars["String"]>;
  beatenDistanceStatus?: Maybe<Scalars["String"]>;
  isp?: Maybe<Scalars["String"]>;
  postRaceReport?: Maybe<Scalars["String"]>;
};

export type Hr_RunnerStats = {
  __typename?: "HR_RunnerStats";
  entityRunnerId?: Maybe<Scalars["String"]>;
  numberRaces?: Maybe<Scalars["Int"]>;
  numberRacesTop3?: Maybe<Scalars["Int"]>;
  numberRacesWon?: Maybe<Scalars["Int"]>;
};

export type Hr_RunnerTimeform = {
  __typename?: "HR_RunnerTimeform";
  analystsComments?: Maybe<Scalars["String"]>;
  flags?: Maybe<Hr_Flags>;
  formFigures?: Maybe<Scalars["String"]>;
  freePick?: Maybe<Hr_TimeformFreePick>;
  productionComment?: Maybe<Scalars["String"]>;
  ratingStars?: Maybe<Scalars["Int"]>;
  silkUrl?: Maybe<Scalars["String"]>;
  silkUrlSvg?: Maybe<Scalars["String"]>;
  tissuePriceDecimal?: Maybe<Scalars["Float"]>;
};

export type Hr_RunnersFilter = {
  hasFreePick?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_ScratchedChange = {
  __typename?: "HR_ScratchedChange";
  date?: Maybe<Scalars["String"]>;
  horseName?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  runnerId?: Maybe<Scalars["String"]>;
  scratched?: Maybe<Scalars["Boolean"]>;
};

export type Hr_ShortNameEnumeration = {
  __typename?: "HR_ShortNameEnumeration";
  code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
};

export type Hr_Snapshot = {
  __typename?: "HR_Snapshot";
  daysOff?: Maybe<Scalars["Int"]>;
  horseStarts?: Maybe<Scalars["Int"]>;
  horseWins?: Maybe<Scalars["Int"]>;
  normalisedPowerRating?: Maybe<Scalars["Float"]>;
  powerRating?: Maybe<Scalars["Float"]>;
  powerRatingRanking?: Maybe<Scalars["String"]>;
};

export enum Hr_Sort {
  Asc = "ASC",
  Desc = "DESC",
}

export type Hr_SpeedAndClass = {
  __typename?: "HR_SpeedAndClass";
  avgClassRating?: Maybe<Scalars["Float"]>;
  avgDistance?: Maybe<Scalars["Float"]>;
  avgSpeed?: Maybe<Scalars["Float"]>;
  highSpeed?: Maybe<Scalars["Float"]>;
  highSpeedRanking?: Maybe<Scalars["String"]>;
  lastClassRating?: Maybe<Scalars["Float"]>;
  normalisedSpeedRating?: Maybe<Scalars["Float"]>;
};

export type Hr_StatusEnumeration = {
  __typename?: "HR_StatusEnumeration";
  code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Hr_SurfaceChanges = {
  __typename?: "HR_SurfaceChanges";
  condition?: Maybe<Array<Maybe<Hr_ConditionChange>>>;
  course?: Maybe<Array<Maybe<Hr_CourseChange>>>;
  distance?: Maybe<Array<Maybe<Hr_DistanceChange>>>;
  tempRailDistance?: Maybe<Array<Maybe<Hr_TempRailDistanceChange>>>;
};

export type Hr_SurfaceEnumeration = {
  __typename?: "HR_SurfaceEnumeration";
  code?: Maybe<Scalars["String"]>;
  defaultCondition?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
};

export enum Hr_TalentPickField {
  Race = "race",
  WagerType = "wagerType",
}

export type Hr_TalentPickSelection = {
  __typename?: "HR_TalentPickSelection";
  order?: Maybe<Scalars["Int"]>;
};

export type Hr_TalentPickType = {
  __typename?: "HR_TalentPickType";
  amount?: Maybe<Scalars["String"]>;
  betCount?: Maybe<Scalars["Int"]>;
  favorites?: Maybe<Array<Maybe<Array<Maybe<Hr_TalentPickSelection>>>>>;
  id?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  race?: Maybe<Hr_Race>;
  selections?: Maybe<Array<Maybe<Array<Maybe<Hr_TalentPickSelection>>>>>;
  talent?: Maybe<Hr_TalentType>;
  wagerType?: Maybe<Hr_WagerType>;
};

export type Hr_TalentPicksFilterType = {
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TalentPicksPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_TalentPickField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TalentPicksSortType = {
  byPostTime?: InputMaybe<Hr_Sort>;
  byTalentPopularity?: InputMaybe<Hr_Sort>;
  byTrackName?: InputMaybe<Hr_Sort>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TalentType = {
  __typename?: "HR_TalentType";
  active?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  phrases?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pickCount?: Maybe<Scalars["Int"]>;
  pictureUrl?: Maybe<Scalars["String"]>;
  popularity?: Maybe<Scalars["Int"]>;
  talentId?: Maybe<Scalars["String"]>;
};

export type Hr_TempRailDistanceChange = {
  __typename?: "HR_TempRailDistanceChange";
  date?: Maybe<Scalars["String"]>;
  newValue?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
};

export type Hr_Timeform = {
  __typename?: "HR_Timeform";
  accBeatenDistance?: Maybe<Scalars["String"]>;
  accBeatenDistanceStatus?: Maybe<Scalars["String"]>;
  accBeatenDistanceStatusAbrev?: Maybe<Scalars["String"]>;
  beatenDistance?: Maybe<Scalars["String"]>;
  beatenDistanceStatus?: Maybe<Scalars["String"]>;
  isp?: Maybe<Scalars["String"]>;
  postRaceReport?: Maybe<Scalars["String"]>;
  winningDistance?: Maybe<Scalars["String"]>;
  winningDistanceStatus?: Maybe<Scalars["String"]>;
};

export type Hr_TimeformFreePick = {
  __typename?: "HR_TimeformFreePick";
  info?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["Int"]>;
};

export type Hr_Track = {
  __typename?: "HR_Track";
  code?: Maybe<Scalars["String"]>;
  currentRace?: Maybe<Hr_Race>;
  featured?: Maybe<Scalars["Boolean"]>;
  hasAboveTheLinePromo?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isFinished?: Maybe<Scalars["Boolean"]>;
  isGreyhound?: Maybe<Scalars["Boolean"]>;
  isOTTExcluded?: Maybe<Scalars["Boolean"]>;
  isPromoTagShown?: Maybe<Scalars["Boolean"]>;
  itspCode?: Maybe<Scalars["String"]>;
  ivrCode?: Maybe<Scalars["Int"]>;
  location?: Maybe<Hr_TrackLocation>;
  name?: Maybe<Scalars["String"]>;
  numberOfRaces?: Maybe<Scalars["Int"]>;
  perfAbbr?: Maybe<Scalars["String"]>;
  raceTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  races?: Maybe<Array<Maybe<Hr_Race>>>;
  shortName?: Maybe<Scalars["String"]>;
  trackDataSource?: Maybe<Scalars["String"]>;
};

export type Hr_TrackCurrentRaceArgs = {
  wagerable?: InputMaybe<Scalars["Boolean"]>;
};

export type Hr_TrackHasAboveTheLinePromoArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Scalars["String"]>;
};

export type Hr_TrackRacesArgs = {
  filter?: InputMaybe<Hr_RaceListFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_RaceListFilter>>>;
  page?: InputMaybe<Hr_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Hr_RaceListPagination>>>;
  pagesByField?: InputMaybe<Hr_TrackRacesPagination>;
  sort?: InputMaybe<Hr_RaceListSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_RaceListSort>>>;
};

export enum Hr_TrackField {
  Country = "country",
}

export type Hr_TrackListFilter = {
  allTrackClasses?: InputMaybe<Scalars["Boolean"]>;
  code?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  currentRaceStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  excludeCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  featured?: InputMaybe<Scalars["Boolean"]>;
  includeCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isFinished?: InputMaybe<Scalars["Boolean"]>;
  isGreyhound?: InputMaybe<Scalars["Boolean"]>;
  isOTTExcluded?: InputMaybe<Scalars["Boolean"]>;
  order?: InputMaybe<Scalars["Int"]>;
  raceStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  raceTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  trackName?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Hr_TrackListPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_TrackField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TrackListSort = {
  byCurrentRaceMtp?: InputMaybe<Hr_Sort>;
  byIvrCode?: InputMaybe<Hr_Sort>;
  byName?: InputMaybe<Hr_Sort>;
  byWagerableCurrentRaceMtp?: InputMaybe<Hr_Sort>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TrackLocation = {
  __typename?: "HR_TrackLocation";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type Hr_TrackRacesPagination = {
  order?: InputMaybe<Scalars["Int"]>;
  raceNumber?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_TracksMetadata = {
  __typename?: "HR_TracksMetadata";
  totalRaces?: Maybe<Scalars["Int"]>;
};

export type Hr_TracksMetadataTotalRacesArgs = {
  profile?: InputMaybe<Scalars["String"]>;
};

export type Hr_TracksWithMetadata = {
  __typename?: "HR_TracksWithMetadata";
  tracks?: Maybe<Array<Maybe<Hr_Track>>>;
  tracksMetadata?: Maybe<Hr_TracksMetadata>;
};

export type Hr_TracksWithMetadataTracksArgs = {
  filter?: InputMaybe<Hr_TrackListFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_TrackListFilter>>>;
  page?: InputMaybe<Hr_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Hr_TrackListPagination>>>;
  profile?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Hr_TrackListSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_TrackListSort>>>;
};

export type Hr_Video = {
  __typename?: "HR_Video";
  flashAvailable?: Maybe<Scalars["Boolean"]>;
  hasReplay?: Maybe<Scalars["Boolean"]>;
  isStreamHighDefinition?: Maybe<Scalars["Boolean"]>;
  liveStreaming?: Maybe<Scalars["Boolean"]>;
  mobileAvailable?: Maybe<Scalars["Boolean"]>;
  onTvg?: Maybe<Scalars["Boolean"]>;
  onTvg2?: Maybe<Scalars["Boolean"]>;
  replayFileName?: Maybe<Scalars["String"]>;
  replays?: Maybe<Array<Maybe<Scalars["String"]>>>;
  streams?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Hr_WpLegResult = {
  __typename?: "HR_WPLegResult";
  legNumber?: Maybe<Scalars["Int"]>;
  winningBi?: Maybe<Scalars["Int"]>;
};

export type Hr_WpPayout = {
  __typename?: "HR_WPPayout";
  bettingInterestNumber?: Maybe<Scalars["Int"]>;
  payoutAmount?: Maybe<Scalars["Float"]>;
};

export type Hr_WagerGroupEnumeration = {
  __typename?: "HR_WagerGroupEnumeration";
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  descriptionInternational?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Hr_WagerType = {
  __typename?: "HR_WagerType";
  amtoteWagerTypeCode?: Maybe<Scalars["String"]>;
  columnCount?: Maybe<Scalars["Int"]>;
  columnType?: Maybe<Scalars["String"]>;
  group?: Maybe<Hr_WagerGroupEnumeration>;
  hasPool?: Maybe<Scalars["Boolean"]>;
  isBox?: Maybe<Scalars["Boolean"]>;
  isKey?: Maybe<Scalars["Boolean"]>;
  isWheel?: Maybe<Scalars["Boolean"]>;
  ivrCode?: Maybe<Scalars["Int"]>;
  ivrWagerTypeCode?: Maybe<Scalars["Int"]>;
  legCount?: Maybe<Scalars["Int"]>;
  maxBIsPerLeg?: Maybe<Scalars["Int"]>;
  maxWagerAmount?: Maybe<Scalars["Float"]>;
  minBIsPerLeg?: Maybe<Scalars["Int"]>;
  minTicketAmount?: Maybe<Scalars["Float"]>;
  minWagerAmount?: Maybe<Scalars["Float"]>;
  poolAmount?: Maybe<Scalars["Float"]>;
  poolsPerBI?: Maybe<Scalars["Int"]>;
  positionCount?: Maybe<Scalars["Int"]>;
  sportechWagerTypeCode?: Maybe<Scalars["String"]>;
  type?: Maybe<Hr_WagerTypeEnumeration>;
  wagerAmounts?: Maybe<Array<Maybe<Scalars["Float"]>>>;
};

export type Hr_WagerTypeEnumeration = {
  __typename?: "HR_WagerTypeEnumeration";
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  descriptionInternational?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum Hr_WagerTypeField {
  CarryOverTitle = "carryOverTitle",
  WagerType = "wagerType",
}

export type Hr_WagerTypesFilter = {
  code?: InputMaybe<Scalars["String"]>;
  hasIvrCode?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  ivrCode?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_WagerTypesPagination = {
  current?: InputMaybe<Scalars["Int"]>;
  field?: InputMaybe<Hr_WagerTypeField>;
  maxPerField?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Scalars["Int"]>;
  results?: InputMaybe<Scalars["Int"]>;
};

export type Hr_WagerTypesSort = {
  byGroupSortOrder?: InputMaybe<Hr_Sort>;
  byId?: InputMaybe<Hr_Sort>;
  byIvrCode?: InputMaybe<Hr_Sort>;
  byName?: InputMaybe<Hr_Sort>;
  order?: InputMaybe<Scalars["Int"]>;
};

export type Hr_WillPayType = {
  __typename?: "HR_WillPayType";
  legResults?: Maybe<Array<Maybe<Hr_WpLegResult>>>;
  payOffType?: Maybe<Scalars["String"]>;
  payouts?: Maybe<Array<Maybe<Hr_WpPayout>>>;
  type?: Maybe<Hr_WagerGroupEnumeration>;
  wagerAmount?: Maybe<Scalars["Float"]>;
};

/** The media type found on an article */
export enum HeroType {
  Image = "IMAGE",
  Jwplayer = "JWPLAYER",
}

export type HomepageContent = {
  __typename?: "HomepageContent";
  articles: Array<Maybe<Article>>;
  selectedSports: Array<Maybe<SelectedSport>>;
  template: Template;
  tools?: Maybe<Array<Maybe<Tool>>>;
};

export type ImageBanner = {
  __typename?: "ImageBanner";
  link?: Maybe<Scalars["String"]>;
  mainImage: Scalars["String"];
  mobileImage?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type ImageReference = {
  __typename?: "ImageReference";
  url: Scalars["String"];
};

export type ImageReferenceNullable = {
  __typename?: "ImageReferenceNullable";
  url?: Maybe<Scalars["String"]>;
};

export type ImageShowcase = {
  __typename?: "ImageShowcase";
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  mainImage: Scalars["String"];
  mobileImage?: Maybe<Scalars["String"]>;
  tabletImage?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export enum LinkBehaviour {
  NewWindow = "NEW_WINDOW",
  Standard = "STANDARD",
}

export type MarketOdds = {
  __typename?: "MarketOdds";
  event?: Maybe<MarketOddsEvent>;
  props?: Maybe<Array<Maybe<MarketOddsProp>>>;
  type?: Maybe<Scalars["String"]>;
};

export type MarketOddsEvent = {
  __typename?: "MarketOddsEvent";
  awayTeam?: Maybe<MarketOddsEventTeam>;
  eventUrl?: Maybe<Scalars["String"]>;
  homeTeam?: Maybe<MarketOddsEventTeam>;
  time?: Maybe<Scalars["String"]>;
};

export type MarketOddsEventTeam = {
  __typename?: "MarketOddsEventTeam";
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type MarketOddsInput = {
  eventId: Scalars["Int"];
  league: Scalars["String"];
  marketId: Scalars["String"];
  marketName: Scalars["String"];
  selectionIds: Array<InputMaybe<Scalars["Int"]>>;
};

export type MarketOddsProp = {
  __typename?: "MarketOddsProp";
  externalMarketId?: Maybe<Scalars["String"]>;
  inPlay?: Maybe<Scalars["Boolean"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketStatus?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<OddsSelection>>>;
  sgmMarket?: Maybe<Scalars["Boolean"]>;
};

export enum MarketType {
  Player = "PLAYER",
  Team = "TEAM",
}

/** Top-level entity describing a single menu */
export type Menu = {
  __typename?: "Menu";
  iconUrl?: Maybe<Scalars["String"]>;
  menuItems: Array<Maybe<NestedLink>>;
  name: Scalars["String"];
};

export type MenuSearchInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type MlbBatter = {
  __typename?: "MlbBatter";
  battingAverage?: Maybe<Scalars["Float"]>;
  caughtStealing?: Maybe<Scalars["Float"]>;
  doubles?: Maybe<Scalars["Float"]>;
  /**  Not Available for WEEKLY Projections  */
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  gameInfo?: Maybe<ProjectionsGameInfo>;
  hits?: Maybe<Scalars["Float"]>;
  homeRuns?: Maybe<Scalars["Float"]>;
  onBasePercentage?: Maybe<Scalars["Float"]>;
  onBasePlusSlugging?: Maybe<Scalars["Float"]>;
  plateAppearances?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  runs?: Maybe<Scalars["Float"]>;
  runsBattedIn?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  salary?: Maybe<Scalars["String"]>;
  singles?: Maybe<Scalars["Float"]>;
  sluggingPercentage?: Maybe<Scalars["Float"]>;
  stolenBases?: Maybe<Scalars["Float"]>;
  strikeouts?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  triples?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  value?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Float"]>;
};

export type MlbPitcher = {
  __typename?: "MlbPitcher";
  earnedRuns?: Maybe<Scalars["Float"]>;
  earnedRunsAvg?: Maybe<Scalars["Float"]>;
  /**  Not Available for WEEKLY Projections  */
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  gameInfo?: Maybe<ProjectionsGameInfo>;
  /**  Only available for REMAINING, WEEKLY, and YEARLY Projections  */
  gamesPlayed?: Maybe<Scalars["Float"]>;
  gamesStarted?: Maybe<Scalars["Float"]>;
  hits?: Maybe<Scalars["Float"]>;
  homeRuns?: Maybe<Scalars["Float"]>;
  inningsPitched?: Maybe<Scalars["Float"]>;
  losses?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  runs?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  salary?: Maybe<Scalars["String"]>;
  saves?: Maybe<Scalars["Float"]>;
  strikeouts?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  /**  Only available for DAILY Projections  */
  value?: Maybe<Scalars["String"]>;
  walks?: Maybe<Scalars["Float"]>;
  walksPlusHitsPerInningsPitched?: Maybe<Scalars["Float"]>;
  wins?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  winsLosses?: Maybe<Scalars["String"]>;
};

export type NbaPlayer = {
  __typename?: "NbaPlayer";
  assists?: Maybe<Scalars["Float"]>;
  blocks?: Maybe<Scalars["Float"]>;
  /**  Not Available for WEEKLY Projections  */
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Not Available for DAILY Projections  */
  fieldGoalShootingPercentage?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted?: Maybe<Scalars["Float"]>;
  fieldGoalsMade?: Maybe<Scalars["Float"]>;
  /**  Not Available for DAILY Projections  */
  freeThrowShootingPercentage?: Maybe<Scalars["Float"]>;
  freeThrowsAttempted?: Maybe<Scalars["Float"]>;
  freeThrowsMade?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  gameInfo?: Maybe<ProjectionsGameInfo>;
  /**  Not Available for DAILY Projections  */
  gamesPlayed?: Maybe<Scalars["Float"]>;
  minutes?: Maybe<Scalars["Float"]>;
  /**  Only Available for YEARLY Projections  */
  overallRank?: Maybe<Scalars["Int"]>;
  player: ProjectionsPlayer;
  points?: Maybe<Scalars["Float"]>;
  /**  Only Available for YEARLY Projections  */
  positionRank?: Maybe<Scalars["Int"]>;
  rebounds?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  salary?: Maybe<Scalars["String"]>;
  steals?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  threePointsAttempted?: Maybe<Scalars["Float"]>;
  threePointsMade?: Maybe<Scalars["Float"]>;
  /**  Not Available for DAILY Projections  */
  threePointsShootingPercentage?: Maybe<Scalars["Float"]>;
  turnovers?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  value?: Maybe<Scalars["String"]>;
};

/**
 * Individual link w/caption.
 * The "children" field is a allows for nested menus - an array
 * of NestedLink can represent the top-level menu.
 */
export type NestedLink = {
  __typename?: "NestedLink";
  behaviour?: Maybe<LinkBehaviour>;
  children?: Maybe<Array<Maybe<NestedLink>>>;
  iconUrl?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  url: Scalars["String"];
};

export type NflDefensePlayer = {
  __typename?: "NflDefensePlayer";
  fumblesRecovered?: Maybe<Scalars["Float"]>;
  gameInfo?: Maybe<ProjectionsGameInfo>;
  interceptions?: Maybe<Scalars["Float"]>;
  /**  Only Available for WEEKLY Projections  */
  opponentOffensiveRank?: Maybe<Scalars["Int"]>;
  passesDefended?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  sacks?: Maybe<Scalars["Float"]>;
  tackles?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  touchdowns?: Maybe<Scalars["Float"]>;
};

export type NflDefenseSt = {
  __typename?: "NflDefenseSt";
  fantasy?: Maybe<Scalars["Float"]>;
  fumblesRecovered?: Maybe<Scalars["Float"]>;
  gameInfo?: Maybe<ProjectionsGameInfo>;
  interceptions?: Maybe<Scalars["Float"]>;
  /**  Only Available for WEEKLY Projections  */
  opponentOffensiveRank?: Maybe<Scalars["Int"]>;
  player: ProjectionsPlayer;
  pointsAllowed?: Maybe<Scalars["Float"]>;
  /**  NOT Available for DAILY Projections  */
  positionRank?: Maybe<Scalars["Int"]>;
  sacks?: Maybe<Scalars["Float"]>;
  /**  NOT Available for REMAINING Projections  */
  salary?: Maybe<Scalars["String"]>;
  team?: Maybe<ProjectionsTeam>;
  touchdowns?: Maybe<Scalars["Float"]>;
  /**  NOT Available for REMAINING Projections  */
  value?: Maybe<Scalars["String"]>;
  yardsAllowed?: Maybe<Scalars["Float"]>;
};

export type NflKicker = {
  __typename?: "NflKicker";
  extraPointsAttempted?: Maybe<Scalars["Float"]>;
  extraPointsMade?: Maybe<Scalars["Float"]>;
  extraPointsMissed?: Maybe<Scalars["Float"]>;
  fantasy?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted0To19?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted20To29?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted30To39?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted40To49?: Maybe<Scalars["Float"]>;
  fieldGoalsAttempted50Plus?: Maybe<Scalars["Float"]>;
  fieldGoalsMade?: Maybe<Scalars["Float"]>;
  fieldGoalsMade0To19?: Maybe<Scalars["Float"]>;
  fieldGoalsMade20To29?: Maybe<Scalars["Float"]>;
  fieldGoalsMade30To39?: Maybe<Scalars["Float"]>;
  fieldGoalsMade40To49?: Maybe<Scalars["Float"]>;
  fieldGoalsMade50Plus?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed0To19?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed20To29?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed30To39?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed40To49?: Maybe<Scalars["Float"]>;
  fieldGoalsMissed50Plus?: Maybe<Scalars["Float"]>;
  gameInfo?: Maybe<ProjectionsGameInfo>;
  /**  Only Available for WEEKLY Projections  */
  opponentDefensiveRank?: Maybe<Scalars["Int"]>;
  player: ProjectionsPlayer;
  /**  NOT Available for DAILY Projections  */
  positionRank?: Maybe<Scalars["Int"]>;
  /**  NOT Available for REMAINING Projections  */
  salary?: Maybe<Scalars["String"]>;
  team?: Maybe<ProjectionsTeam>;
  /**  NOT Available for REMAINING Projections  */
  value?: Maybe<Scalars["String"]>;
};

export type NflSkill = {
  __typename?: "NflSkill";
  completionsAttempts?: Maybe<Scalars["String"]>;
  fantasy?: Maybe<Scalars["Float"]>;
  fiftyPass?: Maybe<Scalars["Float"]>;
  fiftyRec?: Maybe<Scalars["Float"]>;
  fiftyRush?: Maybe<Scalars["Float"]>;
  fortyPass?: Maybe<Scalars["Float"]>;
  fortyRec?: Maybe<Scalars["Float"]>;
  fortyRush?: Maybe<Scalars["Float"]>;
  fum?: Maybe<Scalars["Float"]>;
  fumLost?: Maybe<Scalars["Float"]>;
  fumTd?: Maybe<Scalars["Float"]>;
  gameInfo?: Maybe<ProjectionsGameInfo>;
  interceptionsThrown?: Maybe<Scalars["Float"]>;
  /**  Only Available for WEEKLY and PPR Projections  */
  opponentDefensiveRank?: Maybe<Scalars["Int"]>;
  /**  Only Available for WEEKLY and PPR Projections  */
  overallRank?: Maybe<Scalars["Int"]>;
  passingTouchdowns?: Maybe<Scalars["Float"]>;
  passingYards?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  /**  Only Available for WEEKLY and PPR Projections  */
  positionRank?: Maybe<Scalars["Int"]>;
  receivingTouchdowns?: Maybe<Scalars["Float"]>;
  receivingYards?: Maybe<Scalars["Float"]>;
  receptions?: Maybe<Scalars["Float"]>;
  rushingAttempts?: Maybe<Scalars["Float"]>;
  rushingTouchdowns?: Maybe<Scalars["Float"]>;
  rushingYards?: Maybe<Scalars["Float"]>;
  sacks?: Maybe<Scalars["Float"]>;
  /**  NOT Available for REMAINING Projections  */
  salary?: Maybe<Scalars["String"]>;
  targets?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  tpcP?: Maybe<Scalars["Float"]>;
  tpcR?: Maybe<Scalars["Float"]>;
  tpcV?: Maybe<Scalars["Float"]>;
  /**  NOT Available for REMAINING Projections  */
  value?: Maybe<Scalars["String"]>;
};

export type NhlGoalie = {
  __typename?: "NhlGoalie";
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  gameInfo?: Maybe<ProjectionsGameInfo>;
  /**  Not Available for DAILY Projections  */
  gamesPlayed?: Maybe<Scalars["Float"]>;
  goalsAgainst?: Maybe<Scalars["Float"]>;
  /**  Not Available for DAILY Projections  */
  goalsAgainstAvg?: Maybe<Scalars["Float"]>;
  losses?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  minutesPlayed?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  /**  Only available for DAILY Projections  */
  salary?: Maybe<Scalars["String"]>;
  savePercent?: Maybe<Scalars["Float"]>;
  saves?: Maybe<Scalars["Float"]>;
  shotsAgainst?: Maybe<Scalars["Float"]>;
  shutouts?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  tiesPlusOvertimeOrShootoutLosses?: Maybe<Scalars["Float"]>;
  timeOnIce?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  value?: Maybe<Scalars["String"]>;
  wins?: Maybe<Scalars["Float"]>;
};

export type NhlSkater = {
  __typename?: "NhlSkater";
  assists?: Maybe<Scalars["Float"]>;
  /**
   * Format: MM:SS
   * Not Available for DAILY Projections
   */
  avgTimeOnIce?: Maybe<Scalars["String"]>;
  blockedShots?: Maybe<Scalars["Float"]>;
  fantasy?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  gameInfo?: Maybe<ProjectionsGameInfo>;
  /**  Not Available for DAILY Projections  */
  gamesPlayed?: Maybe<Scalars["Float"]>;
  goals?: Maybe<Scalars["Float"]>;
  /**  Only Available for DAILY Projections  */
  minutesPlayed?: Maybe<Scalars["Float"]>;
  penaltiesInMinutes?: Maybe<Scalars["Float"]>;
  player: ProjectionsPlayer;
  plusMinus?: Maybe<Scalars["Float"]>;
  points?: Maybe<Scalars["Float"]>;
  powerPlayAssists?: Maybe<Scalars["Float"]>;
  powerPlayGoals?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  salary?: Maybe<Scalars["String"]>;
  shots?: Maybe<Scalars["Float"]>;
  team?: Maybe<ProjectionsTeam>;
  /**  Format: in seconds  */
  timeOnIce?: Maybe<Scalars["Float"]>;
  /**  Only available for DAILY Projections  */
  value?: Maybe<Scalars["String"]>;
};

export type OddsEvent = {
  __typename?: "OddsEvent";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type OddsLeague = {
  __typename?: "OddsLeague";
  competitionIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  eventTypeId: Scalars["Int"];
  name: Scalars["String"];
};

export type OddsMarketTypes = {
  __typename?: "OddsMarketTypes";
  id: Scalars["String"];
  name: Scalars["String"];
};

/** SportsBook Live Market Odds input */
export type OddsMarketTypesInput = {
  eventId: Scalars["Int"];
};

export type OddsMarkets = {
  __typename?: "OddsMarkets";
  name: Scalars["String"];
  selectionId: Scalars["Int"];
};

export type OddsMarketsInput = {
  marketId: Scalars["String"];
};

export type OddsSelection = {
  __typename?: "OddsSelection";
  formattedHandicap?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["String"]>;
  odds?: Maybe<Scalars["String"]>;
  result?: Maybe<OddsSelectionResult>;
  runnerName?: Maybe<Scalars["String"]>;
  runnerStatus?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
  sortPriority?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type OddsSelectionResult = {
  __typename?: "OddsSelectionResult";
  type?: Maybe<Scalars["String"]>;
};

export type OpenGraphTag = {
  __typename?: "OpenGraphTag";
  image?: Maybe<ImageReference>;
  title: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type PageContentWidget =
  | Faq
  | FeaturedSport
  | ImageBanner
  | ImageShowcase
  | PlayerOddsTabs
  | Promos_TaggedArticles
  | Scoreboard
  | ScrollableOdds
  | TaggedArticles
  | TaggedArticles_PlayerOddsTabs
  | TaggedArticles_ScrollableOdds
  | TextBanner;

export type PageInfo = {
  __typename?: "PageInfo";
  cursor: Scalars["String"];
  hasNextPage: Scalars["Boolean"];
};

/** The page where to find featured articles */
export enum PageType {
  Homepage = "HOMEPAGE",
  Sport = "SPORT",
}

export type Player = SportParticipant &
  SportsEntity & {
    __typename?: "Player";
    identifier: Scalars["String"];
    image?: Maybe<ImageReference>;
    name: Scalars["String"];
    number?: Maybe<Scalars["Int"]>;
    position?: Maybe<Scalars["String"]>;
    relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
    sports?: Maybe<Array<Maybe<Sport>>>;
    team?: Maybe<Team>;
    type: Scalars["String"];
  };

export type PlayerInput = {
  /** The player's numberfire id */
  numberFireId?: InputMaybe<Scalars["Int"]>;
  /** The player's position */
  positionAbbrev?: InputMaybe<PositionAbbrev>;
};

export type PlayerOddsTabs = {
  __typename?: "PlayerOddsTabs";
  bettingTabs: Array<Maybe<BettingTabs>>;
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type PlayerOrTeam = Player | Team;

export enum PlayerPositions {
  GolfPlayer = "GOLF_PLAYER",
  MlbBatter = "MLB_BATTER",
  MlbPitcher = "MLB_PITCHER",
  NbaPlayer = "NBA_PLAYER",
  NflDSt = "NFL_D_ST",
  NflIdp = "NFL_IDP",
  NflKicker = "NFL_KICKER",
  NflSkill = "NFL_SKILL",
  NhlGoalie = "NHL_GOALIE",
  NhlSkater = "NHL_SKATER",
}

export enum PositionAbbrev {
  C = "C",
  D = "D",
  Db = "DB",
  Dh = "DH",
  Dl = "DL",
  F = "F",
  G = "G",
  If = "IF",
  K = "K",
  Lb = "LB",
  Of = "OF",
  Ol = "OL",
  P = "P",
  Qb = "QB",
  Rb = "RB",
  Te = "TE",
  W = "W",
  Wr = "WR",
}

export type PositionFilter = {
  __typename?: "PositionFilter";
  isDefaultPosition?: Maybe<Scalars["Boolean"]>;
  label: Scalars["String"];
  positionGroup?: Maybe<Array<Maybe<Scalars["String"]>>>;
  slug?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type ProjectionPlayer =
  | GolfPlayer
  | MlbBatter
  | MlbPitcher
  | NbaPlayer
  | NflDefensePlayer
  | NflDefenseSt
  | NflKicker
  | NflSkill
  | NhlGoalie
  | NhlSkater;

export enum ProjectionScope {
  PerGame = "PER_GAME",
  Total = "TOTAL",
}

export enum ProjectionSports {
  Golf = "GOLF",
  Mlb = "MLB",
  Nba = "NBA",
  Nfl = "NFL",
  Nhl = "NHL",
}

/** Projection metadata */
export type ProjectionTableMetadata = {
  __typename?: "ProjectionTableMetadata";
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  columns?: Maybe<Array<Maybe<ColumnGroups>>>;
  id: Scalars["String"];
  name: Scalars["String"];
  positionFilter: Array<Maybe<PositionFilter>>;
  slug: Scalars["String"];
  sortOrder?: Maybe<Scalars["Int"]>;
  sport: Sport;
};

export type ProjectionTableMetadataInput = {
  /**
   * Case-sensitive
   *
   * The type of projection
   */
  projectionType?: InputMaybe<ProjectionType>;
  /**
   * Case-sensitive
   *
   * The slug of the scope for the projection. Only for NBA Projections
   */
  scope?: InputMaybe<ProjectionScope>;
  /**
   * Case-sensitive
   *
   * The slug that will be redirected to a new page
   */
  slug?: InputMaybe<Scalars["String"]>;
  /**
   * Case-sensitive
   *
   * The slug of the sport for the projection
   */
  sportSlug?: InputMaybe<Scalars["String"]>;
};

export enum ProjectionType {
  Daily = "DAILY",
  /**  Points per reception: Only available for NFL Skill Players  */
  Ppr = "PPR",
  Remaining = "REMAINING",
  SeasonStats = "SEASON_STATS",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export type ProjectionsGameInfo = {
  __typename?: "ProjectionsGameInfo";
  awayTeam?: Maybe<ProjectionsTeam>;
  betGeniusId?: Maybe<Scalars["String"]>;
  /**  Format: ISO, i.e. YYYY-MM-DDTHH:mm:ssZ  */
  gameTime?: Maybe<Scalars["String"]>;
  homeTeam?: Maybe<ProjectionsTeam>;
  sportRadarId?: Maybe<Scalars["String"]>;
};

export type ProjectionsInput = {
  /**
   *  Required only for WEEKLY projections
   * Format: YYYY-MM-DD (Date should be on a Monday)
   */
  date?: InputMaybe<Scalars["String"]>;
  /**  Only for Golf Projections  */
  eventId?: InputMaybe<Scalars["String"]>;
  nfPlayerIds?: InputMaybe<Array<Scalars["Int"]>>;
  position: PlayerPositions;
  /**  Only for NBA Projections  */
  scope?: InputMaybe<ProjectionScope>;
  /**  Required only for DAILY projections */
  slateId?: InputMaybe<Scalars["String"]>;
  sport: ProjectionSports;
  type: ProjectionType;
};

export type ProjectionsPlayer = {
  __typename?: "ProjectionsPlayer";
  betGeniusId?: Maybe<Scalars["String"]>;
  handedness?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  numberFireId: Scalars["Int"];
  playerPageUrl?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  sportRadarId?: Maybe<Scalars["String"]>;
};

export type ProjectionsTeam = {
  __typename?: "ProjectionsTeam";
  abbreviation?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  numberFireId: Scalars["Int"];
};

export type Promo = {
  __typename?: "Promo";
  buttonOne: PromoButton;
  buttonTwo?: Maybe<PromoButton>;
  image?: Maybe<Scalars["String"]>;
};

export type PromoButton = {
  __typename?: "PromoButton";
  text: Scalars["String"];
  url: Scalars["String"];
};

export type Promos = {
  __typename?: "Promos";
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  promos: Array<Promo>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type Promos_TaggedArticles = {
  __typename?: "Promos_TaggedArticles";
  promos?: Maybe<Promos>;
  taggedArticles?: Maybe<TaggedArticles>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type Promotion = {
  __typename?: "Promotion";
  ctaText?: Maybe<Scalars["String"]>;
  ctaUrl?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  detailsUrl?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  promoCode?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title?: Maybe<Scalars["String"]>;
};

export type PromotionsInput = {
  /** Add tags to find related promos. */
  tags?: InputMaybe<Array<InputMaybe<PromotionsTagInput>>>;
};

export type PromotionsTagInput = {
  /** Tags to search */
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type Query = {
  __typename?: "Query";
  HR_CarryOverPools?: Maybe<Array<Maybe<Hr_CarryOverPool>>>;
  HR_HasTalentPicks?: Maybe<Scalars["Boolean"]>;
  HR_Race?: Maybe<Hr_Race>;
  HR_RaceDate: Scalars["String"];
  HR_RaceLastHash?: Maybe<Scalars["String"]>;
  HR_Races?: Maybe<Array<Maybe<Hr_Race>>>;
  HR_TalentPicks?: Maybe<Array<Maybe<Hr_TalentPickType>>>;
  HR_Track?: Maybe<Hr_Track>;
  HR_Tracks?: Maybe<Array<Maybe<Hr_Track>>>;
  HR_TracksWithMetadata?: Maybe<Hr_TracksWithMetadata>;
  HR_WagerTypes?: Maybe<Array<Maybe<Hr_WagerType>>>;
  /**
   * Return a list of Past Races:
   *  - Passing a runner name and a runner dob return a list of races that the horse has participated (the runner dob optional for runner filtering)
   *  - Passing a Track Code and Date returns a list of all races of that track for that date
   *  - Passing a Track Code, a Date and a Race Number returns a list with a race for that track, date and number with more info (BI, etc..)
   */
  HS_PastRaces?: Maybe<Array<Maybe<Hr_PastRace>>>;
  /** Return a list of horse names that contains a given string */
  HS_PastRunnersName?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**
   * Returns a list of Past Tracks
   *  - Passing no arguments returns the list of all Tracks
   *  - Passing a date returns the list of Tracks that ran on that date
   *  - Passing a Track Code returns a list with the Track for that Track Code (doesn't return the races)
   *  - Passing a Track Code and Date returns a list with the Track for that Track Code and Date (including the races if requested)
   *  - Passing a Track Code, Date and Race Number returns a list with the Track for that Track Code and Date (including the races if requested and extra info (BI, etc..))
   */
  HS_PastTracks?: Maybe<Array<Maybe<Hr_PastTrack>>>;
  /**
   * Returns a list of race entities
   *  - Fragment: String to search for, minimum 3 chars
   *  - Type: R,J,T,O (Runner, Jockey, Trainer, Owner)
   */
  HS_RaceEntities?: Maybe<Hr_RaceEntities>;
  /** Returns the statistics of a Runner */
  HS_RunnerStats?: Maybe<Hr_RunnerStats>;
  ctp_Bblg?: Maybe<Ctp_Bblg>;
  ctp_Cba?: Maybe<Ctp_Cba>;
  ctp_Fibaa?: Maybe<Ctp_Fibaa>;
  ctp_Golf?: Maybe<Ctp_Golf>;
  ctp_Mlb?: Maybe<Ctp_Mlb>;
  ctp_Nba?: Maybe<Ctp_Nba>;
  ctp_Ncaafb?: Maybe<Ctp_Ncaafb>;
  ctp_Ncaamb?: Maybe<Ctp_Ncaamb>;
  ctp_Nfl?: Maybe<Ctp_Nfl>;
  ctp_Tbsl?: Maybe<Ctp_Tbsl>;
  ctp__entities: Array<Maybe<Ctp__Entity>>;
  ctp__service: Ctp__Service;
  /**
   * Returns the "current" set of fixtures.
   * What constitutes "current" is determined by the server.
   */
  getActiveFixtures?: Maybe<Array<Maybe<Fixture>>>;
  /** Retrieve all articles */
  getArticles?: Maybe<ArticleList>;
  /**
   * Returns all authors
   * Combine with the article/short-form retrieval queries to obtain all
   * content written by one or more specific authors
   */
  getAuthors?: Maybe<ContentAuthorList>;
  getBulkArticlesById?: Maybe<Array<Maybe<Article>>>;
  getBulkShortFormsById?: Maybe<Array<Maybe<ShortForm>>>;
  /**
   * Returns content feed for a given context
   * (e.g. home page) & sport.
   *
   * TODO: define behaviour if neither context nor sport
   * are provided.
   *
   * WIP
   */
  getContentFeed?: Maybe<ContentFeed>;
  getContentFilters?: Maybe<ContentFilters>;
  /**
   * Returns the featured articles from the given page type.
   *
   * Note: If wanting the `SPORT` featured articles, be sure to specify the `sportName`
   */
  getFeaturedArticles?: Maybe<Array<Maybe<Article>>>;
  getGolfEvents?: Maybe<Array<Maybe<GolfEvent>>>;
  /** Retrieve a homepage data */
  getHomepage?: Maybe<HomepageContent>;
  /** Get Market Odds */
  getMarketOdds?: Maybe<MarketOdds>;
  /** Search for one or more menus. */
  getMenu?: Maybe<Array<Maybe<Menu>>>;
  /** Retrieve _all_ menu. */
  getMenus?: Maybe<Array<Maybe<Menu>>>;
  /** Get Odds Events by sports */
  getOddsEvents?: Maybe<Array<Maybe<OddsEvent>>>;
  /** Get Odds Market Types by EventId */
  getOddsMarketTypes?: Maybe<Array<Maybe<OddsMarketTypes>>>;
  /** Get Odds Marekts by EventTypeId */
  getOddsMarkets?: Maybe<Array<Maybe<OddsMarkets>>>;
  /** Get Odds supported Leagues */
  getOddsSupportedLeagues?: Maybe<Array<Maybe<OddsLeague>>>;
  /** Retrieve projection table metadata */
  getProjectionTableMetadata?: Maybe<Array<Maybe<ProjectionTableMetadata>>>;
  getProjections?: Maybe<Array<Maybe<ProjectionPlayer>>>;
  /** Get Promotions */
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  /** Search for one redirect. */
  getRedirect?: Maybe<Redirect>;
  /** Retrieve _all_ redirects. */
  getRedirects?: Maybe<Array<Maybe<Redirect>>>;
  /**
   * Obtain latest scores for a given fixture.
   * The expectation is that the front-end will hit this query fairly frequently in order to supply quasi-realtime score information.
   *
   * In the absence of an `input` value, this query will return scores for all fixtures - that is, the equivalent
   * of running `getActiveFixtures`, and then `getScoreUpdates` for all fixtures.
   *
   * Note: this query is likely to be superseded in the future by a streaming solution.
   * WIP
   */
  getScoreUpdates?: Maybe<Array<Maybe<ScoreUpdate>>>;
  /** Retrieve a specific piece of short-form content */
  getShortForm?: Maybe<ShortForm>;
  /** Retrieve all items of short-form contenet */
  getShortForms?: Maybe<ShortFormList>;
  /** Retrieve a specific article */
  getSingleArticle?: Maybe<Article>;
  /** Get Site SiteSettigs */
  getSiteSettings?: Maybe<SiteSettings>;
  getSlates?: Maybe<Array<Maybe<Slate>>>;
  /** Get Sub category */
  getSubCategory?: Maybe<SubCategory>;
  /** Returns a list of all supported sports */
  getSupportedSports?: Maybe<Array<Maybe<Sport>>>;
  /** Retrieve single tentpole event */
  getTentpoleEvent?: Maybe<TentpoleEvent>;
  /** Retrieve list of tentpole events */
  getTentpoleEvents?: Maybe<Array<Maybe<TentpoleEvent>>>;
  sdl: Scalars["String"];
  /** Get the odds for a team to win their group for the NBA In-Season Tournament */
  tournament_GetTournamentGroupWinnerOdds?: Maybe<
    Array<Maybe<Tournament_NbaIstGroupedTeamOdds>>
  >;
  /** Player Futures for the In-Season Tournament */
  tournament_GetTournamentPlayerFutures?: Maybe<
    Array<Maybe<Tournament_NbaIstGroupedPlayerOdds>>
  >;
  /** Player Props for the In-Season Tournament Games */
  tournament_GetTournamentPlayerProps?: Maybe<
    Array<Maybe<Tournament_NbaIstPlayerProps>>
  >;
  /** Get all NBA teams odds to win the In-Season Tournament */
  tournament_GetTournamentWinnerOdds?: Maybe<Tournament_NbaIstGroupedTeamOdds>;
  tournament_TournamentGames?: Maybe<Tournament_NbaTournamentGameDetails>;
};

/** ## Queries */
export type QueryHr_CarryOverPoolsArgs = {
  filter?: InputMaybe<Hr_CarryOverListFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_CarryOverListFilter>>>;
  pages?: InputMaybe<Array<InputMaybe<Hr_CarryOverPoolsPagination>>>;
  profile?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHr_HasTalentPicksArgs = {
  filter?: InputMaybe<Hr_TalentPicksFilterType>;
  profile?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHr_RaceArgs = {
  number?: InputMaybe<Scalars["String"]>;
  profile?: InputMaybe<Scalars["String"]>;
  track?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHr_RaceLastHashArgs = {
  number: Scalars["String"];
  profile?: InputMaybe<Scalars["String"]>;
  track: Scalars["String"];
};

/** ## Queries */
export type QueryHr_RacesArgs = {
  filter?: InputMaybe<Hr_RaceListFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_RaceListFilter>>>;
  page?: InputMaybe<Hr_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Hr_RaceListPagination>>>;
  profile?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Hr_RaceListSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_RaceListSort>>>;
  tvgRaceIds?: InputMaybe<Array<InputMaybe<Scalars["HR_Long"]>>>;
};

/** ## Queries */
export type QueryHr_TalentPicksArgs = {
  filter?: InputMaybe<Hr_TalentPicksFilterType>;
  filters?: InputMaybe<Array<InputMaybe<Hr_TalentPicksFilterType>>>;
  pages?: InputMaybe<Array<InputMaybe<Hr_TalentPicksPagination>>>;
  profile?: InputMaybe<Scalars["String"]>;
  raceNumber?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Hr_TalentPicksSortType>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_TalentPicksSortType>>>;
  trackCode?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHr_TrackArgs = {
  code?: InputMaybe<Scalars["String"]>;
  profile?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHr_TracksArgs = {
  filter?: InputMaybe<Hr_TrackListFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_TrackListFilter>>>;
  page?: InputMaybe<Hr_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Hr_TrackListPagination>>>;
  profile?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Hr_TrackListSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_TrackListSort>>>;
};

/** ## Queries */
export type QueryHr_WagerTypesArgs = {
  filter?: InputMaybe<Hr_WagerTypesFilter>;
  filters?: InputMaybe<Array<InputMaybe<Hr_WagerTypesFilter>>>;
  pages?: InputMaybe<Array<InputMaybe<Hr_WagerTypesPagination>>>;
  sort?: InputMaybe<Hr_WagerTypesSort>;
  sorts?: InputMaybe<Array<InputMaybe<Hr_WagerTypesSort>>>;
};

/** ## Queries */
export type QueryHs_PastRacesArgs = {
  date?: InputMaybe<Scalars["String"]>;
  entityRunnerId?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Hr_PastRaceListFilter>;
  page?: InputMaybe<Hr_Pagination>;
  profile: Scalars["String"];
  raceNumber?: InputMaybe<Scalars["String"]>;
  runnerDob?: InputMaybe<Scalars["Int"]>;
  runnerName?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Hr_PastRaceListSort>;
  trackCode?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHs_PastRunnersNameArgs = {
  runnerName: Scalars["String"];
};

/** ## Queries */
export type QueryHs_PastTracksArgs = {
  date?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Hr_PastTrackListFilter>;
  profile: Scalars["String"];
  raceNumber?: InputMaybe<Scalars["String"]>;
  trackCode?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHs_RaceEntitiesArgs = {
  searchText: Scalars["String"];
  sort?: InputMaybe<Hr_RaceEntityListSort>;
  type?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryHs_RunnerStatsArgs = {
  entityRunnerId?: InputMaybe<Scalars["String"]>;
  profile: Scalars["String"];
  runnerDob?: InputMaybe<Scalars["Int"]>;
  runnerName?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryCtp__EntitiesArgs = {
  representations: Array<Scalars["CTP__Any"]>;
};

/** ## Queries */
export type QueryGetActiveFixturesArgs = {
  input?: InputMaybe<FixtureSearchInput>;
};

/** ## Queries */
export type QueryGetArticlesArgs = {
  filter: ArticleSearchInput;
};

/** ## Queries */
export type QueryGetAuthorsArgs = {
  input?: InputMaybe<AuthorSearchInput>;
};

/** ## Queries */
export type QueryGetBulkArticlesByIdArgs = {
  ids: Array<InputMaybe<Scalars["String"]>>;
};

/** ## Queries */
export type QueryGetBulkShortFormsByIdArgs = {
  ids: Array<InputMaybe<Scalars["String"]>>;
};

/** ## Queries */
export type QueryGetContentFeedArgs = {
  filter?: InputMaybe<ContentFeedInput>;
};

/** ## Queries */
export type QueryGetFeaturedArticlesArgs = {
  filter?: InputMaybe<FeaturedArticleInput>;
};

/** ## Queries */
export type QueryGetHomepageArgs = {
  isPreview?: InputMaybe<Scalars["Boolean"]>;
};

/** ## Queries */
export type QueryGetMarketOddsArgs = {
  input: MarketOddsInput;
};

/** ## Queries */
export type QueryGetMenuArgs = {
  input?: InputMaybe<MenuSearchInput>;
};

/** ## Queries */
export type QueryGetMenusArgs = {
  isPreview?: InputMaybe<Scalars["Boolean"]>;
};

/** ## Queries */
export type QueryGetOddsEventsArgs = {
  competitionIds: Array<InputMaybe<Scalars["Int"]>>;
};

/** ## Queries */
export type QueryGetOddsMarketTypesArgs = {
  input: OddsMarketTypesInput;
};

/** ## Queries */
export type QueryGetOddsMarketsArgs = {
  input: OddsMarketsInput;
};

/** ## Queries */
export type QueryGetProjectionTableMetadataArgs = {
  input?: InputMaybe<ProjectionTableMetadataInput>;
};

/** ## Queries */
export type QueryGetProjectionsArgs = {
  input: ProjectionsInput;
};

/** ## Queries */
export type QueryGetPromotionsArgs = {
  input?: InputMaybe<PromotionsInput>;
};

/** ## Queries */
export type QueryGetRedirectArgs = {
  input: RedirectSearchInput;
};

/** ## Queries */
export type QueryGetRedirectsArgs = {
  isPreview?: InputMaybe<Scalars["Boolean"]>;
};

/** ## Queries */
export type QueryGetScoreUpdatesArgs = {
  input?: InputMaybe<ScoreUpdateInput>;
};

/** ## Queries */
export type QueryGetShortFormArgs = {
  contentStatus?: InputMaybe<StatusSelector>;
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

/** ## Queries */
export type QueryGetShortFormsArgs = {
  filter: ShortFormSearchInput;
};

/** ## Queries */
export type QueryGetSingleArticleArgs = {
  contentStatus?: InputMaybe<StatusSelector>;
  isPreview?: InputMaybe<Scalars["Boolean"]>;
  slug: Scalars["String"];
};

/** ## Queries */
export type QueryGetSlatesArgs = {
  range?: InputMaybe<Range>;
  sport: ProjectionSports;
};

/** ## Queries */
export type QueryGetSubCategoryArgs = {
  input: SubCategoryInput;
};

/** ## Queries */
export type QueryGetSupportedSportsArgs = {
  featured?: InputMaybe<Scalars["Boolean"]>;
  isPreview?: InputMaybe<Scalars["Boolean"]>;
};

/** ## Queries */
export type QueryGetTentpoleEventArgs = {
  input: TentpoleEventInput;
};

/** ## Queries */
export type QueryGetTentpoleEventsArgs = {
  input?: InputMaybe<TentpoleEventsInput>;
};

export type Question = {
  __typename?: "Question";
  answer: Scalars["String"];
  questionText: Scalars["String"];
};

export type Range = {
  date?: InputMaybe<Scalars["String"]>;
  season?: InputMaybe<Scalars["Int"]>;
  week?: InputMaybe<Scalars["Int"]>;
};

export type Redirect = {
  __typename?: "Redirect";
  fromSlug: Scalars["String"];
  isPermanent?: Maybe<Scalars["Boolean"]>;
  toSlug?: Maybe<Scalars["String"]>;
};

export type RedirectSearchInput = {
  /**
   * Case-sensitive
   *
   * The slug that will be redirected to a new page
   */
  slug?: InputMaybe<Scalars["String"]>;
};

export type SeoInformation = {
  __typename?: "SEOInformation";
  canonicalUrl?: Maybe<Scalars["String"]>;
  /** Note: This defaults to grab the first 3 sentences from the 'body' of the content item if the SEO description isn't present in the CMS */
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<ImageReference>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  noIndex?: Maybe<Scalars["Boolean"]>;
  openGraphTag?: Maybe<OpenGraphTag>;
  synonyms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title?: Maybe<Scalars["String"]>;
};

export type ScoreEventMapping = {
  __typename?: "ScoreEventMapping";
  /**
   * Represents a single score in a game (i.e. the event of the score itself).
   * This is *NOT* a summary of scores for a given team or player within a fixture.
   */
  fixture: Fixture;
  score: Scalars["Int"];
  scoreTime?: Maybe<Scalars["String"]>;
  scoringPlayer: Player;
  scoringTeam: Team;
};

export type ScoreUpdate = {
  __typename?: "ScoreUpdate";
  entityUpdates: Array<Maybe<ScoreEventMapping>>;
};

export type ScoreUpdateInput = {
  fixtureId: Scalars["String"];
};

export type Scoreboard = {
  __typename?: "Scoreboard";
  icon?: Maybe<Scalars["String"]>;
  tabs: Array<ScoreboardTab>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type ScoreboardTab = {
  __typename?: "ScoreboardTab";
  label: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type ScrollMenu = {
  __typename?: "ScrollMenu";
  label: Scalars["String"];
  target: Scalars["String"];
};

export type ScrollableOdds = {
  __typename?: "ScrollableOdds";
  icon?: Maybe<Scalars["String"]>;
  markets: Array<Maybe<BettingMarket>>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type SelectedSport = {
  __typename?: "SelectedSport";
  featuredArticles?: Maybe<Array<Maybe<Article>>>;
  sport: Sport;
  tool?: Maybe<Tool>;
};

export type ShortForm = ContentItem & {
  __typename?: "ShortForm";
  analysis: Scalars["String"];
  attribution?: Maybe<Attribution>;
  author: ContentAuthor;
  /**
   * The SEO description without needing to go through the 'seo' object
   *
   * Note: This defaults to grab the first 3 sentences from the 'body' if the SEO description isn't present in the CMS
   */
  description: Scalars["String"];
  fact: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastPublishedAt?: Maybe<Scalars["String"]>;
  modifiedDate: Scalars["String"];
  newsType?: Maybe<ShortFormNewsType>;
  primaryRef: PlayerOrTeam;
  publishedDate?: Maybe<Scalars["String"]>;
  quant?: Maybe<Scalars["String"]>;
  seo: SeoInformation;
  slug: Scalars["String"];
  sport: Sport;
  tags: Array<Maybe<ContentTag>>;
  title: Scalars["String"];
};

/** Data encapsulating a specific short form and the id to help with pagination */
export type ShortFormConnection = {
  __typename?: "ShortFormConnection";
  cursor: Scalars["String"];
  entity: ShortForm;
};

/** List of short forms including pagination data and total short forms in the list */
export type ShortFormList = {
  __typename?: "ShortFormList";
  pageInfo: PageInfo;
  shortForms: Array<Maybe<ShortFormConnection>>;
  totalCount: Scalars["Int"];
};

export type ShortFormNewsType = {
  __typename?: "ShortFormNewsType";
  enum?: Maybe<ShortFormNewsTypeEnum>;
  name?: Maybe<Scalars["String"]>;
};

export enum ShortFormNewsTypeEnum {
  GameUpdate = "GAME_UPDATE",
  Injury = "INJURY",
  Lineup = "LINEUP",
  Outlook = "OUTLOOK",
  Recap = "RECAP",
  Transaction = "TRANSACTION",
}

/** Filter short form searches */
export type ShortFormSearchInput = {
  /**
   * Case-sensitive
   *
   * Used for pagination. Retrieve items that come after the given id.
   */
  afterCursor?: InputMaybe<Scalars["String"]>;
  /**
   * Case-sensitive
   *
   * Filter short form searches by those that were written by a specific author with the given id.
   */
  author?: InputMaybe<AuthorInput>;
  /** Filter short forms where any of the above filters apply to any players mentioned in the tags field */
  includeNonPrimary?: InputMaybe<Scalars["Boolean"]>;
  /**
   * Default 10.
   *
   * Input how many items to return.
   */
  limit?: InputMaybe<Scalars["Int"]>;
  player?: InputMaybe<PlayerInput>;
  /**
   * Format: ISO, i.e. YYYY-MM-DDTHH:mm:ssZ
   *
   * Filter short form searches by those published within a given timeline
   */
  publishedWithin?: InputMaybe<TimestampBoundaries>;
  /** Filter short forms searches by their news type */
  shortFormNewsType?: InputMaybe<ShortFormNewsTypeEnum>;
  /** Filter short form searches by those about the given sport. */
  sport?: InputMaybe<ContentSportsEnum>;
  team?: InputMaybe<TeamInput>;
  /**
   * Case-sensitive
   *
   * Filter short form searches by those with the given title.
   */
  title?: InputMaybe<Scalars["String"]>;
};

export type SiteSettings = {
  __typename?: "SiteSettings";
  sbkPromoBanner?: Maybe<SbkPromoBanner>;
};

export type Slate = {
  __typename?: "Slate";
  id: Scalars["String"];
  name: Scalars["String"];
};

/** An author's social media data */
export type SocialMediaAccount = {
  __typename?: "SocialMediaAccount";
  accountName: Scalars["String"];
  type: SocialMediaType;
  url: Scalars["String"];
};

/** Available social medias authors may have */
export enum SocialMediaType {
  Instagram = "INSTAGRAM",
  Twitter = "TWITTER",
}

export type Sport = {
  contentSport?: Maybe<ContentSportsEnum>;
  description?: Maybe<Scalars["String"]>;
  governingBody?: Maybe<Scalars["String"]>;
  hasLiveScoring?: Maybe<Scalars["Boolean"]>;
  hasPlayerUpdates?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  logo?: Maybe<ImageReferenceNullable>;
  name: Scalars["String"];
  positions?: Maybe<Array<Maybe<PositionAbbrev>>>;
  relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
  seo: SeoInformation;
  showAuthors?: Maybe<Scalars["Boolean"]>;
  slug: Scalars["String"];
  teams?: Maybe<Array<Maybe<Team>>>;
};

export type SportParticipant = {
  identifier: Scalars["String"];
  image?: Maybe<ImageReference>;
  name: Scalars["String"];
  relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
  sports?: Maybe<Array<Maybe<Sport>>>;
  type: Scalars["String"];
};

export type SportsEntity = {
  relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
};

/** The published status of a Content Item */
export enum StatusSelector {
  Both = "BOTH",
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
}

export type SubCategory = {
  __typename?: "SubCategory";
  articles?: Maybe<Array<Maybe<ArticleConnection>>>;
  image?: Maybe<ImageReference>;
  seo?: Maybe<SeoInformation>;
  sport?: Maybe<Sport>;
  text?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SubCategoryArticlesArgs = {
  filter?: InputMaybe<ArticleSearchInput>;
};

export type SubCategoryInput = {
  slug: Scalars["String"];
  /** find sub category searches by the given sport. */
  sportName: Scalars["String"];
};

export type Subscription = {
  __typename?: "Subscription";
  HR_FeaturedTrackAndRaceMtpOrStatusUpdate?: Maybe<Array<Maybe<Hr_Race>>>;
  HR_NewRaceDateUpdate?: Maybe<Scalars["String"]>;
  HR_NewRaceDayUpdate?: Maybe<Array<Maybe<Hr_Race>>>;
  HR_NewRaceDayUpdateV2?: Maybe<Hr_NewRaceDayUpdate>;
  HR_NewTalentPickUpdate?: Maybe<Hr_TalentPickType>;
  HR_RaceChangesUpdate?: Maybe<Hr_Race>;
  /** this only exists because the one above was done with String! my bad... */
  HR_RaceMtpUpdate?: Maybe<Hr_Race>;
  HR_RaceProbablesUpdate?: Maybe<Hr_Race>;
  HR_RaceResultsUpdate?: Maybe<Hr_Race>;
  HR_RaceStatusUpdate?: Maybe<Hr_Race>;
  HR_RaceUpdate?: Maybe<Hr_Race>;
  HR_RaceUpdateByTvgRaceIds?: Maybe<Hr_Race>;
  HR_RacesByTrackCodes?: Maybe<Array<Maybe<Hr_Race>>>;
  HR_TrackRacesUpdate?: Maybe<Hr_Track>;
};

export type SubscriptionHr_FeaturedTrackAndRaceMtpOrStatusUpdateArgs = {
  profile: Scalars["String"];
  tvgRaceIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type SubscriptionHr_NewRaceDayUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionHr_NewRaceDayUpdateV2Args = {
  initWithCurrent?: InputMaybe<Scalars["Boolean"]>;
  profile?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionHr_NewTalentPickUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionHr_RaceChangesUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionHr_RaceMtpUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
  targetMtp?: InputMaybe<Scalars["Int"]>;
};

export type SubscriptionHr_RaceProbablesUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
  tvgRaceIds?: InputMaybe<Array<InputMaybe<Scalars["HR_Long"]>>>;
};

export type SubscriptionHr_RaceResultsUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionHr_RaceStatusUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
  targetStatusCode?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SubscriptionHr_RaceUpdateArgs = {
  number: Scalars["String"];
  profile?: InputMaybe<Scalars["String"]>;
  track: Scalars["String"];
};

export type SubscriptionHr_RaceUpdateByTvgRaceIdsArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  product?: InputMaybe<Scalars["String"]>;
  profile?: InputMaybe<Scalars["String"]>;
  sourcePage?: InputMaybe<Scalars["String"]>;
  tvgRaceIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type SubscriptionHr_RacesByTrackCodesArgs = {
  profile?: InputMaybe<Scalars["String"]>;
  trackCodes: Array<Scalars["String"]>;
};

export type SubscriptionHr_TrackRacesUpdateArgs = {
  profile?: InputMaybe<Scalars["String"]>;
  track: Scalars["String"];
};

export type Tournament_GameOddsDetails = {
  __typename?: "TOURNAMENT_GameOddsDetails";
  moneyLine?: Maybe<Tournament_MoneyLine>;
  spread?: Maybe<Tournament_SpreadTotal>;
  total?: Maybe<Tournament_SpreadTotal>;
};

export type Tournament_MoneyLine = {
  __typename?: "TOURNAMENT_MoneyLine";
  odd?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Tournament_NbaBroadcastLocale {
  Away = "AWAY",
  Home = "HOME",
  International = "INTERNATIONAL",
  National = "NATIONAL",
}

export type Tournament_NbaGameBroadcasts = {
  __typename?: "TOURNAMENT_NbaGameBroadcasts";
  awayBroadcastInfo?: Maybe<Array<Maybe<Tournament_NbaLocaleBroadcastInfo>>>;
  homeBroadcastInfo?: Maybe<Array<Maybe<Tournament_NbaLocaleBroadcastInfo>>>;
  internationalBroadcastInfo?: Maybe<
    Array<Maybe<Tournament_NbaLocaleBroadcastInfo>>
  >;
  nationalBroadcastInfo?: Maybe<
    Array<Maybe<Tournament_NbaLocaleBroadcastInfo>>
  >;
};

export type Tournament_NbaGameOdds = {
  __typename?: "TOURNAMENT_NbaGameOdds";
  away?: Maybe<Tournament_TeamGameOdds>;
  eventId?: Maybe<Scalars["Int"]>;
  home?: Maybe<Tournament_TeamGameOdds>;
  scheduledTime?: Maybe<Scalars["TOURNAMENT_Instant"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaIstGame = {
  __typename?: "TOURNAMENT_NbaIstGame";
  awayTeam?: Maybe<Tournament_NbaIstTeam>;
  broadcasts?: Maybe<Tournament_NbaGameBroadcasts>;
  gameDateTime?: Maybe<Scalars["TOURNAMENT_LocalDateTime"]>;
  gameOdds?: Maybe<Tournament_NbaGameOdds>;
  homeTeam?: Maybe<Tournament_NbaIstTeam>;
  status?: Maybe<Tournament_SrNbaGameStatus>;
  venue?: Maybe<Tournament_NbaIstVenue>;
};

export type Tournament_NbaIstGroupedPlayerOdds = {
  __typename?: "TOURNAMENT_NbaIstGroupedPlayerOdds";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Array<Maybe<Tournament_NbaIstPlayerOdd>>>;
};

export type Tournament_NbaIstGroupedTeamOdds = {
  __typename?: "TOURNAMENT_NbaIstGroupedTeamOdds";
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  odds?: Maybe<Array<Maybe<Tournament_NbaIstTeamOdd>>>;
};

export type Tournament_NbaIstPlayerOdd = {
  __typename?: "TOURNAMENT_NbaIstPlayerOdd";
  odds?: Maybe<Scalars["String"]>;
  /** Player Image For Futures */
  playerImage?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaIstPlayerProps = {
  __typename?: "TOURNAMENT_NbaIstPlayerProps";
  contentText?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<Tournament_NbaPlayerPropsEvent>>>;
  id?: Maybe<Scalars["String"]>;
  /** Player Image For Props */
  playerImage?: Maybe<Scalars["String"]>;
  playerName?: Maybe<Scalars["String"]>;
  playerPosition?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaIstTeam = {
  __typename?: "TOURNAMENT_NbaIstTeam";
  /** In-Season Tournament Group Name that the team is a part of - i.e. "EAST_GROUP_A" */
  istTeamGroupAlias?: Maybe<Scalars["String"]>;
  /** In-Season Tournament Group Name that the team is a part of - i.e. "East Group A" */
  istTeamGroupName?: Maybe<Scalars["String"]>;
  teamMeta?: Maybe<Tournament_NbaTeamMetadata>;
};

export type Tournament_NbaIstTeamOdd = {
  __typename?: "TOURNAMENT_NbaIstTeamOdd";
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  /** Player Image For Team Odds */
  teamImage?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaIstVenue = {
  __typename?: "TOURNAMENT_NbaIstVenue";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaLocaleBroadcastInfo = {
  __typename?: "TOURNAMENT_NbaLocaleBroadcastInfo";
  channel?: Maybe<Scalars["String"]>;
  locale?: Maybe<Tournament_NbaBroadcastLocale>;
  network?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaPlayerPropsEvent = {
  __typename?: "TOURNAMENT_NbaPlayerPropsEvent";
  date?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["Int"]>;
  eventName?: Maybe<Scalars["String"]>;
  markets?: Maybe<Array<Maybe<Tournament_NbaPlayerPropsMarket>>>;
};

export type Tournament_NbaPlayerPropsMarket = {
  __typename?: "TOURNAMENT_NbaPlayerPropsMarket";
  alternateMarket?: Maybe<Scalars["Boolean"]>;
  contentText?: Maybe<Scalars["String"]>;
  eligibleForSameGameParlay?: Maybe<Scalars["Boolean"]>;
  externalMarketId?: Maybe<Scalars["String"]>;
  marketId?: Maybe<Scalars["String"]>;
  marketName?: Maybe<Scalars["String"]>;
  marketType?: Maybe<Scalars["String"]>;
  marketTypeName?: Maybe<Scalars["String"]>;
  selections?: Maybe<Array<Maybe<Tournament_NbaPlayerPropsSelection>>>;
};

export type Tournament_NbaPlayerPropsSelection = {
  __typename?: "TOURNAMENT_NbaPlayerPropsSelection";
  betUrl?: Maybe<Scalars["String"]>;
  contentText?: Maybe<Scalars["String"]>;
  handicap?: Maybe<Scalars["Float"]>;
  odds?: Maybe<Scalars["String"]>;
  runnerName?: Maybe<Scalars["String"]>;
  selectionId?: Maybe<Scalars["Int"]>;
};

/** Metadata pertaining to NBA team */
export type Tournament_NbaTeamMetadata = {
  __typename?: "TOURNAMENT_NbaTeamMetadata";
  code?: Maybe<Scalars["String"]>;
  colors?: Maybe<Tournament_TeamColors>;
  conference?: Maybe<Scalars["String"]>;
  division?: Maybe<Scalars["String"]>;
  headCoach?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  logo?: Maybe<Tournament_TeamLogo>;
  market?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortName?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type Tournament_NbaTournamentGameDetails = {
  __typename?: "TOURNAMENT_NbaTournamentGameDetails";
  getAllTournamentGames?: Maybe<Array<Maybe<Tournament_NbaIstGame>>>;
  getUpcomingTournamentGames?: Maybe<Array<Maybe<Tournament_NbaIstGame>>>;
  season?: Maybe<Scalars["String"]>;
  seasonYear?: Maybe<Scalars["Int"]>;
};

export type Tournament_NbaTournamentGameDetailsGetAllTournamentGamesArgs = {
  teamGroupAlias?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type Tournament_NbaTournamentGameDetailsGetUpcomingTournamentGamesArgs =
  {
    teamGroupAlias?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  };

export type Tournament_SpreadTotal = {
  __typename?: "TOURNAMENT_SpreadTotal";
  odd?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum Tournament_SrNbaGameStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Complete = "COMPLETE",
  Created = "CREATED",
  Delayed = "DELAYED",
  Halftime = "HALFTIME",
  IfNecessary = "IF_NECESSARY",
  InProgress = "IN_PROGRESS",
  Postponed = "POSTPONED",
  Scheduled = "SCHEDULED",
  TimeTbd = "TIME_TBD",
  Unnecessary = "UNNECESSARY",
}

export type Tournament_TeamColors = {
  __typename?: "TOURNAMENT_TeamColors";
  primary?: Maybe<Scalars["String"]>;
  secondary?: Maybe<Scalars["String"]>;
};

export type Tournament_TeamGameOdds = {
  __typename?: "TOURNAMENT_TeamGameOdds";
  oddsDetails?: Maybe<Tournament_GameOddsDetails>;
  teamCode?: Maybe<Scalars["String"]>;
};

export type Tournament_TeamLogo = {
  __typename?: "TOURNAMENT_TeamLogo";
  bitmap?: Maybe<Scalars["String"]>;
  svg?: Maybe<Scalars["String"]>;
};

export type TvNetwork = {
  __typename?: "TVNetwork";
  name: Scalars["String"];
};

export type TaggedArticles = ArticleGroup & {
  __typename?: "TaggedArticles";
  articles?: Maybe<Array<Maybe<Article>>>;
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  tag?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

export type TaggedArticles_PlayerOddsTabs = {
  __typename?: "TaggedArticles_PlayerOddsTabs";
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  playerOddsTabs?: Maybe<PlayerOddsTabs>;
  taggedArticles?: Maybe<TaggedArticles>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type TaggedArticles_ScrollableOdds = {
  __typename?: "TaggedArticles_ScrollableOdds";
  cornerLinkText?: Maybe<Scalars["String"]>;
  cornerLinkUrl?: Maybe<Scalars["String"]>;
  scrollableOdds?: Maybe<ScrollableOdds>;
  taggedArticles?: Maybe<TaggedArticles>;
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type Team = SportParticipant &
  SportsEntity & {
    __typename?: "Team";
    abbreviation?: Maybe<Scalars["String"]>;
    drawCount: Scalars["Int"];
    identifier: Scalars["String"];
    image?: Maybe<ImageReference>;
    loseCount: Scalars["Int"];
    name: Scalars["String"];
    numberFireId?: Maybe<Scalars["Int"]>;
    primaryColor?: Maybe<Scalars["String"]>;
    relatedContent?: Maybe<Array<Maybe<ContentItem>>>;
    secondaryColor?: Maybe<Scalars["String"]>;
    sports?: Maybe<Array<Maybe<Sport>>>;
    sportsbookLink?: Maybe<Scalars["String"]>;
    /** Only available for NFL  */
    teamJerseyImage?: Maybe<ImageReferenceNullable>;
    type: Scalars["String"];
    winCount: Scalars["Int"];
  };

export type TeamInput = {
  /** The team's CMS id */
  id?: InputMaybe<Scalars["String"]>;
  /** The team's numberfire id */
  numberFireId?: InputMaybe<Scalars["Int"]>;
};

export enum Template {
  Template1 = "TEMPLATE1",
  Template2 = "TEMPLATE2",
  Template3 = "TEMPLATE3",
}

/** Tentpole event data */
export type TentpoleEvent = {
  __typename?: "TentpoleEvent";
  description: Scalars["String"];
  heroHeight?: Maybe<Scalars["String"]>;
  image: Scalars["String"];
  menu?: Maybe<Array<Maybe<ScrollMenu>>>;
  pageContent?: Maybe<Array<Maybe<PageContentWidget>>>;
  seo: SeoInformation;
  slug: Scalars["String"];
  sport: Sport;
  subHeading?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

/** Single Tentpole input */
export type TentpoleEventInput = {
  slug: Scalars["String"];
  sportSlug: Scalars["String"];
};

/** Tentpole events input */
export type TentpoleEventsInput = {
  sportSlug: Scalars["String"];
};

export type TextBanner = {
  __typename?: "TextBanner";
  icon?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  linkText?: Maybe<Scalars["String"]>;
  mainImage: Scalars["String"];
  target?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: Scalars["String"];
};

/** Format: ISO, i.e. YYYY-MM-DDTHH:mm:ssZ */
export type TimestampBoundaries = {
  /**
   * Inclusive
   *
   * The ending timestamp to search for content
   */
  end?: InputMaybe<Scalars["String"]>;
  /**
   * Inclusive
   *
   * The beginning timestamp to search for content
   */
  start?: InputMaybe<Scalars["String"]>;
};

export type Tool = {
  __typename?: "Tool";
  description?: Maybe<Scalars["String"]>;
  newWindow?: Maybe<Scalars["Boolean"]>;
  title: Scalars["String"];
  url: Scalars["String"];
};

/** Content Item type */
export enum Type {
  Article = "ARTICLE",
  Shortform = "SHORTFORM",
}

export type SbkPromoBanner = {
  __typename?: "sbkPromoBanner";
  desktopButtonText?: Maybe<Scalars["String"]>;
  desktopLink?: Maybe<Scalars["String"]>;
  hidePromoBanner?: Maybe<Scalars["Boolean"]>;
  mobileButtonText?: Maybe<Scalars["String"]>;
  mobileLink?: Maybe<Scalars["String"]>;
};

export type GetActiveFixturesQueryVariables = Exact<{
  input?: InputMaybe<FixtureSearchInput>;
}>;

export type GetActiveFixturesQuery = {
  __typename?: "Query";
  getActiveFixtures?: Array<{
    __typename?: "Fixture";
    id: string;
    scheduledStartTime?: string | null;
    currentGameTime?: string | null;
    scheduledEndTime?: string | null;
    gameStatus?: GameStatus | null;
    eventUrl?: string | null;
    tvNetwork?: { __typename?: "TVNetwork"; name: string } | null;
    sport?: { __typename?: "GenericSport"; name: string } | null;
    teams: Array<{
      __typename?: "FixtureTeamSummary";
      currentScore?: number | null;
      moneyLine?: number | null;
      pointSpread?: number | null;
      winProbability?: number | null;
      fixtureMoneyLine?: {
        __typename?: "FixtureMoneyLine";
        odds?: number | null;
        link?: string | null;
      } | null;
      fixtureSpread?: {
        __typename?: "FixtureSpread";
        odds?: number | null;
        value?: number | null;
        link?: string | null;
      } | null;
      fixtureTotal?: {
        __typename?: "FixtureTotal";
        odds?: number | null;
        value?: string | null;
        link?: string | null;
      } | null;
      team: {
        __typename?: "Team";
        abbreviation?: string | null;
        name: string;
        primaryColor?: string | null;
        winCount: number;
        loseCount: number;
        drawCount: number;
        sportsbookLink?: string | null;
        image?: { __typename?: "ImageReference"; url: string } | null;
      };
    } | null>;
  } | null> | null;
};

export type GetTentpoleEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTentpoleEventsQuery = {
  __typename?: "Query";
  getTentpoleEvents?: Array<{
    __typename?: "TentpoleEvent";
    slug: string;
    sport: { __typename?: "GenericSport"; slug: string };
  } | null> | null;
};

export type GetArticleBySlugQueryVariables = Exact<{
  slug: Scalars["String"];
  contentStatus?: InputMaybe<StatusSelector>;
  isPreview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetArticleBySlugQuery = {
  __typename?: "Query";
  getSingleArticle?: {
    __typename?: "Article";
    hideTopNewsletter?: boolean | null;
    hideBottomNewsletter?: boolean | null;
    firstPublishedAt?: string | null;
    publishedDate?: string | null;
    slug: string;
    id: string;
    title: string;
    body: string;
    description: string;
    dataskriveId?: string | null;
    seo: {
      __typename?: "SEOInformation";
      description?: string | null;
      noIndex?: boolean | null;
      canonicalUrl?: string | null;
      title?: string | null;
      image?: { __typename?: "ImageReference"; url: string } | null;
      openGraphTag?: {
        __typename?: "OpenGraphTag";
        title: string;
        image?: { __typename?: "ImageReference"; url: string } | null;
      } | null;
    };
    sport: {
      __typename?: "GenericSport";
      name: string;
      slug: string;
      logo?: {
        __typename?: "ImageReferenceNullable";
        url?: string | null;
      } | null;
    };
    thumbnailImageUrl?: { __typename?: "ImageReference"; url: string } | null;
    hero?: {
      __typename?: "ArticleHero";
      jwPlayerId?: string | null;
      jwPlayerPlayerId?: string | null;
      imageUrl?: { __typename?: "ImageReference"; url: string } | null;
    } | null;
    author: {
      __typename?: "ContentAuthor";
      id: string;
      name: string;
      thumbnailUrl?: string | null;
      socialMedia?: Array<{
        __typename?: "SocialMediaAccount";
        accountName: string;
        type: SocialMediaType;
        url: string;
      } | null> | null;
    };
    tags?: Array<{
      __typename?: "ContentTag";
      id: string;
      name?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type GetArticlesByDateRangeQueryVariables = Exact<{
  filter: ArticleSearchInput;
}>;

export type GetArticlesByDateRangeQuery = {
  __typename?: "Query";
  getArticles?: {
    __typename?: "ArticleList";
    articles: Array<{
      __typename?: "ArticleConnection";
      entity: {
        __typename?: "Article";
        lastPublishedAt?: string | null;
        slug: string;
        title: string;
        sport: { __typename?: "GenericSport"; name: string };
      };
    } | null>;
  } | null;
};

export type GetArticlesNoSportQueryVariables = Exact<{
  filter: ArticleSearchInput;
}>;

export type GetArticlesNoSportQuery = {
  __typename?: "Query";
  getArticles?: {
    __typename?: "ArticleList";
    pageInfo: { __typename?: "PageInfo"; cursor: string; hasNextPage: boolean };
    articles: Array<{
      __typename?: "ArticleConnection";
      entity: {
        __typename?: "Article";
        id: string;
        lastPublishedAt?: string | null;
        slug: string;
      };
    } | null>;
  } | null;
};

export type GetArticlesQueryVariables = Exact<{
  filter: ArticleSearchInput;
}>;

export type GetArticlesQuery = {
  __typename?: "Query";
  getArticles?: {
    __typename?: "ArticleList";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; cursor: string };
    articles: Array<{
      __typename?: "ArticleConnection";
      entity: {
        __typename?: "Article";
        firstPublishedAt?: string | null;
        publishedDate?: string | null;
        slug: string;
        description: string;
        id: string;
        title: string;
        seo: { __typename?: "SEOInformation"; description?: string | null };
        sport: {
          __typename?: "GenericSport";
          name: string;
          slug: string;
          logo?: {
            __typename?: "ImageReferenceNullable";
            url?: string | null;
          } | null;
        };
        thumbnailImageUrl?: {
          __typename?: "ImageReference";
          url: string;
        } | null;
        hero?: {
          __typename?: "ArticleHero";
          imageUrl?: { __typename?: "ImageReference"; url: string } | null;
        } | null;
        author: {
          __typename?: "ContentAuthor";
          name: string;
          id: string;
          socialMedia?: Array<{
            __typename?: "SocialMediaAccount";
            type: SocialMediaType;
            url: string;
          } | null> | null;
        };
      };
    } | null>;
  } | null;
};

export type GetAuthorsQueryVariables = Exact<{
  input?: InputMaybe<AuthorSearchInput>;
  contentType?: InputMaybe<Type>;
  sport?: InputMaybe<ContentSportsEnum>;
}>;

export type GetAuthorsQuery = {
  __typename?: "Query";
  getAuthors?: {
    __typename?: "ContentAuthorList";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; cursor: string };
    authors: Array<{
      __typename?: "ContentAuthorConnection";
      entity: {
        __typename?: "ContentAuthor";
        id: string;
        name: string;
        thumbnailUrl?: string | null;
        authoredContent?: Array<
          | {
              __typename?: "Article";
              slug: string;
              title: string;
              id: string;
              modifiedDate: string;
              sport: {
                __typename?: "GenericSport";
                name: string;
                slug: string;
              };
              thumbnailImageUrl?: {
                __typename?: "ImageReference";
                url: string;
              } | null;
            }
          | {
              __typename?: "ShortForm";
              slug: string;
              title: string;
              id: string;
              modifiedDate: string;
            }
          | null
        > | null;
        socialMedia?: Array<{
          __typename?: "SocialMediaAccount";
          url: string;
          accountName: string;
          type: SocialMediaType;
        } | null> | null;
      };
    } | null>;
  } | null;
};

export type GetContentFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetContentFiltersQuery = {
  __typename?: "Query";
  getContentFilters?: {
    __typename?: "ContentFilters";
    shortFormNewsType?: Array<{
      __typename?: "ShortFormNewsType";
      enum?: ShortFormNewsTypeEnum | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetFeaturedArticlesQueryVariables = Exact<{
  filter?: InputMaybe<FeaturedArticleInput>;
}>;

export type GetFeaturedArticlesQuery = {
  __typename?: "Query";
  getFeaturedArticles?: Array<{
    __typename?: "Article";
    firstPublishedAt?: string | null;
    publishedDate?: string | null;
    slug: string;
    id: string;
    title: string;
    description: string;
    seo: {
      __typename?: "SEOInformation";
      description?: string | null;
      noIndex?: boolean | null;
      canonicalUrl?: string | null;
      title?: string | null;
    };
    sport: {
      __typename?: "GenericSport";
      name: string;
      slug: string;
      logo?: {
        __typename?: "ImageReferenceNullable";
        url?: string | null;
      } | null;
    };
    thumbnailImageUrl?: { __typename?: "ImageReference"; url: string } | null;
    hero?: {
      __typename?: "ArticleHero";
      imageUrl?: { __typename?: "ImageReference"; url: string } | null;
    } | null;
    author: {
      __typename?: "ContentAuthor";
      name: string;
      id: string;
      socialMedia?: Array<{
        __typename?: "SocialMediaAccount";
        url: string;
        type: SocialMediaType;
      } | null> | null;
    };
  } | null> | null;
};

export type GetGolfEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGolfEventsQuery = {
  __typename?: "Query";
  getGolfEvents?: Array<{
    __typename?: "GolfEvent";
    id: string;
    name: string;
  } | null> | null;
};

export type GetHomepageQueryVariables = Exact<{
  isPreview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetHomepageQuery = {
  __typename?: "Query";
  getHomepage?: {
    __typename?: "HomepageContent";
    template: Template;
    tools?: Array<{
      __typename?: "Tool";
      description?: string | null;
      title: string;
      url: string;
      newWindow?: boolean | null;
    } | null> | null;
    selectedSports: Array<{
      __typename?: "SelectedSport";
      sport: {
        __typename?: "GenericSport";
        name: string;
        slug: string;
        hasPlayerUpdates?: boolean | null;
        logo?: {
          __typename?: "ImageReferenceNullable";
          url?: string | null;
        } | null;
      };
      tool?: {
        __typename?: "Tool";
        description?: string | null;
        newWindow?: boolean | null;
        title: string;
        url: string;
      } | null;
      featuredArticles?: Array<{
        __typename?: "Article";
        description: string;
        firstPublishedAt?: string | null;
        id: string;
        slug: string;
        subHeading?: string | null;
        title: string;
        author: {
          __typename?: "ContentAuthor";
          id: string;
          name: string;
          socialMedia?: Array<{
            __typename?: "SocialMediaAccount";
            type: SocialMediaType;
            url: string;
          } | null> | null;
        };
        hero?: {
          __typename?: "ArticleHero";
          imageUrl?: { __typename?: "ImageReference"; url: string } | null;
        } | null;
        seo: {
          __typename?: "SEOInformation";
          description?: string | null;
          noIndex?: boolean | null;
          canonicalUrl?: string | null;
          title?: string | null;
        };
        thumbnailImageUrl?: {
          __typename?: "ImageReference";
          url: string;
        } | null;
      } | null> | null;
    } | null>;
    articles: Array<{
      __typename?: "Article";
      description: string;
      firstPublishedAt?: string | null;
      id: string;
      slug: string;
      subHeading?: string | null;
      title: string;
      author: {
        __typename?: "ContentAuthor";
        id: string;
        name: string;
        socialMedia?: Array<{
          __typename?: "SocialMediaAccount";
          type: SocialMediaType;
          url: string;
        } | null> | null;
      };
      hero?: {
        __typename?: "ArticleHero";
        imageUrl?: { __typename?: "ImageReference"; url: string } | null;
      } | null;
      seo: {
        __typename?: "SEOInformation";
        description?: string | null;
        noIndex?: boolean | null;
        canonicalUrl?: string | null;
        title?: string | null;
      };
      thumbnailImageUrl?: { __typename?: "ImageReference"; url: string } | null;
      sport: {
        __typename?: "GenericSport";
        name: string;
        slug: string;
        logo?: {
          __typename?: "ImageReferenceNullable";
          url?: string | null;
        } | null;
      };
    } | null>;
  } | null;
};

export type GetHorseRacesByTrackQueryVariables = Exact<{
  profile: Scalars["String"];
  trackCode?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type GetHorseRacesByTrackQuery = {
  __typename?: "Query";
  HR_Races?: Array<{
    __typename?: "HR_Race";
    number?: string | null;
    raceDate?: string | null;
    postTime?: string | null;
    track?: {
      __typename?: "HR_Track";
      code?: string | null;
      name?: string | null;
      trackDataSource?: string | null;
    } | null;
    status?: {
      __typename?: "HR_StatusEnumeration";
      code?: string | null;
      name?: string | null;
    } | null;
    bettingInterests?: Array<{
      __typename?: "HR_BettingInterest";
      saddleColor?: string | null;
      numberColor?: string | null;
      favorite?: boolean | null;
      currentOdds?: {
        __typename: "HR_Odd";
        numerator?: number | null;
        denominator?: number | null;
      } | null;
      runners?: Array<{
        __typename?: "HR_Runner";
        runnerId?: string | null;
        horseName?: string | null;
        scratched?: boolean | null;
        trainer?: string | null;
        jockey?: string | null;
        handicapping?: {
          __typename: "HR_Handicapping";
          freePick?: {
            __typename: "HR_FreePick";
            number?: number | null;
          } | null;
        } | null;
        timeform?: {
          __typename?: "HR_RunnerTimeform";
          silkUrl?: string | null;
          silkUrlSvg?: string | null;
          freePick?: {
            __typename?: "HR_TimeformFreePick";
            number?: number | null;
            info?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetUpcomingRacesQueryVariables = Exact<{
  profile: Scalars["String"];
  current: Scalars["Int"];
  results: Scalars["Int"];
}>;

export type GetUpcomingRacesQuery = {
  __typename?: "Query";
  HR_Races?: Array<{
    __typename: "HR_Race";
    number?: string | null;
    numRunners?: string | null;
    purse?: number | null;
    distance?: string | null;
    raceDate?: string | null;
    postTime?: string | null;
    type?: {
      __typename?: "HR_ShortNameEnumeration";
      name?: string | null;
    } | null;
    raceClass?: {
      __typename?: "HR_ShortNameEnumeration";
      name?: string | null;
    } | null;
    surface?: {
      __typename?: "HR_SurfaceEnumeration";
      name?: string | null;
      defaultCondition?: string | null;
    } | null;
    track?: {
      __typename: "HR_Track";
      code?: string | null;
      name?: string | null;
      trackDataSource?: string | null;
    } | null;
    status?: {
      __typename: "HR_StatusEnumeration";
      code?: string | null;
      name?: string | null;
    } | null;
    bettingInterests?: Array<{
      __typename: "HR_BettingInterest";
      saddleColor?: string | null;
      numberColor?: string | null;
      favorite?: boolean | null;
      currentOdds?: {
        __typename: "HR_Odd";
        numerator?: number | null;
        denominator?: number | null;
      } | null;
      runners?: Array<{
        __typename: "HR_Runner";
        runnerId?: string | null;
        horseName?: string | null;
        scratched?: boolean | null;
        trainer?: string | null;
        jockey?: string | null;
        handicapping?: {
          __typename: "HR_Handicapping";
          freePick?: {
            __typename: "HR_FreePick";
            number?: number | null;
          } | null;
        } | null;
        timeform?: {
          __typename: "HR_RunnerTimeform";
          silkUrl?: string | null;
          silkUrlSvg?: string | null;
          freePick?: {
            __typename: "HR_TimeformFreePick";
            number?: number | null;
            info?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetHorseTrackListQueryVariables = Exact<{
  profile: Scalars["String"];
}>;

export type GetHorseTrackListQuery = {
  __typename?: "Query";
  HS_PastTracks?: Array<{
    __typename?: "HR_PastTrack";
    code?: string | null;
    name?: string | null;
    location?: {
      __typename?: "HR_PastTrackLocation";
      state?: string | null;
      country?: string | null;
    } | null;
  } | null> | null;
};

export type GetMenusQueryVariables = Exact<{
  isPreview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetMenusQuery = {
  __typename?: "Query";
  getMenus?: Array<{
    __typename?: "Menu";
    iconUrl?: string | null;
    name: string;
    menuItems: Array<{
      __typename?: "NestedLink";
      behaviour?: LinkBehaviour | null;
      text: string;
      url: string;
      iconUrl?: string | null;
      imageUrl?: string | null;
      children?: Array<{
        __typename?: "NestedLink";
        behaviour?: LinkBehaviour | null;
        text: string;
        url: string;
        imageUrl?: string | null;
        iconUrl?: string | null;
        children?: Array<{
          __typename?: "NestedLink";
          behaviour?: LinkBehaviour | null;
          text: string;
          url: string;
          imageUrl?: string | null;
          iconUrl?: string | null;
          children?: Array<{
            __typename?: "NestedLink";
            behaviour?: LinkBehaviour | null;
            text: string;
            url: string;
            iconUrl?: string | null;
            imageUrl?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null>;
  } | null> | null;
};

export type FuturesQueryVariables = Exact<{
  marketType?: InputMaybe<Scalars["String"]>;
}>;

export type FuturesQuery = {
  __typename?: "Query";
  ctp_Nba?: {
    __typename?: "CTP_NBA";
    futures?: Array<{
      __typename?: "CTP_NbaTeamFutureOdd";
      futureOdds?: Array<{
        __typename?: "CTP_NbaFutureOdd";
        description?: string | null;
        marketType?: string | null;
        odds?: string | null;
        text?: string | null;
        url?: string | null;
      } | null> | null;
      teamMeta?: {
        __typename?: "CTP_NbaTeamMetadata";
        code?: string | null;
        conference?: string | null;
        division?: string | null;
        headCoach?: string | null;
        icon?: string | null;
        market?: string | null;
        name?: string | null;
        shortName?: string | null;
        slug?: string | null;
        colors?: {
          __typename?: "CTP_TeamColors";
          primary?: string | null;
          secondary?: string | null;
        } | null;
        logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Tournament_GetTournamentPlayerFuturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Tournament_GetTournamentPlayerFuturesQuery = {
  __typename?: "Query";
  tournament_GetTournamentPlayerFutures?: Array<{
    __typename?: "TOURNAMENT_NbaIstGroupedPlayerOdds";
    marketName?: string | null;
    marketType?: string | null;
    odds?: Array<{
      __typename?: "TOURNAMENT_NbaIstPlayerOdd";
      odds?: string | null;
      playerImage?: string | null;
      runnerName?: string | null;
      url?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetNcaabNextGameMarketOddsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNcaabNextGameMarketOddsQueryQuery = {
  __typename?: "Query";
  ctp_Ncaamb?: {
    __typename?: "CTP_NCAAMB";
    teams?: Array<{
      __typename?: "CTP_NcaaMbTeam";
      meta?: {
        __typename?: "CTP_NcaaMbTeamMetadata";
        code?: string | null;
        shortName?: string | null;
        logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
      } | null;
      prevNextGame?: {
        __typename?: "CTP_NcaaMbPrevNextGame";
        nextGames?: Array<{
          __typename?: "CTP_NcaaMbUpcomingTeamGame";
          gameTitle?: string | null;
          homeGame?: boolean | null;
          opponentTeamMeta?: {
            __typename?: "CTP_NcaaMbTeamMetadata";
            code?: string | null;
            shortName?: string | null;
            logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
          } | null;
          popularGameBets?: Array<{
            __typename?: "CTP_BetInfo";
            marketType?: string | null;
            description?: string | null;
            selectionName?: string | null;
            odds?: string | null;
            line?: number | null;
            url?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetNcaabTeamFuturesQueryQueryVariables = Exact<{
  marketType?: InputMaybe<Scalars["String"]>;
}>;

export type GetNcaabTeamFuturesQueryQuery = {
  __typename?: "Query";
  ctp_Ncaamb?: {
    __typename?: "CTP_NCAAMB";
    futures?: Array<{
      __typename?: "CTP_NcaaMbTeamFutureOdd";
      futureOdds?: Array<{
        __typename?: "CTP_NcaaMbFutureOdd";
        description?: string | null;
        marketType?: string | null;
        odds?: string | null;
        text?: string | null;
        url?: string | null;
      } | null> | null;
      teamMeta?: {
        __typename?: "CTP_NcaaMbTeamMetadata";
        icon?: string | null;
        code?: string | null;
        shortName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetNcaabUpcomingGamesQueryQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
}>;

export type GetNcaabUpcomingGamesQueryQuery = {
  __typename?: "Query";
  ctp_Ncaamb?: {
    __typename?: "CTP_NCAAMB";
    schedule?: Array<{
      __typename?: "CTP_NcaaMbLeagueSchedule";
      seasonType?: Ctp_SrNcaaMbSeasonType | null;
      title?: string | null;
      games?: Array<{
        __typename?: "CTP_NcaaMbGame";
        scheduledTime?: any | null;
        venueName?: string | null;
        status?: Ctp_SrNcaaMbGameStatus | null;
        broadcasts?: {
          __typename?: "CTP_NcaaMbGameBroadcasts";
          tvBroadcastInfo?: Array<{
            __typename?: "CTP_NcaaMbMediaTypeBroadcastInfo";
            network?: string | null;
          } | null> | null;
        } | null;
        homeTeamMeta?: {
          __typename?: "CTP_NcaaMbTeamMetadata";
          code?: string | null;
          name?: string | null;
          logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
        } | null;
        awayTeamMeta?: {
          __typename?: "CTP_NcaaMbTeamMetadata";
          icon?: string | null;
          code?: string | null;
          name?: string | null;
          logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
        } | null;
        odds?: {
          __typename?: "CTP_NcaaMbGameOdds";
          url?: string | null;
          home?: {
            __typename?: "CTP_TeamGameOdds";
            oddsDetails?: {
              __typename?: "CTP_GameOddsDetails";
              moneyLine?: {
                __typename?: "CTP_MoneyLine";
                odd?: string | null;
                url?: string | null;
              } | null;
              spread?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
              total?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
            } | null;
          } | null;
          away?: {
            __typename?: "CTP_TeamGameOdds";
            oddsDetails?: {
              __typename?: "CTP_GameOddsDetails";
              moneyLine?: {
                __typename?: "CTP_MoneyLine";
                odd?: string | null;
                url?: string | null;
              } | null;
              spread?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
              total?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
    teams?: Array<{
      __typename?: "CTP_NcaaMbTeam";
      meta?: {
        __typename?: "CTP_NcaaMbTeamMetadata";
        name?: string | null;
      } | null;
      record?: {
        __typename?: "CTP_NcaaMbTeamRecord";
        leagueRecord?: {
          __typename?: "CTP_WinLoseRecord";
          wins?: number | null;
          losses?: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpcomingGamesComponentQueryQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["CTP_Instant"]>;
  endDate?: InputMaybe<Scalars["CTP_Instant"]>;
}>;

export type UpcomingGamesComponentQueryQuery = {
  __typename?: "Query";
  ctp_Nfl?: {
    __typename?: "CTP_NFL";
    schedule?: Array<{
      __typename?: "CTP_NflLeagueSchedule";
      seasonType?: Ctp_SrSeasonType | null;
      title?: string | null;
      games?: Array<{
        __typename?: "CTP_NflGame";
        scheduledTime?: any | null;
        venueName?: string | null;
        gameTitle?: string | null;
        status?: Ctp_SrGameStatus | null;
        broadcastInfo?: {
          __typename?: "CTP_NflBroadcastInfo";
          network?: string | null;
        } | null;
        homeTeamMeta?: {
          __typename?: "CTP_NflTeamMetadata";
          code?: string | null;
          name?: string | null;
          shortName?: string | null;
          colors?: {
            __typename?: "CTP_TeamColors";
            primary?: string | null;
            secondary?: string | null;
          } | null;
          logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
        } | null;
        awayTeamMeta?: {
          __typename?: "CTP_NflTeamMetadata";
          icon?: string | null;
          code?: string | null;
          name?: string | null;
          shortName?: string | null;
          colors?: {
            __typename?: "CTP_TeamColors";
            primary?: string | null;
            secondary?: string | null;
          } | null;
          logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
        } | null;
        odds?: {
          __typename?: "CTP_NflGameOdds";
          url?: string | null;
          home?: {
            __typename?: "CTP_TeamGameOdds";
            oddsDetails?: {
              __typename?: "CTP_GameOddsDetails";
              moneyLine?: {
                __typename?: "CTP_MoneyLine";
                odd?: string | null;
                url?: string | null;
              } | null;
              spread?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
              total?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
            } | null;
          } | null;
          away?: {
            __typename?: "CTP_TeamGameOdds";
            oddsDetails?: {
              __typename?: "CTP_GameOddsDetails";
              moneyLine?: {
                __typename?: "CTP_MoneyLine";
                odd?: string | null;
                url?: string | null;
              } | null;
              spread?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
              total?: {
                __typename?: "CTP_SpreadTotal";
                odd?: string | null;
                url?: string | null;
                value?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type NflPlayerPropsComponentQueryQueryVariables = Exact<{
  seasonStatisticsFilter?: InputMaybe<Ctp_LeaguePlayerStatisticsFilterInput>;
}>;

export type NflPlayerPropsComponentQueryQuery = {
  __typename?: "Query";
  ctp_Nfl?: {
    __typename?: "CTP_NFL";
    teams?: Array<{
      __typename?: "CTP_NflTeam";
      meta?: {
        __typename?: "CTP_NflTeamMetadata";
        code?: string | null;
        shortName?: string | null;
        logo?: { __typename?: "CTP_TeamLogo"; svg?: string | null } | null;
      } | null;
    } | null> | null;
    starPlayers?: Array<{
      __typename?: "CTP_NflLeaguePlayer";
      teamCode?: string | null;
      playerProps?: {
        __typename?: "CTP_NflPlayerProps";
        events?: Array<{
          __typename?: "CTP_NflPlayerPropsEvent";
          eventName?: string | null;
          markets?: Array<{
            __typename?: "CTP_NflPlayerPropsMarket";
            marketId?: string | null;
            marketName?: string | null;
            marketType?: string | null;
            eligibleForSameGameParlay?: boolean | null;
            selections?: Array<{
              __typename?: "CTP_NflPlayerPropsSelection";
              odds?: string | null;
              betUrl?: string | null;
              handicap?: number | null;
              runnerName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
        playerJersey?: {
          __typename?: "CTP_PlayerPropsJersey";
          png?: string | null;
          svg?: string | null;
          background?: string | null;
        } | null;
      } | null;
      playerInfo?: {
        __typename?: "CTP_NflPlayerInfo";
        name?: string | null;
        position?: string | null;
      } | null;
      statistics?: {
        __typename?: "CTP_LeaguePlayerStatisticsContext_NflLeaguePlayer";
        seasons?: Array<{
          __typename?: "CTP_NflPlayerSeasonStatisticsBySeason";
          seasonInfo?: {
            __typename?: "CTP_SeasonDetails";
            seasonYear?: number | null;
          } | null;
          seasonStatistics?: {
            __typename?: "CTP_NflPlayerSeasonStatistics";
            passing?: {
              __typename?: "CTP_NflPassingStatistics_CumulativeAverageStatistics_Integer_RatioStatistic";
              yards?: {
                __typename?: "CTP_CumulativeAverageStatistics_Integer";
                average?: any | null;
              } | null;
              touchdowns?: {
                __typename?: "CTP_CumulativeAverageStatistics_Integer";
                average?: any | null;
              } | null;
            } | null;
            rushing?: {
              __typename?: "CTP_NflRushingStatistics_CumulativeAverageStatistics_Integer";
              yards?: {
                __typename?: "CTP_CumulativeAverageStatistics_Integer";
                average?: any | null;
              } | null;
            } | null;
            receiving?: {
              __typename?: "CTP_NflReceivingStatistics_CumulativeAverageStatistics_Integer";
              yards?: {
                __typename?: "CTP_CumulativeAverageStatistics_Integer";
                average?: any | null;
              } | null;
              receptions?: {
                __typename?: "CTP_CumulativeAverageStatistics_Integer";
                average?: any | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SuperBowlOddsComponentQueryQueryVariables = Exact<{
  marketType?: InputMaybe<Scalars["String"]>;
}>;

export type SuperBowlOddsComponentQueryQuery = {
  __typename?: "Query";
  ctp_Nfl?: {
    __typename?: "CTP_NFL";
    futures?: Array<{
      __typename?: "CTP_NflTeamFutureOdd";
      futureOdds?: Array<{
        __typename?: "CTP_NflFutureOdd";
        description?: string | null;
        marketType?: string | null;
        odds?: string | null;
        text?: string | null;
        url?: string | null;
      } | null> | null;
      teamMeta?: {
        __typename?: "CTP_NflTeamMetadata";
        icon?: string | null;
        code?: string | null;
        shortName?: string | null;
      } | null;
    } | null> | null;
    featuredEvents?: Array<{
      __typename?: "CTP_NflEvent";
      eventId?: number | null;
      name?: string | null;
      openDate?: any | null;
      markets?: Array<{
        __typename?: "CTP_NflMarket";
        marketName?: string | null;
        marketType?: string | null;
        markets?: Array<{
          __typename?: "CTP_NflSelection";
          odds?: string | null;
          runnerName?: string | null;
          selectionId?: number | null;
          url?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetPgaPlayerOddsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPgaPlayerOddsQueryQuery = {
  __typename?: "Query";
  ctp_Golf?: {
    __typename?: "CTP_Golf";
    featuredEvents?: Array<{
      __typename?: "CTP_GolfEvent";
      eventId?: number | null;
      name?: string | null;
      openDate?: any | null;
      markets?: Array<{
        __typename?: "CTP_GolfMarket";
        marketName?: string | null;
        marketType?: string | null;
        markets?: Array<{
          __typename?: "CTP_GolfSelection";
          odds?: string | null;
          runnerName?: string | null;
          selectionId?: number | null;
          url?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetPlayerNewsHomeQueryVariables = Exact<{
  filter: ShortFormSearchInput;
}>;

export type GetPlayerNewsHomeQuery = {
  __typename?: "Query";
  getShortForms?: {
    __typename?: "ShortFormList";
    shortForms: Array<{
      __typename?: "ShortFormConnection";
      entity: {
        __typename?: "ShortForm";
        id: string;
        title: string;
        publishedDate?: string | null;
        slug: string;
        newsType?: {
          __typename?: "ShortFormNewsType";
          name?: string | null;
          enum?: ShortFormNewsTypeEnum | null;
        } | null;
        sport: { __typename?: "GenericSport"; name: string; slug: string };
        primaryRef:
          | {
              __typename?: "Player";
              name: string;
              identifier: string;
              type: string;
              image?: { __typename?: "ImageReference"; url: string } | null;
              team?: {
                __typename?: "Team";
                name: string;
                image?: { __typename?: "ImageReference"; url: string } | null;
                teamJerseyImage?: {
                  __typename?: "ImageReferenceNullable";
                  url?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: "Team";
              name: string;
              identifier: string;
              type: string;
              image?: { __typename?: "ImageReference"; url: string } | null;
              teamJerseyImage?: {
                __typename?: "ImageReferenceNullable";
                url?: string | null;
              } | null;
            };
        attribution?: {
          __typename?: "Attribution";
          name?: string | null;
          link?: string | null;
        } | null;
      };
    } | null>;
  } | null;
};

export type GetPlayerNewsSportsQueryVariables = Exact<{
  isPreview?: InputMaybe<Scalars["Boolean"]>;
  featured?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetPlayerNewsSportsQuery = {
  __typename?: "Query";
  getSupportedSports?: Array<{
    __typename?: "GenericSport";
    name: string;
    hasLiveScoring?: boolean | null;
    hasPlayerUpdates?: boolean | null;
    showAuthors?: boolean | null;
    contentSport?: ContentSportsEnum | null;
    slug: string;
    seo: {
      __typename?: "SEOInformation";
      description?: string | null;
      canonicalUrl?: string | null;
      title?: string | null;
      noIndex?: boolean | null;
      image?: { __typename?: "ImageReference"; url: string } | null;
    };
  } | null> | null;
};

export type GetProjectionTableMetadataQueryVariables = Exact<{
  input?: InputMaybe<ProjectionTableMetadataInput>;
}>;

export type GetProjectionTableMetadataQuery = {
  __typename?: "Query";
  getProjectionTableMetadata?: Array<{
    __typename?: "ProjectionTableMetadata";
    name: string;
    slug: string;
    id: string;
    sortOrder?: number | null;
    sport: { __typename?: "GenericSport"; name: string; slug: string };
    attributes?: Array<{
      __typename?: "Attribute";
      key: AttributeKey;
      value: string;
    } | null> | null;
    positionFilter: Array<{
      __typename?: "PositionFilter";
      label: string;
      slug?: string | null;
      value: string;
      positionGroup?: Array<string | null> | null;
      isDefaultPosition?: boolean | null;
    } | null>;
    columns?: Array<{
      __typename?: "ColumnGroups";
      label: string;
      positions: Array<string | null>;
      columnHeaders: Array<{
        __typename?: "ColumnHeaders";
        label?: string | null;
        columns: Array<{
          __typename?: "Column";
          label: string;
          value: string;
          description: string;
        } | null>;
      } | null>;
    } | null> | null;
  } | null> | null;
};

export type GetProjectionsQueryVariables = Exact<{
  input: ProjectionsInput;
}>;

export type GetProjectionsQuery = {
  __typename?: "Query";
  getProjections?: Array<
    | {
        __typename?: "GolfPlayer";
        fantasy?: number | null;
        salary?: string | null;
        value?: string | null;
        score?: number | null;
        madeCut?: string | null;
        first?: string | null;
        topFive?: string | null;
        topTen?: string | null;
        topTwentyFive?: string | null;
        eagles?: number | null;
        birdies?: number | null;
        pars?: number | null;
        bogeys?: number | null;
        doubleBogeys?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
      }
    | {
        __typename?: "MlbBatter";
        salary?: string | null;
        value?: string | null;
        plateAppearances?: number | null;
        runs?: number | null;
        hits?: number | null;
        singles?: number | null;
        doubles?: number | null;
        triples?: number | null;
        homeRuns?: number | null;
        runsBattedIn?: number | null;
        stolenBases?: number | null;
        caughtStealing?: number | null;
        walks?: number | null;
        strikeouts?: number | null;
        battingAverage?: number | null;
        onBasePercentage?: number | null;
        sluggingPercentage?: number | null;
        onBasePlusSlugging?: number | null;
        fantasy?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "MlbPitcher";
        winsLosses?: string | null;
        salary?: string | null;
        value?: string | null;
        wins?: number | null;
        losses?: number | null;
        earnedRunsAvg?: number | null;
        gamesStarted?: number | null;
        saves?: number | null;
        inningsPitched?: number | null;
        hits?: number | null;
        runs?: number | null;
        earnedRuns?: number | null;
        homeRuns?: number | null;
        walks?: number | null;
        strikeouts?: number | null;
        walksPlusHitsPerInningsPitched?: number | null;
        gamesPlayed?: number | null;
        fantasy?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NbaPlayer";
        salary?: string | null;
        value?: string | null;
        minutes?: number | null;
        fieldGoalsMade?: number | null;
        fieldGoalsAttempted?: number | null;
        threePointsMade?: number | null;
        threePointsAttempted?: number | null;
        freeThrowsMade?: number | null;
        freeThrowsAttempted?: number | null;
        assists?: number | null;
        steals?: number | null;
        blocks?: number | null;
        turnovers?: number | null;
        points?: number | null;
        rebounds?: number | null;
        gamesPlayed?: number | null;
        fieldGoalShootingPercentage?: number | null;
        threePointsShootingPercentage?: number | null;
        freeThrowShootingPercentage?: number | null;
        fantasy?: number | null;
        positionRank?: number | null;
        overallRank?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NflDefensePlayer";
        tackles?: number | null;
        sacks?: number | null;
        interceptions?: number | null;
        touchdowns?: number | null;
        passesDefended?: number | null;
        fumblesRecovered?: number | null;
        opponentOffensiveRank?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NflDefenseSt";
        salary?: string | null;
        value?: string | null;
        pointsAllowed?: number | null;
        yardsAllowed?: number | null;
        sacks?: number | null;
        interceptions?: number | null;
        fumblesRecovered?: number | null;
        touchdowns?: number | null;
        fantasy?: number | null;
        positionRank?: number | null;
        opponentOffensiveRank?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NflKicker";
        salary?: string | null;
        value?: string | null;
        extraPointsAttempted?: number | null;
        extraPointsMade?: number | null;
        fieldGoalsAttempted?: number | null;
        fieldGoalsMade?: number | null;
        fieldGoalsMade0To19?: number | null;
        fieldGoalsMade20To29?: number | null;
        fieldGoalsMade30To39?: number | null;
        fieldGoalsMade40To49?: number | null;
        fieldGoalsMade50Plus?: number | null;
        fantasy?: number | null;
        positionRank?: number | null;
        opponentDefensiveRank?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NflSkill";
        salary?: string | null;
        value?: string | null;
        completionsAttempts?: string | null;
        passingYards?: number | null;
        passingTouchdowns?: number | null;
        interceptionsThrown?: number | null;
        rushingAttempts?: number | null;
        rushingYards?: number | null;
        rushingTouchdowns?: number | null;
        receptions?: number | null;
        targets?: number | null;
        receivingYards?: number | null;
        receivingTouchdowns?: number | null;
        fantasy?: number | null;
        positionRank?: number | null;
        overallRank?: number | null;
        opponentDefensiveRank?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NhlGoalie";
        minutesPlayed?: number | null;
        salary?: string | null;
        value?: string | null;
        goalsAgainst?: number | null;
        shotsAgainst?: number | null;
        saves?: number | null;
        shutouts?: number | null;
        wins?: number | null;
        losses?: number | null;
        savePercent?: number | null;
        timeOnIce?: number | null;
        tiesPlusOvertimeOrShootoutLosses?: number | null;
        fantasy?: number | null;
        gamesPlayed?: number | null;
        goalsAgainstAvg?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "NhlSkater";
        minutesPlayed?: number | null;
        salary?: string | null;
        value?: string | null;
        shots?: number | null;
        goals?: number | null;
        assists?: number | null;
        points?: number | null;
        powerPlayGoals?: number | null;
        powerPlayAssists?: number | null;
        plusMinus?: number | null;
        blockedShots?: number | null;
        penaltiesInMinutes?: number | null;
        fantasy?: number | null;
        timeOnIce?: number | null;
        avgTimeOnIce?: string | null;
        gamesPlayed?: number | null;
        player: {
          __typename?: "ProjectionsPlayer";
          numberFireId: number;
          name: string;
          position?: string | null;
          playerPageUrl?: string | null;
          imageUrl?: string | null;
          handedness?: string | null;
        };
        team?: {
          __typename?: "ProjectionsTeam";
          numberFireId: number;
          name: string;
          imageUrl?: string | null;
          abbreviation?: string | null;
        } | null;
        gameInfo?: {
          __typename?: "ProjectionsGameInfo";
          gameTime?: string | null;
          homeTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
          awayTeam?: {
            __typename?: "ProjectionsTeam";
            numberFireId: number;
            name: string;
            imageUrl?: string | null;
            abbreviation?: string | null;
          } | null;
        } | null;
      }
    | null
  > | null;
};

export type GetPromotionsQueryVariables = Exact<{
  input?: InputMaybe<PromotionsInput>;
}>;

export type GetPromotionsQuery = {
  __typename?: "Query";
  getPromotions?: Array<{
    __typename?: "Promotion";
    title?: string | null;
    image?: string | null;
    detailsUrl?: string | null;
    ctaUrl?: string | null;
    ctaText?: string | null;
    promoCode?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetRaceByTrackQueryVariables = Exact<{
  profile: Scalars["String"];
  trackCode: Scalars["String"];
  raceNumber: Scalars["Int"];
}>;

export type GetRaceByTrackQuery = {
  __typename?: "Query";
  HR_Track?: {
    __typename?: "HR_Track";
    code?: string | null;
    name?: string | null;
    trackDataSource?: string | null;
    races?: Array<{
      __typename?: "HR_Race";
      number?: string | null;
      postTime?: string | null;
      numRunners?: string | null;
      distance?: string | null;
      purse?: number | null;
      status?: {
        __typename?: "HR_StatusEnumeration";
        code?: string | null;
      } | null;
      type?: {
        __typename?: "HR_ShortNameEnumeration";
        name?: string | null;
      } | null;
      raceClass?: {
        __typename?: "HR_ShortNameEnumeration";
        name?: string | null;
      } | null;
      surface?: {
        __typename?: "HR_SurfaceEnumeration";
        name?: string | null;
        defaultCondition?: string | null;
      } | null;
      timeform?: {
        __typename?: "HR_RaceTimeform";
        analystVerdict?: string | null;
      } | null;
      bettingInterests?: Array<{
        __typename?: "HR_BettingInterest";
        saddleColor?: string | null;
        numberColor?: string | null;
        favorite?: boolean | null;
        currentOdds?: {
          __typename?: "HR_Odd";
          numerator?: number | null;
          denominator?: number | null;
        } | null;
        runners?: Array<{
          __typename?: "HR_Runner";
          jockey?: string | null;
          trainer?: string | null;
          runnerId?: string | null;
          horseName?: string | null;
          scratched?: boolean | null;
          timeform?: {
            __typename?: "HR_RunnerTimeform";
            silkUrl?: string | null;
            silkUrlSvg?: string | null;
            freePick?: {
              __typename?: "HR_TimeformFreePick";
              number?: number | null;
              info?: string | null;
            } | null;
          } | null;
          handicapping?: {
            __typename?: "HR_Handicapping";
            freePick?: {
              __typename?: "HR_FreePick";
              number?: number | null;
              info?: string | null;
            } | null;
            snapshot?: {
              __typename?: "HR_Snapshot";
              powerRating?: number | null;
              normalisedPowerRating?: number | null;
            } | null;
            speedAndClass?: {
              __typename?: "HR_SpeedAndClass";
              highSpeed?: number | null;
              normalisedSpeedRating?: number | null;
            } | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetRedirectQueryVariables = Exact<{
  input: RedirectSearchInput;
}>;

export type GetRedirectQuery = {
  __typename?: "Query";
  getRedirect?: {
    __typename?: "Redirect";
    fromSlug: string;
    toSlug?: string | null;
    isPermanent?: boolean | null;
  } | null;
};

export type GetRunnerStatsQueryVariables = Exact<{
  runnerName: Scalars["String"];
  profile: Scalars["String"];
}>;

export type GetRunnerStatsQuery = {
  __typename?: "Query";
  HS_RunnerStats?: {
    __typename?: "HR_RunnerStats";
    numberRaces?: number | null;
    numberRacesWon?: number | null;
    numberRacesTop3?: number | null;
  } | null;
};

export type GetShortFormQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type GetShortFormQuery = {
  __typename?: "Query";
  getShortForm?: {
    __typename?: "ShortForm";
    id: string;
    slug: string;
    title: string;
    fact: string;
    analysis: string;
    quant?: string | null;
    firstPublishedAt?: string | null;
    lastPublishedAt?: string | null;
    description: string;
    seo: {
      __typename?: "SEOInformation";
      title?: string | null;
      description?: string | null;
    };
    newsType?: {
      __typename?: "ShortFormNewsType";
      enum?: ShortFormNewsTypeEnum | null;
      name?: string | null;
    } | null;
    sport: {
      __typename?: "GenericSport";
      id: string;
      slug: string;
      name: string;
      contentSport?: ContentSportsEnum | null;
      logo?: {
        __typename?: "ImageReferenceNullable";
        url?: string | null;
      } | null;
    };
    primaryRef:
      | {
          __typename?: "Player";
          identifier: string;
          name: string;
          position?: string | null;
          number?: number | null;
          image?: { __typename?: "ImageReference"; url: string } | null;
          team?: {
            __typename?: "Team";
            numberFireId?: number | null;
            name: string;
            sportsbookLink?: string | null;
            abbreviation?: string | null;
            teamJerseyImage?: {
              __typename?: "ImageReferenceNullable";
              url?: string | null;
            } | null;
            image?: { __typename?: "ImageReference"; url: string } | null;
          } | null;
        }
      | {
          __typename?: "Team";
          name: string;
          abbreviation?: string | null;
          numberFireId?: number | null;
          sportsbookLink?: string | null;
          teamJerseyImage?: {
            __typename?: "ImageReferenceNullable";
            url?: string | null;
          } | null;
          image?: { __typename?: "ImageReference"; url: string } | null;
        };
    author: {
      __typename?: "ContentAuthor";
      id: string;
      name: string;
      thumbnailUrl?: string | null;
      socialMedia?: Array<{
        __typename?: "SocialMediaAccount";
        type: SocialMediaType;
        accountName: string;
        url: string;
      } | null> | null;
    };
    attribution?: {
      __typename?: "Attribution";
      name?: string | null;
      link?: string | null;
    } | null;
  } | null;
};

export type GetPlayerNewsQueryQueryVariables = Exact<{
  filter: ShortFormSearchInput;
}>;

export type GetPlayerNewsQueryQuery = {
  __typename?: "Query";
  getShortForms?: {
    __typename?: "ShortFormList";
    pageInfo: { __typename?: "PageInfo"; cursor: string; hasNextPage: boolean };
    shortForms: Array<{
      __typename?: "ShortFormConnection";
      cursor: string;
      entity: {
        __typename?: "ShortForm";
        id: string;
        slug: string;
        title: string;
        fact: string;
        analysis: string;
        quant?: string | null;
        firstPublishedAt?: string | null;
        lastPublishedAt?: string | null;
        description: string;
        newsType?: {
          __typename?: "ShortFormNewsType";
          name?: string | null;
          enum?: ShortFormNewsTypeEnum | null;
        } | null;
        sport: {
          __typename?: "GenericSport";
          id: string;
          name: string;
          slug: string;
          contentSport?: ContentSportsEnum | null;
          logo?: {
            __typename?: "ImageReferenceNullable";
            url?: string | null;
          } | null;
        };
        primaryRef:
          | {
              __typename?: "Player";
              identifier: string;
              name: string;
              number?: number | null;
              position?: string | null;
              image?: { __typename?: "ImageReference"; url: string } | null;
              team?: {
                __typename?: "Team";
                name: string;
                sportsbookLink?: string | null;
                abbreviation?: string | null;
                teamJerseyImage?: {
                  __typename?: "ImageReferenceNullable";
                  url?: string | null;
                } | null;
                image?: { __typename?: "ImageReference"; url: string } | null;
              } | null;
            }
          | {
              __typename?: "Team";
              name: string;
              abbreviation?: string | null;
              numberFireId?: number | null;
              sportsbookLink?: string | null;
              image?: { __typename?: "ImageReference"; url: string } | null;
              teamJerseyImage?: {
                __typename?: "ImageReferenceNullable";
                url?: string | null;
              } | null;
            };
        author: {
          __typename?: "ContentAuthor";
          id: string;
          name: string;
          thumbnailUrl?: string | null;
          socialMedia?: Array<{
            __typename?: "SocialMediaAccount";
            type: SocialMediaType;
            accountName: string;
            url: string;
          } | null> | null;
        };
        attribution?: {
          __typename?: "Attribution";
          name?: string | null;
          link?: string | null;
        } | null;
      };
    } | null>;
  } | null;
};

export type GetSiteSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSiteSettingsQuery = {
  __typename?: "Query";
  getSiteSettings?: {
    __typename?: "SiteSettings";
    sbkPromoBanner?: {
      __typename?: "sbkPromoBanner";
      hidePromoBanner?: boolean | null;
      desktopButtonText?: string | null;
      desktopLink?: string | null;
      mobileButtonText?: string | null;
      mobileLink?: string | null;
    } | null;
  } | null;
};

export type GetSitemapPlayerNewsQueryQueryVariables = Exact<{
  filter: ShortFormSearchInput;
}>;

export type GetSitemapPlayerNewsQueryQuery = {
  __typename?: "Query";
  getShortForms?: {
    __typename?: "ShortFormList";
    pageInfo: { __typename?: "PageInfo"; cursor: string; hasNextPage: boolean };
    shortForms: Array<{
      __typename?: "ShortFormConnection";
      cursor: string;
      entity: {
        __typename?: "ShortForm";
        id: string;
        slug: string;
        title: string;
        firstPublishedAt?: string | null;
        lastPublishedAt?: string | null;
        newsType?: {
          __typename?: "ShortFormNewsType";
          name?: string | null;
          enum?: ShortFormNewsTypeEnum | null;
        } | null;
        sport: { __typename?: "GenericSport"; slug: string };
      };
    } | null>;
  } | null;
};

export type GetSlatesQueryVariables = Exact<{
  sport: ProjectionSports;
  range?: InputMaybe<Range>;
}>;

export type GetSlatesQuery = {
  __typename?: "Query";
  getSlates?: Array<{
    __typename?: "Slate";
    id: string;
    name: string;
  } | null> | null;
};

export type GetSubCategoryQueryQueryVariables = Exact<{
  input: SubCategoryInput;
  filter?: InputMaybe<ArticleSearchInput>;
}>;

export type GetSubCategoryQueryQuery = {
  __typename?: "Query";
  getSubCategory?: {
    __typename?: "SubCategory";
    text?: string | null;
    title?: string | null;
    image?: { __typename?: "ImageReference"; url: string } | null;
    sport?: { __typename?: "GenericSport"; name: string } | null;
    articles?: Array<{
      __typename?: "ArticleConnection";
      cursor: string;
      entity: {
        __typename?: "Article";
        id: string;
        title: string;
        url: string;
        publishedDate?: string | null;
        description: string;
        sport: { __typename?: "GenericSport"; name: string; slug: string };
        thumbnailImageUrl?: {
          __typename?: "ImageReference";
          url: string;
        } | null;
        tags?: Array<{
          __typename?: "ContentTag";
          name?: string | null;
          key: string;
          id: string;
          type?: string | null;
        } | null> | null;
        author: { __typename?: "ContentAuthor"; id: string; name: string };
      };
    } | null> | null;
    seo?: {
      __typename?: "SEOInformation";
      canonicalUrl?: string | null;
      title?: string | null;
      keywords?: Array<string | null> | null;
      synonyms?: Array<string | null> | null;
      description?: string | null;
      noIndex?: boolean | null;
      image?: { __typename?: "ImageReference"; url: string } | null;
      openGraphTag?: {
        __typename?: "OpenGraphTag";
        title: string;
        type?: string | null;
        image?: { __typename?: "ImageReference"; url: string } | null;
      } | null;
    } | null;
  } | null;
};

export type GetSupportedSportsQueryVariables = Exact<{
  isPreview?: InputMaybe<Scalars["Boolean"]>;
  featured?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetSupportedSportsQuery = {
  __typename?: "Query";
  getSupportedSports?: Array<{
    __typename?: "GenericSport";
    name: string;
    hasLiveScoring?: boolean | null;
    hasPlayerUpdates?: boolean | null;
    showAuthors?: boolean | null;
    contentSport?: ContentSportsEnum | null;
    slug: string;
    seo: {
      __typename?: "SEOInformation";
      description?: string | null;
      canonicalUrl?: string | null;
      title?: string | null;
      noIndex?: boolean | null;
      image?: { __typename?: "ImageReference"; url: string } | null;
    };
  } | null> | null;
  getSupportedSportsFilters?: Array<{
    __typename?: "GenericSport";
    positions?: Array<PositionAbbrev | null> | null;
    contentSport?: ContentSportsEnum | null;
    teams?: Array<{
      __typename?: "Team";
      abbreviation?: string | null;
      name: string;
      numberFireId?: number | null;
    } | null> | null;
  } | null> | null;
  getContentFilters?: {
    __typename?: "ContentFilters";
    shortFormNewsType?: Array<{
      __typename?: "ShortFormNewsType";
      enum?: ShortFormNewsTypeEnum | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type GetSupportedSportsFiltersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSupportedSportsFiltersQuery = {
  __typename?: "Query";
  getSupportedSports?: Array<{
    __typename?: "GenericSport";
    positions?: Array<PositionAbbrev | null> | null;
    contentSport?: ContentSportsEnum | null;
    teams?: Array<{
      __typename?: "Team";
      abbreviation?: string | null;
      name: string;
      numberFireId?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetTentpoleEventQueryVariables = Exact<{
  input: TentpoleEventInput;
}>;

export type GetTentpoleEventQuery = {
  __typename?: "Query";
  getTentpoleEvent?: {
    __typename?: "TentpoleEvent";
    title: string;
    slug: string;
    subHeading?: string | null;
    description: string;
    image: string;
    heroHeight?: string | null;
    sport: {
      __typename?: "GenericSport";
      name: string;
      slug: string;
      logo?: {
        __typename?: "ImageReferenceNullable";
        url?: string | null;
      } | null;
    };
    menu?: Array<{
      __typename?: "ScrollMenu";
      label: string;
      target: string;
    } | null> | null;
    seo: {
      __typename?: "SEOInformation";
      title?: string | null;
      description?: string | null;
    };
    pageContent?: Array<
      | {
          __typename: "Faq";
          icon?: string | null;
          title: string;
          target?: string | null;
          questions: Array<{
            __typename?: "Question";
            questionText: string;
            answer: string;
          } | null>;
        }
      | {
          __typename: "FeaturedSport";
          type: string;
          icon?: string | null;
          title: string;
          target?: string | null;
          articles?: Array<{
            __typename?: "Article";
            id: string;
            slug: string;
            title: string;
            publishedDate?: string | null;
            modifiedDate: string;
            firstPublishedAt?: string | null;
            lastPublishedAt?: string | null;
            subHeading?: string | null;
            description: string;
            thumbnailImageUrl?: {
              __typename?: "ImageReference";
              url: string;
            } | null;
            author: {
              __typename?: "ContentAuthor";
              id: string;
              name: string;
              thumbnailUrl?: string | null;
              socialMedia?: Array<{
                __typename?: "SocialMediaAccount";
                type: SocialMediaType;
                accountName: string;
                url: string;
              } | null> | null;
            };
            hero?: {
              __typename?: "ArticleHero";
              imageUrl?: { __typename?: "ImageReference"; url: string } | null;
            } | null;
          } | null> | null;
        }
      | {
          __typename: "ImageBanner";
          mainImage: string;
          mobileImage?: string | null;
          link?: string | null;
          target?: string | null;
        }
      | {
          __typename: "ImageShowcase";
          title: string;
          cornerLinkText?: string | null;
          cornerLinkUrl?: string | null;
          icon?: string | null;
          link?: string | null;
          mainImage: string;
          tabletImage?: string | null;
          mobileImage?: string | null;
          target?: string | null;
          type: string;
        }
      | {
          __typename: "PlayerOddsTabs";
          icon?: string | null;
          title: string;
          target?: string | null;
          cornerLinkText?: string | null;
          cornerLinkUrl?: string | null;
          bettingTabs: Array<{
            __typename?: "BettingTabs";
            title: string;
            market: string;
          } | null>;
        }
      | {
          __typename: "Promos_TaggedArticles";
          title: string;
          target?: string | null;
          taggedArticles?: {
            __typename: "TaggedArticles";
            type: string;
            tag?: string | null;
            target?: string | null;
            icon?: string | null;
            title: string;
            cornerLinkText?: string | null;
            cornerLinkUrl?: string | null;
            articles?: Array<{
              __typename?: "Article";
              id: string;
              slug: string;
              title: string;
              publishedDate?: string | null;
              modifiedDate: string;
              firstPublishedAt?: string | null;
              lastPublishedAt?: string | null;
              subHeading?: string | null;
              description: string;
              hero?: {
                __typename?: "ArticleHero";
                imageUrl?: {
                  __typename?: "ImageReference";
                  url: string;
                } | null;
              } | null;
              thumbnailImageUrl?: {
                __typename?: "ImageReference";
                url: string;
              } | null;
              author: {
                __typename?: "ContentAuthor";
                id: string;
                name: string;
                thumbnailUrl?: string | null;
                socialMedia?: Array<{
                  __typename?: "SocialMediaAccount";
                  accountName: string;
                  type: SocialMediaType;
                  url: string;
                } | null> | null;
              };
            } | null> | null;
          } | null;
          promos?: {
            __typename: "Promos";
            cornerLinkText?: string | null;
            cornerLinkUrl?: string | null;
            icon?: string | null;
            title: string;
            target?: string | null;
            type: string;
            promos: Array<{
              __typename?: "Promo";
              image?: string | null;
              buttonOne: {
                __typename?: "PromoButton";
                text: string;
                url: string;
              };
              buttonTwo?: {
                __typename?: "PromoButton";
                url: string;
                text: string;
              } | null;
            }>;
          } | null;
        }
      | {
          __typename: "Scoreboard";
          icon?: string | null;
          title: string;
          target?: string | null;
          tabs: Array<{
            __typename?: "ScoreboardTab";
            label: string;
            value?: string | null;
          }>;
        }
      | {
          __typename: "ScrollableOdds";
          icon?: string | null;
          title: string;
          target?: string | null;
          markets: Array<{
            __typename?: "BettingMarket";
            type: MarketType;
            title: string;
            market: string;
            info?: string | null;
            bottomLinkText?: string | null;
            bottomLinkUrl?: string | null;
          } | null>;
        }
      | {
          __typename: "TaggedArticles";
          type: string;
          target?: string | null;
          icon?: string | null;
          title: string;
          cornerLinkText?: string | null;
          cornerLinkUrl?: string | null;
          tag?: string | null;
          articles?: Array<{
            __typename?: "Article";
            id: string;
            slug: string;
            title: string;
            publishedDate?: string | null;
            modifiedDate: string;
            firstPublishedAt?: string | null;
            lastPublishedAt?: string | null;
            subHeading?: string | null;
            description: string;
            hero?: {
              __typename?: "ArticleHero";
              imageUrl?: { __typename?: "ImageReference"; url: string } | null;
            } | null;
            thumbnailImageUrl?: {
              __typename?: "ImageReference";
              url: string;
            } | null;
            author: {
              __typename?: "ContentAuthor";
              id: string;
              name: string;
              thumbnailUrl?: string | null;
              socialMedia?: Array<{
                __typename?: "SocialMediaAccount";
                accountName: string;
                type: SocialMediaType;
                url: string;
              } | null> | null;
            };
          } | null> | null;
        }
      | {
          __typename: "TaggedArticles_PlayerOddsTabs";
          title: string;
          target?: string | null;
          taggedArticles?: {
            __typename: "TaggedArticles";
            type: string;
            tag?: string | null;
            target?: string | null;
            icon?: string | null;
            title: string;
            cornerLinkText?: string | null;
            cornerLinkUrl?: string | null;
            articles?: Array<{
              __typename?: "Article";
              id: string;
              slug: string;
              title: string;
              publishedDate?: string | null;
              modifiedDate: string;
              firstPublishedAt?: string | null;
              lastPublishedAt?: string | null;
              subHeading?: string | null;
              description: string;
              hero?: {
                __typename?: "ArticleHero";
                imageUrl?: {
                  __typename?: "ImageReference";
                  url: string;
                } | null;
              } | null;
              thumbnailImageUrl?: {
                __typename?: "ImageReference";
                url: string;
              } | null;
              author: {
                __typename?: "ContentAuthor";
                id: string;
                name: string;
                thumbnailUrl?: string | null;
                socialMedia?: Array<{
                  __typename?: "SocialMediaAccount";
                  accountName: string;
                  type: SocialMediaType;
                  url: string;
                } | null> | null;
              };
            } | null> | null;
          } | null;
          playerOddsTabs?: {
            __typename: "PlayerOddsTabs";
            icon?: string | null;
            title: string;
            target?: string | null;
            cornerLinkText?: string | null;
            cornerLinkUrl?: string | null;
            bettingTabs: Array<{
              __typename?: "BettingTabs";
              title: string;
              market: string;
            } | null>;
          } | null;
        }
      | {
          __typename: "TaggedArticles_ScrollableOdds";
          title: string;
          target?: string | null;
          taggedArticles?: {
            __typename: "TaggedArticles";
            type: string;
            tag?: string | null;
            target?: string | null;
            icon?: string | null;
            title: string;
            cornerLinkText?: string | null;
            cornerLinkUrl?: string | null;
            articles?: Array<{
              __typename?: "Article";
              id: string;
              slug: string;
              title: string;
              publishedDate?: string | null;
              modifiedDate: string;
              firstPublishedAt?: string | null;
              lastPublishedAt?: string | null;
              subHeading?: string | null;
              description: string;
              hero?: {
                __typename?: "ArticleHero";
                imageUrl?: {
                  __typename?: "ImageReference";
                  url: string;
                } | null;
              } | null;
              thumbnailImageUrl?: {
                __typename?: "ImageReference";
                url: string;
              } | null;
              author: {
                __typename?: "ContentAuthor";
                id: string;
                name: string;
                thumbnailUrl?: string | null;
                socialMedia?: Array<{
                  __typename?: "SocialMediaAccount";
                  accountName: string;
                  type: SocialMediaType;
                  url: string;
                } | null> | null;
              };
            } | null> | null;
          } | null;
          scrollableOdds?: {
            __typename: "ScrollableOdds";
            icon?: string | null;
            title: string;
            target?: string | null;
            markets: Array<{
              __typename?: "BettingMarket";
              type: MarketType;
              title: string;
              market: string;
              info?: string | null;
              bottomLinkText?: string | null;
              bottomLinkUrl?: string | null;
            } | null>;
          } | null;
        }
      | {
          __typename: "TextBanner";
          title: string;
          mainImage: string;
          icon?: string | null;
          linkText?: string | null;
          link?: string | null;
          target?: string | null;
        }
      | null
    > | null;
  } | null;
};

export type Tournament_TournamentGamesQueryVariables = Exact<{
  teamGroupAlias?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type Tournament_TournamentGamesQuery = {
  __typename?: "Query";
  tournament_TournamentGames?: {
    __typename?: "TOURNAMENT_NbaTournamentGameDetails";
    getUpcomingTournamentGames?: Array<{
      __typename?: "TOURNAMENT_NbaIstGame";
      gameDateTime?: any | null;
      status?: Tournament_SrNbaGameStatus | null;
      awayTeam?: {
        __typename?: "TOURNAMENT_NbaIstTeam";
        istTeamGroupAlias?: string | null;
        istTeamGroupName?: string | null;
        teamMeta?: {
          __typename?: "TOURNAMENT_NbaTeamMetadata";
          code?: string | null;
          conference?: string | null;
          division?: string | null;
          headCoach?: string | null;
          icon?: string | null;
          market?: string | null;
          name?: string | null;
          shortName?: string | null;
          slug?: string | null;
          colors?: {
            __typename?: "TOURNAMENT_TeamColors";
            primary?: string | null;
            secondary?: string | null;
          } | null;
          logo?: {
            __typename?: "TOURNAMENT_TeamLogo";
            bitmap?: string | null;
            svg?: string | null;
          } | null;
        } | null;
      } | null;
      broadcasts?: {
        __typename?: "TOURNAMENT_NbaGameBroadcasts";
        awayBroadcastInfo?: Array<{
          __typename?: "TOURNAMENT_NbaLocaleBroadcastInfo";
          channel?: string | null;
          locale?: Tournament_NbaBroadcastLocale | null;
          network?: string | null;
          type?: string | null;
        } | null> | null;
        homeBroadcastInfo?: Array<{
          __typename?: "TOURNAMENT_NbaLocaleBroadcastInfo";
          channel?: string | null;
          locale?: Tournament_NbaBroadcastLocale | null;
          network?: string | null;
          type?: string | null;
        } | null> | null;
        internationalBroadcastInfo?: Array<{
          __typename?: "TOURNAMENT_NbaLocaleBroadcastInfo";
          channel?: string | null;
          locale?: Tournament_NbaBroadcastLocale | null;
          network?: string | null;
          type?: string | null;
        } | null> | null;
        nationalBroadcastInfo?: Array<{
          __typename?: "TOURNAMENT_NbaLocaleBroadcastInfo";
          channel?: string | null;
          locale?: Tournament_NbaBroadcastLocale | null;
          network?: string | null;
          type?: string | null;
        } | null> | null;
      } | null;
      gameOdds?: {
        __typename?: "TOURNAMENT_NbaGameOdds";
        scheduledTime?: any | null;
        url?: string | null;
        away?: {
          __typename?: "TOURNAMENT_TeamGameOdds";
          teamCode?: string | null;
          oddsDetails?: {
            __typename?: "TOURNAMENT_GameOddsDetails";
            moneyLine?: {
              __typename?: "TOURNAMENT_MoneyLine";
              odd?: string | null;
              url?: string | null;
            } | null;
            spread?: {
              __typename?: "TOURNAMENT_SpreadTotal";
              odd?: string | null;
              url?: string | null;
              value?: string | null;
            } | null;
            total?: {
              __typename?: "TOURNAMENT_SpreadTotal";
              odd?: string | null;
              url?: string | null;
              value?: string | null;
            } | null;
          } | null;
        } | null;
        home?: {
          __typename?: "TOURNAMENT_TeamGameOdds";
          teamCode?: string | null;
          oddsDetails?: {
            __typename?: "TOURNAMENT_GameOddsDetails";
            moneyLine?: {
              __typename?: "TOURNAMENT_MoneyLine";
              odd?: string | null;
              url?: string | null;
            } | null;
            spread?: {
              __typename?: "TOURNAMENT_SpreadTotal";
              odd?: string | null;
              url?: string | null;
              value?: string | null;
            } | null;
            total?: {
              __typename?: "TOURNAMENT_SpreadTotal";
              odd?: string | null;
              url?: string | null;
              value?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      homeTeam?: {
        __typename?: "TOURNAMENT_NbaIstTeam";
        istTeamGroupAlias?: string | null;
        istTeamGroupName?: string | null;
        teamMeta?: {
          __typename?: "TOURNAMENT_NbaTeamMetadata";
          code?: string | null;
          conference?: string | null;
          division?: string | null;
          headCoach?: string | null;
          icon?: string | null;
          market?: string | null;
          name?: string | null;
          shortName?: string | null;
          slug?: string | null;
          colors?: {
            __typename?: "TOURNAMENT_TeamColors";
            primary?: string | null;
            secondary?: string | null;
          } | null;
          logo?: {
            __typename?: "TOURNAMENT_TeamLogo";
            bitmap?: string | null;
            svg?: string | null;
          } | null;
        } | null;
      } | null;
      venue?: {
        __typename?: "TOURNAMENT_NbaIstVenue";
        address?: string | null;
        city?: string | null;
        country?: string | null;
        name?: string | null;
        state?: string | null;
        zip?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Tournament_GetTournamentPlayerPropsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Tournament_GetTournamentPlayerPropsQuery = {
  __typename?: "Query";
  tournament_GetTournamentPlayerProps?: Array<{
    __typename?: "TOURNAMENT_NbaIstPlayerProps";
    contentText?: string | null;
    id?: string | null;
    playerImage?: string | null;
    playerName?: string | null;
    playerPosition?: string | null;
    events?: Array<{
      __typename?: "TOURNAMENT_NbaPlayerPropsEvent";
      date?: string | null;
      eventId?: number | null;
      eventName?: string | null;
      markets?: Array<{
        __typename?: "TOURNAMENT_NbaPlayerPropsMarket";
        contentText?: string | null;
        eligibleForSameGameParlay?: boolean | null;
        externalMarketId?: string | null;
        marketId?: string | null;
        marketType?: string | null;
        marketTypeName?: string | null;
        selections?: Array<{
          __typename?: "TOURNAMENT_NbaPlayerPropsSelection";
          betUrl?: string | null;
          contentText?: string | null;
          handicap?: number | null;
          odds?: string | null;
          runnerName?: string | null;
          selectionId?: number | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetTrackInfoQueryVariables = Exact<{
  profile: Scalars["String"];
  trackCode: Scalars["String"];
}>;

export type GetTrackInfoQuery = {
  __typename?: "Query";
  HS_PastTracks?: Array<{
    __typename?: "HR_PastTrack";
    code?: string | null;
    name?: string | null;
    imageLink?: string | null;
    location?: {
      __typename?: "HR_PastTrackLocation";
      city?: string | null;
      state?: string | null;
      country?: string | null;
    } | null;
  } | null> | null;
};

export type GetTrackRacesQueryVariables = Exact<{
  trackCode: Scalars["String"];
  profile: Scalars["String"];
}>;

export type GetTrackRacesQuery = {
  __typename?: "Query";
  HR_Track?: {
    __typename: "HR_Track";
    races?: Array<{
      __typename: "HR_Race";
      number?: string | null;
      postTime?: string | null;
      status?: {
        __typename: "HR_StatusEnumeration";
        code?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export const GetActiveFixturesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetActiveFixtures" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "FixtureSearchInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getActiveFixtures" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledStartTime" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentGameTime" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledEndTime" },
                },
                { kind: "Field", name: { kind: "Name", value: "gameStatus" } },
                { kind: "Field", name: { kind: "Name", value: "eventUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tvNetwork" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentScore" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "moneyLine" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pointSpread" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "winProbability" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fixtureMoneyLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fixtureSpread" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fixtureTotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "primaryColor" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "winCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "loseCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "drawCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sportsbookLink" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActiveFixturesQuery,
  GetActiveFixturesQueryVariables
>;
export const GetTentpoleEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTentpoleEvents" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTentpoleEvents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTentpoleEventsQuery,
  GetTentpoleEventsQueryVariables
>;
export const GetArticleBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticleBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contentStatus" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "StatusSelector" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPreview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSingleArticle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "contentStatus" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contentStatus" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPreview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPreview" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hideTopNewsletter" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hideBottomNewsletter" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstPublishedAt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "publishedDate" },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataskriveId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openGraphTag" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noIndex" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "thumbnailImageUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hero" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jwPlayerId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jwPlayerPlayerId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "thumbnailUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "socialMedia" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticleBySlugQuery,
  GetArticleBySlugQueryVariables
>;
export const GetArticlesByDateRangeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticlesByDateRange" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ArticleSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getArticles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesByDateRangeQuery,
  GetArticlesByDateRangeQueryVariables
>;
export const GetArticlesNoSportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticlesNoSport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ArticleSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getArticles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesNoSportQuery,
  GetArticlesNoSportQueryVariables
>;
export const GetArticlesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ArticleSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getArticles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publishedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "thumbnailImageUrl",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hero" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "socialMedia",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetAuthorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAuthors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AuthorSearchInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contentType" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Type" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sport" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ContentSportsEnum" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAuthors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authoredContent" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "contentType" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "contentType",
                                    },
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "sport" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "sport" },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifiedDate",
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Article" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sport",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "slug",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailImageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thumbnailUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "socialMedia" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "accountName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorsQuery, GetAuthorsQueryVariables>;
export const GetContentFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetContentFilters" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getContentFilters" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortFormNewsType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "enum" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContentFiltersQuery,
  GetContentFiltersQueryVariables
>;
export const GetFeaturedArticlesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFeaturedArticles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "FeaturedArticleInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFeaturedArticles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstPublishedAt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "publishedDate" },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noIndex" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "thumbnailImageUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hero" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "socialMedia" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFeaturedArticlesQuery,
  GetFeaturedArticlesQueryVariables
>;
export const GetGolfEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetGolfEvents" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getGolfEvents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGolfEventsQuery, GetGolfEventsQueryVariables>;
export const GetHomepageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetHomepage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPreview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getHomepage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPreview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPreview" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "template" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tools" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newWindow" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "selectedSports" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sport" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasPlayerUpdates" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tool" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newWindow" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featuredArticles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subHeading" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "socialMedia",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hero" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "noIndex" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canonicalUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "thumbnailImageUrl",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstPublishedAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subHeading" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "author" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "socialMedia" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hero" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noIndex" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "canonicalUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "thumbnailImageUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sport" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHomepageQuery, GetHomepageQueryVariables>;
export const GetHorseRacesByTrackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getHorseRacesByTrack" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trackCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HR_Races" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "ObjectValue",
                      fields: [
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "hasFreePick" },
                          value: { kind: "BooleanValue", value: true },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "isOpen" },
                          value: { kind: "BooleanValue", value: true },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "order" },
                          value: { kind: "IntValue", value: "1" },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "trackCode" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "trackCode" },
                          },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sorts" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "ObjectValue",
                      fields: [
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "byPostTime" },
                          value: { kind: "EnumValue", value: "ASC" },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "order" },
                          value: { kind: "IntValue", value: "2" },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "number" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "track" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trackDataSource" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "raceDate" } },
                { kind: "Field", name: { kind: "Name", value: "postTime" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bettingInterests" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filters" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "hasFreePick" },
                                value: { kind: "BooleanValue", value: true },
                              },
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "IntValue", value: "1" },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "saddleColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorite" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numerator" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "denominator" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "runners" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filters" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: {
                                        kind: "Name",
                                        value: "hasFreePick",
                                      },
                                      value: {
                                        kind: "BooleanValue",
                                        value: true,
                                      },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "order" },
                                      value: { kind: "IntValue", value: "1" },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "runnerId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "horseName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scratched" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trainer" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jockey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handicapping" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "freePick" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "number",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timeform" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "silkUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "silkUrlSvg" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "freePick" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "number",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "info" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHorseRacesByTrackQuery,
  GetHorseRacesByTrackQueryVariables
>;
export const GetUpcomingRacesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUpcomingRaces" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "current" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "results" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HR_Races" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "ObjectValue",
                      fields: [
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "hasFreePick" },
                          value: { kind: "BooleanValue", value: true },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "isOpen" },
                          value: { kind: "BooleanValue", value: true },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "order" },
                          value: { kind: "IntValue", value: "1" },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sorts" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "ObjectValue",
                      fields: [
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "byPostTime" },
                          value: { kind: "EnumValue", value: "ASC" },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "order" },
                          value: { kind: "IntValue", value: "2" },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pages" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "ObjectValue",
                      fields: [
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "current" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "current" },
                          },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "results" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "results" },
                          },
                        },
                        {
                          kind: "ObjectField",
                          name: { kind: "Name", value: "order" },
                          value: { kind: "IntValue", value: "3" },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "number" } },
                { kind: "Field", name: { kind: "Name", value: "numRunners" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "purse" } },
                { kind: "Field", name: { kind: "Name", value: "distance" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "raceClass" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "surface" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "defaultCondition" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "track" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "trackDataSource" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "status" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "raceDate" } },
                { kind: "Field", name: { kind: "Name", value: "postTime" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bettingInterests" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filters" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "hasFreePick" },
                                value: { kind: "BooleanValue", value: true },
                              },
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "IntValue", value: "1" },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "saddleColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberColor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorite" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numerator" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "denominator" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "runners" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filters" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: {
                                        kind: "Name",
                                        value: "hasFreePick",
                                      },
                                      value: {
                                        kind: "BooleanValue",
                                        value: true,
                                      },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "order" },
                                      value: { kind: "IntValue", value: "1" },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "runnerId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "horseName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scratched" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trainer" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jockey" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handicapping" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "freePick" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "number",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timeform" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "silkUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "silkUrlSvg" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "freePick" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "number",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "info" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "__typename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUpcomingRacesQuery,
  GetUpcomingRacesQueryVariables
>;
export const GetHorseTrackListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getHorseTrackList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HS_PastTracks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isGreyhound" },
                      value: { kind: "BooleanValue", value: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHorseTrackListQuery,
  GetHorseTrackListQueryVariables
>;
export const GetMenusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getMenus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPreview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getMenus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPreview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPreview" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "iconUrl" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "menuItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "behaviour" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "iconUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "behaviour" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "iconUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "children" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "behaviour" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "iconUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "children" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "behaviour",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "iconUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "imageUrl",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMenusQuery, GetMenusQueryVariables>;
export const FuturesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Futures" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "marketType" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Nba" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "futures" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "marketType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "marketType" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "futureOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "teamMeta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "colors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "primary" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "secondary" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conference" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "division" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "headCoach" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "svg" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "market" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FuturesQuery, FuturesQueryVariables>;
export const Tournament_GetTournamentPlayerFuturesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Tournament_GetTournamentPlayerFutures" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "tournament_GetTournamentPlayerFutures",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "marketName" } },
                { kind: "Field", name: { kind: "Name", value: "marketType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "odds" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "odds" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "playerImage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "runnerName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Tournament_GetTournamentPlayerFuturesQuery,
  Tournament_GetTournamentPlayerFuturesQueryVariables
>;
export const GetNcaabNextGameMarketOddsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNcaabNextGameMarketOddsQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Ncaamb" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "svg" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prevNextGame" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nextGames" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameTitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "homeGame" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "opponentTeamMeta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "shortName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "logo" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "svg",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "popularGameBets",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "marketType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selectionName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "odds" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "line" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNcaabNextGameMarketOddsQueryQuery,
  GetNcaabNextGameMarketOddsQueryQueryVariables
>;
export const GetNcaabTeamFuturesQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNcaabTeamFuturesQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "marketType" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Ncaamb" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "futures" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "marketType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "marketType" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "futureOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "teamMeta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNcaabTeamFuturesQueryQuery,
  GetNcaabTeamFuturesQueryQueryVariables
>;
export const GetNcaabUpcomingGamesQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNcaabUpcomingGamesQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CTP_Instant" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CTP_Instant" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Ncaamb" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "schedule" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "endDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDate" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "startDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDate" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seasonType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "games" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scheduledTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "venueName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "broadcasts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "tvBroadcastInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "network",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "home" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oddsDetails",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "moneyLine",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "spread",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "total",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "away" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oddsDetails",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "moneyLine",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "spread",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "total",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "record" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "leagueRecord" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "wins" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "losses" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNcaabUpcomingGamesQueryQuery,
  GetNcaabUpcomingGamesQueryQueryVariables
>;
export const UpcomingGamesComponentQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UpcomingGamesComponentQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CTP_Instant" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CTP_Instant" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Nfl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "schedule" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "startDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDate" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "endDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDate" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seasonType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "games" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scheduledTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "venueName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "broadcastInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "network" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shortName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "colors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primary",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "secondary",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shortName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "colors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primary",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "secondary",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "home" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oddsDetails",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "moneyLine",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "spread",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "total",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "away" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oddsDetails",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "moneyLine",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "spread",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "total",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "odd",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpcomingGamesComponentQueryQuery,
  UpcomingGamesComponentQueryQueryVariables
>;
export const NflPlayerPropsComponentQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "NflPlayerPropsComponentQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seasonStatisticsFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "CTP_LeaguePlayerStatisticsFilterInput",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Nfl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "svg" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "starPlayers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "teamCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "playerProps" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "events" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "eventName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "markets" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "marketId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "marketName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "marketType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "marketName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "eligibleForSameGameParlay",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selections",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odds",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "betUrl",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "handicap",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "runnerName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerJersey" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "png" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "svg" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "background" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "playerInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statistics" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seasons" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "seasonStatisticsFilter",
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seasonInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seasonYear",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "seasonStatistics",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "passing",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "yards",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "average",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "touchdowns",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "average",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "rushing",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "yards",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "average",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "receiving",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "yards",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "average",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "receptions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "average",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NflPlayerPropsComponentQueryQuery,
  NflPlayerPropsComponentQueryQueryVariables
>;
export const SuperBowlOddsComponentQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SuperBowlOddsComponentQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "marketType" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Nfl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "futures" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "marketType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "marketType" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "futureOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "odds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "teamMeta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "featuredEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "markets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "markets" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "odds" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runnerName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "selectionId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuperBowlOddsComponentQueryQuery,
  SuperBowlOddsComponentQueryQueryVariables
>;
export const GetPgaPlayerOddsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPgaPlayerOddsQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ctp_Golf" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "featuredEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "markets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "markets" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "odds" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runnerName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "selectionId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPgaPlayerOddsQueryQuery,
  GetPgaPlayerOddsQueryQueryVariables
>;
export const GetPlayerNewsHomeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPlayerNewsHome" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ShortFormSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getShortForms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortForms" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publishedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newsType" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "enum" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "primaryRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Player" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "identifier",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "image",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "team" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "image",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "teamJerseyImage",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Team" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "identifier",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "image",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "teamJerseyImage",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attribution" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "link" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlayerNewsHomeQuery,
  GetPlayerNewsHomeQueryVariables
>;
export const GetPlayerNewsSportsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPlayerNewsSports" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPreview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "featured" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSupportedSports" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPreview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPreview" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "featured" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "featured" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasLiveScoring" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasPlayerUpdates" },
                },
                { kind: "Field", name: { kind: "Name", value: "showAuthors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentSport" },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noIndex" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlayerNewsSportsQuery,
  GetPlayerNewsSportsQueryVariables
>;
export const GetProjectionTableMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjectionTableMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ProjectionTableMetadataInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getProjectionTableMetadata" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "positionFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionGroup" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isDefaultPosition" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "columns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "columnHeaders" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "columns" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "sortOrder" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProjectionTableMetadataQuery,
  GetProjectionTableMetadataQueryVariables
>;
export const GetProjectionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetProjections" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProjectionsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getProjections" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "MlbPitcher" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "winsLosses" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "wins" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "losses" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "earnedRunsAvg" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gamesStarted" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "saves" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "inningsPitched" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "hits" } },
                      { kind: "Field", name: { kind: "Name", value: "runs" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "earnedRuns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "homeRuns" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "walks" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "strikeouts" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "walksPlusHitsPerInningsPitched",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gamesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "MlbBatter" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "plateAppearances" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "runs" } },
                      { kind: "Field", name: { kind: "Name", value: "hits" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "singles" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "doubles" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "triples" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "homeRuns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "runsBattedIn" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stolenBases" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "caughtStealing" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "walks" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "strikeouts" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "battingAverage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "onBasePercentage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sluggingPercentage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "onBasePlusSlugging" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NflSkill" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completionsAttempts" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passingYards" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passingTouchdowns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interceptionsThrown" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rushingAttempts" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rushingYards" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rushingTouchdowns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receptions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targets" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingYards" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivingTouchdowns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionRank" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overallRank" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opponentDefensiveRank" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NflKicker" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extraPointsAttempted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extraPointsMade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsAttempted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade0To19" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade20To29" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade30To39" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade40To49" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade50Plus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionRank" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opponentDefensiveRank" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NflDefenseSt" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pointsAllowed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "yardsAllowed" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "sacks" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interceptions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fumblesRecovered" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "touchdowns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionRank" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opponentOffensiveRank" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NflDefensePlayer" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tackles" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "sacks" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interceptions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "touchdowns" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passesDefended" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fumblesRecovered" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opponentOffensiveRank" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NhlSkater" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minutesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "shots" } },
                      { kind: "Field", name: { kind: "Name", value: "goals" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assists" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "points" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "powerPlayGoals" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "powerPlayAssists" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "plusMinus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blockedShots" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "penaltiesInMinutes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timeOnIce" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avgTimeOnIce" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gamesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NhlGoalie" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minutesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goalsAgainst" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shotsAgainst" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "saves" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shutouts" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "wins" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "losses" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "savePercent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timeOnIce" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "tiesPlusOvertimeOrShootoutLosses",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gamesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "goalsAgainstAvg" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "NbaPlayer" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "team" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "awayTeam" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imageUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameTime" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minutes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsMade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fieldGoalsAttempted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "threePointsMade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "threePointsAttempted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeThrowsMade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeThrowsAttempted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "assists" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "steals" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blocks" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "turnovers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "points" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rebounds" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gamesPlayed" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "fieldGoalShootingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "threePointsShootingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "freeThrowShootingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionRank" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overallRank" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "GolfPlayer" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "player" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerPageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "handedness" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fantasy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salary" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "madeCut" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "first" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topFive" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topTen" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topTwentyFive" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eagles" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "birdies" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "pars" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bogeys" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "doubleBogeys" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectionsQuery, GetProjectionsQueryVariables>;
export const GetPromotionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPromotions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PromotionsInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPromotions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "detailsUrl" } },
                { kind: "Field", name: { kind: "Name", value: "ctaUrl" } },
                { kind: "Field", name: { kind: "Name", value: "ctaText" } },
                { kind: "Field", name: { kind: "Name", value: "promoCode" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPromotionsQuery, GetPromotionsQueryVariables>;
export const GetRaceByTrackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRaceByTrack" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trackCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "raceNumber" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HR_Track" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "trackCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackDataSource" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "races" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pagesByField" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "raceNumber" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "raceNumber" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "results" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numRunners" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "type" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "distance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "raceClass" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "surface" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "defaultCondition" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "purse" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timeform" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "analystVerdict" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bettingInterests" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "saddleColor" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberColor" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "favorite" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currentOdds" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "numerator" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "denominator",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "runners" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "jockey" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "trainer" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "timeform" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "silkUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "silkUrlSvg",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runnerId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "horseName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "scratched" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "handicapping",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freePick",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "number",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "info",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "snapshot",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "powerRating",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "normalisedPowerRating",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "speedAndClass",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "highSpeed",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "normalisedSpeedRating",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "timeform" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freePick",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "number",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "info",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRaceByTrackQuery, GetRaceByTrackQueryVariables>;
export const GetRedirectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRedirect" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RedirectSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getRedirect" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "fromSlug" } },
                { kind: "Field", name: { kind: "Name", value: "toSlug" } },
                { kind: "Field", name: { kind: "Name", value: "isPermanent" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRedirectQuery, GetRedirectQueryVariables>;
export const GetRunnerStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRunnerStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "runnerName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HS_RunnerStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "runnerName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "runnerName" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "numberRaces" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "numberRacesWon" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "numberRacesTop3" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRunnerStatsQuery, GetRunnerStatsQueryVariables>;
export const GetShortFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetShortForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getShortForm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "fact" } },
                { kind: "Field", name: { kind: "Name", value: "analysis" } },
                { kind: "Field", name: { kind: "Name", value: "quant" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newsType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "enum" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contentSport" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryRef" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Player" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "identifier" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "number" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "team" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "teamJerseyImage",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "numberFireId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "sportsbookLink",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "abbreviation",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Team" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "abbreviation" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberFireId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sportsbookLink" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "teamJerseyImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "author" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "thumbnailUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "socialMedia" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstPublishedAt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastPublishedAt" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attribution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "link" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShortFormQuery, GetShortFormQueryVariables>;
export const GetPlayerNewsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPlayerNewsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ShortFormSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getShortForms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortForms" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fact" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "analysis" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quant" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newsType" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "enum" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "contentSport",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "primaryRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Player" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "identifier",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "number",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "position",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "image",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "team" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "teamJerseyImage",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "image",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "sportsbookLink",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "abbreviation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Team" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "abbreviation",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberFireId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "sportsbookLink",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "image",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "teamJerseyImage",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "thumbnailUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "socialMedia",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "accountName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attribution" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "link" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlayerNewsQueryQuery,
  GetPlayerNewsQueryQueryVariables
>;
export const GetSiteSettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSiteSettings" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSiteSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sbkPromoBanner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hidePromoBanner" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "desktopButtonText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "desktopLink" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mobileButtonText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mobileLink" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSiteSettingsQuery,
  GetSiteSettingsQueryVariables
>;
export const GetSitemapPlayerNewsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSitemapPlayerNewsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ShortFormSearchInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getShortForms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortForms" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "newsType" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "enum" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstPublishedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastPublishedAt" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSitemapPlayerNewsQueryQuery,
  GetSitemapPlayerNewsQueryQueryVariables
>;
export const GetSlatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSlates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sport" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProjectionSports" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "range" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Range" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSlates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sport" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sport" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "range" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "range" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSlatesQuery, GetSlatesQueryVariables>;
export const GetSubCategoryQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSubCategoryQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SubCategoryInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ArticleSearchInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSubCategory" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articles" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entity" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sport" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "thumbnailImageUrl",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "key" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "author" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "publishedDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "keywords" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "synonyms" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noIndex" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openGraphTag" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSubCategoryQueryQuery,
  GetSubCategoryQueryQueryVariables
>;
export const GetSupportedSportsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSupportedSports" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPreview" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "featured" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSupportedSports" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPreview" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPreview" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "featured" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "featured" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasLiveScoring" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasPlayerUpdates" },
                },
                { kind: "Field", name: { kind: "Name", value: "showAuthors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentSport" },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalUrl" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "noIndex" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "getSupportedSportsFilters" },
            name: { kind: "Name", value: "getSupportedSports" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "abbreviation" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberFireId" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "positions" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentSport" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getContentFilters" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortFormNewsType" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "enum" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSupportedSportsQuery,
  GetSupportedSportsQueryVariables
>;
export const GetSupportedSportsFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSupportedSportsFilters" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSupportedSports" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "teams" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "abbreviation" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberFireId" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "positions" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentSport" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSupportedSportsFiltersQuery,
  GetSupportedSportsFiltersQueryVariables
>;
export const GetTentpoleEventDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTentpoleEvent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TentpoleEventInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTentpoleEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sport" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "subHeading" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "heroHeight" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "menu" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "target" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageContent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ImageBanner" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mobileImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ScrollableOdds" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "markets" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "market" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "info" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "bottomLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "bottomLinkUrl",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Faq" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "questions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "questionText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "answer" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "Scoreboard" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tabs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "TextBanner" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "linkText" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "PlayerOddsTabs" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkText" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "bettingTabs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "market" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ImageShowcase" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkText" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "link" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tabletImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mobileImage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "FeaturedSport" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "thumbnailImageUrl",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "author" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "socialMedia",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "accountName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "publishedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstPublishedAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastPublishedAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subHeading" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hero" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "imageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "TaggedArticles" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkText" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cornerLinkUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tag" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hero" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "imageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "thumbnailImageUrl",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "author" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "socialMedia",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "accountName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "type",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "publishedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifiedDate",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "firstPublishedAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "lastPublishedAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subHeading" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "Promos_TaggedArticles",
                          },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taggedArticles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tag" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "hero" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "imageUrl",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailImageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "author",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "thumbnailUrl",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "socialMedia",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "accountName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "publishedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifiedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subHeading",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "promos" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "promos" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "buttonOne",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "text",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "buttonTwo",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "text",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "image",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "TaggedArticles_ScrollableOdds",
                          },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taggedArticles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tag" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "hero" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "imageUrl",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailImageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "author",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "thumbnailUrl",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "socialMedia",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "accountName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "publishedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifiedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subHeading",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scrollableOdds" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "markets" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "market",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "info" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "bottomLinkText",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "bottomLinkUrl",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "TaggedArticles_PlayerOddsTabs",
                          },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "target" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taggedArticles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tag" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "hero" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "imageUrl",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "thumbnailImageUrl",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "author",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "thumbnailUrl",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "socialMedia",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "accountName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "type",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "url",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "publishedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifiedDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastPublishedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subHeading",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "playerOddsTabs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "target" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cornerLinkUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "bettingTabs",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "market",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTentpoleEventQuery,
  GetTentpoleEventQueryVariables
>;
export const Tournament_TournamentGamesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Tournament_TournamentGames" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "teamGroupAlias" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tournament_TournamentGames" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "getUpcomingTournamentGames" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "teamGroupAlias" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "teamGroupAlias" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awayTeam" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "istTeamGroupAlias",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "istTeamGroupName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "teamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "colors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primary",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "secondary",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "conference" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "division" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "headCoach" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "bitmap",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "market" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shortName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "broadcasts" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "awayBroadcastInfo",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locale" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "network" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "homeBroadcastInfo",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locale" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "network" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "internationalBroadcastInfo",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locale" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "network" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "nationalBroadcastInfo",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "channel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locale" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "network" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameDateTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gameOdds" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "away" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "oddsDetails",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "moneyLine",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "spread",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "total",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "teamCode" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "home" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "teamCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "oddsDetails",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "moneyLine",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "spread",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "total",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "odd",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scheduledTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "homeTeam" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "istTeamGroupAlias",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "istTeamGroupName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "teamMeta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "colors" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "primary",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "secondary",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "conference" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "division" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "headCoach" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "bitmap",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "svg" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "market" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shortName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "venue" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "zip" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Tournament_TournamentGamesQuery,
  Tournament_TournamentGamesQueryVariables
>;
export const Tournament_GetTournamentPlayerPropsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Tournament_GetTournamentPlayerProps" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "tournament_GetTournamentPlayerProps",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "contentText" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "events" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "markets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contentText" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "eligibleForSameGameParlay",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalMarketId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "marketTypeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selections" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "betUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "contentText",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "handicap" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "odds" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runnerName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "selectionId",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "playerImage" } },
                { kind: "Field", name: { kind: "Name", value: "playerName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "playerPosition" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Tournament_GetTournamentPlayerPropsQuery,
  Tournament_GetTournamentPlayerPropsQueryVariables
>;
export const GetTrackInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTrackInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trackCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HS_PastTracks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "trackCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "trackCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "imageLink" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTrackInfoQuery, GetTrackInfoQueryVariables>;
export const GetTrackRacesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTrackRaces" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "trackCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "HR_Track" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "trackCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "races" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTrackRacesQuery, GetTrackRacesQueryVariables>;
