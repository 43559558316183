import { graphql } from '../gql';

export default graphql(`query getTrackRaces($trackCode: String!, $profile: String!) {
    HR_Track(profile: $profile, code: $trackCode) {
      races {
        number
        postTime
        status {
          code
          __typename
        }
        __typename
      }
      __typename
    }
  }
  `);
