import { graphql } from '../gql';

export default graphql(`query getTrackInfo(
    $profile: String!
    $trackCode: String!
  ) {
    HS_PastTracks(profile: $profile, trackCode: $trackCode) {
      code
      name
      location {
          city
        state
        country
      }
      imageLink
    }
  }`);
