import "twin.macro";
import NextLink from "next/link";
import { MouseEventHandler } from "react";

type LinkProps = {
  children: React.ReactNode;
  href: string;
  next: boolean;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const Link = ({
  children,
  href,
  next,
  target = "_blank",
  onClick,
}: LinkProps) => {
  const internalLink = (
    <NextLink onClick={onClick} prefetch={false} href={href} tw="text-blue-d1">
      {children}
    </NextLink>
  );

  const externalLink = (
    <a
      href={href}
      onClick={onClick}
      target={target}
      rel="noreferrer"
      tw="text-blue-d1"
    >
      {children}
    </a>
  );

  return next ? internalLink : externalLink;
};

export default Link;
