import { Maybe, NestedLink } from "@/graphql/gql/graphql";
import { filterEmpty } from "@/utils/index";
import cx from "classnames";
import Styles from "./MobileNavigation.module.scss";
import MobileMenuItem from "./MobileMenuItem";

type MobileNavigationProps = {
  menuItems: Maybe<NestedLink>[];
  isMenuOpen: boolean;
  activeSport: string;
  setActive: (sport: string) => void;
};

const MobileNavigation = ({
  menuItems,
  isMenuOpen,
  activeSport,
  setActive,
}: MobileNavigationProps) => (
  <div className={cx(Styles.container, isMenuOpen ? Styles.menuIsOpen : "")}>
    <ul className={Styles.Sportslist}>
      {filterEmpty(menuItems).map((menuItem, index) => (
        <MobileMenuItem
          key={menuItem.url}
          {...menuItem}
          setActive={setActive}
          activeSport={activeSport}
          index={index}
        />
      ))}
    </ul>
  </div>
);

export default MobileNavigation;
