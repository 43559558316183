import { useQuery } from "@tanstack/react-query";
import { GetSiteSettingsQuery } from "@/graphql/gql/graphql";
import { getSiteSettings } from "../api";

const useSiteSettings = () => {
  const { data, isLoading, isError } = useQuery<GetSiteSettingsQuery>({
    queryFn: getSiteSettings,
    queryKey: ["getSiteSettings"],
  });

  return { data, isError, isLoading };
};

export default useSiteSettings;
