import { graphql } from "../gql";

export default graphql(`
  query getSiteSettings {
    getSiteSettings {
      sbkPromoBanner {
        hidePromoBanner
        desktopButtonText
        desktopLink
        mobileButtonText
        mobileLink
      }
    }
  }
`);
