import Image from "next/image";

const ChevronsTexture = () => (
  <Image
    src="https://cdn.sanity.io/images/pbwem9y5/ch_production/e0b0a425fa82026919c0e289dd2624c153536b20-485x66.png"
    alt="Chevrons Texture"
    width={485}
    height={66}
    priority
  />
);

export default ChevronsTexture;
