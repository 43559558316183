import { graphql } from '../gql';

export default graphql(`
query getUpcomingRaces($profile: String!, $current: Int!, $results: Int!) {
  HR_Races(profile: $profile, filters: [{hasFreePick: true, isOpen: true, order: 1}], sorts: [{byPostTime: ASC, order: 2}], pages: [{current: $current, results: $results, order: 3}]) {
    number
    numRunners
    type {
      name
    }
    purse
    distance
    raceClass {
      name
    }
    surface {
      name
      defaultCondition
    }
    track {
      code
      name
      trackDataSource
      __typename
    }
    status {
      code
      name
      __typename
    }
    raceDate
    postTime
    bettingInterests(filters: [{hasFreePick: true, order: 1}]) {
      saddleColor
      numberColor
      favorite
      currentOdds {
        numerator
        denominator
        __typename
      }
      runners(filters: [{hasFreePick: true, order: 1}]) {
        runnerId
        horseName
        scratched
        trainer
        jockey
        handicapping {
          freePick {
            number
            __typename
          }
          __typename
        }
        timeform {
          silkUrl
          silkUrlSvg
          freePick {
            number
            info
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
  `);
