import { graphql } from '../../gql/gql';

const getSupportedSportsFilters = graphql(`
  query GetSupportedSportsFilters {
    getSupportedSports {
      teams {
        abbreviation
        name
        numberFireId
      }
      positions
      contentSport
    }
  }
`);

export default getSupportedSportsFilters;
