import { FdrCrossSellHeader, NestedLink } from "@fanduel/og-fe-library";
import { useNavMenus } from "@/utils/hooks/useMenus";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { useEffect, useRef, useState } from "react";
import { Maybe } from "@/graphql/gql/graphql";
import { filterEmpty } from "@/utils/index";
import ampAppendSbkUrl from "@/utils/amplitude";
import Styles from "./HeaderContainer.module.scss";
import MainNavigation from "../MainNavigation/MainNavigation";
import MobileContainer from "../MobileContainer/MobileContainer";

interface HeaderContainerProps {
  sport?: string;
  sportSlug?: string;
}

const HeaderContainer = ({ sport, sportSlug }: HeaderContainerProps) => {
  const { menuData, isLoading } = useNavMenus();
  const [crossSellMenu, setCrossSellMenu] = useState<Maybe<NestedLink>[]>([]);
  const { sessionId, deviceId } = useAmplitude();
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current && sessionId && deviceId) {
      hasRun.current = true;
      const menuItemsData: NestedLink[] = menuData.header?.menuItems
        ? filterEmpty(menuData.header.menuItems).map((link) => ({
            ...link,
            url: ampAppendSbkUrl(link?.url, sessionId, deviceId),
          }))
        : [];
      setCrossSellMenu(menuItemsData);
    } else setCrossSellMenu(menuData.header?.menuItems ?? []);
  }, [menuData.header?.menuItems, sessionId, deviceId, menuData.header]);

  const contentNav = menuData?.["Research Menu"];
  const mobile = menuData?.["Research Mobile"];

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      <div className={Styles.placeholder} />
      <div className={Styles.container}>
        <div className={Styles.scrollContainer}>
          <FdrCrossSellHeader menuItems={crossSellMenu} />
        </div>
        <div className={Styles.researchNav}>
          <MainNavigation
            sport={sport}
            sportSlug={sportSlug}
            menuItems={contentNav?.menuItems ?? []}
          />
          <MobileContainer menuItems={mobile?.menuItems ?? []} />
        </div>
      </div>
    </>
  );
};
export default HeaderContainer;
