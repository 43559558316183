export const colorBlueL5 = '#EAF4FF';
export const colorBlueL1 = '#2B90FF';
export const colorBlue = '#0070EB';
export const colorBlueD1 = '#005FC8';
export const colorBlueSvg = '#1381E0';
export const colorNavyL5 = '#EDEFF2';
export const colorNavyL4 = '#C6D3E1';
export const colorNavyL3 = '#9CB1CB';
export const colorNavyL2 = '#819CBB';
export const colorNavyL1 = '#6682A2';
export const colorNavy = '#184C8B';
export const colorNavyD1 = '#14417A';
export const colorNavyD2 = '#103469';
export const colorNavyD3 = '#05285A';
export const colorNavyD4 = '#011638';
export const colorNavyD5 = '#1F375B';
export const colorGreyL5 = '#F7FBFF';
export const colorGreyL4 = '#EAF0F6';
export const colorGreyL3 = '#C9D1DB';
export const colorGreyL2 = '#B0B7BF';
export const colorGreyL1 = '#969DA3';
export const colorGrey = '#7F848A';
export const colorGreyD1 = '#6A6F73';
export const colorGreyD2 = '#2D2F30';
export const colorGreyD3 = '#1D1E1F';
export const colorGreyD4 = '#131314';
export const colorGreyMid = '#CFD6DB';
export const colorBlack = '#0A0A0A';
export const colorWhite = '#ffffff';
