import { graphql } from '../gql';

const getArticleInfoByDateRangeQuery = graphql(`
query GetArticlesByDateRange($filter: ArticleSearchInput!) {
  getArticles(filter: $filter) {
    articles {
      entity {
        lastPublishedAt
        slug
        title
        sport{
          name
        }
      }
    }
  }
}
`);

export default getArticleInfoByDateRangeQuery;
