import { graphql } from '../gql';

const getArticleBySlugQuery = graphql(`
query GetArticleBySlug(
  $slug: String!,
  $contentStatus: StatusSelector,
  $isPreview: Boolean
) {
  getSingleArticle(
    slug: $slug,
    contentStatus: $contentStatus,
    isPreview: $isPreview
  ) {
    hideTopNewsletter
    hideBottomNewsletter
    firstPublishedAt
    publishedDate
    slug
    id
    title
    body
    description
    dataskriveId
    seo{
      image {
        url
      }
      openGraphTag {
        image {
          url
        }
        title
      }
      description
      noIndex
      canonicalUrl
      title
    }
    sport {
      name
      slug
      logo {
        url
      }
    }
    thumbnailImageUrl {
      url
    }
    hero {
      imageUrl {
        url
      }
      jwPlayerId
      jwPlayerPlayerId
    }
    author {
      id
      name
      thumbnailUrl
      socialMedia {
        accountName
        type
        url
      }
    }
    tags {
      id
      name
      type
    }
  }
}
`);

export default getArticleBySlugQuery;
