import { graphql } from '../gql';

const getSubCategoryQuery = graphql(`query GetSubCategoryQuery($input: SubCategoryInput!, $filter: ArticleSearchInput) {
  getSubCategory(input: $input) {
    text
    title
    image {
      url
    }
    sport {
      name
    }
    articles(filter: $filter) {
      entity {
        id
        title
        sport {
          name
          slug
        }
        url
        thumbnailImageUrl {
          url
        }
        tags {
          name
          key
          id
          type
        }
        author {
          id
          name
        }
        publishedDate
        description
      }
      cursor
    }
    seo {
      canonicalUrl
      title
      keywords
      synonyms
      description
      noIndex
      image {
        url
      }
      openGraphTag {
        title
        type
        image {
          url
        }
      }
    }
  }
}

`);

export default getSubCategoryQuery;