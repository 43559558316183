import { graphql } from '../../gql/gql';

const getSports = graphql(`
  query GetSupportedSports($isPreview: Boolean, $featured: Boolean) {
    getSupportedSports(isPreview: $isPreview, featured: $featured) {
      name
      hasLiveScoring
      hasPlayerUpdates
      showAuthors
      contentSport
      slug
      seo {
        description
        canonicalUrl
        title
        image {
          url
        }
        noIndex
      }
    }
    getSupportedSportsFilters: getSupportedSports {
      teams {
        abbreviation
        name
        numberFireId
      }
      positions
      contentSport
    }
    getContentFilters {
      shortFormNewsType {
        enum
        name
      }
    }
  }
`);

export default getSports;
