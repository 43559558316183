import { graphql } from "../gql";

export const getPromotionsQuery = graphql(`
  query getPromotions($input: PromotionsInput) {
    getPromotions(input: $input) {
      title
      image
      detailsUrl
      ctaUrl
      ctaText
      promoCode
      name
    }
  }
`);
