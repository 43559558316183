import Image from "next/image";

const PlayerImg = () => (
  <Image
    src="https://cdn.sanity.io/images/pbwem9y5/ch_production/9ea2cf5a2cf74c2d84114cfa340079163402773b-4006x2048.png"
    alt="Player Image"
    width={151}
    height={66}
  />
);

export default PlayerImg;
