import { graphql } from '../gql';

export default graphql(`query getHorseRacesByTrack($profile: String!, $trackCode: [String]) {
  HR_Races(
    profile: $profile
    filters: [
      { hasFreePick: true, isOpen: true, order: 1, trackCode: $trackCode }
    ]
    sorts: [{ byPostTime: ASC, order: 2 }]
  ) {
    number
    track {
      code
      name
      trackDataSource
    }
    status {
      code
      name
    }
    raceDate
    postTime
    bettingInterests(filters: [{ hasFreePick: true, order: 1 }]) {
      saddleColor
      numberColor
      favorite
      currentOdds {
        numerator
        denominator
        __typename
      }
      runners(filters: [{ hasFreePick: true, order: 1 }]) {
        runnerId
        horseName
        scratched
        trainer
        jockey
        handicapping {
          freePick {
            number
            __typename
          }
          __typename
        }
        timeform {
          silkUrl
          silkUrlSvg
          freePick {
            number
            info
          }
        }
      }
    }
  }
}
`);
