import { graphql } from '../gql';

export default graphql(`
query GetRunnerStats(
  $runnerName: String!
  $profile: String!
) {
  HS_RunnerStats(
    runnerName: $runnerName
    profile: $profile
  ) {
    numberRaces
    numberRacesWon
    numberRacesTop3
  }
}`);
