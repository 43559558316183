import { NAV_LINK_CLICKED } from "@/constants/amplitude";
import { Menu } from "@/graphql/gql/graphql";
import { GeneralMenu, GeneralCross } from "@fanduel/formation-react-icons";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Image from "@/components/Image";
import Styles from "./MobileContainer.module.scss";
import MobileNavigation from "../MobileNavigation";

const MobileContainer = ({ menuItems }: Pick<Menu, "menuItems">) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeSport, setActive] = useState("");
  const router = useRouter();
  const { logAmplitudeEvent } = useAmplitude();
  useEffect(() => {
    setMenuOpen(false);
    setActive("");
  }, [router.asPath]);
  const [home, ...menu] = menuItems;
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <button
          aria-label="Nav Menu"
          onClick={() => setMenuOpen((currMenuState) => !currMenuState)}
          type="button"
        >
          {isMenuOpen ? (
            <GeneralCross
              data-testid="mobile-cross"
              height={34}
              width={34}
              color="coreGreyD3"
            />
          ) : (
            <GeneralMenu
              data-testid="mobile-menu"
              height={34}
              width={34}
              color="coreGreyD3"
            />
          )}
        </button>
        <Link
          href="/"
          aria-label="home"
          onClick={() =>
            logAmplitudeEvent(NAV_LINK_CLICKED, {
              "Link Text": "fd_logo",
              "Link URL": "/",
              Module: "navigation_menu",
              Menu: "mobile_nav",
            })
          }
        >
          <Image
            className={Styles.mainImage}
            height={30}
            width={105}
            alt="Logo"
            src={home?.imageUrl ?? ""}
          />
        </Link>
        <div className={Styles.placeholder} />
      </div>
      <MobileNavigation
        menuItems={menu}
        isMenuOpen={isMenuOpen}
        activeSport={activeSport}
        setActive={setActive}
      />
    </div>
  );
};
export default MobileContainer;
