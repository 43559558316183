import { Menu } from "@/graphql/gql/graphql";
import { filterEmpty } from "@/utils/index";
import { useMemo } from "react";

import Styles from "./MainNavigation.module.scss";
import MenuItem from "./MenuItem/MenuItem";

type MainNavigationProps = Pick<Menu, "menuItems"> & {
  sport?: string;
  sportSlug?: string;
};

const MainNavigation = ({
  menuItems,
  sport,
  sportSlug,
}: MainNavigationProps) => {
  const links = useMemo(() => {
    const menuLinks = filterEmpty(menuItems);
    const [more, ...menu] = [...menuLinks].reverse();
    const sportMenu = more?.children?.find(
      (item) => item?.text.toLocaleLowerCase() === sport?.toLocaleLowerCase()
    );
    if (sportMenu) {
      return [...menu.reverse(), sportMenu, more];
    }
    return menuLinks;
  }, [menuItems, sport]);

  return (
    <div data-testid="main-navigation" className={Styles.container}>
      {links.map((menuLink) => (
        <MenuItem
          key={menuLink.text}
          sport={sport}
          menuLink={menuLink}
          sportSlug={sportSlug}
        />
      ))}
    </div>
  );
};
export default MainNavigation;
