import { graphql } from '../gql';

export default graphql(`
query getHorseTrackList($profile: String!) {
    HS_PastTracks(profile: $profile, filter: { isGreyhound: false }) {
      code
      name
      location {
        state
        country
      }
    }
  }`);
