const ChevronsTextureMobile = () => (
  <svg
    width="272"
    height="94"
    viewBox="0 0 272 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M1.7715 -6.22546C-0.590498 -3.86347 -0.590498 -0.0237145 1.7715 2.33828L45.9347 46.5015L1.7715 90.6648C-0.590498 93.0268 -0.590498 96.8665 1.7715 99.2285C4.13349 101.591 7.97325 101.591 10.3352 99.2285L58.7864 50.7773C59.9735 49.5903 60.5549 48.0398 60.5549 46.4894C60.5549 44.939 59.9613 43.3885 58.7864 42.2015L10.3352 -6.24969C7.97325 -8.58746 4.1456 -8.58746 1.7715 -6.22546Z"
      fill="#103469"
    />
    <path
      d="M74.9982 -6.22546C72.6362 -3.86347 72.6362 -0.0237145 74.9982 2.33828L119.161 46.5015L74.9982 90.6648C72.6362 93.0268 72.6362 96.8665 74.9982 99.2285C77.3602 101.591 81.1999 101.591 83.5619 99.2285L132.013 50.7773C133.2 49.5903 133.782 48.0398 133.782 46.4894C133.782 44.939 133.188 43.3885 132.013 42.2015L83.5619 -6.24969C81.1999 -8.58746 77.3723 -8.58746 74.9982 -6.22546Z"
      fill="#103469"
    />
    <path
      d="M148.225 -6.22546C145.863 -3.86347 145.863 -0.0237145 148.225 2.33828L192.388 46.5015L148.225 90.6648C145.863 93.0268 145.863 96.8665 148.225 99.2285C150.587 101.591 154.427 101.591 156.789 99.2285L205.24 50.7773C206.427 49.5903 207.008 48.0398 207.008 46.4894C207.008 44.939 206.415 43.3885 205.24 42.2015L156.789 -6.24969C154.427 -8.58746 150.599 -8.58746 148.225 -6.22546Z"
      fill="#103469"
    />
    <path
      d="M221.452 -6.22546C219.09 -3.86347 219.09 -0.0237145 221.452 2.33828L265.615 46.5015L221.452 90.6648C219.09 93.0268 219.09 96.8665 221.452 99.2285C223.814 101.591 227.654 101.591 230.016 99.2285L278.467 50.7773C279.654 49.5903 280.235 48.0398 280.235 46.4894C280.235 44.939 279.642 43.3885 278.467 42.2015L230.016 -6.24969C227.654 -8.58746 223.826 -8.58746 221.452 -6.22546Z"
      fill="#103469"
    />
  </svg>
);

export default ChevronsTextureMobile;
