// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SEO {
  export const SITE_NAME = "FanDuel Research";
  export const FANDUEL_URL = "https://www.fanduel.com";
  export const FANDUEL_URL_RESEARCH = `${FANDUEL_URL}/research`;
  export const RESEARCH_PAGE_TITLE =
    "Sports Betting and DFS News with Analysis";
  export const SEO_DESCRIPTION =
    "Get the latest sports betting and DFS news from the experts at FanDuel Research. Join our community of sports enthusiasts and take your game to the next level.";
  export const TWITTERHANDLE = "@FanDuel";
  export const TRACK_BASE_URL =
    "https://www.fanduel.com/research/horse-racing/tracks";
  export const HR_BETTING_TERMS_URL =
    "https://www.fanduel.com/research/horse-racing/horse-betting-terms";
  export const UPCOMING_RACES_URL =
    "https://www.fanduel.com/research/horse-racing/picks";
  export const CONSENSUS_PICKS_URL =
    "https://fanduel.com/research/horse-racing/consensus-picks-tool";

  // functions
  export const sportTitle = (sport: string | undefined) => `${sport} Content`;
  export const sportDes = (sport: string | undefined) =>
    `Home of ${sport} content hub`;
  export const sportNews = (sport: string | undefined) => `${sport} News`;

  export const getTrackDetailUrl = (trackCode: string, trackName: string) => {
    const baseSegment = "/research/horse-racing";
    const formattedTrackName = trackName
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
    return `${baseSegment}/picks/${trackCode}/${formattedTrackName}`;
  };

  export const getTrackListUrl = (
    trackCode: string,
    trackName: string,
    raceNumber: number
  ) => {
    const baseSegment = "/research/horse-racing";
    const formattedTrackName = trackName
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
    return `${baseSegment}/picks/${trackCode}/${formattedTrackName}?race=${raceNumber}`;
  };
}

export const PUBLISHER_LOGO =
  "https://www.fanduel.com/research/_next/image?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fpbwem9y5%2Fch_production%2Fc8e986e90721bd1b9f283a76ac2c19d25ce745b3-105x30.svg&w=256&q=100";
