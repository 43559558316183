import { ReactElement, useCallback } from "react";
import { Button } from "@fanduel/og-fe-library";
import ErrorVan from "@/components/svg/ErrorVan";
import styles from "./ErrorComponent.module.scss";

type ErrorComponentProps = {
  image?: React.ReactNode;
  headline?: string;
  subtext?: string | ReactElement;
  showCta?: boolean;
  ctaButtonLabel?: string;
  ctaButtonOnClick?: (arg?: unknown) => void;
};

const ErrorComponent = ({
  image = <ErrorVan />,
  headline = "Content failed to load",
  subtext = "Please check back later.",
  ctaButtonLabel = "Refresh",
  ctaButtonOnClick,
  showCta = true,
}: ErrorComponentProps) => {
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <div data-testid="error-component" className={styles.container}>
      {image}
      <div className={styles.headline}>{headline}</div>
      <div className={styles.subtext}>{subtext}</div>
      <div className={styles.cta}>
        {showCta && (
          <Button
            ariaLabelText={ctaButtonLabel}
            buttonText={ctaButtonLabel}
            buttonStyle="Outlined"
            rightIconName="icons.refresh"
            iconColor="colorBaseBlueDarken2"
            className={styles.ctaButton}
            onClick={ctaButtonOnClick || reload}
          />
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
